import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { EVENTS_FLOOR_MAP_SCHEMA } from "schemas";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Events/EventsManagement/FloorMap/table.config";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import FloorMapEvents from "pages/Events/EventsManagement/FloorMap";
import { Flex, Spinner } from "@chakra-ui/react";
import { useGetAllEventSectorsByEventId } from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import Actions from "components/Stepper/Actions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import { useSetCompletedEventsStatus } from "graphql/hooks/useMutations";
import { useEventContext } from "hooks/useEventContext.hook";
import { useState } from "react";
import { useToastContext } from "hooks/useToastContext";
import { transformEventsFloormapSortState } from "connectors";

function FloorMapContainerEvents() {
  const [sortState, setSortState] = useState();

  const navigate = useNavigate();
  const { getEventId } = useEventContext();

  const methods = useForm({
    defaultValues: {
      search: "",
    },
    resolver: yupResolver(EVENTS_FLOOR_MAP_SCHEMA),
  });

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 7 } });

  const search = methods.watch("search");

  const { data: allSectorsData, loading: sectorsLoading } =
    useGetAllEventSectorsByEventId({
      variables: {
        options: {
          eventId: getEventId(),
          limit: queryPageSize,
          offset: offsetData,
          search: useDebounce(search) || null,
          sort: transformEventsFloormapSortState(sortState),
        },
      },
    });

  const { showToast } = useToastContext();

  const [setCompletedEvents] = useSetCompletedEventsStatus();

  const completeStatus = () => {
    setCompletedEvents({
      variables: {
        id: getEventId(),
        isCompleted: true,
      },
      onCompleted: () => {
        navigate(PATHS.events);
        showToast({
          description: "Successfully completed!",
        });
      },
      onError: (error) => {
        console.log(error?.message);
      },
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <TableProvider
          options={{
            columns: COLUMNS,
            data: allSectorsData?.sectors?.rows || [],
            initialState: {
              pageSize: queryPageSize,
              pageIndex: queryPageIndex,
            },
            pageCount: pagesQuantity,
            manualPagination: true,
            manualSortBy: true,
          }}
          dataCount={allSectorsData?.sectors?.count}
          onPageChanged={onPageChanged}
          onPageSizeChanged={onPageSizeChanged}
          onTotalCountChanged={onTotalCountChanged}
          onSetSortState={setSortState}
        >
          {sectorsLoading ? (
            <Flex alignItems="center" justify="center" h="100px">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <FloorMapEvents
              isEventId={getEventId()}
              hasData={allSectorsData?.sectors?.rows?.length}
            />
          )}
        </TableProvider>
      </FormProvider>
      <Actions
        handleNext={completeStatus}
        nextLabel="Finish"
        previousLabel="Back"
      />
    </>
  );
}

export default FloorMapContainerEvents;
