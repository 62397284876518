import { gql } from "@apollo/client";

export const GET_LANGUAGES = gql`
  query getLanguage {
    getLanguage {
      id
      code
      name
    }
  }
`;
