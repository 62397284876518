export const transformUserAdminListSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    type: state.desc ? "DESC" : "ASC",
    field: "name",
  };
};
