export const getDefaultCurrency = (currencyId, currencies = []) => {
  let defaultCurrency = null;

  currencies?.forEach((currency) => {
    if (currency.id === currencyId) {
      defaultCurrency = { value: currencyId, label: currency.code };
    }
  });
  return defaultCurrency;
};
