const tagWrapper = {
  width: "70px",
  height: "24px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "grey.700",
  borderRadius: "12px",
};

const tagWrapperOpen = {
  width: "70px",
  height: "24px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "blue.500",
  borderRadius: "12px",
};

const tagItem = {
  color: "grey.700",
  textAlign: "center",
  lineHeight: "22px",
};

const tagItemOpen = {
  color: "blue.500",
  textAlign: "center",
  lineHeight: "22px",
};

export default { tagWrapper, tagWrapperOpen, tagItem, tagItemOpen };
