import { gql } from "@apollo/client";

export const GET_ALL_COMMENTS = gql`
  query GetAllComments($params: GetAllComments) {
    getAllComments(params: $params) {
      count
      rows {
        id
        text
        parent {
          __typename
          ... on Article {
            articleId: id
            title
            url
          }
          ... on Post {
            postId: id
            title
          }
          ... on Event {
            eventId: id
            title
            url
          }
          ... on Attraction {
            attractionId: id
            title
            url
          }
          ... on Offer {
            offerId: id
            title
            url
          }
          ... on Venue {
            venueId: id
            title
            url
          }
        }
        modelName
        commentAuthor {
          ... on Company {
            __typename
            id
            companyName
            image
          }
          ... on User {
            __typename
            id
            firstName
            lastName
            photo
            company {
              companyName
            }
          }
        }
        createdAt
      }
    }
  }
`;
