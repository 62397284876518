export const transformAddNewVenueMenu = (formData, venueId, languages) => {
  if (formData) {
    const multiLanguageContent = languages.map((language) => ({
      languageId: Number(language?.id),
      name: formData.name?.[language.code],
      description: formData.description?.[language.code],
      shortDescription: formData.short_description?.[language.code],
    }));

    const imageData =
      formData.image && Object.keys(formData.image).length > 1
        ? { image: formData.image }
        : null;

    let fullMenuData;
    if (formData.file && formData.file.size) {
      fullMenuData = {
        fullMenu: formData.file,
      };
    }

    return {
      ...fullMenuData,
      ...imageData,
      venueId,
      content: multiLanguageContent,
    };
  }
  return {};
};
