import React from "react";
import { Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Logo(prop) {
  const { width, height, src } = prop;

  return (
    <Link to="/">
      <Image w={width} height={height} src={src} />
    </Link>
  );
}
