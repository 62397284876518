export const transformAddCompanyData = (data) => {
  if (data) {
    return {
      companyName: data.companyName,
      image: data.companyLogo,
      cityId: Number(data.city?.value),
      adress: data.address,
      licenceNumber: Number(data.license),
      photosLicense: data.license_photo,
    };
  }

  return {};
};
