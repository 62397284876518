import { Icon } from "@chakra-ui/react";

export default function DeleteOptionIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path d="M7.77783 7.8573H8.88867V14.2858H7.77783V7.8573Z" />
      <path d="M11.1108 7.8573H12.2217V14.2858H11.1108V7.8573Z" />
      <path d="M3.3335 4.6431V5.71424H4.44433V16.4289C4.44433 16.5696 4.47309 16.709 4.52897 16.839C4.58484 16.969 4.66674 17.0871 4.76998 17.1866C4.87322 17.286 4.99577 17.3649 5.13064 17.4187C5.26551 17.4725 5.41005 17.5001 5.556 17.5H14.4443C14.7389 17.5 15.0215 17.3872 15.2298 17.1863C15.4381 16.9854 15.5552 16.713 15.5552 16.4289V5.71424H16.6668V4.6431H3.3335ZM5.556 16.4289V5.71424H14.4443V16.4289H5.556Z" />
      <path d="M7.77783 2.5H12.222V3.57114H7.77783V2.5Z" />
    </Icon>
  );
}
