import { Icon } from "@chakra-ui/react";

export default function SortedDescIcon({ fill = "#CACACA", ...props }) {
  return (
    <Icon viewBox="0 0 8 5" {...props}>
      <path
        d="M4.00007 4.45454L0.818249 0.636363L7.18188 0.636363L4.00007 4.45454Z"
        fill={fill}
      />
    </Icon>
  );
}
