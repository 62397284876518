import { gql } from "@apollo/client";

export const SET_EVENT_SECTOR_ROWS_COUNT = gql`
  mutation setEventSectorRowsCount(
    $eventSectorId: ID!
    $rowsCount: Int!
    $EventSectorRowPlaceCount: Int!
  ) {
    setEventSectorRowsCount(
      eventSectorId: $eventSectorId
      rowsCount: $rowsCount
      EventSectorRowPlaceCount: $EventSectorRowPlaceCount
    ) {
      status
    }
  }
`;
