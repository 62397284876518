import { gql } from "@apollo/client";

export const GET_ALL_PERMISION_ROLE = gql`
  query getAllPermisionRole {
    getAllPermisionRole {
      id
      name
      visibility
      scopes {
        id
        scope
      }
    }
  }
`;
