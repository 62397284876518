import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      firstName
      lastName
      id
      firstName
      lastName
      email
      phone
      gender
      status
      photo
      languageId
      currencyId
      cityId
      roleId
      address
      description
      companyId
      role {
        id
        name
        visibility
        scopes {
          id
          scope
        }
      }
      company {
        id
        companyName
      }
    }
  }
`;
