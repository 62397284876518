export const transformAddUserData = (data) => {
  if (data) {
    const reqObj = {
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      password: data.password,
      email: data.email,
      phone: data.phone,
      cityId: Number(data.city?.value),
      roleId: Number(data.userRole?.value),
      sendToEmail: data.sendToEmail,
    };

    if (data.photo?.preview && data.photo?.path) {
      Object.assign(reqObj, { photo: data.photo });
    }

    return reqObj;
  }

  return {};
};
