import { gql } from "@apollo/client";

export const GET_ALL_INFLUENCER_OPTIONS = gql`
  query GetAllInfluencerOptions {
    getAllInfluencerOptions {
      influencerLevel
      influencerStatus
    }
  }
`;
