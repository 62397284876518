import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import OffersContainerMain from "containers/offers/offers.container";
import ModalButton from "components/ModalButton";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function OffersMainPage() {
  const { accessCreateOffers, accessViewOffers } = getAccessScopesControl();
  const button = (
    <ModalButton
      modalName="offerCreateEditModal"
      label="Add offer"
      ml={4}
      minW="146px"
      data={{ isEditMode: false }}
    />
  );
  return (
    <Box px={10} py={6} w="full">
      <PageHeader button={accessCreateOffers ? button : null} mb={6}>
        Offers
      </PageHeader>
      <OffersContainerMain accessViewOffers={accessViewOffers} />
    </Box>
  );
}
