import { useContext, useMemo } from "react";
import { useGetAllUserOptions } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetUserOptions = ({ skip } = {}) => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, userPermisionRoles, userGenders } =
    useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return (
      !isAuth || !!userPermisionRoles.length || !!userGenders.length || skip
    );
  }, [isAuth, userGenders.length, userPermisionRoles.length, skip]);

  useGetAllUserOptions({
    onCompleted: (response) => {
      updateOptionsState({
        userPermisionRoles: response?.getAllUserOptions?.roles,
        userGenders: response?.getAllUserOptions?.userGender,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetUserOptions;
