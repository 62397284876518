export const transformCityOptions = (selectedCountry, data = []) => {
  if (data && selectedCountry) {
    const country = data?.find(
      (currentCountry) => currentCountry?.id === selectedCountry?.value
    );
    return (
      country?.Cities?.map((option) => ({
        value: option.id,
        label: option.name,
      })) || []
    );
  }
  return [];
};
