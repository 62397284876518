const toggleItem = {
  borderRadius: "4xl",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  minW: "65px",
  minH: "42px",
};

export default {
  toggleItem,
};
