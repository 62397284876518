import { createContext } from "react";

const defaultValue = {
  nextStep: () => {},
  prevStep: () => {},
  reset: () => {},
  activeStep: 1,
};

export default createContext(defaultValue);
