import { Box, Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { useModalContext } from "hooks/useModalContext";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { useUpdateEventSectorRow } from "graphql/hooks/useMutations";

function EditSectorRow() {
  const {
    modal: { sectorId, sectorName, seatsCount, sequenceNumber, rowId },
    closeModal: closeCurrentModal,
  } = useModalContext("editSectorRowModal");

  const { openModal: openSectorManagementModal } = useModalContext(
    "sectorManagementModal"
  );

  const methods = useForm({
    defaultValues: { seats_per_row: seatsCount },
  });

  const [updateRowPlaceCount] = useUpdateEventSectorRow();

  const backToSectorManagementModal = () => {
    methods.reset();
    openSectorManagementModal({ sectorId });
    closeCurrentModal();
  };

  const handleDone = () => {
    const seatsPerRow = methods.getValues("seats_per_row");

    updateRowPlaceCount({
      variables: {
        input: {
          id: rowId,
          placeCount: Number(seatsPerRow),
        },
      },
    });

    backToSectorManagementModal();
  };

  return (
    <FormProvider {...methods}>
      <VStack spacing="10" width="full">
        <Box width="full">
          <HStack spacing={2.5} mb={5} alignItems="flex-end">
            <Text>Sector:</Text>
            <Text size="modalContent">{sectorName}</Text>
          </HStack>

          <HStack spacing={2.5} mb={8} alignItems="flex-end">
            <Text>Row #:</Text>
            <Text size="modalContent">{sequenceNumber}</Text>
          </HStack>

          <TextFieldRHF
            id="seats_per_row"
            name="seats_per_row"
            inputType="text"
            label="Seats per row"
          />
        </Box>

        <Flex w="full" justifyContent="space-between" pb={7}>
          <Button
            variant="outline"
            minW="140px"
            onClick={backToSectorManagementModal}
          >
            Cancel
          </Button>
          <Button minW="140px" onClick={handleDone}>
            Done
          </Button>
        </Flex>
      </VStack>
    </FormProvider>
  );
}

export default EditSectorRow;
