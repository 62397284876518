import { Global } from "@emotion/react";

function Fonts() {
  return (
    <Global
      styles={`
        @font-face {
            font-weight: normal;
            font-family: FuturaPT Light;
            font-style: normal;
            src: url('/fonts/FuturaPT-Light.woff2') format('woff2'),
            url('/fonts/FuturaPT-Light.woff') format('woff');
            font-display: swap;
        }
        @font-face {
            font-weight: normal;
            font-family: FuturaPT Book;
            font-style: normal;
            src: url('/fonts/FuturaPT-Book.woff2') format('woff2'),
            url('/fonts/FuturaPT-Book.woff') format('woff');
            font-display: swap;
        }
        @font-face {
            font-weight: normal;
            font-family: FuturaPT Demi;
            font-style: normal;
            src: url('/fonts/FuturaPT-Demi.woff2') format('woff2'),
            url('/fonts/FuturaPT-Demi.woff') format('woff');
            font-display: swap;
        }
        @font-face {
            font-weight: normal;
            font-family: FuturaPT Medium;
            font-style: normal;
            src: url('/fonts/FuturaPT-Medium.woff2') format('woff2'),
            url('/fonts/FuturaPT-Medium.woff') format('woff');
            font-display: swap;
        }
        @font-face {
            font-weight: normal;
            font-family: FuturaPT Bold;
            font-style: normal;
            src: url('/fonts/FuturaPT-Bold.woff2') format('woff2'),
            url('/fonts/FuturaPT-Bold.woff') format('woff');
            font-display: swap;
        }`}
    />
  );
}

export default Fonts;
