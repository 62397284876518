export const transformEditCompanyData = (data) => {
  if (data) {
    const requestObj = {
      companyName: data.companyName,
      cityId: Number(data.city?.value),
      adress: data.address,
      licenceNumber: Number(data.license),
      photosLicense: data?.license_photo?.filter((photo) => !photo.id) || [],
    };

    if (data.companyLogo?.path || data.companyLogo === null) {
      Object.assign(requestObj, { image: data.companyLogo });
    }

    return requestObj;
  }
  return {};
};
