import { Box, Center, Divider, Spinner, Text, VStack } from "@chakra-ui/react";
import { styles } from "./chakra.styles";

export default function SearchResult({ loading, error, isEmpty, children }) {
  const renderContent = () => {
    if (loading) {
      return (
        <Center pos="absolute" top="0" right="0" left="0" bottom="0">
          <Spinner color="blue.500" size="md" />
        </Center>
      );
    }
    if (error) {
      return (
        <Center pos="absolute" top="0" right="0" left="0" bottom="0">
          <Text color="red.500">{error}</Text>
        </Center>
      );
    }
    if (isEmpty) {
      return (
        <Text fontSize="sm" mt="8px">
          Nothing found
        </Text>
      );
    }
    return (
      <VStack spacing={5} alignItems="flex-start" divider={<Divider />}>
        {children}
      </VStack>
    );
  };

  return <Box sx={styles}>{renderContent()}</Box>;
}
