import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Event",
    accessor: "event",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Special",
    accessor: "special_price",
  },
  {
    Header: "Quota",
    accessor: "quota",
  },
  {
    Header: "Sold",
    accessor: "sold",
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: () => {
      return <Options />;
    },
  },
];

// displayed rows
export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
