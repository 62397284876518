import { HStack, Text, VStack } from "@chakra-ui/react";
import Heading from "components/Heading";
import SearchResultItem from "../SearchResultItem";

export default function SearchResultList({ title, count, items }) {
  return (
    <VStack spacing={5} alignItems="flex-start">
      <HStack spacing={5}>
        <Heading size="menuItem">{title}</Heading>
        <Text color="dark.300">{count} results</Text>
      </HStack>
      {items.map((item) => (
        <SearchResultItem key={item.link} {...item} />
      ))}
    </VStack>
  );
}
