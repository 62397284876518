import { useContext } from "react";

import ModalContext from "contexts/modal/modal.context";

export const useModalContext = (modalName) => {
  const modalsValue = useContext(ModalContext);

  return {
    modal: modalsValue.modals[modalName],
    openModal: (data) => modalsValue.openModalHandler(modalName, data),
    closeModal: () => modalsValue.closeModalHandler(modalName),
    updateModalState: (data) => modalsValue.updateModalState(modalName, data),
  };
};
