import dayjs from "dayjs";
import { getDefaultLocation } from "utils/getDefaultLocation";

export const transformReceivedCompanyModalData = ({
  responseData,
  countries,
}) => {
  if (responseData) {
    return {
      id: responseData.id,
      addNewCompany: {
        companyName: responseData.company,
        companyLogo: responseData.image
          ? {
              preview: responseData?.image?.preview,
            }
          : null,
        country: getDefaultLocation(countries, responseData.cityId)
          .defaultCountry,
        city: getDefaultLocation(countries, responseData.cityId).defaultCity,
        address: responseData.adress,
        license: responseData.licenceNumber,
        license_photo: responseData.photos.map((photo) => {
          return {
            id: photo.id,
            preview: `${process.env.REACT_APP_API_URL}/${photo.photo}`,
          };
        }),
      },
      owners: responseData.owners.map((item) => {
        return {
          id: item.id,
          owner: item.ownerName,
          birthDate: dayjs(new Date(Number(item.dateBirth)))?.toDate() || "",
          share: item.companyShare,
          passport: item.passport,
          passportPhoto: item.photos.map((photo) => {
            return {
              id: photo.id,
              preview: `${process.env.REACT_APP_API_URL}/${photo.photo}`,
            };
          }),
        };
      }),
      usersCompany: responseData.users.map((item) => item.id),
    };
  }

  return {};
};
