import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getAllUsers($options: OptionsFind) {
    getAllUsers(options: $options) {
      count
      rows {
        id
        firstName
        lastName
        email
        phone
        gender
        status
        photo
        languageId
        currencyId
        cityId
        roleId
        role {
          id
          name
          visibility
          scopes {
            id
            scope
          }
        }
        address
        description
        companyId
        company {
          id
          adress
          companyName
          cityId
        }
      }
    }
  }
`;
