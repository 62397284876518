import { Box, Divider } from "@chakra-ui/react";
import Logo from "components/Logo";
import NavList from "components/NavList";
import SidebarLogoSrc from "assets/logo-white.png";
import { SIDEBAR_WIDTH } from "constants/constants";

export default function Sidebar() {
  return (
    <Box
      minW={SIDEBAR_WIDTH}
      h="100%"
      bg="black.500"
      borderRadius="0px 30px 30px 0px"
      overflow="hidden"
    >
      <Box pt="24px" pl="24px" pb="16px">
        <Logo width="100px" height="52px" src={SidebarLogoSrc} />
      </Box>
      <Divider borderColor="grey.500" opacity="0.15" />
      <NavList />
    </Box>
  );
}
