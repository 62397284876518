import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";
import AddNewEntertainmentWrapper from "components/Modals/AddNewEntertainment/AddNewEntertainmentWrapper";

export default function AddNewEntertainmentContainer() {
  const { modal, closeModal } = useModalContext("createNewEntertainmentModal");

  const initialValues = {};

  const methods = useForm({ defaultValues: initialValues });
  const name = methods.watch("name");

  const onSubmit = () => {
    methods.reset();
    closeModal();
  };

  const cancelHandler = () => {
    methods.reset();
    closeModal();
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>Add entertainment</Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <AddNewEntertainmentWrapper
            onSubmit={onSubmit}
            cancelHandler={cancelHandler}
            methods={methods}
            name={name}
            venueId={modal.venueId}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
