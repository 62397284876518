import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import CommentsListContainer from "containers/comments/commentsList.container";

function Comments() {
  return (
    <Box px={10} py={6} w="full">
      <PageHeader mb={6}>Comments</PageHeader>
      <CommentsListContainer />
    </Box>
  );
}
export default Comments;
