import { gql } from "@apollo/client";

export const SET_ORDER_STATIC_PAGE = gql`
  mutation setOrderPosition($id: Int!, $type: OrderType!) {
    setOrderPosition(id: $id, type: $type) {
      rows {
        id
        title
        status
        description
        order
        url
        tr {
          languageId
          title
          description
          content
        }
        content
        staticPageSEO {
          id
          languageId
          metaTitle
          metaDescription
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;
