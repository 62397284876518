import { Icon } from "@chakra-ui/react";

export default function CommentsIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M8 2h8c3.309 0 6 2.691 6 6v13a1 1 0 0 1-1 1H8c-3.309 0-6-2.691-6-6V8c0-3.309 2.691-6 6-6ZM4 16c0 2.206 1.794 4 4 4h12V8c0-2.206-1.794-4-4-4H8C5.794 4 4 5.794 4 8v8Z" />
      <path d="M7 9h10v2H7V9Zm0 4h7v2H7v-2Z" />
    </Icon>
  );
}
