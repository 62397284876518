import {
  EditOptionIcon,
  DeleteOptionIcon,
  EyeOpenIcon,
} from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useDeleteArticle } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { useToastContext } from "hooks/useToastContext";
import { previewUnpublishedEntity } from "utils/previewUnpublishedEntity";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ article }) {
  const { showToast } = useToastContext();
  const { openModal: openCreateModal } = useModalContext(
    "articleCreateEditModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessUpdateArticles, accessDeleteArticles } =
    getAccessScopesControl();

  const editArticle = () => {
    openCreateModal({
      editArticle: article,
      isEditMode: true,
    });
  };

  const [removeArticle] = useDeleteArticle({
    variables: {
      id: article.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The article has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: article.title,
      type: "article",
      onDelete: removeArticle,
    });
  };

  const openPreview = () => {
    previewUnpublishedEntity("articles", article?.url);
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EyeOpenIcon sx={CHS.eyeIcon} />}
        onMenuItemClick={openPreview}
      >
        Preview
      </CustomMenuItem>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={editArticle}
        isDisabled={Boolean(!accessUpdateArticles)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteArticles)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
