import { ACCESS_SCOPES } from "constants/constants";
import { useOptionsContext } from "hooks/useOptionsContext";

export const getAccessScopesControl = () => {
  const { currentUser } = useOptionsContext();

  const accessUserActionControl = (scopes, action) => {
    return scopes?.some((item) => item.scope === action);
  };

  // Offers
  const accessCreateOffers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.OFFER_CREATE
  );

  const accessReadOffers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.OFFER_READ
  );

  const accessUpdateOffers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.OFFER_UPDATE
  );

  const accessDeleteOffers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.OFFER_DELETE
  );

  const accessViewOffers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.OFFER_VIEW
  );

  // Feeds
  const accessCreateFeeds = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.FEED_CREATE
  );

  const accessReadFeeds = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.FEED_READ
  );

  const accessUpdateFeeds = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.FEED_UPDATE
  );

  const accessDeleteFeeds = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.FEED_DELETE
  );

  const accessViewFeeds = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.FEED_VIEW
  );

  // SEO
  const accessCreateSeo = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.SEO_CREATE
  );

  const accessReadSeo = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.SEO_READ
  );

  const accessUpdateSeo = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.SEO_UPDATE
  );

  const accessDeleteSeo = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.SEO_DELETE
  );

  // Comments
  const accessCreateComments = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.COMMENT_CREATE
  );

  const accessReadComments = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.COMMENT_READ
  );

  const accessUpdateComments = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.COMMENT_UPDATE
  );

  const accessDeleteComments = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.COMMENT_DELETE
  );

  const accessViewComments = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.COMMENT_VIEW
  );

  // Reports
  const accessCreateReports = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.REPORT_CREATE
  );

  const accessReadReports = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.REPORT_READ
  );

  const accessUpdateReports = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.REPORT_UPDATE
  );

  const accessDeleteReports = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.REPORT_DELETE
  );

  const accessViewReports = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.REPORT_VIEW
  );

  // Articles
  const accessCreateArticles = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ARTICLE_CREATE
  );

  const accessReadArticles = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ARTICLE_READ
  );

  const accessUpdateArticles = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ARTICLE_UPDATE
  );

  const accessDeleteArticles = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ARTICLE_DELETE
  );

  const accessViewArticles = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ARTICLE_VIEW
  );

  // Content
  const accessCreateContent = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.CONTENT_CREATE
  );

  const accessReadContent = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.CONTENT_READ
  );

  const accessUpdateContent = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.CONTENT_UPDATE
  );

  const accessDeleteContent = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.CONTENT_DELETE
  );

  const accessViewContent = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.CONTENT_VIEW
  );

  // Location
  const accessCreateLocations = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.LOCATION_CREATE
  );

  const accessReadLocations = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.LOCATION_READ
  );

  const accessUpdateLocations = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.LOCATION_UPDATE
  );

  const accessDeleteLocations = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.LOCATION_DELETE
  );

  const accessViewLocations = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.LOCATION_VIEW
  );

  // Banners
  const accessCreateBanners = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.BANNER_CREATE
  );

  const accessReadBanners = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.BANNER_READ
  );

  const accessUpdateBanners = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.BANNER_UPDATE
  );

  const accessDeleteBanners = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.BANNER_DELETE
  );

  const accessViewBanners = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.BANNER_VIEW
  );

  // Venues
  const accessCreateVenues = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.VENUE_CREATE
  );

  const accessReadVenues = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.VENUE_READ
  );

  const accessUpdateVenues = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.VENUE_UPDATE
  );

  const accessDeleteVenues = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.VENUE_DELETE
  );

  const accessViewVenues = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.VENUE_VIEW
  );

  // Events
  const accessCreateEvents = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.EVENT_CREATE
  );

  const accessReadEvents = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.EVENT_READ
  );

  const accessUpdateEvents = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.EVENT_UPDATE
  );

  const accessDeleteEvents = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.EVENT_DELETE
  );

  const accessViewEvents = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.EVENT_VIEW
  );

  // Attractions
  const accessCreateAttractions = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ATTRACTION_CREATE
  );

  const accessReadAttractions = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ATTRACTION_READ
  );

  const accessUpdateAttractions = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ATTRACTION_UPDATE
  );

  const accessDeleteAttractions = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ATTRACTION_DELETE
  );

  const accessViewAttractions = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.ATTRACTION_VIEW
  );

  // Users
  const accessCreateUsers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.USER_CREATE
  );

  const accessReadUsers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.USER_READ
  );

  const accessUpdateUsers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.USER_UPDATE
  );

  const accessDeleteUsers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.USER_DELETE
  );

  const accessViewUsers = accessUserActionControl(
    currentUser?.currentUser?.role?.scopes,
    ACCESS_SCOPES.USER_VIEW
  );

  return {
    accessCreateOffers,
    accessReadOffers,
    accessUpdateOffers,
    accessDeleteOffers,
    accessViewOffers,
    accessCreateFeeds,
    accessReadFeeds,
    accessUpdateFeeds,
    accessDeleteFeeds,
    accessViewFeeds,

    accessCreateSeo,
    accessReadSeo,
    accessUpdateSeo,
    accessDeleteSeo,

    accessCreateComments,
    accessReadComments,
    accessUpdateComments,
    accessDeleteComments,
    accessViewComments,
    accessCreateReports,
    accessReadReports,
    accessUpdateReports,
    accessDeleteReports,
    accessViewReports,
    accessCreateArticles,
    accessReadArticles,
    accessUpdateArticles,
    accessDeleteArticles,
    accessViewArticles,
    accessCreateContent,
    accessReadContent,
    accessUpdateContent,
    accessDeleteContent,
    accessViewContent,
    accessCreateLocations,
    accessReadLocations,
    accessUpdateLocations,
    accessDeleteLocations,
    accessViewLocations,
    accessCreateBanners,
    accessReadBanners,
    accessUpdateBanners,
    accessDeleteBanners,
    accessViewBanners,
    accessCreateVenues,
    accessReadVenues,
    accessUpdateVenues,
    accessDeleteVenues,
    accessViewVenues,
    accessCreateEvents,
    accessReadEvents,
    accessUpdateEvents,
    accessDeleteEvents,
    accessViewEvents,
    accessCreateAttractions,
    accessReadAttractions,
    accessUpdateAttractions,
    accessDeleteAttractions,
    accessViewAttractions,
    accessCreateUsers,
    accessReadUsers,
    accessUpdateUsers,
    accessDeleteUsers,
    accessViewUsers,
  };
};
