import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  VStack,
} from "@chakra-ui/react";
import SwitchRHF from "components/react-hook-form/SwitchRHF";
import { useFormContext } from "react-hook-form";
import ToggleGroupRHF from "components/react-hook-form/ToggleGroupRHF";
import TimePickerRHF from "components/react-hook-form/TimePickerRHF";
import { ToggleItem } from "components/ToggleGroup";
import Heading from "components/Heading";
import { getDayNameSwitch } from "utils/getDayNameSwitch";

function CreateEditScheduleModal(props) {
  const { onSubmit, cancelHandler } = props;

  const { watch } = useFormContext();
  const daysValues = watch("days");

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <Flex flexDirection="column">
          <Box position="relative" marginBottom={10}>
            <ToggleGroupRHF name="days" label="Working Days">
              <ToggleItem data-value="MON">Mon</ToggleItem>
              <ToggleItem data-value="TUE">Tue</ToggleItem>
              <ToggleItem data-value="WED">Wed</ToggleItem>
              <ToggleItem data-value="THU">Thu</ToggleItem>
              <ToggleItem data-value="FRI">Fri</ToggleItem>
              <ToggleItem data-value="SAT">Sat</ToggleItem>
              <ToggleItem data-value="SUN">Sun</ToggleItem>
            </ToggleGroupRHF>
          </Box>
          <VStack spacing={7}>
            {daysValues?.map((day) => {
              return (
                <VStack key={day} width="full">
                  <Flex
                    width="full"
                    justifyContent="space-between"
                    marginBottom={4}
                  >
                    <Heading size="h3">{getDayNameSwitch(day)}</Heading>
                    <SwitchRHF
                      name={`daySchedule.${day}.enabled`}
                      label="Enabled"
                    />
                  </Flex>

                  <HStack width="full" alignItems="flex-end" spacing={7.5}>
                    <TimePickerRHF
                      name={`daySchedule.${day}.from`}
                      suffix="Start:"
                      label="Working hours"
                      size="md"
                    />
                    <TimePickerRHF
                      name={`daySchedule.${day}.to`}
                      suffix="End:"
                      size="md"
                    />
                    <TimePickerRHF
                      name={`daySchedule.${day}.duration`}
                      label="Uptime hours"
                      suffix="Up:"
                      size="md"
                    />
                  </HStack>
                </VStack>
              );
            })}
          </VStack>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <HStack spacing="auto" w="full">
          <Button
            variant="outline"
            colorScheme="blue.500"
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button type="submit">Save schedule</Button>
        </HStack>
      </ModalFooter>
    </form>
  );
}

export default CreateEditScheduleModal;
