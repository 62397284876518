import { Flex, Center, Image, Text } from "@chakra-ui/react";
import { UserIcon } from "components/Icons";
import Options from "./Options";
import CHS from "./chakra.styles";

export const COLUMNS = [
  {
    Header: "Company",
    accessor: "company",
    Cell: ({ row }) => {
      return (
        <Flex alignItems="center">
          <Center sx={CHS.userImageWrap}>
            <Image
              sx={CHS.userImage}
              src={row.original.image?.preview}
              fallback={<UserIcon sx={CHS.userIcon} />}
            />
          </Center>
          <Text>{row.original.company}</Text>
        </Flex>
      );
    },
  },
  {
    Header: "Owner",
    accessor: "owner",
    disableSortBy: true,
    Cell: ({ row }) => {
      const ownerNames = row?.original?.owners
        .map((name) => name.ownerName)
        .join();
      return !ownerNames ? "not available" : ownerNames;
    },
  },
  {
    Header: "Address",
    accessor: "address",
    disableSortBy: true,
    Cell: ({ row }) => {
      return !row?.original?.adress ? "—" : row?.original?.adress;
    },
  },
  {
    Header: "Options",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options row={row} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
