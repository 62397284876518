import { Icon } from "@chakra-ui/react";

export default function AlertInfoIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8901 3.57872L22.5601 16.2987C22.8385 16.755 22.9899 17.2774 22.9987 17.8119C23.0075 18.3464 22.8734 18.8735 22.6101 19.3387C22.3093 19.8495 21.8792 20.272 21.363 20.5634C20.8468 20.8549 20.2629 21.005 19.6701 20.9987H4.3301C3.73343 21.001 3.14684 20.8448 2.6303 20.5461C2.11376 20.2475 1.68578 19.817 1.3901 19.2987C1.13436 18.8383 1.00434 18.3186 1.01311 17.792C1.02189 17.2654 1.16915 16.7504 1.4401 16.2987L9.1101 3.57872C9.42024 3.09353 9.84755 2.69424 10.3526 2.41766C10.8577 2.14107 11.4243 1.99609 12.0001 1.99609C12.5759 1.99609 13.1425 2.14107 13.6476 2.41766C14.1527 2.69424 14.58 3.09353 14.8901 3.57872ZM13.5408 9.23681L14.7395 10.4399C14.9005 10.5938 14.9941 10.8055 14.9998 11.0285C15.0056 11.2516 14.923 11.4678 14.7702 11.6298L10.8326 15.5816C10.69 15.7235 10.5031 15.8119 10.3032 15.8319L8.47884 15.9987H8.43947C8.38189 15.999 8.32481 15.988 8.2715 15.9661C8.2182 15.9443 8.16972 15.9121 8.12884 15.8714C8.08325 15.8258 8.04824 15.7707 8.02631 15.71C8.00438 15.6492 7.99607 15.5844 8.00196 15.5201L8.19009 13.6891C8.21 13.4886 8.29805 13.301 8.43947 13.1578L12.377 9.20607C12.541 9.06704 12.7501 8.99336 12.9647 8.99902C13.1793 9.00469 13.3843 9.08932 13.5408 9.23681ZM12.0795 10.7297L13.252 11.9064L14.127 11.0502L12.9326 9.85153L12.0795 10.7297Z"
      />
    </Icon>
  );
}
