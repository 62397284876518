import { useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useAddNewVenueMenu,
  useUpdateVenueMenu,
} from "graphql/hooks/useMutations";
import { useVenueContext } from "hooks/useVenueContext.hook";
import { useOptionsContext } from "hooks/useOptionsContext";
import stepsContext from "contexts/steps/steps.context";
import {
  transformLanguageSelect,
  transformAddNewVenueMenu,
  transformReceivedAddVenueMenu,
  transformUpdateVenueMenu,
  transformReceivedUpdateVenueMenu,
} from "connectors";
import Menu from "pages/Venues/VenueManagement/Menu";
import { ADD_NEW_VENUE_MENU_SCHEMA } from "schemas";
import { onLanguageChange } from "utils/onLanguageChange";

function MenuContainer() {
  const { venue, getVenueId, getMenu, updateVenueState } = useVenueContext();

  const { languages } = useOptionsContext();

  const methods = useForm({
    defaultValues: getMenu(),
    resolver: yupResolver(ADD_NEW_VENUE_MENU_SCHEMA.schema(languages)),
  });

  const { nextStep } = useContext(stepsContext);

  const [addNewVenueMenu] = useAddNewVenueMenu();
  const [updateVenueMenu] = useUpdateVenueMenu();

  const selectedLanguage = methods.watch("language");

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  const onSubmit = (formData) => {
    if (!getMenu()?.id) {
      const requestData = transformAddNewVenueMenu(
        formData,
        getVenueId(),
        languages
      );
      addNewVenueMenu({
        variables: { input: requestData },
        onCompleted: (response) => {
          updateVenueState(
            transformReceivedAddVenueMenu(response?.addNewVenueMenu)
          );
          nextStep();
        },
      });
    } else {
      const requestData = transformUpdateVenueMenu(
        formData,
        getMenu()?.id,
        languages
      );
      updateVenueMenu({
        variables: { input: requestData },
        onCompleted: (response) => {
          updateVenueState(
            transformReceivedUpdateVenueMenu(response?.UpdateVenueMenu)
          );
          nextStep();
        },
      });
    }
  };

  useEffect(() => {
    if (venue.id) {
      methods.reset(getMenu());
    }
  }, [venue.id]);

  return (
    <FormProvider {...methods}>
      <Menu
        onSubmit={methods.handleSubmit(onSubmit)}
        languageOptions={transformLanguageSelect(languages)}
        selectedLanguage={selectedLanguage}
        languageChangeHandler={languageChangeHandler}
      />
    </FormProvider>
  );
}

export default MenuContainer;
