import { Box, Center } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import Heading from "components/Heading";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import Button from "components/Button";
import ValidationNotification from "components/ValidationNotification";
import { ArrowLeftIcon } from "components/Icons";

function RecoverPassword(props) {
  const { view, onSubmit, apiErrorMessage, handleNavigateToLogin } = props;
  const { handleSubmit } = useFormContext();

  return (
    <Center h="100vh" w="full" bg="brand.gradient">
      <Box layerStyle="box" width="500px">
        <Heading mb="5">Recover password</Heading>
        {view === "initialView" && (
          <>
            <Heading mb={7.5} size="textMain" variant="text">
              Enter the email address associated with your account.
            </Heading>
            <ValidationNotification>Invalid email!</ValidationNotification>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldRHF
                id="email"
                name="email"
                label="Email address"
                marginBottom={12.5}
              />
              <Center>
                <Button type="submit">Reset password</Button>
              </Center>
            </form>
          </>
        )}
        {view === "success" && (
          <>
            <Heading my={12} h={36} size="textMain" variant="success">
              Check your email and follow the attached link.
            </Heading>
            <Center>
              <Button type="link" onClick={handleNavigateToLogin}>
                <Box mr={4}>
                  <ArrowLeftIcon stroke="currentColor" />
                </Box>
                Go to login
              </Button>
            </Center>
          </>
        )}
        {view === "error" && apiErrorMessage && (
          <>
            <Heading my={12} h={27} size="textMain" variant="error">
              {apiErrorMessage}
            </Heading>
            <Center>
              <Button type="link" onClick={handleNavigateToLogin}>
                <Box mr={4}>
                  <ArrowLeftIcon stroke="currentColor" />
                </Box>
                Go to login
              </Button>
            </Center>
          </>
        )}
      </Box>
    </Center>
  );
}

export default RecoverPassword;
