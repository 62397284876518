import { getDefaultCurrency } from "utils/getDefaultCurrency";

export const transformTickets = ({ data = [], currencies }) => {
  if (data) {
    return data?.map((ticket) => ({
      // For table
      tableType: ticket.type
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase()),
      tablePrice: `${ticket.price} ${
        getDefaultCurrency(ticket?.currencyId, currencies).label
      }`,
      tableOldPrice: ticket.oldPrice
        ? `${ticket.oldPrice} ${
            getDefaultCurrency(ticket?.currencyId, currencies).label
          }`
        : "-",
      // For modal
      id: ticket.id,
      name: ticket.name,
      bestPrice: ticket.bestPrice,
      type: { value: ticket.type, label: ticket.type },
      currency: getDefaultCurrency(ticket?.currencyId, currencies),
      price: ticket.price,
      oldPrice: ticket.oldPrice,
    }));
  }
  return [];
};
