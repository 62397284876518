import {
  Container,
  Box,
  Grid,
  GridItem,
  Divider,
  Text,
  Spacer,
  HStack,
  Button,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import Heading from "components/Heading";
import ValidationNotification from "components/ValidationNotification";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";

export default function Profile(props) {
  const {
    onSubmit,
    isEditProfile,
    onEditFunc,
    onDiscardChanges,
    optionsCountry,
    optionsCity,
    initialValues,
    countryChangeHandler,
  } = props;
  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  return (
    <Container minW="100%" px="10" pt="10">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns="repeat(6, 1fr)" gap={5}>
          <VStack
            w={250}
            py={10}
            px={5}
            spacing={5}
            bgColor="grey.100"
            borderRadius="10"
            alignSelf="flex-start"
          >
            {isEditProfile ? (
              <DropzoneFieldRHF
                height="208px"
                id="photo"
                name="photo"
                acceptStr="image/jpeg,image/png"
              />
            ) : (
              <Avatar
                id="photo"
                name={
                  initialValues?.firstName && initialValues?.lastName
                    ? `${initialValues?.firstName} ${initialValues?.lastName}`
                    : null
                }
                alt="avatar"
                bg="blue.500"
                src={initialValues.photo?.preview}
                sx={{
                  width: 208,
                  height: 206,
                  borderRadius: 24,
                  "& img": { borderRadius: "24px!important" },
                }}
              />
            )}

            <Heading fontSize="md" fontWeight="medium">
              {`${initialValues.firstName} ${initialValues.lastName}`}
            </Heading>

            {initialValues.description ? (
              <Text textAlign="center" fontSize="xs" color="dark.300">
                <p>{initialValues.description}</p>
              </Text>
            ) : null}

            {isEditProfile && <Button type="submit">Save changes</Button>}

            {!isEditProfile && (
              <Button onClick={onEditFunc}>Edit profile</Button>
            )}

            {isEditProfile && (
              <Button variant="outline" onClick={onDiscardChanges}>
                Discard changes
              </Button>
            )}
          </VStack>
          <GridItem colSpan={5}>
            <>
              <HStack>
                <Heading fontSize="2xl" fontWeight="normal">
                  Personal Information
                </Heading>
                <Spacer />
                <ValidationNotification icon={false} m={0}>
                  <Text fontSize="md" color="red.500">
                    {Object.values(errors)[0]?.message}!
                  </Text>
                </ValidationNotification>
              </HStack>

              <Box pt={6}>
                <Text fontSize="lg" pb={5}>
                  Account Information
                </Text>
                <Grid templateColumns="repeat(3, 1fr)" gap={5} py="2.5">
                  <GridItem>
                    <TextFieldRHF
                      id="firstName"
                      name="firstName"
                      inputType="text"
                      label="First Name"
                      isDisabled={!isEditProfile}
                    />
                  </GridItem>

                  <GridItem>
                    <TextFieldRHF
                      id="lastName"
                      name="lastName"
                      inputType="text"
                      label="Last Name"
                      isDisabled={!isEditProfile}
                    />
                  </GridItem>
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)" gap={5} py="2.5">
                  <GridItem>
                    <TextFieldRHF
                      id="role"
                      name="role"
                      inputType="text"
                      label="Role"
                      isReadOnly={true}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <TextFieldRHF
                      id="company"
                      name="company"
                      inputType="text"
                      label="Company"
                      isReadOnly={true}
                    />
                  </GridItem>
                </Grid>
              </Box>

              <Box pt={7}>
                <Text fontSize="lg">Account Information</Text>
                <Box py={5}>
                  <Divider />
                </Box>
                <Grid templateColumns="repeat(3, 1fr)" gap={5} py="2.5">
                  <GridItem>
                    <TextFieldRHF
                      id="phone"
                      name="phone"
                      inputType="tel"
                      label="Mobile Phone Number"
                      isDisabled={!isEditProfile}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <TextFieldRHF
                      id="email"
                      name="email"
                      inputType="text"
                      label="Email Address"
                      isDisabled={!isEditProfile}
                    />
                  </GridItem>
                </Grid>
              </Box>

              <Box pt={14}>
                <Text fontSize="lg">Address</Text>
                <Box py={5}>
                  <Divider />
                </Box>

                <Grid templateColumns="repeat(3, 1fr)" gap={5} py="2.5">
                  <GridItem colSpan={1}>
                    <SelectRHF
                      name="country"
                      label="Country"
                      options={optionsCountry}
                      placeholder="Select country..."
                      isDisabled={!isEditProfile}
                      onChangeHandler={countryChangeHandler}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <SelectRHF
                      name="city"
                      label="City"
                      options={optionsCity}
                      placeholder="Select city..."
                      isDisabled={!isEditProfile}
                    />
                  </GridItem>
                </Grid>
              </Box>
            </>
          </GridItem>
        </Grid>
      </form>
    </Container>
  );
}
