import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModalContext } from "hooks/useModalContext";
import AddVenuesTicketModal from "components/Modals/AddVenuesTicketModal";
import { ADD_VENUES_TICKET_SCHEMA } from "schemas";
import { TICKET_EVENTS_DATA } from "mock/TICKET_EVENTS_DATA";
import { TICKET_TYPES_DATA } from "mock/TICKET_TYPES_DATA";
import { TICKET_OFFER_TYPES_DATA } from "mock/TICKET_OFFER_TYPES_DATA";

function AddNewTicketsContainer() {
  const { modal, closeModal } = useModalContext("addVenuesTicketModal");

  const methods = useForm({
    resolver: yupResolver(ADD_VENUES_TICKET_SCHEMA.schema),
    defaultValues: {
      name: "",
      event: null,
      ticket_type: null,
      price: "",
      special_price: "",
      offer_type: null,
      quota: "",
    },
  });

  const onChangeEventHandler = (value) => {
    console.log("event", value);
  };

  const onChangeTicketTypeHandler = (value) => {
    console.log("ticket type", value);
  };

  const onChangeOfferTypeHandler = (value) => {
    console.log("offer type", value);
  };

  const cancelHandler = () => {
    methods.reset();
    closeModal();
  };

  const onSubmit = (value) => {
    console.log("submit", value);
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <AddVenuesTicketModal
        isOpen={modal.isOpen}
        onClose={closeModal}
        cancelHandler={cancelHandler}
        onSubmit={onSubmit}
        eventOptions={TICKET_EVENTS_DATA}
        onChangeEventHandler={onChangeEventHandler}
        ticketTypeOptions={TICKET_TYPES_DATA}
        onChangeTicketTypeHandler={onChangeTicketTypeHandler}
        offerTypeOptions={TICKET_OFFER_TYPES_DATA}
        onChangeOfferTypeHandler={onChangeOfferTypeHandler}
      />
    </FormProvider>
  );
}

export default AddNewTicketsContainer;
