import { useTheme } from "@chakra-ui/react";

export const useSelectStyles = (hasError, isDisabled, styles) => {
  const theme = useTheme();
  return {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      height: "42px",
      backgroundColor: theme.colors.white[900],
      borderRadius: "22px",
      borderColor: hasError ? theme.colors.red[500] : theme.colors.white[500],
      ":hover": {
        borderColor: hasError ? theme.colors.red[500] : theme.colors.white[500],
        boxShadow: "none",
      },
      ":focus": {
        borderColor: theme.colors.blue[500],
      },
      ...styles?.control,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDisabled ? theme.colors.dark[300] : theme.colors.black[500],
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.colors.dark[300],
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "20px",
      paddingRight: "5px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      marginRight: "15px",
      opacity: isDisabled ? "0.5" : 1,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
    }),
    menuList: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: theme.colors.blue[500],
        borderRadius: "3px",
      },
      "::-webkit-scrollbar-button": {
        backgroundColor: "transparent",
        height: "4px",
      },
    }),
  };
};
