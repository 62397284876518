const toastContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "430px",
  borderTopRadius: "14px",
  py: "12px",
  px: "12px",
};

const toastInfoWrap = {
  ml: "10px",
  flexDirection: "column",
  alignItems: "flex-start",
};

const toastTitle = {
  color: "white.900",
  fontWeight: "bold",
};

const toastDesc = {
  color: "white.900",
};

export default { toastContainer, toastInfoWrap, toastTitle, toastDesc };
