import React, { useState } from "react";
import TableProvider from "contexts/table/table.provider";
import { Flex, Spinner } from "@chakra-ui/react";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetUsers } from "graphql/hooks/useQueries";
import { useForm } from "react-hook-form";
import { useDebounce } from "hooks/useDebounce";
import UsersUsersTable from "pages/Users/Users/UsersUsersTable";
import UsersUsersFilters from "pages/Users/Users/UsersUsersFilters";
import { COLUMNS } from "pages/Users/Users/UsersUsersTable/table.config";
import { transformAllUsersList, transformUserListSortState } from "connectors";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function UsersContainer() {
  const [sortState, setSortState] = useState();
  const methods = useForm();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const name = methods.watch("name");

  const { data: allUsers, loading: allUsersLoading } = useGetUsers({
    variables: {
      options: {
        search: useDebounce(name) || null,
        offset: offsetData,
        limit: queryPageSize,
        isUser: true,
        sort: transformUserListSortState(sortState),
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAllUsersList(allUsers?.getAllUsers?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={allUsers?.getAllUsers?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <UsersUsersFilters methods={methods} />
      {allUsersLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : allUsers?.getAllUsers?.rows.length ? (
        <UsersUsersTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default UsersContainer;
