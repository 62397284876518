export const transformAttractionsListSortState = (state) => {
  if (!state) {
    return undefined;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };

  if (state.id === "name") requestObj.field = "title";

  return requestObj;
};
