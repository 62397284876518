export const transformCreateEditAttractionTicket = ({
  id,
  formData,
  isEdit = false,
}) => {
  if (formData) {
    const reguestObj = {
      name: formData.name,
      type: formData.type?.value,
      price: formData.price,
      currencyId: Number(formData.currency.value),
      bestPrice: formData.bestPrice,
    };

    if (formData.oldPrice || formData.oldPrice === 0) {
      Object.assign(reguestObj, { oldPrice: formData.oldPrice });
    }

    if (!isEdit) {
      Object.assign(reguestObj, { attractionId: id });
    } else {
      Object.assign(reguestObj, { id });
    }

    return reguestObj;
  }
  return {};
};
