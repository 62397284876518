export const getDefaultUserPhoto = (data, isReadOnly) => {
  const reg = /https?:\/\/(www\.)?/;
  if (isReadOnly) {
    if (reg.test(data)) {
      return { preview: data };
    }
    return {
      preview: `${process.env.REACT_APP_API_URL}/${data}`,
    };
  }
  return data;
};
