import { Icon } from "@chakra-ui/react";

export default function EyeOpenIcon({ fill, stroke, width, height, ...rest }) {
  return (
    <Icon
      viewBox="0 0 18 18"
      fill={fill}
      width={width}
      height={height}
      stroke={stroke}
      {...rest}
    >
      <path
        d="M0.75 8.75C0.75 8.75 3.75 2.75 9 2.75C14.25 2.75 17.25 8.75 17.25 8.75C17.25 8.75 14.25 14.75 9 14.75C3.75 14.75 0.75 8.75 0.75 8.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.75C10.6569 11.75 12 10.4069 12 8.75C12 7.09315 10.6569 5.75 9 5.75C7.34315 5.75 6 7.09315 6 8.75C6 10.4069 7.34315 11.75 9 11.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
