import { Flex } from "@chakra-ui/react";
import Heading from "components/Heading";

function PageHeader(props) {
  const { children, size = "title", button, ...rest } = props;

  return (
    <Flex justify="space-between" align="center" {...rest}>
      <Heading size={size}>{children}</Heading>
      {button}
    </Flex>
  );
}

export default PageHeader;
