import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Grid,
  GridItem,
  Flex,
  Spacer,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";

export default function InfluencerReview(props) {
  const {
    isOpen,
    onClose,
    onSubmit,
    cancelHandler,
    name,
    status,
    level,
    statusOptions,
    levelsOptions,
  } = props;

  const defaultOptions = (option) => {
    return {
      value: option,
      label: option,
    };
  };

  const { handleSubmit } = useFormContext();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>{name}</Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={6}>
              <Grid
                templateColumns="repeat(3, 1fr)"
                templateRows="repeat(3, 1fr)"
                columnGap={6}
                rowGap={4}
                w="100%"
              >
                <GridItem colSpan={3} rowSpan={1}>
                  <TextFieldRHF
                    id="socialAccount1"
                    name="socialAccount1"
                    inputType="text"
                    label="Social media accounts"
                    inputPlaceholder="Paste link here"
                  />
                </GridItem>
                <GridItem colSpan={3} rowSpan={1} alignSelf="center">
                  <TextFieldRHF
                    id="socialAccount2"
                    name="socialAccount2"
                    inputType="text"
                    inputPlaceholder="Paste link here"
                  />
                </GridItem>
                <GridItem colSpan={3} rowSpan={1}>
                  <TextFieldRHF
                    id="socialAccount3"
                    name="socialAccount3"
                    inputType="text"
                    inputPlaceholder="Paste link here"
                  />
                </GridItem>
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" columnGap={8} w="100%">
                <GridItem colSpan={1}>
                  <SelectRHF
                    name="level"
                    label="Influencer level"
                    placeholder="Select level"
                    defaultValue={defaultOptions(level)}
                    options={levelsOptions}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <SelectRHF
                    name="status"
                    label="Status"
                    placeholder="Select status"
                    defaultValue={defaultOptions(status)}
                    options={statusOptions}
                  />
                </GridItem>
              </Grid>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" px={6}>
              <Button
                onClick={cancelHandler}
                variant="outline"
                colorScheme="blue.500"
              >
                Cancel
              </Button>
              <Spacer />
              <Button type="submit">Save</Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
