const optionIcon = {
  w: "20px",
  h: "20px",
  fill: "dark.500",
  _groupHover: { fill: "blue.500" },
};

const icon = {
  minW: 0,
  w: 10,
  h: 10,
};

export default {
  optionIcon,
  icon,
};
