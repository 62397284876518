import React, { useState } from "react";
import TableProvider from "contexts/table/table.provider";
import { Flex, Spinner } from "@chakra-ui/react";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllCompanies } from "graphql/hooks/useQueries";
import { useForm } from "react-hook-form";
import { useDebounce } from "hooks/useDebounce";
import UsersCompaniesFilters from "pages/Users/Companies/UsersCompaniesFilters";
import UsersCompaniesTable from "pages/Users/Companies/UsersCompaniesTable";
import { COLUMNS } from "pages/Users/Companies/UsersCompaniesTable/table.config";
import {
  transformAllCompanyList,
  transformUserCompanySortState,
} from "connectors";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function UsersCompaniesContainer() {
  const [sortState, setSortState] = useState();

  const methods = useForm();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const company = methods.watch("company");

  const { data: allCompanies, loading: allCompaniesLoading } =
    useGetAllCompanies({
      variables: {
        limit: queryPageSize,
        offset: offsetData,
        search: useDebounce(company) || null,
        sort: transformUserCompanySortState(sortState),
      },
    });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAllCompanyList(allCompanies?.getAllCompanies?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={allCompanies?.getAllCompanies?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <UsersCompaniesFilters methods={methods} />
      {allCompaniesLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : allCompanies?.getAllCompanies?.rows.length ? (
        <UsersCompaniesTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default UsersCompaniesContainer;
