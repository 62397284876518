import { useCallback, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";
import HyptModal from "components/HyptModal";
import SectorManagement from "components/Modals/SectorManagement";
import SetNewRowsCount from "components/Modals/SetNewRowsCount";
import EditSectorRow from "components/Modals/EditSectorRow";
import {
  useAddEventSector,
  useUpdateEventSector,
} from "graphql/hooks/useMutations";
import {
  transformSectorCreatingData,
  transformSectorUpdatingData,
  transformSectorFormData,
} from "connectors";
import { useGetEventSectorById } from "graphql/hooks/useQueries";
import { useToastContext } from "hooks/useToastContext";

const INITIAL_VALUES = {
  sector_name: "",
  map_image: "",
  short_description: "",
  rows_count: "",
  seats_per_row: "",
};

function EventFloorMapManagementContainer() {
  const { modal: sectorModal, closeModal: closeSectorModal } = useModalContext(
    "sectorManagementModal"
  );

  const { modal: rowsModal, closeModal: closeRowsModal } = useModalContext(
    "setNewRowsCountModal"
  );

  const { modal: singleRowModal, closeModal: closeSingleRowModal } =
    useModalContext("editSectorRowModal");

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
  });

  const { showToast } = useToastContext();

  const [sectorId, setSectorId] = useState();

  const [addSector] = useAddEventSector();
  const [updateSector] = useUpdateEventSector();
  const [getSectorById] = useGetEventSectorById();

  const onCloseModal = (cb) => () => {
    cb();
    // Cleanup (because of modal persists in the dom after closing action)
    setSectorId(undefined);
    methods.reset(INITIAL_VALUES);
  };

  const handleSave = useCallback(
    (data) => {
      if (sectorId) {
        updateSector({
          variables: {
            input: { id: sectorId, ...transformSectorUpdatingData(data) },
          },
          onCompleted: () => {
            showToast({
              description: "Successfully saved!",
            });
          },
        }).finally(() => {
          methods.reset(data);
        });
      } else {
        addSector({
          variables: {
            input: {
              eventId: Number(sectorModal.id),
              ...transformSectorCreatingData(data),
            },
          },
        })
          .then((response) => {
            if (response?.data.addEventSector.id) {
              setSectorId(response.data.addEventSector.id);
            }
          })
          .finally(() => {
            methods.reset(data);
          });
      }
    },
    [addSector, sectorId, updateSector, sectorModal.id]
  );

  useEffect(() => {
    if (sectorModal.sectorId) {
      setSectorId(sectorModal.sectorId);
      getSectorById({
        variables: {
          id: sectorModal.sectorId,
        },
      }).then((response) => {
        methods.reset(
          transformSectorFormData(response?.data?.getEventSectorById)
        );
      });
    }
  }, [sectorModal.sectorId]);

  return (
    <>
      <HyptModal
        isOpen={sectorModal.isOpen}
        onClose={onCloseModal(closeSectorModal)}
        heading={sectorId ? "Edit Sector" : "Create Sector"}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSave)}>
            <SectorManagement
              handleClose={onCloseModal(closeSectorModal)}
              sectorId={sectorId}
            />
          </form>
        </FormProvider>
      </HyptModal>

      <HyptModal
        isOpen={rowsModal.isOpen}
        onClose={onCloseModal(closeRowsModal)}
        heading="Set new rows count"
      >
        <FormProvider {...methods}>
          <SetNewRowsCount />
        </FormProvider>
      </HyptModal>

      <HyptModal
        isOpen={singleRowModal.isOpen}
        onClose={onCloseModal(closeSingleRowModal)}
        heading="Edit Row"
      >
        <FormProvider {...methods}>
          <EditSectorRow />
        </FormProvider>
      </HyptModal>
    </>
  );
}

export default EventFloorMapManagementContainer;
