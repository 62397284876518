import { allCategories } from "constants/constants";
import { getDefaultCategory } from "utils/getDefaultCategory";
import { getDefaultOfferType } from "utils/getDefaultOfferType";
import { getDefaultCurrency } from "utils/getDefaultCurrency";
import { capitalize } from "utils/capitalize";
import { transformFieldsLangsFormat } from "utils/transformFieldsLangsFormat";

export const transformBeforeEditOffer = (
  editOffer,
  categories,
  typeOffers,
  currencies,
  translatedValues,
  languages,
  editPostFromComments = false
) => {
  if (editOffer && !editPostFromComments) {
    const textData = transformFieldsLangsFormat(languages, editOffer.tr, [
      "title",
      "shortDescription",
      "description",
    ])?.reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;
        acc.shortDescription[valueObj.language.code] =
          valueObj?.shortDescription || "";
        acc.description[valueObj.language.code] = valueObj.description;

        return acc;
      },
      {
        title: {},
        shortDescription: {},
        description: {},
      }
    );

    const offerSEO = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
    };

    if (editOffer?.offerSEO) {
      editOffer?.offerSEO.forEach((valueObj) => {
        offerSEO.metaTitle[valueObj.language.code] = valueObj.metaTitle;
        offerSEO.metaDescription[valueObj.language.code] =
          valueObj.metaDescription;
      });
    }

    return {
      userType: editOffer?.userType,
      title: textData?.title,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      type: getDefaultOfferType(editOffer?.type, typeOffers),
      status: {
        value: editOffer?.status,
        label: capitalize(editOffer?.status).split("_").join(" "),
      },
      url: editOffer.url,
      price: editOffer.price,
      oldPrice: editOffer.oldPrice,
      isAddToSlider: editOffer.isAddToSlider,
      currency: getDefaultCurrency(editOffer?.currencyId, currencies),
      address: {
        description: editOffer.address || "",
        place_id: editOffer.geolocation || "",
      },
      category: getDefaultCategory(
        editOffer?.categoryId,
        allCategories.offerCategory,
        categories
      ),
      shortDescription: textData?.shortDescription,
      description: textData?.description,
      mainImage: {
        preview: `${process.env.REACT_APP_API_URL}/${editOffer?.image}`,
      },
      metaTitle: offerSEO?.metaTitle,
      metaDescription: offerSEO?.metaDescription,
      inf_numberOfUses: editOffer?.forInfluencerCount,
      inf_rank: {
        value: editOffer?.influencerLevel,
        label: editOffer?.influencerLevel,
      },
      slider_photo: editOffer?.photo.map((photo) => ({
        preview: `${process.env.REACT_APP_API_URL}/${photo}`,
      })),
    };
  }

  // --- *** ---
  if (editOffer && editPostFromComments) {
    const textData = editOffer?.tr?.reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;
        acc.shortDescription[valueObj.language.code] =
          valueObj?.shortDescription || "";
        acc.description[valueObj.language.code] = valueObj.description;

        return acc;
      },
      {
        title: {},
        shortDescription: {},
        description: {},
      }
    );

    const offerSEO = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
    };

    if (editOffer?.offerSEO) {
      editOffer?.offerSEO.forEach((valueObj) => {
        offerSEO.metaTitle[valueObj.language.code] = valueObj.metaTitle;
        offerSEO.metaDescription[valueObj.language.code] =
          valueObj.metaDescription;
      });
    }

    return {
      userType: editOffer?.isForInfluencerOnly ? "Influencer" : "Regular",
      title: textData?.title,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      type: getDefaultOfferType(editOffer?.type, typeOffers),
      status: {
        value: editOffer?.status,
        label: capitalize(editOffer?.status).split("_").join(" "),
      },
      url: editOffer.url,
      price: editOffer.price,
      oldPrice: editOffer.oldPrice,
      isAddToSlider: editOffer.isAddToSlider,
      currency: getDefaultCurrency(editOffer?.currencyId, currencies),
      address: {
        description: editOffer.address || "",
        place_id: editOffer.geolocation || "",
      },
      category: getDefaultCategory(
        editOffer?.categoryId,
        allCategories.offerCategory,
        categories
      ),
      shortDescription: textData?.shortDescription,
      description: textData?.description,
      mainImage: {
        preview: `${process.env.REACT_APP_API_URL}/${editOffer?.image}`,
      },
      metaTitle: offerSEO?.metaTitle,
      metaDescription: offerSEO?.metaDescription,
      inf_numberOfUses: editOffer?.forInfluencerCount,
      inf_rank: {
        value: editOffer?.influencerLevel,
        label: editOffer?.influencerLevel,
      },
      slider_photo: editOffer?.photo.map((photo) => ({
        preview: `${process.env.REACT_APP_API_URL}/${photo}`,
      })),
    };
  }
  return {};
};
