import {
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Grid,
  GridItem,
  Flex,
  Spacer,
  Box,
  Text,
  Divider,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import TextEditorRHF from "components/react-hook-form/TextEditorRHF";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";
import LanguageIndicators from "components/LanguageIndicators";
import { parseJSONToArray } from "utils/parseJSONToArray";
import { useFormContext } from "react-hook-form";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import { useMemo } from "react";
import CHS from "./chakra.styles";

export default function StaticPageCreateEditModal(props) {
  const {
    onSubmit,
    cancelHandler,
    modal,
    languageChangeHandler,
    languageOptions,
    selectedLanguage,
    statusOptions,
  } = props;

  const {
    formState: { errors },
    getValues,
  } = useFormContext();

  const values = getValues();

  const selectedLang = useGetLanguageKey(selectedLanguage);
  const content = useMemo(() => {
    const arr = parseJSONToArray(values?.content?.[selectedLang]);
    return arr;
  }, [selectedLang, values?.content]);

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gridAutoRows="auto"
            gap={6}
            w="full"
          >
            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="title"
                name={`title.${selectedLang}`}
                inputType="text"
                label="Title"
                inputPlaceholder="Create page title"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <SelectRHF
                name="language"
                label="Content language"
                options={languageOptions}
                placeholder="English (en-US) - required"
                onChangeHandler={languageChangeHandler}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <SelectRHF
                name="status"
                label="Status"
                options={statusOptions}
                placeholder="Status"
              />
            </GridItem>

            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="meta_title"
                name={`meta_title.${selectedLang}`}
                inputType="text"
                label="Meta title"
                inputPlaceholder="Add meta title"
              />
            </GridItem>

            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextareaRHF
                name={`meta_description.${selectedLang}`}
                label="Meta description"
                placeholder="Add meta description"
                width="full"
                height="280px"
              />
            </GridItem>

            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <TextFieldRHF
                id="url"
                name="url"
                inputType="text"
                label="Custom URL - hypt.com/..."
                inputPlaceholder="Create custom URL"
                errorMessage={errors?.url?.message}
              />
              <Box sx={CHS.helperText}>
                Provide url ending for specific page. Example:
                &quot;/venues&quot;, &quot; /events&quot;, etc.
              </Box>
            </GridItem>

            {!modal.isEditMode ? (
              <GridItem colSpan={4} sx={{ position: "relative" }}>
                <LanguageIndicators selectedLanguage={selectedLanguage} />
                <TextEditorRHF
                  name={`main_text.${selectedLang}`}
                  label="Main text"
                />
              </GridItem>
            ) : (
              content?.map((it) => {
                const lastKey = it.name.split(".").pop();
                const parent = it.name
                  .split(".")
                  .filter((el) => el !== lastKey)
                  .pop();

                return (
                  <GridItem colSpan={4} key={it.name}>
                    {parent && Number.isNaN(+parent) && (
                      <Box>
                        <Text sx={CHS.subTitle} fontSize="md">
                          {parent}
                        </Text>
                      </Box>
                    )}
                    {lastKey === "text" ? (
                      <Box sx={{ position: "relative" }}>
                        <LanguageIndicators
                          selectedLanguage={selectedLanguage}
                        />
                        <TextEditorRHF
                          name={`content.${selectedLang}.${it?.name}`}
                          label={<Text sx={CHS.subTitle}>{lastKey}</Text>}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ position: "relative" }}>
                        <LanguageIndicators
                          selectedLanguage={selectedLanguage}
                        />
                        <TextFieldRHF
                          name={`content.${selectedLang}.${it?.name}`}
                          label={<Text sx={CHS.subTitle}>{lastKey}</Text>}
                          inputType="text"
                          inputPlaceholder={`...${lastKey}`}
                        />
                      </Box>
                    )}
                    {parent && Number.isNaN(+parent) && (
                      <Box pt="40px">
                        <Divider orientation="horizontal" />
                      </Box>
                    )}
                  </GridItem>
                );
              })
            )}
          </Grid>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%">
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            Cancel
          </Button>
          <Spacer />
          <Button type="submit">
            {modal.isEditMode ? "Update" : "Create Page"}
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
