import { Button, Flex, useTheme } from "@chakra-ui/react";
import {
  GoToFirstIcon,
  GoToLastIcon,
  GoToPreviousIcon,
  GoToNextIcon,
} from "components/Icons";
import { useContext, useEffect, useState } from "react";
import TableContext from "contexts/table/table.context";
import { formatVisiblePages, getVisiblePages } from "utils/pagination";
import { PaginationItem } from "./PaginationItem";
import { PaginationDots } from "./PaginationDots";

function TablePagination() {
  const {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex: currentPageIndex },
  } = useContext(TableContext);

  const goToFirstPage = () => {
    gotoPage(0);
  };
  const goToLastPage = () => {
    gotoPage(pageCount - 1);
  };
  const goToPreviousPage = () => {
    previousPage();
  };
  const goToNextPage = () => {
    nextPage();
  };
  const goToSpecificPage = (page, onCurrentPage) => () => {
    if (!onCurrentPage) {
      gotoPage(page);
    }
  };

  const theme = useTheme();

  const [visiblePages, setVisiblePages] = useState(
    getVisiblePages(currentPageIndex + 1, pageCount)
  );

  useEffect(() => {
    setVisiblePages(getVisiblePages(currentPageIndex + 1, pageCount));
  }, [currentPageIndex, pageCount]);

  return (
    <Flex justify="center" align="center" my={5.5}>
      <Button
        variant="pagination"
        size="pagination"
        onClick={goToFirstPage}
        disabled={!canPreviousPage}
      >
        <GoToFirstIcon fill="white.500" height={2.5} />
      </Button>
      <Button
        variant="paginationInner"
        size="pagination"
        onClick={goToPreviousPage}
        disabled={!canPreviousPage}
        type="button"
      >
        <GoToPreviousIcon
          stroke={
            !canPreviousPage
              ? theme.colors.grey["500"]
              : theme.colors.white["900"]
          }
          fill={!canPreviousPage ? "white.500" : "black.900"}
          height={2.5}
        />
      </Button>
      {formatVisiblePages(visiblePages).map((page) => {
        if (typeof page === "number") {
          return (
            <PaginationItem
              key={page}
              onCurrentPage={page === currentPageIndex + 1}
              onClick={goToSpecificPage(
                page - 1,
                page === currentPageIndex + 1
              )}
            >
              {page}
            </PaginationItem>
          );
        }
        return (
          <PaginationItem key={Math.random()} height={2} width={3}>
            <PaginationDots />
          </PaginationItem>
        );
      })}
      <Button
        variant="paginationInner"
        size="pagination"
        onClick={goToNextPage}
        disabled={!canNextPage}
        type="button"
      >
        <GoToNextIcon
          stroke={
            !canNextPage ? theme.colors.grey["500"] : theme.colors.white["900"]
          }
          fill={!canNextPage ? "white.500" : "black.900"}
          height={2.5}
        />
      </Button>
      <Button
        variant="pagination"
        size="pagination"
        onClick={goToLastPage}
        disabled={!canNextPage}
        type="button"
      >
        <GoToLastIcon fill="white.500" height={2.5} />
      </Button>
    </Flex>
  );
}

export default TablePagination;
