export const transformEventsFloormapSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };
};
