import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  VStack,
  Flex,
} from "@chakra-ui/react";
import SelectRHF from "components/react-hook-form/SelectRHF";
import SwitchRHF from "components/react-hook-form/SwitchRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import NumberFormatRHF from "components/react-hook-form/NumberFormatRHF";

function CreateEditTicketsModal(props) {
  const { onSubmit, cancelHandler, currencyOptions, ticketTypesOptions } =
    props;

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={4}>
          <Flex width="full" justifyContent="space-between">
            <TextFieldRHF
              id="name"
              name="name"
              label="Name"
              inputPlaceholder="Enter name"
              w="376px"
            />
            <SwitchRHF name="bestPrice" label="Best price" vertical />
          </Flex>

          <HStack spacing={6} w="full">
            <SelectRHF
              name="type"
              label="Ticket type"
              options={ticketTypesOptions}
              placeholder="Select type..."
            />
            <SelectRHF
              name="currency"
              label="Currency"
              options={currencyOptions}
              placeholder="Select currency..."
            />
          </HStack>
          <HStack spacing={6} w="full">
            <NumberFormatRHF
              name="price"
              label="Price"
              inputProps={{ placeholder: "Enter price" }}
            />
            <NumberFormatRHF
              name="oldPrice"
              label="Old price"
              inputProps={{ placeholder: "Enter old price" }}
            />
          </HStack>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <HStack spacing="auto" w="full">
          <Button
            variant="outline"
            colorScheme="blue.500"
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button type="submit">Save ticket</Button>
        </HStack>
      </ModalFooter>
    </form>
  );
}

export default CreateEditTicketsModal;
