import { Box, Center } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import Heading from "components/Heading";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import Button from "components/Button";
import ValidationNotification from "components/ValidationNotification";
import { ArrowLeftIcon } from "components/Icons";

function ResetPassword(props) {
  const { onSubmit, view, handleNavigateToLogin, apiErrorMessage } = props;
  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  return (
    <Center h="100vh" w="full" bg="brand.gradient">
      <Box layerStyle="box" width="500px">
        <Heading mb="6">Set new password</Heading>
        {view === "initialView" && (
          <>
            <Heading mb={7.5} size="textMain" variant="text">
              Enter new password.
            </Heading>
            <ValidationNotification>
              {errors?.newPassword?.message || errors?.confirmPassword?.message}
            </ValidationNotification>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldRHF
                id="newPassword"
                name="newPassword"
                inputType="password"
                label="New password"
                marginBottom={4}
                inputRightElement
              />
              <TextFieldRHF
                id="confirmPassword"
                name="confirmPassword"
                inputType="password"
                label="Confirm password"
                marginBottom={12.5}
                inputRightElement
              />
              <Center>
                <Button type="submit">Save Password</Button>
              </Center>
            </form>
          </>
        )}
        {view === "success" && (
          <>
            <Heading my={12} h={36} size="textMain" variant="success">
              Password changed successfully.
            </Heading>
            <Center>
              <Button type="link" onClick={handleNavigateToLogin}>
                Go to login
                <Box ml={4} transform="rotate(180deg)">
                  <ArrowLeftIcon stroke="currentColor" />
                </Box>
              </Button>
            </Center>
          </>
        )}
        {view === "error" && apiErrorMessage && (
          <>
            <Heading my={12} h={27} size="textMain" variant="error">
              {apiErrorMessage}
            </Heading>
            <Center>
              <Button type="link" onClick={handleNavigateToLogin}>
                <Box mr={4}>
                  <ArrowLeftIcon stroke="currentColor" />
                </Box>
                Go to login
              </Button>
            </Center>
          </>
        )}
      </Box>
    </Center>
  );
}

export default ResetPassword;
