export const transformVenuesFloormapSortState = (state) => {
  if (!state) {
    return undefined;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
  };

  if (state.id === "name") requestObj.field = "hall";
  if (state.id === "zonesCount") requestObj.field = "areas";

  return requestObj;
};
