import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query getAllUsers($options: OptionsFind) {
    getAllUsers(options: $options) {
      count
      rows {
        id
        firstName
        lastName
        email
        phone
        gender
        status
        photo
        languageId
        currencyId
        cityId
        roleId
        role {
          id
          name
          visibility
          scopes {
            id
            scope
          }
        }
        address
        description
        influencer {
          socialAccount1
          socialAccount2
          socialAccount3
          userId
          user {
            id
            firstName
            lastName
            email
            phone
            gender
            status
            photo
            languageId
            currencyId
            cityId
            roleId
            role {
              id
              name
              visibility
              scopes {
                id
                scope
              }
            }
            address
            description
            companyId
            company {
              id
              image
              adress
              companyName
            }
          }
          level
          status
        }
        acceptOffers {
          count
          rows {
            id
            modelName
            modelId
            date
            startTime
            stopTime
            price
            currencyId
            pdf
          }
        }
        companyId
        company {
          id
          image
          adress
          companyName
          cityId
          city {
            id
            name
            country {
              id
              code
              name
            }
            cityArea {
              id
              name
              isLiked
            }
          }
          licenceNumber
          photos {
            id
            photo
            companyId
          }
          owners {
            id
            dateBirth
            ownerName
            companyShare
            passport
            companyId
            company {
              id
            }
            photos {
              id
              photo
              ownerId
            }
          }
        }
      }
    }
  }
`;
