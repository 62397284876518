import { useReducer } from "react";
import { companyReducer } from "reducers";
import CompanyContext from "./company.context";

export const initialValue = {
  id: null,
  addNewCompany: {
    companyName: "",
    companyLogo: null,
    country: null,
    city: null,
    address: "",
    license: null,
    license_photo: null,
  },
  owners: [
    {
      owner: "",
      birthDate: "",
      share: null,
      passport: "",
      passportPhoto: null,
    },
  ],
  usersCompany: [],
};

export default function CompanyProvider({ children }) {
  const [company, dispatch] = useReducer(companyReducer, initialValue);

  const updateCompanyState = (data) => {
    dispatch({
      type: "UPDATE_COMPANY_STATE",
      payload: data,
    });
  };

  const getCompanyId = () => company.id;

  const getAddNewCompany = () => ({
    id: company.id,
    ...company.addNewCompany,
  });

  const getOwners = () => ({ owners: company.owners });
  const getUsersCompany = () => ({ usersCompany: company.usersCompany });

  const clearCompanyState = () => {
    dispatch({
      type: "CLEAR_COMPANY_STATE",
      payload: initialValue,
    });
  };

  return (
    <CompanyContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        company,
        getCompanyId,
        updateCompanyState,
        getAddNewCompany,
        getOwners,
        getUsersCompany,
        clearCompanyState,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}
