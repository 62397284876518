import React, { useContext, useEffect, useState } from "react";
import TableProvider from "contexts/table/table.provider";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { Flex, Spinner } from "@chakra-ui/react";
import {
  useGetAllInfluencer,
  useGetAllInfluencerOptions,
} from "graphql/hooks/useQueries";
import { useForm } from "react-hook-form";
import { useDebounce } from "hooks/useDebounce";
import UsersInfluencersFilters from "pages/Users/Influencers/UsersInfluencersFilters";
import UsersInfluencersTable from "pages/Users/Influencers/UsersInfluencersTable";
import { COLUMNS } from "pages/Users/Influencers/UsersInfluencersTable/table.config";
import {
  transformAllInfluencersList,
  transformInfluencerLevel,
  transformInfluencerStatus,
  transformUserInfluencerListSortState,
} from "connectors";
import optionsContext from "contexts/options/options.context";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function UsersInfluencersContainer() {
  const [sortState, setSortState] = useState();

  const methods = useForm();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const { updateOptionsState } = useContext(optionsContext);

  const [name, level, status] = methods.watch(["name", "level", "status"]);

  const { data: allInfluencerOptions } = useGetAllInfluencerOptions();
  useEffect(() => {
    if (allInfluencerOptions?.getAllInfluencerOptions) {
      updateOptionsState({
        allInfluencerOptions: allInfluencerOptions.getAllInfluencerOptions,
      });
    }
  }, [allInfluencerOptions]);

  const influencerLevel =
    allInfluencerOptions?.getAllInfluencerOptions?.influencerLevel;

  const influencerStatus =
    allInfluencerOptions?.getAllInfluencerOptions?.influencerStatus;

  const { data: allInfluencer, loading: allInfluencerLoading } =
    useGetAllInfluencer({
      variables: {
        options: {
          limit: queryPageSize,
          offset: offsetData,
          search: useDebounce(name) || null,
          level: level?.value || null,
          status: status?.value || null,
          sort: transformUserInfluencerListSortState(sortState),
        },
      },
    });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAllInfluencersList(
          allInfluencer?.getAllInfluencer?.rows
        ),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={allInfluencer?.getAllInfluencer?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <UsersInfluencersFilters
        methods={methods}
        influencerLevelOptions={transformInfluencerLevel(influencerLevel)}
        influencerStatusOptions={transformInfluencerStatus(influencerStatus)}
      />

      {allInfluencerLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : allInfluencer?.getAllInfluencer?.rows.length ? (
        <UsersInfluencersTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default UsersInfluencersContainer;
