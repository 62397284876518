import { useContext } from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import ModalButton from "components/ModalButton";
import Actions from "components/Stepper/Actions";
import StepsContext from "contexts/steps/steps.context";
import { FormProvider } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { ROWS_PER_PAGE } from "./table.config";

function Entertainments({ methods, loading, venueId, hasData }) {
  const { nextStep } = useContext(StepsContext);

  return (
    <>
      <Flex justify="space-between" align="center" mb={7} w="full">
        <FormProvider {...methods}>
          <TextFieldRHF
            name="name"
            inputType="text"
            inputPlaceholder="Search events"
            inputLeftElement={
              <GlassIcon
                stroke="dark.300"
                w="24px"
                h="24px"
                fill="none"
                mt={1}
                ml={4}
              />
            }
            mr={4}
            width="480px"
            inputProps={{ pl: "55px" }}
          />
        </FormProvider>
        <Flex justify="space-between" align="center">
          <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          <ModalButton
            modalName="createNewEntertainmentModal"
            label="Add entertainment"
            w={52}
            ml={4}
            data={{ venueId }}
          />
        </Flex>
      </Flex>
      <Box width="full">
        {loading ? (
          <Flex alignItems="center" justify="center" h="100px">
            <Spinner size="xl" />
          </Flex>
        ) : hasData ? (
          <>
            <TableBody hasSorting />
            <TablePagination />
          </>
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </Box>
      <Actions nextLabel="Next" previousLabel="Back" handleNext={nextStep} />
    </>
  );
}

export default Entertainments;
