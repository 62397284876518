import { useQuery, useLazyQuery } from "@apollo/client";
import * as QUERIES from "graphql/queries";

export const useGetVenues = (options) => {
  return useQuery(QUERIES.GET_VENUES, options);
};

export const useGetAllUsers = (options) => {
  return useQuery(QUERIES.GET_ALL_USERS, options);
};

export const useGetVenueByURL = (options) => {
  return useQuery(QUERIES.GET_VENUE_BY_URL, options);
};

export const useGetVenueById = (options) => {
  return useQuery(QUERIES.GET_VENUE_BY_ID, options);
};

export const useGetAllVenueOptions = (options) => {
  return useQuery(QUERIES.GET_ALL_VENUE_OPTIONS, options);
};

export const useGetAllEventOptions = (options) => {
  return useQuery(QUERIES.GET_ALL_EVENT_OPTIONS, options);
};

export const useGetAttractionStatuses = (options) => {
  return useQuery(QUERIES.GET_ATTRACTION_STATUSES, options);
};

export const useGetArticleStatuses = (options) => {
  return useQuery(QUERIES.GET_ARTICLE_STATUSES, options);
};

export const useGetCountries = (options) => {
  return useQuery(QUERIES.GET_COUNTRIES, options);
};

export const useGetAllPermisionRole = (options) => {
  return useQuery(QUERIES.GET_ALL_PERMISION_ROLE, options);
};

export const useGetAllUserOptions = (options) => {
  return useQuery(QUERIES.GET_ALL_USER_OPTIONS, options);
};

export const useGetAllInfluencer = (options) => {
  return useQuery(QUERIES.GET_ALL_INFLUENCER, options);
};

export const useGetAllAdminUsers = (input) => {
  return useQuery(QUERIES.GET_ALL_ADMIN_USERS, {
    ...input,
    variables: {
      ...input.variables,
      options: { ...input.variables.options, role: "ADMIN" },
    },
  });
};

export const useGetAllInfluencerOptions = (options) => {
  return useQuery(QUERIES.GET_ALL_INFLUENCER_OPTIONS, options);
};

export const useGetCurrencies = (options) => {
  return useQuery(QUERIES.GET_CURRENCIES, options);
};

export const useGetLanguages = (options) => {
  return useQuery(QUERIES.GET_LANGUAGES, options);
};

export const useGetCategories = (options) => {
  return useQuery(QUERIES.GET_CATEGORIES, options);
};

export const useGetOffers = (options) => {
  return useQuery(QUERIES.GET_OFFERS, options);
};

export const useGetAllOfferOptions = (options) => {
  return useQuery(QUERIES.GET_ALL_OFFER_OPTIONS, {
    ...options,
  });
};

export const useGetAllVenues = (options) => {
  return useQuery(QUERIES.GET_ALL_VENUES, options);
};

export const useGetAllVenuesLazy = (options) => {
  return useLazyQuery(QUERIES.GET_ALL_VENUES, options);
};

export const useGetAllLocations = (options) => {
  return useQuery(QUERIES.GET_ALL_LOCATIONS, options);
};

export const useGetAllEvents = (options) => {
  return useQuery(QUERIES.GET_ALL_EVENTS, options);
};

export const useGetAllAttractions = (options) => {
  return useQuery(QUERIES.GET_ALL_ATTRACTIONS, options);
};

export const useGetAllVenueEvents = (options) => {
  return useQuery(QUERIES.GET_ALL_VENUE_EVENTS, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetAllEventsWithoutVenueId = (input) => {
  return useQuery(QUERIES.GET_ALL_EVENTS_WITHOUT_VENUE_ID, {
    fetchPolicy: "network-only",
    ...input,
  });
};

export const useGetAllServices = (options) => {
  return useQuery(QUERIES.GET_ALL_SERVICES, options);
};

export const useGetAllRestrictions = (options) => {
  return useQuery(QUERIES.GET_ALL_RESTRICTIONS, options);
};

export const useGetOfferById = (options) => {
  return useQuery(QUERIES.GET_OFFER_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useLazyGetOfferById = (options) => {
  return useLazyQuery(QUERIES.GET_OFFER_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetOfferByIdLazy = (options) => {
  return useLazyQuery(QUERIES.GET_OFFER_BY_ID, options);
};

export const useGetAllOffersAdmin = (options) => {
  return useQuery(QUERIES.GET_ALL_OFFERS_ADMIN, {
    ...options,
    fetchPolicy: "network-only",
  });
};

export const useGetAllHalls = (input) => {
  return useQuery(QUERIES.GET_ALL_HALLS, input);
};

export const useGetAreasByHallId = (input) => {
  return useQuery(QUERIES.GET_AREAS_BY_HALL_ID, input);
};

export const useGetTablesByAreaId = (input) => {
  return useQuery(QUERIES.GET_TABLES_BY_AREA_ID, input);
};

export const useGetHallById = (input) => {
  return useLazyQuery(QUERIES.GET_HALL_BY_ID, input);
};

export const useGetAllArticleAdmin = (options) => {
  return useQuery(QUERIES.GET_ALL_ARTICLE_ADMIN, {
    ...options,
    fetchPolicy: "network-only",
  });
};

export const useGetArticleById = (options) => {
  return useQuery(QUERIES.GET_ARTICLE_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useLazyGetArticleById = (options) => {
  return useLazyQuery(QUERIES.GET_ARTICLE_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetAllStaticPages = (input) => {
  return useQuery(QUERIES.GET_ALL_STATIC_PAGES, {
    fetchPolicy: "network-only",
    ...input,
  });
};

export const useGetAllStaticPagesOptions = (options) => {
  return useQuery(QUERIES.GET_ALL_STATIC_PAGES_OPTIONS, options);
};

export const useGetAllEventSectorsByEventId = (input) => {
  return useQuery(QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID, input);
};

export const useGetAllCompanies = (input) => {
  return useQuery(QUERIES.GET_ALL_COMPANIES, input);
};

export const useGetEventSectorById = (input) => {
  return useLazyQuery(QUERIES.GET_EVENT_SECTOR_BY_ID, input);
};

export const useGetEventSectorRowsBySectorId = (input) => {
  return useQuery(QUERIES.GET_EVENT_SECTOR_ROWS_BY_SECTOR_ID, input);
};

export const useGetEventById = (options) => {
  return useQuery(QUERIES.GET_EVENT_BY_ID, options);
};

export const useLazyGetEventById = (options) => {
  return useLazyQuery(QUERIES.GET_EVENT_BY_ID, options);
};

export const useGetAttractionTicketTypes = (options) => {
  return useQuery(QUERIES.GET_ATTRACTION_TICKET_TYPES, options);
};

export const useGetAllAttractionTickets = (options) => {
  return useQuery(QUERIES.GET_ALL_ATTRACTION_TICKETS, options);
};

export const useGetReportStatuses = (options) => {
  return useQuery(QUERIES.GET_REPORT_STATUSES, options);
};

export const useGetAllReports = (options) => {
  return useQuery(QUERIES.GET_ALL_REPORTS, options);
};

export const useGetAllPosts = (options) => {
  return useQuery(QUERIES.GET_ALL_POSTS, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetPostById = (options) => {
  return useQuery(QUERIES.GET_POST_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useLazyGetPostById = (options) => {
  return useLazyQuery(QUERIES.GET_POST_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetReportById = (input) => {
  return useQuery(QUERIES.GET_REPORT_BY_ID, input);
};

export const useGetAllAds = (options) => {
  return useQuery(QUERIES.GET_ALL_ADS, options);
};

export const useGetAllAttractionSchedules = (options) => {
  return useQuery(QUERIES.GET_ALL_ATTRACTION_SCHEDULES, options);
};

export const useGetAllPositions = (options) => {
  return useQuery(QUERIES.GET_ALL_POSITIONS, options);
};

export const useGetOneAttraction = (input) => {
  return useQuery(QUERIES.GET_ONE_ATTRACTION, input);
};

export const useGetUsers = (input) => {
  return useQuery(QUERIES.GET_USERS, input);
};

export const useGetAllComments = (options) => {
  return useQuery(QUERIES.GET_ALL_COMMENTS, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetCurrentUser = (options) => {
  return useQuery(QUERIES.GET_CURRENT_USER, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useLazyGetCommentById = (options) => {
  return useLazyQuery(QUERIES.GET_COMMENT_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useLazyGetUserById = (options) => {
  return useLazyQuery(QUERIES.GET_USER_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useLazyGetCompanyById = (options) => {
  return useLazyQuery(QUERIES.GET_COMPANY_BY_ID, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetAdStatuses = (options) => {
  return useQuery(QUERIES.GET_AD_STATUSES, options);
};

export const useGetSeoDataUrls = (options) => {
  return useQuery(QUERIES.GET_SEO_DATA_URLS, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetSeoDataByUrl = (options) => {
  return useQuery(QUERIES.GET_SEO_DATA_BY_URL, {
    fetchPolicy: "network-only",
    ...options,
  });
};
