import { gql } from "@apollo/client";

export const CREATE_ATTRACTION_TICKET = gql`
  mutation createAttractionTicket($input: CreateAttractionTicket!) {
    createAttractionTicket(input: $input) {
      id
      name
      type
      price
      oldPrice
      currencyId
      bestPrice
    }
  }
`;
