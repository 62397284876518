export const transformCreateEditScheduleAttraction = ({
  isEdit = false,
  data = {},
  attractionId,
}) => {
  if (data) {
    const reqArr = Object.entries(data).map((item) => {
      const reqObj = {
        dayOfWeek: item[0],
        upTime: item[1].duration
          ? `${item[1].duration?.getHours()}:${item[1].duration?.getMinutes()}`
          : "00:00",
        startTime: item[1].from
          ? `${item[1].from?.getHours()}:${item[1].from?.getMinutes()}`
          : "00:00",
        stopTime: item[1].to
          ? `${item[1].to?.getHours()}:${item[1].to?.getMinutes()}`
          : "00:00",
        status: item[1].enabled ? "ENABLED" : "DISABLED",
      };

      if (isEdit) {
        Object.assign(reqObj, { id: item[1].id });
      } else {
        Object.assign(reqObj, { attractionId });
      }

      return reqObj;
    });

    return reqArr;
  }
  return [];
};
