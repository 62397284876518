import {
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Grid,
  GridItem,
  Divider,
  Flex,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import SwitchRHF from "components/react-hook-form/SwitchRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import RoundedRadioGroupRHF from "components/react-hook-form/RoundedRadioGroupRHF";
import RoundedRadioItem from "components/RoundedRadioGroup/RoundedRadioItem";
import { capitalize } from "utils/capitalize";

export default function AddNewUser(props) {
  const {
    onSubmit,
    cancelHandler,
    genderOptions,
    countriesOptions,
    citiesOptions,
    roleOptions,
    isEditMode,
    editUserReadOnly,
    countryChangeHandler,
  } = props;

  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <VStack spacing={6}>
          <Grid
            templateColumns="repeat(3, 1fr)"
            templateRows="repeat(2, 1fr)"
            columnGap={6}
            rowGap={4}
            w="100%"
          >
            <GridItem colSpan={2} rowSpan={1}>
              <TextFieldRHF
                id="firstName"
                name="firstName"
                inputType="text"
                label="First name"
                inputPlaceholder="Enter your name"
                errorMessage={errors?.firstName?.message}
                isDisabled={editUserReadOnly}
              />
            </GridItem>
            <GridItem colSpan={1} rowSpan={2}>
              <DropzoneFieldRHF
                name="photo"
                width="160px"
                height="160px"
                label="Upload photo"
                acceptStr="image/jpeg,image/png"
              />
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <TextFieldRHF
                id="lastName"
                name="lastName"
                inputType="text"
                label="Last name"
                isDisabled={editUserReadOnly}
                inputPlaceholder="Enter your last name"
                errorMessage={errors?.lastName?.message}
              />
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(3, 1fr)"
            templateRows="repeat(2, auto)"
            columnGap={6}
            rowGap={1}
            w="100%"
          >
            <GridItem rowSpan={1} colSpan={3}>
              <Text
                sx={{
                  marginLeft: 2,
                  fontFamily: "medium",
                  fontWeight: "medium",
                  fontSize: "xs",
                  color: "dark.500",
                }}
              >
                Gender
              </Text>
            </GridItem>
            <GridItem rowSpan={1} colSpan={3}>
              <RoundedRadioGroupRHF name="gender">
                {genderOptions.map((gender) => (
                  <RoundedRadioItem key={gender} value={gender}>
                    <Text>{capitalize(gender)}</Text>
                  </RoundedRadioItem>
                ))}
              </RoundedRadioGroupRHF>
            </GridItem>
          </Grid>
          <Grid
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(2, 1fr)"
            columnGap={6}
            rowGap={4}
            w="100%"
          >
            <GridItem rowSpan={1} colSpan={2}>
              <TextFieldRHF
                id="password"
                name="password"
                inputType="password"
                label="Password"
                inputPlaceholder="Enter your password"
                inputRightElement
                errorMessage={errors?.password?.message}
                isDisabled={editUserReadOnly}
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <TextFieldRHF
                id="phone"
                name="phone"
                inputType="tel"
                label="Phone number"
                inputPlaceholder="Enter your phone number"
                errorMessage={errors?.phone?.message}
                isDisabled={editUserReadOnly}
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <TextFieldRHF
                id="email"
                name="email"
                inputType="email"
                label="Email address"
                inputPlaceholder="Enter your email address"
                errorMessage={errors?.email?.message}
                isDisabled={editUserReadOnly}
              />
            </GridItem>
          </Grid>
          <Divider />
          <Grid
            templateColumns="repeat(1, 1fr)"
            templateRows="repeat(2, 1fr)"
            rowGap={4}
            w="100%"
          >
            <GridItem rowSpan={1} colSpan={1}>
              <SelectRHF
                name="country"
                label="Country"
                options={countriesOptions}
                placeholder="Select country"
                errorMessage={errors?.country?.message}
                isDisabled={editUserReadOnly}
                onChangeHandler={countryChangeHandler}
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <SelectRHF
                name="city"
                label="City"
                options={citiesOptions}
                placeholder="Select city"
                errorMessage={errors?.city?.message}
                isDisabled={!watch("country") || editUserReadOnly}
              />
            </GridItem>
          </Grid>
          <Divider />
          <Grid templateColumns="repeat(2, 1fr)" w="100%">
            <GridItem colSpan={1}>
              <SelectRHF
                name="userRole"
                label="User role"
                options={roleOptions}
                placeholder="Select role"
                isDisabled={editUserReadOnly}
                errorMessage={errors?.userRole?.message}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <Flex
                height="100%"
                width="full"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <SwitchRHF
                  name="sendToEmail"
                  label="Send login data via email"
                  isReadOnly={editUserReadOnly}
                />
              </Flex>
            </GridItem>
          </Grid>
        </VStack>
      </ModalBody>
      {!editUserReadOnly ? (
        <ModalFooter>
          <Flex w="100%" px={6}>
            <Button
              onClick={cancelHandler}
              variant="outline"
              colorScheme="blue.500"
            >
              Cancel
            </Button>
            <Spacer />
            <Button type="submit">
              {isEditMode ? "Update User" : "Create User"}
            </Button>
          </Flex>
        </ModalFooter>
      ) : null}
    </form>
  );
}
