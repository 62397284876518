import { useModalContext } from "hooks/useModalContext";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import NumberFormatRHF from "components/react-hook-form/NumberFormatRHF";
import {
  useAddVenueAreaTable,
  useUpdateVenueAreaTable,
} from "graphql/hooks/useMutations";
import { transformVenueTableData } from "connectors";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";

const DEFAULT_ZONE_TABLE_VALUES = {
  table_name: "",
  tables_count: "",
  seats_per_table: "",
};

function AddEditZoneTables() {
  const { openModal: openZoneTablesManagementModal } = useModalContext(
    "zoneTablesManagementModal"
  );

  const {
    modal: { defaultValues, hallName, zoneName, zoneId, tableId },
    closeModal: closeCurrentModal,
  } = useModalContext("addEditZoneTablesModal");

  const [addZoneTable] = useAddVenueAreaTable({
    variables: {
      zoneId,
    },
  });

  const [updateVenueAreaTable] = useUpdateVenueAreaTable({
    variables: {
      zoneId,
    },
  });

  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_ZONE_TABLE_VALUES,
  });

  const backToZoneTablesManagement = () => {
    methods.reset();
    openZoneTablesManagementModal({
      hallName,
      zoneName,
      zoneId,
    });
    closeCurrentModal();
  };

  const submitHandler = tableId ? updateVenueAreaTable : addZoneTable;

  const handleDone = () => {
    const [tableName, tableCount, seatsPerTable] = methods.getValues([
      "table_name",
      "tables_count",
      "seats_per_table",
    ]);
    submitHandler({
      variables: {
        input: {
          ...(tableId ? { id: tableId } : { venueAreaId: zoneId }),
          ...transformVenueTableData({ tableName, tableCount, seatsPerTable }),
        },
      },
    }).then(backToZoneTablesManagement);
  };

  return (
    <FormProvider {...methods}>
      <Flex w="full" flexDirection="column">
        <Flex w="full" alignItems="flex-end">
          <Text as="span" mr={6} color="grey.700" minW="40px">
            Hall:
          </Text>
          <Text fontWeight="medium" fontSize="md" as="h3">
            {hallName}
          </Text>
        </Flex>
        <Flex w="full" alignItems="flex-end" mb={10}>
          <Text as="span" mr={6} color="grey.700" minW="40px">
            Zone:
          </Text>
          <Text fontWeight="medium" fontSize="md" as="h3">
            {zoneName}
          </Text>
        </Flex>
        <VStack w="full" spacing={4}>
          <TextFieldRHF
            id="table_name"
            name="table_name"
            inputType="text"
            label="Table name"
            inputPlaceholder="Enter table name"
          />
          <HStack w="full" spacing={4}>
            <NumberFormatRHF
              name="tables_count"
              label="Tables count"
              inputProps={{ placeholder: "Enter number of tables" }}
            />
            <NumberFormatRHF
              name="seats_per_table"
              label="Seats per table"
              inputProps={{ placeholder: "Enter number of seats" }}
            />
          </HStack>
        </VStack>
        <Box w="full" py={4}>
          <Divider />
        </Box>
        <Flex justifyContent="space-between" w="full" pb={7}>
          <Button
            variant="outline"
            minW="140px"
            onClick={backToZoneTablesManagement}
          >
            Cancel
          </Button>
          <Button minW="140px" onClick={handleDone}>
            Done
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  );
}

export default AddEditZoneTables;
