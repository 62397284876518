import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import ModalButton from "components/ModalButton";
import FeedsListContainer from "containers/feeds/feedsList.container";
import { useOptionsContext } from "hooks/useOptionsContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

const addFeedButton = (
  <ModalButton modalName="feedCreateEditModal" label="Add post" w={44} />
);

function Feeds() {
  const { currentUser } = useOptionsContext();
  const { accessCreateFeeds, accessViewFeeds } = getAccessScopesControl();

  const createPostPermission =
    currentUser?.currentUser?.companyId &&
    Number.isInteger(currentUser?.currentUser?.companyId);

  return (
    <Box px={10} py={6} w="full">
      <PageHeader
        button={
          createPostPermission || accessCreateFeeds ? addFeedButton : null
        }
        mb={6}
      >
        Feed
      </PageHeader>
      <FeedsListContainer accessViewFeeds={accessViewFeeds} />
    </Box>
  );
}
export default Feeds;
