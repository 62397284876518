import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import ModalButton from "components/ModalButton";
import ArticlesListContainer from "containers/articles/articlesList.container";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

const addArticleButton = (
  <ModalButton modalName="articleCreateEditModal" label="Add article" w={44} />
);

function Articles() {
  const { accessCreateArticles, accessViewArticles } = getAccessScopesControl();
  return (
    <Box px={10} py={6} w="full">
      <PageHeader
        button={accessCreateArticles ? addArticleButton : null}
        mb={6}
      >
        Articles
      </PageHeader>
      <ArticlesListContainer accessViewArticles={accessViewArticles} />
    </Box>
  );
}
export default Articles;
