import { Text } from "@chakra-ui/react";
import LongDash from "components/TableComponents/LongDash";

export default function EmailLink({ email }) {
  if (email) {
    return (
      <Text as="a" href={`mailto:${email}`} target="_blank" color="blue.500">
        {email}
      </Text>
    );
  }

  return <LongDash />;
}
