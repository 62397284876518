import {
  Textarea as TextareaChakra,
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { styles } from "./chakra.styles";

const Textarea = forwardRef((props, ref) => {
  const {
    id,
    label,
    placeholder,
    isDisabled,
    isReadOnly,
    height,
    hasError,
    errorMessage,
    wrapperStyles,
    maxLength,
    value,
    ...inputProps
  } = props;

  return (
    <FormControl isInvalid={hasError} {...wrapperStyles}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <InputGroup size="lg">
        <TextareaChakra
          ref={ref}
          id={id}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          sx={styles({ hasError, height })}
          value={value}
          {...inputProps}
          maxLength={maxLength}
        />
      </InputGroup>
      {maxLength && (
        <Text sx={{ textAlign: "right", mt: "5px" }}>{`${
          value?.length || 0
        }/${maxLength}`}</Text>
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default Textarea;
