import { getDefaultLocation } from "utils/getDefaultLocation";
import { transformFieldsLangsFormat } from "utils/transformFieldsLangsFormat";

export const transformReceivedVenueGeneralInfo = ({
  responseData,
  countries,
  translatedValues,
  languages,
}) => {
  if (responseData) {
    const services = responseData.services?.map((serviceObj) => ({
      value: Number(serviceObj.service.id),
      label: serviceObj.service.name,
    }));

    const restrictions = responseData.restrictions?.map((restrictionObj) => ({
      value: Number(restrictionObj.restriction.id),
      label: restrictionObj.restriction.name,
    }));

    const contentValues = transformFieldsLangsFormat(
      languages,
      responseData.tr,
      [
        "title",
        "description",
        "policyTitle",
        "policyDescription",
        "infoTitle",
        "infoDescription",
      ]
    )?.reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;
        acc.description[valueObj.language.code] = valueObj.description;
        acc.policyTitle[valueObj.language.code] = valueObj.policyTitle;
        acc.policyDescription[valueObj.language.code] =
          valueObj.policyDescription;
        acc.infoTitle[valueObj.language.code] = valueObj.infoTitle;
        acc.infoDescription[valueObj.language.code] = valueObj.infoDescription;

        return acc;
      },
      {
        title: {},
        description: {},
        policyTitle: {},
        policyDescription: {},
        infoTitle: {},
        infoDescription: {},
      }
    );

    const venueSeoAdmin = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
    };

    if (responseData.venueSeoAdmin) {
      responseData.venueSeoAdmin.forEach((valueObj) => {
        venueSeoAdmin.metaTitle[valueObj.language.code] = valueObj.metaTitle;
        venueSeoAdmin.metaDescription[valueObj.language.code] =
          valueObj.metaDescription;
      });
    }

    return {
      id: responseData.id,
      generalInfo: {
        title: contentValues?.title,
        status: {
          value: responseData.status.toUpperCase(),
          label: responseData.status.toUpperCase(),
        },
        language: { label: "English", value: { id: 1, code: "en-US" } },
        isAddToSlider: responseData.isAddToSlider,
        price: responseData.price,
        country: getDefaultLocation(countries, responseData.cityId)
          .defaultCountry,
        city: getDefaultLocation(countries, responseData.cityId).defaultCity,
        location: {
          value: Number(responseData?.locationAdmin?.id),
          label: responseData?.locationAdmin?.title,
        },
        coordinates: {
          description: responseData.placeDetails?.address || "",
          place_id: responseData.geolocation || "",
        },
        category: {
          value: responseData.venueCategory.id,
          label: responseData.venueCategory.name,
        },
        rank: { value: responseData.rank, label: responseData.rank },
        description: contentValues?.description,
        restrictions,
        services,
        policy_title: contentValues?.policyTitle,
        policy_description: contentValues?.policyDescription,
        url: responseData.url,
        main_img: {
          preview: `${process.env.REACT_APP_API_URL}/${responseData.image}`,
        },
        slider_photo: responseData?.photo?.map((photo) => ({
          preview: `${process.env.REACT_APP_API_URL}/${photo}`,
        })),
        info_title: contentValues?.infoTitle,
        info_description: contentValues?.infoDescription,
        meta_title: venueSeoAdmin?.metaTitle,
        meta_description: venueSeoAdmin?.metaDescription,
      },
    };
  }

  return {};
};
