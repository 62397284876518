import { useCallback, useState } from "react";
import { Checkbox as ChakraCheckbox } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { AcceptIcon } from "components/Icons";

function CheckboxRHF(props) {
  const { name, isChecked, children, ...rest } = props;
  const { register } = useFormContext();

  const [checked, setChecked] = useState();
  const onHandleChange = useCallback((event) => {
    setChecked(event.currentTarget.checked);
  }, []);

  return (
    <ChakraCheckbox
      name={name}
      defaultChecked={isChecked}
      isChecked={checked}
      onChange={onHandleChange}
      {...register(name)}
      {...rest}
      icon={<AcceptIcon width="22px" height="22px" fill="blue.500" />}
    >
      {children}
    </ChakraCheckbox>
  );
}
export default CheckboxRHF;
