const offersContainer = {
  maxHeight: "386px",
  paddingRight: "24px",
  overflowY: "auto",
  whiteSpace: "wrap",
  "::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "white.500",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "blue.500",
    borderRadius: "3px",
  },
  "::-webkit-scrollbar-button": {
    backgroundColor: "transparent",
    height: "5px",
  },
};

const offersText = {
  fontSize: "xl",
  textAlign: "center",
  color: "black.500",
  paddingTop: 4,
};

export default { offersContainer, offersText };
