import dayjs from "dayjs";

export const transformAddEventGallery = (data) => {
  if (data) {
    return {
      title: data.title,
      description: data.description,
      url: data.url,
      categoryId: Number(data.category.value),
      date: dayjs(data.date).format("YYYY-MM-DD"),
      photos: data.photos.map((photo, index) => {
        if (photo?.image?.id) {
          return { ...photo, image: null, order: index };
        }
        return { ...photo, order: index };
      }),
    };
  }
  return {};
};
