import { Box } from "@chakra-ui/react";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";

function Table() {
  return (
    <Box w="full" textAlign="left">
      <TableBody hasSorting={false} variant="modalTable3" />
      <TablePagination />
    </Box>
  );
}

export default Table;
