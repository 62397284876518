import VenueProvider from "contexts/venue/venue.provider";

const withVenueProvider = (Component) => {
  // eslint-disable-next-line react/function-component-definition
  return (props) => {
    return (
      <VenueProvider>
        <Component {...props} />
      </VenueProvider>
    );
  };
};

export default withVenueProvider;
