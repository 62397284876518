import { LocationIcon } from "components/Icons";
import { Flex, Text } from "@chakra-ui/react";
import Status from "components/TableComponents/Status";
import CHS from "./chakra.styles";
import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => {
      return <Text>{value || "—"}</Text>;
    },
  },
  {
    Header: "URL",
    accessor: "url",
    Cell: ({ value }) => {
      return <Text>{value || "—"}</Text>;
    },
  },
  {
    Header: "Address",
    accessor: "address",
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <Flex>
          <LocationIcon fill="blue.500" w={5} height={5} mr={1} />
          <Text sx={CHS.addressSplit}>{value || "not available"}</Text>
        </Flex>
      );
    },
  },
  {
    Header: "Category",
    accessor: "category",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <Status value={value || "—"} />;
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options row={row} />;
    },
  },
];
