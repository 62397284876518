import { multiLangValidate } from "utils/multiLangValidate";
import * as yup from "yup";

const schema = (languages) => {
  return yup.object().shape({
    title: yup
      .object()
      .shape(multiLangValidate(languages, ["promo_text", "main_text"])),

    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),

    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),

    url: yup.string().required("URL is required"),

    show_on_main: yup.boolean(),

    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),

    promo_image: yup
      .mixed()
      .nullable()
      .test("preview", "has preview", (value) => {
        return typeof value?.preview === "string";
      }),

    promo_text: yup
      .object()
      .shape(multiLangValidate(languages, ["main_text", "title"])),

    tagsEdit: yup.array().nullable(),

    category: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),

    main_image: yup
      .mixed()
      .nullable()
      .test("preview", "has preview", (value) => {
        return typeof value?.preview === "string";
      }),

    main_text: yup
      .object()
      .shape(multiLangValidate(languages, ["promo_text", "title"])),

    author_name: yup.string(),

    author_avatar: yup.mixed().nullable(),

    publication_date: yup.string().nullable(),
  });
};

export default { schema };
