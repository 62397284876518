import { useContext, useMemo } from "react";
import { useGetAllOfferOptions } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetOffersOptions = ({ skip } = {}) => {
  const { isAuth } = useContext(AuthContext);
  const {
    updateOptionsState,
    offerLevels,
    modelTypeOffers,
    typeOffers,
    statusOffers,
  } = useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return (
      !isAuth ||
      !!offerLevels.length ||
      !!modelTypeOffers.length ||
      !!typeOffers.length ||
      !!statusOffers.length ||
      skip
    );
  }, [
    isAuth,
    offerLevels.length,
    modelTypeOffers.length,
    typeOffers.length,
    statusOffers.length,
    skip,
  ]);

  useGetAllOfferOptions({
    onCompleted: (response) => {
      updateOptionsState({
        offerLevels: response?.getAllOfferOptions?.offerLevel,
        modelTypeOffers: response?.getAllOfferOptions?.modelTypeOffer,
        typeOffers: response?.getAllOfferOptions?.typeOffer,
        statusOffers: response?.getAllOfferOptions?.offerStatus,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetOffersOptions;
