import { gql } from "@apollo/client";

export const UPDATE_ATTRACTION_COMPLETE_STATUS = gql`
  mutation updateAttractionCompleteStatus(
    $input: UpdateAttractionCompleteStatus
  ) {
    updateAttractionCompleteStatus(input: $input) {
      status
    }
  }
`;
