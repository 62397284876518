export const transformAddNewLocation = (data, languages) => {
  if (data) {
    const multiLanguageContent = languages.map((language) => ({
      languageId: Number(language?.id),
      title: data?.title?.[language.code],
      description: data.description?.[language.code],
    }));

    const inputObject = {
      id: data.id,
      cityId: Number(data.city?.value),
      content: multiLanguageContent,
      address: data?.address,
      isActive: data.isActive,
    };

    if (data.image?.preview && data.image?.path) {
      Object.assign(inputObject, { image: data.image });
    }
    return inputObject;
  }

  return {};
};
