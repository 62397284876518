import { useForm, FormProvider } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";
import HallManagement from "components/Modals/HallManagement";
import HyptModal from "components/HyptModal";
import AddEditZone from "components/Modals/AddEditZone";
import ZoneTablesManagement from "components/Modals/ZoneTablesManagement";
import AddEditZoneTables from "components/Modals/AddEditZoneTables";
import { useCallback, useEffect, useState } from "react";
import {
  useAddVenueHall,
  useUpdateVenueHall,
} from "graphql/hooks/useMutations";
import {
  transformHallCreatingData,
  transformHallUpdatingData,
  transformHallFormData,
} from "connectors/transformHallData";
import { useGetHallById } from "graphql/hooks/useQueries";

const INITIAL_VALUES = {
  hall_name: "",
  map_image: "",
  short_description: "",
};

function FloorMapManagementContainer() {
  const { modal: hallModal, closeModal: closeHallModal } = useModalContext(
    "hallManagementModal"
  );
  const { modal: zoneModal, closeModal: closeZoneModal } =
    useModalContext("addEditZoneModal");
  const { modal: zoneTablesModal, closeModal: closeZoneTablesModal } =
    useModalContext("zoneTablesManagementModal");
  const {
    modal: addEditZoneTablesModal,
    closeModal: closeAddEditZoneTablesModal,
  } = useModalContext("addEditZoneTablesModal");

  const [hallId, setHallId] = useState();

  const [addHall] = useAddVenueHall();
  const [updateVenueHall] = useUpdateVenueHall();
  const [getHallById] = useGetHallById();

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
  });

  const onCloseModal = (cb) => () => {
    cb();
    // Cleanup (because of modal persists in the dom after closing action)
    setHallId(undefined);
    methods.reset(INITIAL_VALUES);
  };

  const handleSave = useCallback(
    (data) => {
      if (hallId) {
        updateVenueHall({
          variables: {
            input: { id: hallId, ...transformHallUpdatingData(data) },
          },
        }).finally(() => {
          methods.reset(data);
        });
      } else {
        addHall({
          variables: {
            input: {
              venueId: Number(hallModal.id),
              ...transformHallCreatingData(data),
            },
          },
        })
          .then((response) => {
            if (response?.data.venueHall.id) {
              setHallId(response.data.venueHall.id);
            }
          })
          .finally(() => {
            methods.reset(data);
          });
      }
    },
    [addHall, hallId, updateVenueHall, hallModal]
  );

  useEffect(() => {
    if (hallModal.hallId) {
      setHallId(hallModal.hallId);
      getHallById({
        variables: {
          id: hallModal.hallId,
        },
      }).then((response) => {
        methods.reset(transformHallFormData(response?.data?.hallData));
      });
    }
  }, [hallModal.hallId]);

  return (
    <>
      <HyptModal
        isOpen={hallModal.isOpen}
        onClose={onCloseModal(closeHallModal)}
        heading={hallId ? "Edit Hall" : "Create Hall"}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSave)}>
            <HallManagement
              handleClose={onCloseModal(closeHallModal)}
              hallId={hallId}
            />
          </form>
        </FormProvider>
      </HyptModal>
      <HyptModal
        isOpen={zoneModal.isOpen}
        onClose={onCloseModal(closeZoneModal)}
        heading={zoneModal.zoneId ? "Edit Zone" : "Add Zone"}
      >
        <FormProvider {...methods}>
          <AddEditZone />
        </FormProvider>
      </HyptModal>
      <HyptModal
        isOpen={zoneTablesModal.isOpen}
        onClose={onCloseModal(closeZoneTablesModal)}
        heading="Edit Zone Tables"
      >
        <FormProvider {...methods}>
          <ZoneTablesManagement />
        </FormProvider>
      </HyptModal>
      <HyptModal
        isOpen={addEditZoneTablesModal.isOpen}
        onClose={onCloseModal(closeAddEditZoneTablesModal)}
        heading={addEditZoneTablesModal.tableId ? "Edit Table" : "Add Table"}
      >
        <FormProvider {...methods}>
          <AddEditZoneTables />
        </FormProvider>
      </HyptModal>
    </>
  );
}

export default FloorMapManagementContainer;
