import dayjs from "dayjs";

export const transformUpdateVenuesGallery = (data, getGallery) => {
  if (data) {
    const isSameUrl = data?.url === getGallery()?.url;

    const photosOrders = getGallery()?.photos.map((photo) => photo.order);

    return {
      title: data.title,
      description: data.description,
      ...(!isSameUrl && { url: data.url }),
      categoryId: Number(data.category.value),
      date: dayjs(data.date).format("YYYY-MM-DD"),
      photos: data.photos.map((photo, index) => {
        // there was an image and we dont update it
        if (photo?.image?.id) {
          return {
            id: photo.image.id,
            content: {
              order: index,
              description: photo.description,
              altTag: photo.altTag,
            },
          };
        }
        // there was an image and we update it
        if (photosOrders.includes(photo?.order)) {
          const imgIndex = getGallery()?.photos.findIndex(
            (item) => item.order === photo.order
          );
          return {
            id: getGallery().photos[imgIndex]?.image.id,
            content: {
              image: photo?.image,
              order: index,
              description: photo.description,
              altTag: photo.altTag,
            },
          };
        }
        // we add new image
        return {
          content: {
            image: photo?.image,
            order: index,
            description: photo?.description,
            altTag: photo?.altTag,
          },
        };
      }),
    };
  }
  return {};
};
