import * as yup from "yup";
import { multiLangValidate } from "utils/multiLangValidate";

const schema = (languages) => {
  return yup.object().shape({
    name: yup
      .object()
      .shape(
        multiLangValidate(languages, ["short_description", "description"])
      ),

    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),

    short_description: yup
      .object()
      .shape(multiLangValidate(languages, ["name", "description"])),

    file: yup.mixed().nullable(),

    image: yup.mixed().nullable(),

    description: yup
      .object()
      .shape(multiLangValidate(languages, ["name", "short_description"])),
  });
};

export default { schema };
