import { Icon } from "@chakra-ui/react";

export default function SortedAscIcon({ fill = "#CACACA", ...props }) {
  return (
    <Icon viewBox="0 0 8 5" {...props}>
      <path
        d="M4.00006 0.545454L7.18187 4.36364H0.818237L4.00006 0.545454Z"
        fill={fill}
      />
    </Icon>
  );
}
