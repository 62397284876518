export const transformAttractionsTicketsSortState = (state) => {
  if (!state) {
    return null;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };

  if (state.id === "tableType") requestObj.field = "type";
  if (state.id === "tablePrice") requestObj.field = "price";
  if (state.id === "tableOldPrice") requestObj.field = "oldPrice";

  return requestObj;
};
