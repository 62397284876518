import {
  REPORT_USER_ACTIONS,
  REPORT_COMMENT_ACTIONS,
  REPORT_POST_ACTIONS,
} from "constants/constants";

export const getReportActionOptions = (modelName) => {
  if (modelName === "USER") return REPORT_USER_ACTIONS;

  if (modelName === "POST") return REPORT_POST_ACTIONS;

  return REPORT_COMMENT_ACTIONS;
};
