import { gql } from "@apollo/client";

export const ADD_VENUE_AREA = gql`
  mutation addVenueArea($input: AddVenueArea) {
    venueZone: addVenueArea(input: $input) {
      id
      zone: area
      venueHallId
      ticketType
      venueZoneTable: venueAreaTable {
        id
        table
        venueZoneId: venueAreaId
        seats
      }
    }
  }
`;
