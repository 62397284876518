import { Button, Divider, Flex, Text, VStack, Box } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import { useModalContext } from "hooks/useModalContext";
import {
  useAddVenueArea,
  useUpdateVenueArea,
} from "graphql/hooks/useMutations";
import { transformZoneData } from "connectors";

// TODO Replace with backend ticket type options
const options = [
  {
    label: "Entry and tables",
    value: "ENTRY_AND_TABLES",
  },
  {
    label: "Entry",
    value: "ENTRY",
  },
  {
    label: "Tables",
    value: "TABLES",
  },
];

const DEFAULT_ZONE_VALUES = {
  zone_name: "",
  ticket_type: "",
};

function AddEditZone() {
  const {
    modal: { defaultValues, hallName, hallId, zoneId },
    closeModal: closeCurrentModal,
  } = useModalContext("addEditZoneModal");

  const { openModal: openHallManagementModal } = useModalContext(
    "hallManagementModal"
  );

  const [addVenueZone] = useAddVenueArea({
    variables: {
      hallId,
    },
  });

  const [updateVenueZone] = useUpdateVenueArea({
    variables: {
      hallId,
    },
  });

  const methods = useForm({
    defaultValues: defaultValues || DEFAULT_ZONE_VALUES,
  });

  const backToHallManagementModal = () => {
    methods.reset();
    openHallManagementModal({ hallId });
    closeCurrentModal();
  };

  const handleAddZone = async (data) => {
    const response = await addVenueZone({
      variables: {
        input: {
          venueHallId: hallId,
          ...transformZoneData(data),
        },
      },
    });

    if (response?.data?.venueZone?.id) {
      backToHallManagementModal();
    }
  };

  const handleEditZone = async (data) => {
    const response = await updateVenueZone({
      variables: {
        input: {
          id: zoneId,
          ...transformZoneData(data),
        },
      },
    });

    if (response?.data?.venueZone?.id) {
      backToHallManagementModal();
    }
  };

  const submitHandler = zoneId ? handleEditZone : handleAddZone;

  const handleDone = () => {
    const [zoneName, ticketType] = methods.getValues([
      "zone_name",
      "ticket_type",
    ]);

    submitHandler({ zone_name: zoneName, ticket_type: ticketType });
  };

  return (
    <FormProvider {...methods}>
      <VStack spacing={6}>
        <Flex w="full" alignItems="flex-end">
          <Text as="span" mr={6} color="grey.700">
            Hall:
          </Text>
          <Text fontWeight="medium" fontSize="md" as="h3">
            {hallName}
          </Text>
        </Flex>
        <VStack spacing={4} w="full">
          <TextFieldRHF
            label="Zone name"
            name="zone_name"
            inputType="text"
            inputProps={{ placeholder: "Enter zone name" }}
          />
          <SelectRHF
            name="ticket_type"
            label="Ticket type"
            options={options}
            placeholder="Select ..."
          />
        </VStack>
        <Box w="full" py={4}>
          <Divider />
        </Box>
        <Flex justifyContent="space-between" w="full" pb={7}>
          <Button
            variant="outline"
            minW="140px"
            onClick={backToHallManagementModal}
          >
            Cancel
          </Button>
          <Button minW="140px" onClick={handleDone}>
            Done
          </Button>
        </Flex>
      </VStack>
    </FormProvider>
  );
}

export default AddEditZone;
