export default {
  baseStyle: {
    color: "blue.500",
    fontFamily: "normal",
    fontWeight: "normal",
    fontSize: "sm",
    lineHeight: 5,
    textDecoration: "none",
    borderBottomWidth: "px",
    borderBottomStyle: "solid",
    borderBottomColor: "blue.500",
    _hover: {
      textDecoration: "none",
    },
  },
};
