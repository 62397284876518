import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "components/Sidebar";
import Header from "components/Header";
import { SIDEBAR_WIDTH } from "constants/constants";

export default function Layout({ children }) {
  return (
    <Flex alignItems="stretch">
      <Box>
        <Sidebar />
      </Box>
      <Box
        width={`calc(100% - ${SIDEBAR_WIDTH}px)`}
        minH="100vh"
        marginLeft="auto"
        overflowX="hidden"
        d="flex"
        flexDirection="column"
      >
        <Header />
        {children}
      </Box>
    </Flex>
  );
}
