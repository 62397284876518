import { Flex, Text } from "@chakra-ui/react";

export default function TableLink({
  value,
  path,
  variant = "tableLink1",
  target = "_blank",
  width = "100px",
  ...rest
}) {
  return (
    <Flex alignItems="center" width={width}>
      <Text as="a" href={path} variant={variant} target={target} {...rest}>
        {value}
      </Text>
    </Flex>
  );
}
