import { Flex, Text } from "@chakra-ui/react";

export function PaginationItem({ onCurrentPage, onClick, children, ...rest }) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width={8}
      height={8}
      borderRadius="sm"
      mr={2}
      fontWeight="medium"
      fontSize="sm"
      fontFamily="medium"
      lineHeight={5}
      bg={onCurrentPage ? "blue.500" : "none"}
      cursor="pointer"
      onClick={onClick}
      {...rest}
    >
      <Text color={onCurrentPage ? "white.900" : "black.900"}>{children}</Text>
    </Flex>
  );
}
