export const transformOffersListSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    field:
      {
        name: "title",
        type: "type",
        status: "status",
        countUsedOffer: "countUsedOffer",
        total: "forInfluencerCount",
        userType: "isForInfluencerOnly",
      }[state.id] || state.id,
    type: state.desc ? "DESC" : "ASC",
  };
};
