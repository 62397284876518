import GeneralInformation from "pages/Venues/VenueManagement/GeneralInformation";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect } from "react";
import stepsContext from "contexts/steps/steps.context";
import {
  useAddVenueGeneralInfo,
  useUpdateVenueGeneralInfo,
} from "graphql/hooks/useMutations";
import {
  transformVenueGeneralInfoData,
  transformReceivedVenueGeneralInfo,
  transformLanguageSelect,
  transformVenueOptions,
  transformCountryOptions,
  transformCityOptions,
  transformServicesOptions,
  transformRestrictionsOptions,
  transformLanguagesToCodeList,
  transformLocationOptions,
} from "connectors";
import { useVenueContext } from "hooks/useVenueContext.hook";
import { useOptionsContext } from "hooks/useOptionsContext";
import { onLanguageChange } from "utils/onLanguageChange";
import {
  ADD_VENUES_GENERAL_INFO_SCHEMA,
  EDIT_VENUES_GENERAL_INFO_SCHEMA,
} from "schemas";
import { useGetAllLocations } from "graphql/hooks/useQueries";
import useGetRestrictions from "hooks/useGetRestrictions.hook";
import useGetServices from "hooks/useGetServices.hook";
import useGetVenuesOptions from "hooks/useGetVenuesOptions.hook";

function GeneralInformationContainer() {
  useGetRestrictions();
  useGetServices();
  useGetVenuesOptions();

  const { venue, updateVenueState, getGeneralInfo, getVenueId } =
    useVenueContext();
  const {
    languages,
    countries,
    venueStatuses,
    venueCategories,
    venueRanks,
    services,
    restrictions,
  } = useOptionsContext();

  const methods = useForm({
    defaultValues: getGeneralInfo(),
    resolver: yupResolver(
      getVenueId()
        ? EDIT_VENUES_GENERAL_INFO_SCHEMA(languages)
        : ADD_VENUES_GENERAL_INFO_SCHEMA(languages)
    ),
  });

  const { nextStep } = useContext(stepsContext);
  const [addVenueGeneralInfo] = useAddVenueGeneralInfo();
  const [updateVenueGeneralInfo] = useUpdateVenueGeneralInfo();

  const { data: allLocationsData } = useGetAllLocations({
    variables: {
      options: {
        isActive: true,
      },
    },
  });

  const translatedValues = transformLanguagesToCodeList(languages).reduce(
    (acc, code) => {
      acc[code] = "";
      return acc;
    },
    {}
  );

  const [selectedLanguage, selectedCountry] = methods.watch([
    "language",
    "country",
  ]);

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  const countryChangeHandler = () => {
    methods.setValue("city", null);
  };

  const onSubmit = (formData) => {
    if (getVenueId()) {
      Object.assign(formData, { id: getVenueId() });

      updateVenueGeneralInfo({
        variables: {
          input: transformVenueGeneralInfoData({
            formData,
            languages,
            prevURL: getGeneralInfo().url,
          }),
        },
        onCompleted: (response) => {
          updateVenueState(
            transformReceivedVenueGeneralInfo({
              responseData: response?.updateVenueGeneralInfo,
              countries,
              translatedValues,
              languages,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Venue under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Venue under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      addVenueGeneralInfo({
        variables: {
          input: transformVenueGeneralInfoData({
            formData,
            languages,
            prevURL: getGeneralInfo().url,
            isCreate: true,
          }),
        },
        onCompleted: (response) => {
          updateVenueState(
            transformReceivedVenueGeneralInfo({
              responseData: response?.createVenueGeneralInfo,
              countries,
              translatedValues,
              languages,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Venue under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Venue under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (venue.id) {
      methods.reset(getGeneralInfo());
    }
  }, [venue.id]);

  return (
    <FormProvider {...methods}>
      <GeneralInformation
        onSubmit={methods.handleSubmit(onSubmit)}
        languageOptions={transformLanguageSelect(languages)}
        countriesOptions={transformCountryOptions(countries)}
        citiesOptions={transformCityOptions(selectedCountry, countries)}
        statusOptions={transformVenueOptions(venueStatuses)}
        categoryOptions={transformVenueOptions(venueCategories)}
        rankOptions={transformVenueOptions(venueRanks)}
        serviceOptions={transformServicesOptions(services)}
        restrictionOptions={transformRestrictionsOptions(restrictions)}
        locationOptions={transformLocationOptions(
          allLocationsData?.getAllLocations?.rows
        )}
        selectedLanguage={selectedLanguage}
        languageChangeHandler={languageChangeHandler}
        countryChangeHandler={countryChangeHandler}
      />
    </FormProvider>
  );
}

export default GeneralInformationContainer;
