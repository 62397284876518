import { Button } from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import { EyeOpenIcon } from "components/Icons";
import {
  useLazyGetCommentById,
  useLazyGetCompanyById,
  useLazyGetPostById,
  useLazyGetUserById,
} from "graphql/hooks/useQueries";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function RedirectFromReportsReview(props) {
  const { parentInstance, viewButtonIsDisabled, setViewButtonIsDisabled } =
    props;

  const { openModal: openPostModal } = useModalContext("feedCreateEditModal");
  const { openModal: openCommentModal } = useModalContext(
    "commentCreateEditModal"
  );
  const { openModal: openUserModal } = useModalContext("createNewUserModal");
  const { openModal: openModalCompany } = useModalContext(
    "createNewCompanyModal"
  );

  const { accessViewUsers } = getAccessScopesControl();

  const [getPost] = useLazyGetPostById({
    variables: {
      id: parentInstance?.id,
    },
  });

  const [getComment] = useLazyGetCommentById({
    variables: {
      id: parentInstance?.id,
    },
  });

  const [getUser] = useLazyGetUserById({
    variables: {
      id: parentInstance?.id,
    },
  });
  const [getCompany] = useLazyGetCompanyById({
    variables: {
      id: parentInstance?.id,
    },
  });

  const viewHandler = {
    Post: () => {
      setViewButtonIsDisabled(true);
      getPost()
        .then((response) => {
          if (response.data) {
            openPostModal({
              editPostReadOnly: true,
              editPostFromComments: true,
              editPost: response.data?.getPostById,
              isEditMode: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setViewButtonIsDisabled(false);
        });
    },
    Comment: () => {
      setViewButtonIsDisabled(true);
      getComment()
        .then((response) => {
          if (response.data) {
            openCommentModal({
              editCommentReadOnly: true,
              editComment: {
                ...response.data?.getCommentById,
                parent: { parent: response.data?.getCommentById?.parent },
                author: response.data?.getCommentById?.commentAuthor,
                publication_date: response.data?.getCommentById?.createdAt,
              },
              isEditMode: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setViewButtonIsDisabled(false);
        });
    },
    Company: () => {
      setViewButtonIsDisabled(true);
      if (!accessViewUsers) {
        // В будущем будет переход на публичную страницу компании на вебе
        window.open(`${process.env.REACT_APP_API_URL}`);
      } else {
        getCompany()
          .then((response) => {
            if (response.data) {
              openModalCompany({
                editUserReadOnly: true,
                company: {
                  ...response.data?.getCompanyById,
                  company: response.data?.getCompanyById.companyName,
                  image: response.data?.getCompanyById.image
                    ? {
                        preview: `${process.env.REACT_APP_API_URL}/${response.data?.getCompanyById.image}`,
                      }
                    : null,
                },
                isEditMode: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setViewButtonIsDisabled(false);
          });
      }
    },
    User: () => {
      setViewButtonIsDisabled(true);
      if (!accessViewUsers) {
        // В будущем будет переход на публичную страницу юзера на вебе
        window.open(`${process.env.REACT_APP_API_URL}`);
      } else {
        getUser()
          .then((response) => {
            if (response.data) {
              openUserModal({
                editUserReadOnly: true,
                editUser: response.data?.getUserById,
                isEditMode: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setViewButtonIsDisabled(false);
          });
      }
    },
  }[parentInstance?.__typename];

  return (
    <Button
      onClick={viewHandler}
      variant="outline"
      alignSelf="flex-end"
      leftIcon={
        <EyeOpenIcon
          fill="blue.500"
          stroke="white.900"
          width="22px"
          height="22px"
        />
      }
      isDisabled={viewButtonIsDisabled}
    >
      View
    </Button>
  );
}
