import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useRemoveAd } from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ ad }) {
  const { showToast } = useToastContext();
  const { openModal: openEditMModal } = useModalContext("adAddEditModal");
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessUpdateBanners, accessDeleteBanners } = getAccessScopesControl();

  const editAd = () => {
    openEditMModal({
      ad,
      isEditMode: true,
    });
  };

  const [remodeAd] = useRemoveAd({
    variables: {
      id: ad.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The ad has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: ad.client,
      type: "advertisement from",
      onDelete: remodeAd,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={editAd}
        isDisabled={Boolean(!accessUpdateBanners)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteBanners)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
