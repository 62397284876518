import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";

export const transformLocations = (data = []) => {
  if (data) {
    return data?.map((location) => ({
      id: location.id,
      name: location.tr?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.title,
      cityTable: location?.city?.name,
      address: location.address,
      image: location.image,
      active: location.isActive,
      // forEditLocation
      tr: location.tr,
      city: location.city,
    }));
  }
  return [];
};
