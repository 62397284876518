import React, { useMemo } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Image,
  InputGroup,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect, { components } from "react-select";
import { DropdownIndicator } from "components/react-hook-form/SelectRHF";
import { RoundCancel } from "components/Icons";

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <RoundCancel
        fill="grey.500"
        w="14px"
        h="14px"
        _hover={{ fill: "red.500" }}
      />
    </components.MultiValueRemove>
  );
}

function Option({ data, ...rest }) {
  return (
    <components.Option {...rest}>
      <Flex alignItems="center">
        {data.icon && (
          <Image
            src={`${process.env.REACT_APP_API_URL}/${data?.icon}`}
            style={{ width: 24, marginRight: 5 }}
            alt={data.label}
          />
        )}
        <Text>{data.label}</Text>
      </Flex>
    </components.Option>
  );
}

export default function MultiSelectRHF(props) {
  const {
    name,
    label,
    options,
    placeholder,
    isDisabled = false,
    isLoading = false,
    onChangeHandler,
    isBanner,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const theme = useTheme();
  const styles = useMemo(
    () => ({
      container: (provided) => {
        return {
          ...provided,
          width: "100%",
          ":before": {
            content: `"${placeholder}"`,
            position: "absolute",
            zIndex: "5",
            left: "20px",
            top: "10px",
            color: theme.colors.dark[300],
          },
        };
      },
      control: (provided) => ({
        ...provided,
        height: "42px",
        backgroundColor: theme.colors.white[900],
        borderRadius: "22px",
        borderColor: errors[name]
          ? theme.colors.red[500]
          : theme.colors.white[500],
        ":hover": {
          borderColor: errors[name]
            ? theme.colors.red[500]
            : theme.colors.white[500],
          boxShadow: "none",
        },
        ":focus": {
          borderColor: theme.colors.blue[500],
        },
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        margin: "0",
        display: "none",
      }),
      valueContainer: (provided, state) => {
        if (state.selectProps?.value?.length) {
          if (isBanner) {
            return {
              ...provided,
              position: "absolute",
              top: "80px",
              left: "-104%",
              right: "0",
              display: "flex",
              flexWrap: "wrap",
              maxHeight: "76px",
              padding: 0,
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                width: "6px",
                backgroundColor: "transparent",
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: theme.colors.blue[500],
                borderRadius: "3px",
              },
              "::-webkit-scrollbar-button": {
                backgroundColor: "transparent",
                height: "30px",
              },
            };
          }

          return {
            ...provided,
            position: "relative",
            top: "50px",
            display: "flex",
            flexWrap: "wrap",
            maxHeight: "76px",
            marginRight: "-55px",
            width: "100%",
            padding: 0,
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              width: "6px",
              backgroundColor: "transparent",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: theme.colors.blue[500],
              borderRadius: "3px",
            },
            "::-webkit-scrollbar-button": {
              backgroundColor: "transparent",
              height: "30px",
            },
          };
        }
        return {
          ...provided,
          display: "flex",
          flexWrap: "no-wrap",
          paddingLeft: "19px",
          maxHeight: "40px",
          width: "100%",
          padding: "0",
        };
      },
      multiValue: (provided) => ({
        ...provided,
        position: "relative",
        minHeight: "30px",
        margin: "0 8px 8px 0",
        paddingLeft: "3px",
        border: "1px solid",
        borderColor: theme.colors.grey[500],
        borderRadius: "15px",
        backgroundColor: theme.colors.white[500],
        textTransform: "lowercase",
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        ":hover": {
          backgroundColor: "transparent",
        },
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        alignSelf: "flex-start",
        alignItems: "flex-start",
        marginLeft: "auto",
        marginRight: "15px",
        opacity: isDisabled ? "0.5" : 1,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
      }),
      menuList: (provided) => ({
        ...provided,
        zIndex: 10,
        "::-webkit-scrollbar": {
          width: "6px",
          backgroundColor: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#00AEEF",
          borderRadius: "3px",
        },
        "::-webkit-scrollbar-button": {
          backgroundColor: "transparent",
          height: "4px",
        },
      }),
    }),
    [theme, isDisabled, errors[name]]
  );

  const handleChange = (hookChangeFunc) => (option) => {
    hookChangeFunc(option);
    onChangeHandler?.(option);
  };

  return (
    <FormControl isInvalid={errors[name]}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <InputGroup size="lg">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              isMulti
              name={name}
              value={value}
              onChange={handleChange(onChange)}
              options={options}
              placeholder={placeholder}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isSearchable={false}
              isClearable={false}
              components={{
                DropdownIndicator,
                MultiValueRemove,
                Option,
              }}
              styles={styles}
            />
          )}
        />
      </InputGroup>
    </FormControl>
  );
}
