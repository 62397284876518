import { gql } from "@apollo/client";

export const ADD_VENUE_EVENTS = gql`
  mutation AddVenueEvents($input: AddVenueEvent) {
    addVenueEvents(input: $input) {
      id
      title
      image
    }
  }
`;
