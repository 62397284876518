import { Icon } from "@chakra-ui/react";

export default function AddIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        d="M13.5 6.5V7.5H7.5V13.5H6.5V7.5H0.5V6.5H6.5V0.5H7.5V6.5H13.5Z"
        // fill={fill}
      />
    </Icon>
  );
}
