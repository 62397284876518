export const styles = ({ vertical, labelColor }) => ({
  switchControl: {
    width: "auto",
    display: "flex",
    flexDirection: vertical ? "column" : "row",
    justifyContent: vertical ? "space-between" : "center",
    alignItems: vertical ? "flex-end" : "center",
  },

  switchLabel: {
    fontWeight: "400",
    marginTop: 0,
    marginLeft: 0,
    marginBottom: vertical ? "10px" : "0",
    marginRight: vertical ? "0" : "10px",
    color: labelColor ? labelColor : "initial",
  },
});
