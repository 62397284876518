import { gql } from "@apollo/client";

export const GET_COMPANY_BY_ID = gql`
  query GetCompanyById($id: Int!) {
    getCompanyById(id: $id) {
      id
      image
      companyName
      cityId
      adress
      licenceNumber
      photos {
        id
        photo
        companyId
      }
      owners {
        id
        ownerName
        companyId
        company {
          id
          image
          companyName
          cityId
          city {
            id
            name
          }
          licenceNumber
          photos {
            id
            photo
            companyId
          }
        }
        photos {
          id
          photo
          ownerId
        }
      }
      users {
        id
        firstName
        lastName
        email
        phone
        photo
        cityId
        companyId
      }
    }
  }
`;
