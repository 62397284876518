import { gql } from "@apollo/client";

export const GET_ALL_ATTRACTION_TICKETS = gql`
  query getAllAttractionTickets($params: GetAllAttractionTickets) {
    getAllAttractionTickets(params: $params) {
      count
      rows {
        id
        name
        type
        price
        oldPrice
        currencyId
        bestPrice
      }
    }
  }
`;
