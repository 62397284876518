import { Icon } from "@chakra-ui/react";

export default function ErrorIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <path
        d="M3.91797 3.91781L1 1.00049L3.91797 3.91846L6.83464 1.00114L3.91797 3.91846L6.83594 6.83577L3.91797 3.91846L1 6.83708L3.91797 3.91781Z"
        stroke="#FE6E6E"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </Icon>
  );
}
