import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { GlassIcon } from "components/Icons";

function SearchField(props) {
  const {
    name,
    label,
    id,
    placeholder,
    onChange,
    isDisabled = false,
    ...searchFieldProps
  } = props;

  return (
    <FormControl {...searchFieldProps}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <InputGroup>
        <InputLeftElement
          width="15"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            variant="iconButton"
            mr="2"
            icon={<GlassIcon stroke="dark.300" fill="none" w="6" h="6" />}
          />
        </InputLeftElement>
        <Input
          id={id}
          type="search"
          isDisabled={isDisabled}
          placeholder={placeholder}
          onChange={onChange}
        />
      </InputGroup>
    </FormControl>
  );
}

export default SearchField;
