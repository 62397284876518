import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query getCountries {
    getCountries {
      id
      code
      name
      Cities {
        id
        name
      }
    }
  }
`;
