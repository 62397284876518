import {
  ModalBody,
  ModalFooter,
  HStack,
  Box,
  VStack,
  Flex,
  Button,
  Spacer,
  Text,
  Wrap,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import RoundedRadioGroupRHF from "components/react-hook-form/RoundedRadioGroupRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextEditorRHF from "components/react-hook-form/TextEditorRHF";
import NumberFormatRHF from "components/react-hook-form/NumberFormatRHF";
import RoundedRadioItem from "components/RoundedRadioGroup/RoundedRadioItem";
import GoogleAutocompleteRHF from "components/react-hook-form/GoogleAutocompleteRHF";
import SwitchRHF from "components/react-hook-form/SwitchRHF";
import LanguageIndicators from "components/LanguageIndicators";
import { LocationIcon } from "components/Icons";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";
import { useModalContext } from "hooks/useModalContext";
import { useFormContext } from "react-hook-form";

export default function OfferCreateEditModal(props) {
  const {
    onSubmit,
    cancelHandler,
    isInfluencer,
    onChangeOfferType,
    offerTypesOptions,
    languagesOptions,
    currencyOptions,
    influencerLevelsOptions,
    categoriesOptions,
    statusesOptions,
    isDiscount,
    isEdit,
    selectedLanguage,
    languageChangeHandler,
  } = props;

  const { openModal } = useModalContext("googleMapModal");
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const coordinates = watch("address");

  const openGoogleMap = () => {
    openModal({
      place_id: coordinates.place_id,
      description: coordinates.description,
      getLocationFromMap: (geolocation) => {
        setValue("address", {
          description: geolocation.description,
          place_id: geolocation.place_id,
        });
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <HStack spacing={6} w="full" align="stretch" justify="space-between">
            <Wrap position="relative">
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="title"
                name={`title.${useGetLanguageKey(selectedLanguage)}`}
                inputType="text"
                inputPlaceholder="Title goes here"
                label="Offer Name"
                width="400px"
              />
            </Wrap>
            <SwitchRHF name="isAddToSlider" label="Show on slider" vertical />
          </HStack>
          <HStack w="full" spacing={6}>
            <SelectRHF
              name="language"
              label="Content language"
              placeholder="Select content language"
              options={languagesOptions}
              onChangeHandler={languageChangeHandler}
            />
            <SelectRHF
              name="status"
              label="Status"
              placeholder="Select status"
              options={statusesOptions}
            />
          </HStack>
          <RoundedRadioGroupRHF name="userType">
            <RoundedRadioItem value="Regular">
              <Text>Regular</Text>
            </RoundedRadioItem>
            <RoundedRadioItem value="Influencer">
              <Text>Influencer</Text>
            </RoundedRadioItem>
          </RoundedRadioGroupRHF>
          {isInfluencer && (
            <HStack spacing={6} w="full">
              <SelectRHF
                name="inf_rank"
                label="Influencer rank"
                placeholder="Select rank"
                options={influencerLevelsOptions}
              />
              <NumberFormatRHF
                name="inf_numberOfUses"
                label="Number of uses"
                inputProps={{ placeholder: "Enter number of uses" }}
              />
            </HStack>
          )}
          <HStack w="full">
            <Box w="100%">
              <SelectRHF
                name="type"
                label="Offer type"
                options={offerTypesOptions}
                onChangeHandler={onChangeOfferType}
              />
            </Box>
            {isDiscount && (
              <Box minW="160px">
                <TextFieldRHF
                  name="discount"
                  inputPlaceholder="%"
                  label="Discount"
                  isDisabled
                />
              </Box>
            )}
          </HStack>
          <HStack w="full" spacing={6}>
            <NumberFormatRHF
              name="price"
              label="Price"
              inputProps={{ placeholder: "Enter price" }}
            />
            <NumberFormatRHF
              name="oldPrice"
              label="Old price"
              inputProps={{
                placeholder: "Enter old price",
                disabled: !isDiscount,
              }}
            />
            <SelectRHF
              name="currency"
              label="Currency"
              options={currencyOptions}
            />
          </HStack>
          <HStack w="full" spacing={6} align="flex-start">
            <Box w="326px">
              <GoogleAutocompleteRHF
                name="address"
                label="Address"
                inputPlaceholder="Enter address"
              />
            </Box>
            <Box paddingTop={6} w="170px">
              <Button
                variant="outline"
                w="full"
                leftIcon={
                  <LocationIcon fill="blue.500" width="24px" height="24px" />
                }
                onClick={openGoogleMap}
              >
                Select on map
              </Button>
            </Box>
          </HStack>
          <SelectRHF
            name="category"
            label="Offer category"
            placeholder="Select category"
            options={categoriesOptions}
          />
          <Wrap position="relative" w="full">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="shortDescription"
              name={`shortDescription.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              inputPlaceholder="Enter short description"
              label="Short description"
            />
          </Wrap>
          <Wrap position="relative">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextEditorRHF
              name={`description.${useGetLanguageKey(selectedLanguage)}`}
              label="Description"
            />
          </Wrap>
          <DropzoneFieldRHF
            width="100%"
            height="170px"
            name="mainImage"
            label="Main image"
            acceptStr="image/jpeg,image/png"
          />
          <TextFieldRHF
            id="url"
            name="url"
            inputType="text"
            label="Custom URL - hypt.com/..."
            inputPlaceholder="Set some nice URL address"
            errorMessage={errors?.url?.message}
          />
          <DropzoneFieldRHF
            width="100%"
            height="100%"
            name="slider_photo"
            label="Slider photo(s)"
            acceptStr="image/jpeg,image/jpg,image/png"
            multiple
          />
          <Wrap position="relative" w="full">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="metaTitle"
              name={`metaTitle.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta title"
              inputPlaceholder="Enter meta title"
            />
          </Wrap>
          <Wrap position="relative" w="full">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="metaDescription"
              name={`metaDescription.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta description"
              inputPlaceholder="Enter meta description"
            />
          </Wrap>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Flex w="100%" px={6}>
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            Cancel
          </Button>
          <Spacer />
          <Button type="submit">
            {isEdit ? "Edit offer" : "Create offer"}
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
