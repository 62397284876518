import { Link } from "@chakra-ui/react";
import { NavLink as ReachLink } from "react-router-dom";

function ChakraLink(props) {
  const { children, path } = props;

  return (
    <Link as={ReachLink} to={`/${path}`}>
      {children}
    </Link>
  );
}
export default ChakraLink;
