const VENUES_TICKETS_TABLE_DATA = [
  {
    id: 1001,
    name: "Main event - adult",
    event: "Main event",
    type: "Adult",
    price: "100 AED",
    special_price: "50 AED",
    quota: "infinity",
    sold: 156,
  },
  {
    id: 1002,
    name: "Main event - adult",
    event: "Main event",
    type: "Child",
    price: "200 AED",
    special_price: null,
    quota: "infinity",
    sold: 201,
  },
  {
    id: 1003,
    name: "Main event - adult",
    event: "Main event",
    type: "Adult",
    price: "100 AED",
    special_price: "50 AED",
    quota: "infinity",
    sold: 156,
  },
  {
    id: 1004,
    name: "Main event - adult",
    event: "Main event",
    type: "Child",
    price: "200 AED",
    special_price: null,
    quota: "infinity",
    sold: 201,
  },
  {
    id: 1005,
    name: "Main event - adult",
    event: "Main event",
    type: "Adult",
    price: "100 AED",
    special_price: "50 AED",
    quota: "infinity",
    sold: 156,
  },
  {
    id: 1006,
    name: "Main event - adult",
    event: "Main event",
    type: "Child",
    price: "200 AED",
    special_price: null,
    quota: "infinity",
    sold: 201,
  },
  {
    id: 1007,
    name: "Main event - adult",
    event: "Main event",
    type: "Adult",
    price: "100 AED",
    special_price: "50 AED",
    quota: "infinity",
    sold: 156,
  },
  {
    id: 1008,
    name: "Main event - adult",
    event: "Main event",
    type: "Child",
    price: "200 AED",
    special_price: null,
    quota: "infinity",
    sold: 201,
  },
];

export default VENUES_TICKETS_TABLE_DATA;
