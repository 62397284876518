export const transformStaticPagesListSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    field:
      {
        order: "order",
        title: "title",
        url: "url",
        status: "status",
      }[state.id] || state.id,
    type: state.desc ? "DESC" : "ASC",
  };
};
