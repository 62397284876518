import { gql } from "@apollo/client";

export const UPDATE_VENUE_AREA = gql`
  mutation updateVenueArea($input: UpdateVenueArea) {
    venueZone: updateVenueArea(input: $input) {
      id
      area
      venueHallId
      ticketType
      venueAreaTable {
        id
        table
        venueAreaId
        seats
      }
    }
  }
`;
