import RoundedRadioGroup from "components/RoundedRadioGroup";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

/*
Example:

<RoundedRadioGroupRHF name="test" label="Test">
  <RoundedRadioItem value="test 1">
    <Text>Test 1</Text>
  </RoundedRadioItem>
  <RoundedRadioItem value="test 2">
    <Text>Test 2</Text>
  </RoundedRadioItem>
  <RoundedRadioItem value="test 3">
    <Text>Test 3</Text>
  </RoundedRadioItem>
</RoundedRadioGroupRHF> */

function RoundedRadioGroupRHF({ name, children, ...rest }) {
  const { setValue, watch, register } = useFormContext();

  const groupValue = watch(name);

  const handleChange = (value) => {
    setValue(name, value);
  };

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <RoundedRadioGroup value={groupValue} onChange={handleChange} {...rest}>
      {children}
    </RoundedRadioGroup>
  );
}

export default RoundedRadioGroupRHF;
