import { gql } from "@apollo/client";

export const GET_COMMENT_BY_ID = gql`
  query GetCommentById($id: ID!) {
    getCommentById(id: $id) {
      id
      text
      commentAuthor {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
          company {
            companyName
          }
        }
      }
      parent {
        ... on Article {
          articleId: id
          title
          url
        }
        ... on Post {
          postId: id
          title
          text
        }
        ... on Event {
          eventId: id
          title
          url
        }
        ... on Attraction {
          attractionId: id
          title
          url
        }
        ... on Offer {
          offerId: id
          type
          title
          url
        }
        ... on Venue {
          venueId: id
          title
          url
        }
      }
      answers {
        id
        text
        commentAuthor {
          ... on Company {
            __typename
            id
            companyName
            image
          }
          ... on User {
            __typename
            id
            firstName
            lastName
            photo
            company {
              companyName
            }
          }
        }
      }
      isLiked
      createdAt
      updatedAt
      modelName
      modelId
    }
  }
`;
