export const transformVenueGeneralInfoData = ({
  formData,
  languages,
  prevURL,
  isCreate,
}) => {
  if (formData) {
    const restrictionIds = formData.restrictions?.map((restriction) =>
      Number(restriction.value)
    );

    const serviceIds = formData.services?.map((service) =>
      Number(service.value)
    );

    const multiLanguageContent = languages.reduce((lang, current) => {
      if (
        formData.title?.[current.code] &&
        formData.description?.[current.code] &&
        formData.info_title?.[current.code] &&
        formData.info_description?.[current.code] &&
        formData.policy_title?.[current.code] &&
        formData.policy_description?.[current.code]
      ) {
        lang.push({
          languageId: Number(current?.id),
          title: formData.title?.[current.code],
          description: formData.description?.[current.code],
          infoTitle: formData.info_title?.[current.code],
          infoDescription: formData.info_description?.[current.code],
          policyTitle: formData.policy_title?.[current.code],
          policyDescription: formData.policy_description?.[current.code],
        });
      }

      return lang;
    }, []);

    const multiVenueSeoAdmin = languages.reduce((lang, current) => {
      if (
        formData.meta_title?.[current.code] &&
        formData.meta_description?.[current.code]
      ) {
        lang.push({
          languageId: Number(current?.id),
          metaTitle: formData.meta_title?.[current.code],
          metaDescription: formData.meta_description?.[current.code],
        });
      }

      return lang;
    }, []);

    const oldPhotoArr = formData.slider_photo
      ?.filter((photo) => !photo.path)
      .map((photoObj) =>
        photoObj.preview.replace(`${process.env.REACT_APP_API_URL}/`, "")
      );
    const newPhotoArr = formData.slider_photo?.filter((photo) => photo.path);

    const reqObj = {
      status: formData.status?.value.toUpperCase(),
      cityId: Number(formData.city?.value),
      geolocation: formData.coordinates?.place_id,
      categoryId: Number(formData.category?.value),
      rank: formData.rank?.value,
      restrictions: restrictionIds,
      services: serviceIds,
      venueSeoAdmin: multiVenueSeoAdmin,
      content: multiLanguageContent,
      locationId: Number(formData.location?.value),
      price: Number(formData.price),
      photo: isCreate ? newPhotoArr : oldPhotoArr,
      isAddToSlider: formData.isAddToSlider,
    };

    if (formData.main_img?.preview && formData.main_img?.path) {
      Object.assign(reqObj, { image: formData.main_img });
    }

    if (prevURL !== formData.url) {
      Object.assign(reqObj, { url: formData.url });
    }

    if (newPhotoArr?.length && !isCreate) {
      Object.assign(reqObj, { addPhoto: newPhotoArr });
    }

    if (formData.id) {
      return {
        ...reqObj,
        id: formData.id,
      };
    }

    return reqObj;
  }
  return {};
};
