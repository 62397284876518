import { getDefaultLocation } from "utils/getDefaultLocation";

export const transformReceivedCreateCompany = ({ responseData, countries }) => {
  if (responseData) {
    return {
      id: responseData.id,
      addNewCompany: {
        companyName: responseData.companyName,
        companyLogo: responseData.image
          ? {
              preview: `${process.env.REACT_APP_API_URL}/${responseData.image}`,
            }
          : null,
        country: getDefaultLocation(countries, responseData.cityId)
          .defaultCountry,
        city: getDefaultLocation(countries, responseData.cityId).defaultCity,
        address: responseData.adress,
        license: responseData.licenceNumber,
        license_photo: responseData.photos.map((photo) => {
          return {
            id: photo.id,
            preview: `${process.env.REACT_APP_API_URL}/${photo.photo}`,
          };
        }),
      },
    };
  }

  return {};
};
