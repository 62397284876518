import { gql } from "@apollo/client";

export const GET_ALL_VENUE_EVENTS = gql`
  query GetAllVenueEvents($options: GetAllVenueEvents) {
    getAllVenueEvents(options: $options) {
      count
      rows {
        id
        url
        image
        tr {
          language {
            id
          }
          title
          description
        }
      }
    }
  }
`;
