import ModalButton from "components/ModalButton";
import AdsListContainer from "containers/banners/adsList.container";
import PromoListContainer from "containers/banners/promoList.container";

export const TabsConfig = [
  {
    id: "one", // for map
    index: 0, // for tabs
    path: "ads", // for URL`s path
    label: "Ads", // for Label
    button: <ModalButton modalName="adAddEditModal" label="Add ad" />,
    content: AdsListContainer,
  },
  {
    id: "two",
    index: 1,
    path: "promo",
    label: "Paid Promo",
    button: <ModalButton modalName="promoAddEditModal" label="Add promo" />,
    content: PromoListContainer,
  },
];
