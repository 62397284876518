import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";

export const transformVenues = (data = []) => {
  if (data) {
    return data?.map((venue) => ({
      id: venue.id,
      name: venue.tr?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.title,
      url: venue.url,
      address: venue.placeDetails?.address || venue.geolocation,
      category: venue.venueCategory?.name,
      rank: venue.rank ?? "-",
      status: venue.status,
      isCompleted: venue.isCompleted,
    }));
  }
  return [];
};
