import { VStack, Text } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import StepsContext from "contexts/steps/steps.context";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CHS from "./chakra.styles";

const getNextPathname = (activeStep, steps) => {
  // activeStep === active step index
  return steps[activeStep + 1].path;
};

const getPrevPathname = (activeStep, steps) => {
  // activeStep === active step index
  return steps[activeStep - 1].path;
};

const getCurrentPathname = (activeStep, steps) => {
  // activeStep === active step index
  return steps[activeStep].path;
};

function Stepper({ steps = [], rootPath = "", isEntityId, ...rest }) {
  const navigate = useNavigate();
  const stepsProps = useSteps({
    initialStep: 0,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    const activeStepIndex = steps.findIndex((el) => pathname.includes(el.path));

    if (activeStepIndex === -1) {
      return navigate(`${rootPath}${steps[0].path}`, { replace: true });
    }

    return stepsProps.setStep(activeStepIndex);
  }, [pathname, navigate]);

  const _nextStep = () => {
    const nextPathname = getNextPathname(stepsProps.activeStep, steps);
    navigate(`${rootPath}${nextPathname}`);
  };

  const _prevStep = () => {
    const prevPathname = getPrevPathname(stepsProps.activeStep, steps);
    navigate(`${rootPath}${prevPathname}`);
  };

  const _currentStep = (step) => {
    const currentPathname = getCurrentPathname(step, steps);

    navigate(`${rootPath}${currentPathname}`);
    stepsProps.setStep(step);
  };

  return (
    <StepsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...stepsProps,
        nextStep: _nextStep,
        prevStep: _prevStep,
        steps,
      }}
    >
      <VStack w="full">
        <Steps
          onClickStep={isEntityId ? (step) => _currentStep(step) : null}
          labelOrientation="vertical"
          activeStep={stepsProps.activeStep}
          {...rest}
        >
          {steps.map(({ label }) => (
            <Step
              label={
                <Text fontSize="sm" fontWeight="medium" mt={3}>
                  {label}
                </Text>
              }
              key={label}
              sx={CHS.overrrides}
            >
              <Outlet />
            </Step>
          ))}
        </Steps>
      </VStack>
    </StepsContext.Provider>
  );
}

export default Stepper;
