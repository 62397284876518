const resultItem = {
  w: "full",
  p: "5px 20px",
  cursor: "pointer",
  _hover: {
    bg: "white.500",
    color: "blue.500",
  },
};

const resultsWrapper = {
  bg: "white",
  p: 4,
  mt: 1,
  borderRadius: "xl",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
};

export default { resultItem, resultsWrapper };
