import { Controller, useFormContext } from "react-hook-form";
import NumberFormatInput from "components/NumberFormatInput";

/*
Example:

<NumberFormatRHF
  name="price"
  suffix="%"
  label="Price"
  inputProps={{ placeholder: "Enter price" }}
/> */

function NumberFormatRHF({ name, ...rest }) {
  const {
    control,
    formState: {
      errors: { [name]: fieldError },
    },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumberFormatInput {...field} hasError={fieldError} {...rest} />
      )}
    />
  );
}

export default NumberFormatRHF;
