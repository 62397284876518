export const transformZoneData = (data) => {
  if (!data) return {};
  return {
    area: data.zone_name,
    ticketType: data.ticket_type.value,
  };
};

// TODO: replace with backend data in future
const options = [
  {
    label: "Entry and tables",
    value: "ENTRY_AND_TABLES",
  },
  {
    label: "Entry",
    value: "ENTRY",
  },
  {
    label: "Tables",
    value: "TABLES",
  },
];

export const transformEditZoneData = (data) => {
  if (!data) return {};
  return {
    zone_name: data.area,
    ticket_type: options.find((item) => item.label === data.ticketType),
  };
};
