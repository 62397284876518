export default {
  "3xs": "0.625rem", // 10px
  "2xs": "0.75rem", // 12px
  xs: "0.875rem", // 14px
  sm: "1rem", // 16px
  md: "1.25rem", // 20px
  lg: "1.375rem", // 22px
  xl: "1.625rem", // 26px
  "2xl": "2.813rem", // 45px
};
