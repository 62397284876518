import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "contexts/auth/auth.context";
import { PATHS } from "constants/constants";
import useCheckServiceRoutes from "hooks/useCheckServiceRoutes";

function PublicRoute({ children }) {
  const auth = useContext(AuthContext);
  const isServiceRoute = useCheckServiceRoutes();

  if (auth.isAuth && isServiceRoute) return <Navigate to={PATHS.home} />;

  return children;
}

export default PublicRoute;
