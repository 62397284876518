import {
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Grid,
  Flex,
  Spacer,
  GridItem,
  Box,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import DatePickerRHF from "components/react-hook-form/DatePickerRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import { getAuthorFormat } from "utils/getAuthorFormat";
import RedirectFromCommentEdit from "./RedirectFromCommentEdit";

export default function CommentCreateEditModal(props) {
  const {
    onSubmit,
    cancelHandler,
    isEditMode,
    editCommentReadOnly = false,
    parentInstance,
    commentInfo,
  } = props;

  const { author, comment } = commentInfo;

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gridAutoRows="auto"
            gap={6}
            w="full"
          >
            <GridItem colSpan={3}>
              <TextFieldRHF
                id="title"
                name="title"
                inputType="text"
                label="Title"
                inputPlaceholder="Create comment`s title"
                isDisabled={isEditMode}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <RedirectFromCommentEdit parentInstance={parentInstance} />
            </GridItem>

            <GridItem colSpan={4}>
              <TextareaRHF
                name="comment_text"
                label="Comment text"
                width="full"
                height="280px"
                isDisabled={editCommentReadOnly}
                placeholder={comment}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <TextFieldRHF
                id="author"
                name="author"
                inputType="text"
                label="Author"
                inputPlaceholder="Add comment`s author"
                isDisabled={isEditMode}
                value={getAuthorFormat(author)}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <Box>
                <DatePickerRHF
                  name="publication_date"
                  label="Publication date"
                  minDate={new Date()}
                  disabled={isEditMode}
                  placeholder="Add publication date"
                />
              </Box>
            </GridItem>
          </Grid>
        </VStack>
      </ModalBody>
      {!editCommentReadOnly ? (
        <ModalFooter>
          <Flex w="100%">
            <Button
              onClick={cancelHandler}
              variant="outline"
              colorScheme="blue.500"
            >
              Cancel
            </Button>
            <Spacer />
            <Button type="submit">{isEditMode ? "Save" : null}</Button>
          </Flex>
        </ModalFooter>
      ) : (
        <Box width="full" height="40px" />
      )}
    </form>
  );
}
