export default {
  baseStyle: {
    container: {
      border: "none",
    },
    button: {
      bgColor: "white.900",
      width: 25,
      height: 7.5,
      mb: 6,
      pl: "none",
      color: "black.900",
      fontWeight: "medium",
      fontSize: "md",
      lineHeight: 6,
      flex: 1,
      align: "center",
      _hover: {
        bgColor: "white.900",
      },
      _focus: {
        boxShadow: "none",
      },
    },
    panel: {
      p: "none",
    },
    icon: {
      ml: 3,
      h: 5,
      mt: 0.5,
    },
  },
  variants: {
    base: {},
  },
  defaultProps: {
    variant: "base",
  },
};
