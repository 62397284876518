import {
  FormLabel,
  FormControl,
  Flex,
  Button,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { AddIcon } from "components/Icons";
import { getFieldName } from "utils/getFieldName";
import PhotoItem from "./PhotoItem";

function PhotoManagementRHF(props) {
  const { label, wrapperProps, name } = props;

  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const hasError = getFieldName(errors, name);

  const { fields, append, remove, swap } = useFieldArray({ control, name });

  const handleAddPhotoItem = useCallback(() => {
    append({
      image: null,
      altTag: null,
      description: null,
    });
  }, [append]);

  const dropzoneCallback = useCallback(() => {
    trigger("photos");
  }, []);

  return (
    <FormControl isInvalid={Object.keys(errors).length > 0} {...wrapperProps}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        {label ? <FormLabel mb="0">{label}</FormLabel> : null}
        <Button
          variant="outline"
          onClick={handleAddPhotoItem}
          borderColor={hasError ? "red.500" : "white.500"}
        >
          <Box mr={2.5}>
            <AddIcon fill="blue.500" w={5} h={5} />
          </Box>
          Add Photos
        </Button>
      </Flex>
      <VStack spacing={6}>
        {fields.map((field, index) => {
          return (
            <PhotoItem
              key={field.id}
              name={name}
              index={index}
              remove={remove}
              showActions={fields.length > 1}
              isFirst={index === 0}
              isLast={index === fields.length - 1}
              swap={swap}
              dropzoneCallback={dropzoneCallback}
              field={field}
            />
          );
        })}
      </VStack>
    </FormControl>
  );
}

export default PhotoManagementRHF;
