import EventProvider from "contexts/event/event.provider";

const withEventProvider = (Component) => {
  // eslint-disable-next-line react/function-component-definition
  return (props) => {
    return (
      <EventProvider>
        <Component {...props} />
      </EventProvider>
    );
  };
};

export default withEventProvider;
