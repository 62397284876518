import ToastContext from "contexts/toast/toast.context";
import { useContext } from "react";

export const useToastContext = () => {
  const toastContext = useContext(ToastContext);
  if (!toastContext) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }
  return {
    showToast: (options) => toastContext.showToast(options),
  };
};
