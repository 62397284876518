import { gql } from "@apollo/client";

export const UPDATE_ATTRACTION_TICKET = gql`
  mutation updateAttractionTicket($input: UpdateAttractionTicket) {
    updateAttractionTicket(input: $input) {
      id
      name
      type
      price
      oldPrice
      currencyId
      bestPrice
    }
  }
`;
