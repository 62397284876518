import { useContext, useEffect } from "react";
import stepsContext from "contexts/steps/steps.context";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import GalleryAttractions from "pages/Attractions/AttractionsManagement/Gallery";

import { useOptionsContext } from "hooks/useOptionsContext";
import { GALLERY_ADD_SCHEMA, GALLERY_EDIT_SCHEMA } from "schemas";
import { allCategories } from "constants/constants";
import {
  transformAddAttractionsGallery,
  transformCategoriesOptions,
  transformReceivedAddAttractionsGallery,
  transformReceivedUpdateAttractionsGallery,
  transformUpdateAttractionsGallery,
} from "connectors";
import { useAddGallery, useUpdateGallery } from "graphql/hooks/useMutations";
import { useAttractionContext } from "hooks/useAttractionContext";

function GalleryContainerAttractions() {
  const { attraction, updateAttractionState, getGallery, getAttractionId } =
    useAttractionContext();

  const { categories } = useOptionsContext();

  const methods = useForm({
    defaultValues: getGallery(),
    resolver: yupResolver(
      !getGallery()?.id ? GALLERY_ADD_SCHEMA : GALLERY_EDIT_SCHEMA
    ),
  });

  const { nextStep } = useContext(stepsContext);

  const [createGallery] = useAddGallery();
  const [updateGallery] = useUpdateGallery();

  const onSubmit = (formData) => {
    if (!getGallery()?.id) {
      const requestObj = {
        modelId: getAttractionId(),
        modelName: "ATTRACTION",
        ...transformAddAttractionsGallery(formData),
      };

      createGallery({
        variables: {
          input: requestObj,
        },
        onCompleted: (response) => {
          updateAttractionState(
            transformReceivedAddAttractionsGallery({
              responseData: response?.createGallery,
              allCategories: allCategories?.galleryCategory,
              categories,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Gallery under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Gallery under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      const requestObj = {
        id: getGallery()?.id,
        ...transformUpdateAttractionsGallery(formData, getGallery),
      };
      updateGallery({
        variables: {
          input: requestObj,
        },
        onCompleted: (response) => {
          updateAttractionState(
            transformReceivedUpdateAttractionsGallery({
              responseData: response?.updateGallery,
              allCategories: allCategories?.galleryCategory,
              categories,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Gallery under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Gallery under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (attraction.id) {
      methods.reset(getGallery());
    }
  }, [attraction.id]);

  return (
    <FormProvider {...methods}>
      <GalleryAttractions
        onSubmit={methods.handleSubmit(onSubmit)}
        categoryOptions={transformCategoriesOptions(
          allCategories.galleryCategory,
          categories
        )}
      />
    </FormProvider>
  );
}

export default GalleryContainerAttractions;
