import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_USERS = gql`
  query getAllUsers($options: OptionsFind) {
    getAllUsers(options: $options) {
      count
      rows {
        id
        firstName
        lastName
        email
        phone
        gender
        type
        status
        photo
        languageId
        currencyId
        cityId
        roleId
        role {
          id
          firstName
          lastName
          email
          phone
          gender
          type
          status
          photo
          languageId
          currencyId
          cityId
          roleId
          role {
            id
            name
            visibility
            scopes {
              id
              scope
            }
          }
          address
          description
          influencer {
            socialAccount1
            socialAccount2
            socialAccount3
            status
          }
          companyName
        }
      }
    }
  }
`;
