export const transformEditInfluencer = (data) => {
  if (data) {
    return {
      id: data.id,
      socialAccount1: data.socialAccount1,
      socialAccount2: data.socialAccount2,
      socialAccount3: data.socialAccount3,
      status: data.status?.value,
      level: data.level?.value,
    };
  }

  return {};
};
