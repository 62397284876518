import { Text, Flex, Image } from "@chakra-ui/react";
import { UserIcon } from "components/Icons";
import Options from "./Options";
import CHS from "./chakra.styles";

export const COLUMNS = [
  {
    Header: "User",
    accessor: "fullName",
    Cell: ({ row }) => {
      return (
        <Flex alignItems="center">
          <Image
            sx={CHS.userImage}
            src={row.original.photo?.preview}
            fallback={<UserIcon sx={CHS.userIcon} />}
          />
          <Text marginLeft={4}>{row.original.fullName}</Text>
        </Flex>
      );
    },
  },
  {
    Header: "Company",
    disableSortBy: true,
    Cell: ({ row }) => {
      return row.original.company ?? "—";
    },
  },
  {
    Header: "Main Role",
    accessor: "mainRole",
    disableSortBy: true,
    Cell: ({ value, row }) => {
      return row?.original?.visibility === "ANY"
        ? `${value} (HYPT)`
        : `${value} (Partner)`;
    },
  },
  {
    Header: "Options",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options user={row.original} />;
    },
  },
];
