import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  InputGroup,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "components/Icons";
import { components } from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { useSelectStyles } from "hooks/useSelectStyles.hook";
import { AsyncPaginate } from "react-select-async-paginate";

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Button size="xs" variant="brandRotate">
        <ArrowDownIcon stroke="white.900" w="10px" h="6px" fill="none" />
      </Button>
    </components.DropdownIndicator>
  );
}

export default function SelectAsyncRHF(props) {
  const {
    name,
    label,
    isDisabled = false,
    errorMessage,
    onChangeHandler,
    wrapperProps,
    customStyles,
    loadOptions,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useSelectStyles(errors[name], isDisabled, customStyles);

  const handleChange = (hookChangeFunc) => (option) => {
    hookChangeFunc(option);
    onChangeHandler?.(option);
  };

  return (
    <FormControl isInvalid={errors[name]} {...wrapperProps}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <InputGroup size="lg">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AsyncPaginate
              components={{ DropdownIndicator }}
              styles={styles}
              value={value || ""}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              loadOptions={loadOptions}
              closeMenuOnSelect
              onChange={handleChange(onChange)}
              additional={{
                page: 1,
              }}
            />
          )}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
