import { gql } from "@apollo/client";

export const GET_VENUE_BY_ID = gql`
  query getVenueById($id: Int!, $width: Int) {
    getVenueById(id: $id) {
      id
      status
      cityId
      geolocation
      categoryId
      price
      photo
      isAddToSlider
      placeDetails {
        address
      }
      venueCategory {
        id
        name
      }
      rank
      restrictions {
        venueId
        restrictionId
        restriction {
          id
          name
        }
      }
      location {
        id
        title
      }
      locationAdmin {
        id
        title
      }
      services {
        venueId
        serviceId
        service {
          id
          name
        }
      }
      url
      image(width: $width)
      venueSeoAdmin {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      tr {
        language {
          id
          name
          code
        }
        title
        description
        infoTitle
        infoDescription
        policyTitle
        policyDescription
      }
      venueMenu {
        id
        fullMenu
        image
        createdAt
        updatedAt
        tr {
          language {
            id
            name
            code
          }
          name
          description
          shortDescription
        }
      }
      galleryVenue {
        id
        title
        modelName
        modelId
        description
        url
        categoryId
        date
        galleryPhoto {
          id
          modelName
          modelId
          path
          order
          description
          altTag
          isLiked
          photoAuthor {
            id
          }
        }
        galleryPhotoCount
      }
    }
  }
`;
