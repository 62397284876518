export const transformEditUserData = ({ data, editUserId }) => {
  if (data) {
    const reqObj = {
      id: editUserId,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      email: data.email,
      phone: data.phone,
      cityId: Number(data.city?.value),
      roleId: Number(data.userRole.value),
      sendToEmail: data.sendToEmail,
    };

    if (data.photo?.path || data.photo === null) {
      Object.assign(reqObj, { photo: data.photo });
    }

    if (data.photo?.preview && !data.photo?.path) {
      Object.assign(reqObj, { photo: undefined });
    }

    if (data.password?.trim() && data.password?.trim().length >= 8) {
      Object.assign(reqObj, { password: data.password });
    }

    return reqObj;
  }

  return {};
};
