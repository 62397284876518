import Actions from "components/Stepper/Actions";
import TicketsAndScheduleAttractions from "pages/Attractions/AttractionsManagement/TicketsAndSchedule";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import { useAttractionContext } from "hooks/useAttractionContext";
import { useUpdateAttractionCompleteStatus } from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";

function TicketsAndScheduleContainerAttractions() {
  const navigate = useNavigate();
  const { getAttractionId } = useAttractionContext();
  const [updateAttractionCompleteStatus] = useUpdateAttractionCompleteStatus();

  const { showToast } = useToastContext();

  const completeStatus = () => {
    updateAttractionCompleteStatus({
      variables: {
        input: {
          attractionId: getAttractionId(),
          isCompleted: true,
        },
      },
      onCompleted: () => {
        showToast({
          description: "Successfully completed!",
        });
        navigate(PATHS.attractions);
      },
    });
  };

  return (
    <>
      <TicketsAndScheduleAttractions />
      <Actions
        handleNext={completeStatus}
        nextLabel="Finish"
        previousLabel="Back"
      />
    </>
  );
}

export default TicketsAndScheduleContainerAttractions;
