export const transformHallCreatingData = (data) => {
  if (!data) return {};
  return {
    hall: data.hall_name,
    image: data.map_image || undefined,
    description: data.short_description,
  };
};

export const transformHallUpdatingData = (data) => {
  if (!data) return {};

  const reguestObj = {
    hall: data.hall_name,
    description: data.short_description,
  };

  if (data.map_image?.preview && data.map_image?.path) {
    Object.assign(reguestObj, { image: data.map_image });
  }

  return reguestObj;
};

export const transformHallFormData = (data) => {
  if (!data) return {};
  return {
    hall_name: data.hall,
    map_image: { preview: `${process.env.REACT_APP_API_URL}/${data.image}` },
    short_description: data.description,
  };
};
