import TextEditor from "components/TextEditor";
import { useEffect } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { getFieldName } from "utils/getFieldName";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";

function TextEditorRHF({ name, label, wrapperProps, ...rest }) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const editorValue = watch(name);

  const editorTextContent = cleanTextFromHTML(editorValue);

  const handleChange = (value) => {
    setValue(name, value);
  };

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <FormControl
      isInvalid={errors[name]}
      {...wrapperProps}
      sx={{
        "& .hypt-draft-wrapper": {
          borderColor:
            getFieldName(errors, name) && !editorTextContent
              ? "red.500"
              : "white.500",
        },
      }}
    >
      {label ? <FormLabel>{label}</FormLabel> : null}
      <TextEditor value={editorValue} onChange={handleChange} {...rest} />
    </FormControl>
  );
}

export default TextEditorRHF;
