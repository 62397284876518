import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";

export const transformEvents = (data = []) => {
  if (data) {
    return data?.map((event) => ({
      id: event.id,
      name: event?.tr?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.title,
      url: event.url,
      address: event.placeDetails?.address || event.geolocation,
      category: event?.category?.name,
      status: event.status,
      isCompleted: event.isCompleted,
    }));
  }
  return [];
};
