import { allCategories } from "constants/constants";
import { transformReceivedEventGeneralInfo } from "./transformReceivedEventGeneralInfo";
import { transformReceivedUpdateEventGallery } from "./transformReceivedUpdateEventGallery";

export const transformFullEvent = ({
  responseData,
  categoryOptions,
  currenciesOptions,
  translatedValues,
  categories,
  languages,
}) => {
  if (responseData) {
    const newContext = {
      id: responseData.id,
      generalInfo: transformReceivedEventGeneralInfo({
        responseData,
        categoryOptions,
        currenciesOptions,
        translatedValues,
        languages,
      })?.generalInfo,
    };

    if (responseData.gallery) {
      Object.assign(newContext, {
        galleryEvent: transformReceivedUpdateEventGallery({
          responseData: responseData.gallery,
          allCategories: allCategories.galleryCategory,
          categories,
        })?.galleryEvent,
      });
    }

    return newContext;
  }
  return {};
};
