import { HStack, VStack, Box } from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import DatePickerRHF from "components/react-hook-form/DatePickerRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import PhotoManagementRHF from "components/react-hook-form/PhotoManagementRHF";
import Actions from "components/Stepper/Actions";
import SelectRHF from "components/react-hook-form/SelectRHF";
import { useFormContext } from "react-hook-form";

function GalleryAttractions({ onSubmit, categoryOptions }) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={6} w="xl" mb="16">
        <TextFieldRHF
          id="title"
          name="title"
          inputType="text"
          label="Gallery Name"
          inputPlaceholder="Enter gallery name"
        />
        <TextFieldRHF
          id="url"
          name="url"
          inputType="text"
          label="Custom URL - hypt.com/..."
          inputPlaceholder="Set custom URL"
          errorMessage={errors?.url?.message}
        />
        <HStack spacing={6} w="full">
          <Box w="346px">
            <SelectRHF
              name="category"
              label="Category"
              options={categoryOptions}
              placeholder="Select Category"
            />
          </Box>
          <Box w="230px">
            <DatePickerRHF
              name="date"
              label="Date"
              placeholder="Select date of the event"
              minDate={new Date()}
              autoComplete="off"
            />
          </Box>
        </HStack>
        <TextareaRHF
          name="description"
          placeholder="Enter description"
          width="full"
          height="56px"
        />
        <PhotoManagementRHF name="photos" label="Photos" />
      </VStack>
      <Actions previousLabel="Back" />
    </form>
  );
}

export default GalleryAttractions;
