const Th = {
  fontSize: "xs",
  fontWeight: 500,
  lineHeight: "18px",
  textTransform: "unset",
  borderBottom: 0,
  padding: 0,
  paddingLeft: 2,
  color: "dark.600",
};

const Td = {
  paddingLeft: 4,
  paddingRight: 4,
};

const TdAction = {
  paddingRight: 0,
  paddingLeft: 0,
};

const icon = {
  minW: 0,
  w: 10,
  h: 10,
};

export default {
  Th,
  Td,
  TdAction,
  icon,
};
