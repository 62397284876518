import { Button, Flex, HStack, Text, Box } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { useModalContext } from "hooks/useModalContext";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { useSetEventSectorRowsCount } from "graphql/hooks/useMutations";

const DEFAULT_SECTOR_ROWS_VALUES = {
  rows_count: "",
  seats_per_row: "",
};

function SetNewRowsCount() {
  const methods = useForm({
    defaultValues: DEFAULT_SECTOR_ROWS_VALUES,
  });

  const {
    modal: { sectorId, sectorName },
    closeModal: closeCurrentModal,
  } = useModalContext("setNewRowsCountModal");

  const { openModal: openSectorManagementModal } = useModalContext(
    "sectorManagementModal"
  );

  const [setRowsCount] = useSetEventSectorRowsCount();

  const backToSectorManagementModal = () => {
    methods.reset();
    openSectorManagementModal({ sectorId });
    closeCurrentModal();
  };

  const handleDone = () => {
    const [rowsCount, seatsPerRow] = methods.getValues([
      "rows_count",
      "seats_per_row",
    ]);

    setRowsCount({
      variables: {
        eventSectorId: sectorId,
        rowsCount: Number(rowsCount),
        EventSectorRowPlaceCount: Number(seatsPerRow),
      },
    });

    backToSectorManagementModal();
  };

  return (
    <FormProvider {...methods}>
      <Box>
        <HStack spacing={2.5} mb={6} alignItems="flex-end">
          <Text>Sector:</Text>
          <Text size="modalContent">{sectorName}</Text>
        </HStack>

        <HStack spacing={4} mb={4}>
          <TextFieldRHF
            id="rows_count"
            name="rows_count"
            inputType="text"
            label="Rows count"
          />
          <TextFieldRHF
            id="seats_per_row"
            name="seats_per_row"
            inputType="text"
            label="Seats per row"
          />
        </HStack>

        <Box mb={10}>
          <Text color="dark.300">
            Current rows and seats settings will stay unaltered if included in
            new row count.
          </Text>

          <Text color="dark.300">
            To clear rows set count to &quot;0&quot;.
          </Text>
        </Box>

        <Flex w="full" justifyContent="space-between" pb={7}>
          <Button
            variant="outline"
            minW="140px"
            onClick={backToSectorManagementModal}
          >
            Cancel
          </Button>
          <Button minW="140px" onClick={handleDone}>
            Done
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
}

export default SetNewRowsCount;
