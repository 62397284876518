import { Box, Flex, Spinner } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import TableProvider from "contexts/table/table.provider";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useDebounce } from "hooks/useDebounce";
import {
  useGetAllReports,
  useGetReportStatuses,
} from "graphql/hooks/useQueries";
import { DEFAULT_REPORT_STATUS } from "constants/constants";
import { COLUMNS, ROWS_PER_PAGE } from "pages/Reports/table.config";
import { transformAllReportsData, transformReportStatuses } from "connectors";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import SelectRHF from "components/react-hook-form/SelectRHF";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { useState } from "react";
import { transformReportsListSortState } from "connectors/TableSort/transformReportsListSortState";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

function ReportsListContainer() {
  const [sortState, setSortState] = useState();
  const { accessViewReports } = getAccessScopesControl();

  const methods = useForm();
  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 7 } });

  const [status, reportText] = methods.watch(["status", "report_text"]);

  const { data: reportsData, loading } = useGetAllReports({
    variables: {
      params: {
        search: useDebounce(reportText) || null,
        status: status?.value === "ALL" ? null : status?.value,
        limit: queryPageSize,
        offset: offsetData,
        sort: transformReportsListSortState(sortState),
      },
    },
  });

  const { data: reportStatuses } = useGetReportStatuses();

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAllReportsData(reportsData?.getAllReports?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={reportsData?.getAllReports?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <Flex align="flex-end" mb={7} w="full">
        <FormProvider {...methods}>
          <TextFieldRHF
            name="report_text"
            inputType="text"
            inputPlaceholder="Search reports"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
            mr={8}
            maxWidth="480px"
          />

          <SelectRHF
            name="status"
            label="Status"
            options={transformReportStatuses({
              data: reportStatuses?.getReportStatuses,
            })}
            placeholder="Select"
            defaultValue={DEFAULT_REPORT_STATUS}
            wrapperProps={{ maxWidth: "275px", marginRight: "auto" }}
          />
        </FormProvider>

        <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
      </Flex>

      <Box width="full">
        {loading ? (
          <Flex alignItems="center" justify="center" h="100px">
            <Spinner size="xl" />
          </Flex>
        ) : reportsData?.getAllReports?.rows?.length && accessViewReports ? (
          <>
            <TableBody hasSorting={true} />
            <TablePagination />
          </>
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </Box>
    </TableProvider>
  );
}

export default ReportsListContainer;
