import { useMemo } from "react";
import BackLink from "components/BackLink";
import Heading from "components/Heading";
import Stepper from "components/Stepper";
import AddEditLayout from "layouts/AddEditLayout";
import { useParams, useNavigate } from "react-router-dom";
import { PATHS, SUB_PATHS } from "constants/constants";
import { useOptionsContext } from "hooks/useOptionsContext";
import { Flex, Spinner } from "@chakra-ui/react";
import { useGetOneAttraction } from "graphql/hooks/useQueries";
import withAttractionProvider from "HOCs/withAttractionProvider";
import EnsureOptions from "components/EnsureOptions";
import {
  transformFullAttraction,
  transformLanguagesToCodeList,
} from "connectors";
import { useAttractionContext } from "hooks/useAttractionContext";
import CHS from "./chakra.styles";

const steps = [
  { label: "General information", path: SUB_PATHS.general },
  { label: "Gallery", path: SUB_PATHS.gallery },
  { label: "Offers", path: SUB_PATHS.offers },
  { label: "Tickets & Schedule", path: SUB_PATHS.ticketsAndSchedule },
];

function AttractionManagement() {
  const navigate = useNavigate();
  const params = useParams();
  const { languages, categories } = useOptionsContext();
  const { updateAttractionState, getAttractionId } = useAttractionContext();

  const isEditMode = useMemo(() => Boolean(params.id), [params.id]);

  const translatedValues = transformLanguagesToCodeList(languages).reduce(
    (acc, code) => {
      acc[code] = "";
      return acc;
    },
    {}
  );

  const { loading } = useGetOneAttraction({
    variables: {
      id: Number(params.id),
    },
    skip: !params.id,
    onCompleted: (response) => {
      updateAttractionState(
        transformFullAttraction({
          responseData: response?.getOneAttraction,
          translatedValues,
          categories,
          languages,
        })
      );
    },
  });

  const rootPath = useMemo(() => {
    return isEditMode
      ? `${PATHS.attractions}/${params.id}/`
      : `${PATHS.newAttractions}/`;
  }, [isEditMode, params.id]);

  const handleBackToList = () => {
    navigate(PATHS.attractions);
  };

  if (!languages.length || loading)
    return (
      <Flex justify="center" marginTop={100} h="100vh">
        <Spinner size="xl" />
      </Flex>
    );

  return (
    <AddEditLayout>
      <BackLink styles={CHS.backLink} onClick={handleBackToList}>
        Back to list
      </BackLink>
      <Heading showAs="h1" size="title" textAlign="center" mb={9.5}>
        {isEditMode ? "Edit Attraction" : "Add new Attraction"}
      </Heading>
      <Stepper
        maxWidth="800px"
        steps={steps}
        mb={10}
        rootPath={rootPath}
        isEntityId={getAttractionId()}
      />
    </AddEditLayout>
  );
}

const EnhancedAttractionManagement =
  withAttractionProvider(AttractionManagement);

function EnsuredAttractionManagement() {
  return (
    <EnsureOptions options={["languages", "categories"]} spinner>
      <EnhancedAttractionManagement />
    </EnsureOptions>
  );
}

export default EnsuredAttractionManagement;
