import { gql } from "@apollo/client";

export const UPDATE_VENUE_AREA_TABLE = gql`
  mutation updateVenueAreaTable($input: UpdateVenueTable) {
    venueAreaTable: updateVenueAreaTable(input: $input) {
      id
      table
      venueAreaId
      seats
    }
  }
`;
