export const transformVenueOptions = (data = []) => {
  if (data) {
    return data?.map((option) => {
      if (typeof option === "string") {
        return {
          value: option,
          label: option,
        };
      }

      return {
        value: option.id,
        label: option.name,
      };
    });
  }
  return [];
};
