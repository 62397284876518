import React, { useContext, useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import {
  transformEventsOffersSortState,
  transformOffersList,
} from "connectors";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Events/EventsManagement/Offers/OffersTable/table.config";
import { useForm } from "react-hook-form";
import { useDebounce } from "hooks/useDebounce";
import ModalButton from "components/ModalButton";
import OffersFilters from "pages/Offers/OffersMainPage/OffersFilters";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllOffersAdmin } from "graphql/hooks/useQueries";
import OffersTable from "pages/Offers/OffersMainPage/OffersTable";
import Actions from "components/Stepper/Actions";
import StepsContext from "contexts/steps/steps.context";
import { allCategories } from "constants/constants";
import { useEventContext } from "hooks/useEventContext.hook";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function OffersContainerEvents() {
  const [sortState, setSortState] = useState();

  const { getEventId } = useEventContext();
  const { nextStep } = useContext(StepsContext);
  const methods = useForm();
  const name = methods.watch("name");
  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 7 } });

  const model = {
    modelId: getEventId(),
    modelName: allCategories.eventCategory,
  };

  const { data, loading } = useGetAllOffersAdmin({
    skip: !model.modelId,
    variables: {
      limit: queryPageSize,
      offset: offsetData,
      search: useDebounce(name) || null,
      modelId: model.modelId,
      modelName: model.modelName,
      sort: transformEventsOffersSortState(sortState),
    },
  });

  return (
    <>
      <TableProvider
        options={{
          columns: COLUMNS,
          data: transformOffersList(data?.getAllOffersAdmin?.rows),
          initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
          pageCount: pagesQuantity,
          manualPagination: true,
          manualSortBy: true,
        }}
        dataCount={data?.getAllOffersAdmin?.count}
        onPageChanged={onPageChanged}
        onPageSizeChanged={onPageSizeChanged}
        onTotalCountChanged={onTotalCountChanged}
        onSetSortState={setSortState}
      >
        <Flex justify="space-between" align="center" mb={4} w="full">
          <OffersFilters methods={methods} />
          {model.modelId ? (
            <ModalButton
              modalName="offerCreateEditModal"
              label="Add offer"
              ml={4}
              minW="146px"
              data={{ model }}
            />
          ) : null}
        </Flex>
        {loading ? (
          <Flex alignItems="center" justify="center" h="100px">
            <Spinner size="xl" />
          </Flex>
        ) : data?.getAllOffersAdmin?.rows?.length ? (
          <OffersTable />
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </TableProvider>
      <Actions handleNext={nextStep} previousLabel="Back" />
    </>
  );
}

export default OffersContainerEvents;
