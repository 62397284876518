import dayjs from "dayjs";

export const transformEditCompanyOwners = (data) => {
  if (data) {
    return data.owners?.map((owner) => {
      return {
        id: owner.id,
        ownerName: owner.owner,
        dateBirth: dayjs(owner.birthDate).format("YYYY-MM-DD"),
        companyShare: Number(owner.share),
        passport: owner.passport,
        photos: owner?.passportPhoto?.filter((photo) => !photo.id) || [],
      };
    });
  }

  return {};
};
