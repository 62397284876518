import { gql } from "@apollo/client";

export const GET_SEO_DATA_URLS = gql`
  query GetSeoDataGroupedByUrl(
    $limit: Int
    $offset: Int
    $search: String
    $sort: Sort
  ) {
    getSeoDataGroupedByUrl(
      limit: $limit
      offset: $offset
      search: $search
      sort: $sort
    ) {
      __typename
      count
      rows {
        url
        datas {
          language {
            id
            code
            name
          }
          languageId
          metaDescription
          metaKeywords
          metaTitle
          url
        }
      }
    }
  }
`;
