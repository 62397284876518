export const styles = {
  position: "absolute",
  top: "110%",
  insetStart: "0",
  insetEnd: "0",
  px: "7.5",
  py: "5",
  borderRadius: "md",
  bgColor: "white.900",
  overflowY: "auto",
  zIndex: "10",
  minHeight: "64px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
  maxHeight: "480px",
  "::-webkit-scrollbar": {
    width: "6px",
    py: "0.5",
    bgColor: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    bgColor: "blue.500",
    borderRadius: "3px",
  },
  "::-webkit-scrollbar-button": {
    bgColor: "transparent",
    height: "10px",
  },
};
