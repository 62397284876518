import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import { ToastProvider } from "contexts/toast/toast.provider";
import theme from "styles/theme";
import App from "components/App";
import Fonts from "components/Fonts";
import client from "graphql/client";
import reportWebVitals from "./reportWebVitals";

import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "styles/index.css";
import CSSReset from "./components/CSSReset";

// https://reactjs.org/link/switch-to-createroot
const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme} resetCSS={false}>
        <ToastProvider>
          <CSSReset />
          <Fonts />
          <App />
        </ToastProvider>
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>,
  container
);

reportWebVitals();
