import { useEffect, useMemo } from "react";
import { useModalContext } from "hooks/useModalContext";
import { FormProvider, useForm } from "react-hook-form";
import {
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CommentCreateEditModal from "components/Modals/CommentCreateEdit";
import { transformBeforeEditComment, transformEditComment } from "connectors";
import { yupResolver } from "@hookform/resolvers/yup";
import { EDIT_COMMENT_SCHEMA } from "schemas";
import { useEditCommentAdmin } from "graphql/hooks/useMutations";
import { GET_ALL_COMMENTS } from "graphql/queries";
import { INITIAL_EDIT_COMMENT_VALUES } from "constants/constants";
import { useToastContext } from "hooks/useToastContext";

export default function CommentCreateEditModalContainer() {
  const { modal, closeModal } = useModalContext("commentCreateEditModal");

  const { showToast } = useToastContext();

  const methods = useForm({
    defaultValues: INITIAL_EDIT_COMMENT_VALUES,
    resolver: yupResolver(modal.isEditMode ? EDIT_COMMENT_SCHEMA : null),
  });

  const { reset } = methods;

  const [editComment] = useEditCommentAdmin();

  const closeHandler = () => {
    reset(INITIAL_EDIT_COMMENT_VALUES);
    closeModal();
  };

  const modalTitle = useMemo(() => {
    if (modal.isEditMode) {
      if (modal.editCommentReadOnly) {
        return "Comment";
      }
      return "Edit comment";
    }
    return "";
  }, [modal.isEditMode, modal.editCommentReadOnly]);

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(
        transformBeforeEditComment(modal.editComment, modal.editCommentReadOnly)
      );
    }
  }, [modal.isEditMode]);

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      // edit Comment

      editComment({
        variables: {
          input: transformEditComment(formData, modal.editComment?.id),
        },
        refetchQueries: [GET_ALL_COMMENTS],
        fetchPolicy: "network-only",
        onCompleted: () => {
          showToast({
            description: "The comment has been successfully updated",
          });
          closeHandler();
        },
        onError: (error) => {
          console.log(error?.message);
        },
      });
    }
  };

  const parentInstance = modal.editComment?.parent;

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>{modalTitle}</Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <CommentCreateEditModal
            onSubmit={methods.handleSubmit(onSubmit)}
            cancelHandler={closeHandler}
            isEditMode={modal.isEditMode}
            editCommentReadOnly={modal.editCommentReadOnly}
            parentInstance={parentInstance}
            commentInfo={modal.editComment}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
