import { transformLanguagesToCodeList } from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useReducer } from "react";
import { eventReducer } from "reducers";
import EventContext from "./event.context";

const initialValue = (codeList) => {
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});
  return {
    id: null,
    generalInfo: {
      title: translatedValues,
      status: null,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      isAddToSlider: false,
      isFeatured: false,
      isUpcoming: false,
      isTop: false,
      eventCategory: null,
      price: null,
      currency: null,
      venueId: null,
      coordinates: { description: "", place_id: "" },
      location: null,
      date: "",
      startHour: null,
      description: translatedValues,
      restrictions: null,
      services: null,
      url: "",
      main_img: null,
      slider_photo: null,
      meta_title: translatedValues,
      meta_description: translatedValues,
    },
    galleryEvent: {
      title: "",
      url: "",
      date: "",
      category: null,
      galleryPhoto: [],
    },
  };
};

// При добавлении нового event после заполнения каждого шага и отправки если Success -> получаем данные и используем updateEventState(data)
// с использованием коннектора и перезаписываем контекст
// в последующих шагах можем использовать данные из контекста (можно использовать getGeneralInfo/getGallery)

export default function EventProvider({ children }) {
  const { languages } = useOptionsContext();

  const [event, dispatch] = useReducer(
    eventReducer,
    initialValue(transformLanguagesToCodeList(languages))
  );

  const updateEventState = (data) => {
    dispatch({
      type: "UPDATE_EVENT_STATE",
      payload: data,
    });
  };

  const getEventId = () => event.id;

  const getGeneralInfo = () => ({
    id: event.id,
    ...event.generalInfo,
  });

  const getGallery = () => ({
    ...event.galleryEvent,
  });

  return (
    <EventContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        event,
        getEventId,
        updateEventState,
        getGeneralInfo,
        getGallery,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}
