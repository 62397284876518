export const transformCreateOffer = ({ formData, languages, model }) => {
  if (formData) {
    const multiTextformData = languages.reduce((lang, current) => {
      if (
        formData.title?.[current.code] &&
        formData.description?.[current.code] &&
        formData.shortDescription?.[current.code]
      ) {
        lang.push({
          languageId: current?.id,
          title: formData.title?.[current.code],
          description: formData.description?.[current.code],
          shortDescription: formData.shortDescription?.[current.code],
        });
      }

      return lang;
    }, []);

    const multiSeoContent = languages.reduce((lang, current) => {
      if (
        formData.metaTitle?.[current.code] &&
        formData.metaDescription?.[current.code]
      ) {
        lang.push({
          metaTitle: formData.metaTitle?.[current.code] || "",
          metaDescription: formData.metaDescription?.[current.code] || "",
          languageId: current?.id,
        });
      }
      return lang;
    }, []);

    const reguestObj = {
      type: formData.type?.value,
      status: formData.status?.value,
      url: formData.url,
      isAddToSlider: formData.isAddToSlider,
      image: formData.mainImage,
      geolocation: formData.address?.place_id,
      price: formData.price,
      currencyId: Number(formData.currency.value),
      categoryId: Number(formData.category.value),
      textData: multiTextformData,
      seoContent: multiSeoContent,
      photo: formData.slider_photo || [],
    };

    if (model) {
      Object.assign(reguestObj, { model });
    }

    if (formData.type.value === "SALE") {
      Object.assign(reguestObj, { oldPrice: Number(formData.oldPrice) });
    }

    if (formData.userType === "Influencer") {
      return {
        ...reguestObj,
        influencer: {
          isForInfluencerOnly: true,
          forInfluencerCount: Number(formData.inf_numberOfUses),
          influencerLevel: formData.inf_rank.value,
        },
      };
    }

    return reguestObj;
  }
  return {};
};
