import { useReducer } from "react";
import { modalReducer } from "reducers";
import ModalContext from "./modal.context";

const initialValues = {
  createNewUserModal: { isOpen: false },
  createNewCompanyModal: { isOpen: false },
  addOwnerInfo: { isOpen: false },
  addUsersToNewCompany: { isOpen: false },
  influencerReviewModal: { isOpen: false },
  acceptedOffersModal: { isOpen: false },
  createNewEntertainmentModal: { isOpen: false },
  addVenuesTicketModal: { isOpen: false },
  addVenuesVoucherModal: { isOpen: false },
  hallManagementModal: { isOpen: false },
  addEditZoneModal: { isOpen: false },
  zoneTablesManagementModal: { isOpen: false },
  addEditZoneTablesModal: { isOpen: false },
  sectorManagementModal: { isOpen: false },
  setNewRowsCountModal: { isOpen: false },
  editSectorRowModal: { isOpen: false },
  offerCreateEditModal: { isOpen: false },
  createNewArticleModal: { isOpen: false },
  createLocation: { isOpen: false },
  createEditTicketsModal: { isOpen: false },
  createEditScheduleModal: { isOpen: false },
  articleCreateEditModal: { isOpen: false },
  reportReviewModal: { isOpen: false },
  staticPageCreateEditModal: { isOpen: false },
  adAddEditModal: { isOpen: false },
  promoAddEditModal: { isOpen: false },
  googleMapModal: { isOpen: false },
  feedCreateEditModal: { isOpen: false },
  seoCreateEditModal: { isOpen: false },
  commentCreateEditModal: { isOpen: false },
  deleteConfirmationModal: { isOpen: false },
};

export default function ModalProvider({ children }) {
  const [modals, dispatch] = useReducer(modalReducer, initialValues);

  const openModalHandler = (modalName, modalData) => {
    dispatch({
      type: "OPEN_MODAL",
      payload: { modalName, modalData },
    });
  };

  const closeModalHandler = (modalName) => {
    dispatch({
      type: "CLOSE_MODAL",
      payload: { modalName },
    });
  };

  const updateModalState = (modalName, modalData) => {
    dispatch({
      type: "UPDATE_MODAL_STATE",
      payload: { modalName, modalData },
    });
  };

  return (
    <ModalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ modals, openModalHandler, closeModalHandler, updateModalState }}
    >
      {children}
    </ModalContext.Provider>
  );
}
