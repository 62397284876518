import { gql } from "@apollo/client";

export const GET_ALL_STATIC_PAGES = gql`
  query GetAllStaticPages(
    $limit: Int
    $sort: Sort
    $search: String
    $offset: Int
  ) {
    getAllStaticPages(
      limit: $limit
      sort: $sort
      search: $search
      offset: $offset
    ) {
      rows {
        id
        title
        status
        description
        url
        order
        tr {
          languageId
          title
          description
          content
          language {
            id
            code
            name
          }
        }
        content
        createdAt
        updatedAt
        staticPageSEO {
          id
          languageId
          metaTitle
          metaDescription
        }
        staticPageSEOAdmin {
          id
          languageId
          metaTitle
          metaDescription
          language {
            id
            code
            name
          }
        }
      }
      count
    }
  }
`;
