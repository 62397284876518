import { useDropzone } from "react-dropzone";
import {
  useDropzoneDropStyles,
  useDropzoneStyles,
} from "hooks/useDropzoneStyles.hook";
import { useCallback, useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Text,
} from "@chakra-ui/react";
import dropIconSrc from "assets/images/dropImg.png";
import { TrashIcon } from "components/Icons";
import FileRejectionsErrors from "components/Dropzone/FileRejectionsErrors";
import VideoPreviewSrc from "assets/images/video.png";
import C from "./constants";

function Dropzone({
  isVideo = false,
  width,
  height,
  label,
  acceptStr,
  hasError = false,
  name,
  onChange,
  value,
  containerStyles,
  small,
  multiple,
  maxSize,
  onDeleteCallback,
}) {
  const styles = useDropzoneStyles(hasError, width, height);

  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(
        multiple
          ? [
              ...(value || []),
              ...acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              ),
            ]
          : Object.assign(acceptedFiles[0], {
              preview: isVideo
                ? VideoPreviewSrc
                : URL.createObjectURL(acceptedFiles[0]),
            })
      );
    },
    [onChange]
  );

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...C.DEFAULT_DROPZONE_OPTIONS,
    accept: acceptStr,
    onDrop,
    multiple,
    maxSize,
  });

  const dropStyle = useDropzoneDropStyles({
    styles,
    isDragAccept,
    isDragReject,
    isFocused,
  });

  const deletePreviewImage = (item) => {
    onChange(
      value.length > 1
        ? value.filter((photo) => photo.preview !== item.preview)
        : null
    );
    onDeleteCallback?.(item);
  };

  useEffect(
    () => () => {
      URL.revokeObjectURL(value?.preview);
    },
    [value]
  );

  return (
    <FormControl mb={0} {...containerStyles}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Box sx={styles.mainContainer}>
        <Box sx={dropStyle} {...getRootProps()}>
          <input type="file" name={name} {...getInputProps({ onChange })} />
          <Image
            src={dropIconSrc}
            paddingBottom="12px"
            {...(small && { sx: styles.smallImage })}
          />
          <Text sx={{ ...styles.dropText, ...(small && styles.smallText) }}>
            Drop your {isVideo ? "video" : multiple ? "images" : "image"} here
            or
            <Text
              sx={{
                ...styles.dropTextColor,
                ...(small && styles.smallTextColor),
              }}
            >
              Browse
            </Text>
          </Text>
          <Text
            sx={{
              ...styles.dropSubText,
              ...(small && styles.smallText),
            }}
          >
            {`Supports: ${isVideo ? "MP4, WEBM" : "JPG, PNG"}`}
          </Text>
        </Box>
        {Boolean(value) && !multiple && (
          <Container sx={styles.previewContainer} role="group">
            <Image src={value.preview} sx={styles.previewImage} alt="review" />
            <Box
              as="button"
              type="button"
              sx={styles.delBtn}
              onClick={deletePreviewImage}
            >
              <TrashIcon w="24px" h="24px" fill="red.500" />
            </Box>
          </Container>
        )}
      </Box>

      {Boolean(value) && multiple && (
        <Flex flexWrap="wrap" gap="13px">
          {value?.map((item) => {
            return (
              <Container sx={styles.previewMuiltiContainer} role="group">
                <Image
                  src={item.preview}
                  sx={styles.previewImage}
                  alt="review"
                />
                <Box
                  as="button"
                  type="button"
                  sx={styles.delBtn}
                  onClick={() => deletePreviewImage(item)}
                >
                  <TrashIcon w="24px" h="24px" fill="red.500" />
                </Box>
              </Container>
            );
          })}
        </Flex>
      )}

      <FileRejectionsErrors fileRejections={fileRejections} />
    </FormControl>
  );
}

export default Dropzone;
