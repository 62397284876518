import { Navigate, Route, Routes } from "react-router-dom";
import { PATHS } from "constants/constants";
import { routes } from "constants/routes";
import MainLayout from "layouts/Layout";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const renderRoutes = (_routes) => {
  return _routes.map((route) => {
    const {
      path,
      access,
      layout: Layout = MainLayout,
      component: Component,
      children,
    } = route;

    return (
      <Route
        key={path}
        path={path}
        element={
          access === "public" ? (
            <PublicRoute>
              <Layout>
                <Component />
              </Layout>
            </PublicRoute>
          ) : (
            <PrivateRoute>
              <Layout>
                <Component />
              </Layout>
            </PrivateRoute>
          )
        }
      >
        {children && renderRoutes(children)}
      </Route>
    );
  });
};

export default function Navigation() {
  return (
    <Routes>
      {renderRoutes(routes)}
      <Route path="*" element={<Navigate replace to={PATHS.home} />} />
    </Routes>
  );
}
