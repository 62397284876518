import { Grid, GridItem } from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import Button from "components/Button";
import TablePageSetter from "components/Table/TablePageSetter";
import SelectRHF from "components/react-hook-form/SelectRHF";
import { GlassIcon } from "components/Icons";
import { ROWS_PER_PAGE } from "../UsersInfluencersTable/table.config";

function UsersInfluencersFilters({
  methods,
  influencerStatusOptions,
  influencerLevelOptions,
}) {
  const { control, reset, handleSubmit, formState } = methods;

  const onSubmit = (data) => {
    console.log(data, "onSubmit");
  };

  const onClearClick = () => {
    reset({
      name: "",
      level: "",
      status: "",
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns="repeat(20, 1fr)" gap={8} alignItems="end" mt={8}>
          <GridItem colSpan={6}>
            <TextFieldRHF
              name="name"
              inputType="text"
              inputPlaceholder="Search users"
              inputLeftElement={
                <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
              }
            />
          </GridItem>
          <GridItem colSpan={3}>
            <SelectRHF
              control={control}
              name="level"
              label="Influencer level"
              placeholder="Select"
              options={influencerLevelOptions}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <SelectRHF
              control={control}
              name="status"
              label="Status"
              placeholder="Select"
              options={influencerStatusOptions}
            />
          </GridItem>
          <GridItem colSpan={1} mt={5.5}>
            <Button
              type="reset"
              disabled={!formState.isDirty}
              variant="clear"
              onClick={onClearClick}
            >
              Clear
            </Button>
          </GridItem>
          <GridItem colSpan={3} />
          <GridItem alignSelf="end" colSpan={4}>
            <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          </GridItem>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default UsersInfluencersFilters;
