import { Box, HStack, Flex, Text } from "@chakra-ui/react";
import {
  HeartIcon,
  MessageIcon,
  PhotoCameraIcon,
  VideoCameraIcon,
  ViewsIcon,
} from "components/Icons";
import LongDash from "components/TableComponents/LongDash";
import dayjs from "dayjs";
import Options from "./Options/Options";

export const COLUMNS = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Author",
    accessor: "author",
  },
  {
    Header: "Published",
    accessor: "date",
    Cell: ({ value }) => {
      return value ? dayjs(value).format("DD/MM/YYYY hh:mm") : <LongDash />;
    },
  },
  {
    Header: "Attachment",
    accessor: "attachment",
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <HStack justify="center" align="center">
          {value.hasPhoto && <PhotoCameraIcon fill="blue.500" />}
          {value.hasVideo && <VideoCameraIcon fill="blue.500" />}
          {!value.hasPhoto && !value.hasVideo && <LongDash />}
        </HStack>
      );
    },
  },
  {
    Header: () => {
      return (
        <Flex
          justify="center"
          align="start"
          display="inline-flex"
          position="relative"
        >
          <ViewsIcon
            width="15px"
            height="15px"
            mr={1.25}
            position="absolute"
            top="4px"
            left="-20px"
          />
          <Box>Views</Box>
        </Flex>
      );
    },
    accessor: "views",
    Cell: ({ value }) => {
      return (
        <Text
          textDecoration="underline"
          textDecorationColor="black.500"
          textUnderlineOffset="3px"
        >
          {value}
        </Text>
      );
    },
  },
  {
    Header: () => {
      return (
        <Flex
          justify="center"
          align="start"
          display="inline-flex"
          position="relative"
        >
          <MessageIcon
            width="15px"
            height="15px"
            mr={1.25}
            position="absolute"
            top="5px"
            left="-20px"
            fill="none"
          />
          <Box>Comments</Box>
        </Flex>
      );
    },
    accessor: "comments",
    Cell: ({ value }) => {
      return (
        <Text
          textDecoration="underline"
          textDecorationColor="black.500"
          textUnderlineOffset="3px"
        >
          {value}
        </Text>
      );
    },
  },
  {
    Header: () => {
      return (
        <Flex
          justify="center"
          align="start"
          display="inline-flex"
          position="relative"
        >
          <HeartIcon
            width="15px"
            height="15px"
            mr={1.25}
            position="absolute"
            top="4px"
            left="-20px"
            fill="blue.500"
          />
          <Box>Likes</Box>
        </Flex>
      );
    },
    accessor: "likes",
    Cell: ({ value }) => {
      return (
        <Text
          textDecoration="underline"
          textDecorationColor="black.500"
          textUnderlineOffset="2px"
        >
          {value}
        </Text>
      );
    },
  },
  {
    Header: "Options",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options post={row.original} />;
    },
  },
];
