import { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import SearchField from "components/Search/SearchField";
import SearchResult from "components/Search/SearchResult";
import SearchResultList from "components/Search/SearcResultList";

export default function TableSearch({
  data,
  placeholder = "Search ...",
  ...rest
}) {
  const [isShowResult, setIsShowResult] = useState(false);

  const searchHandler = (e) => {
    if (e.target.value.length >= 3) {
      console.log("Search...");
      if (!isShowResult) setIsShowResult(true);
    } else {
      setIsShowResult(false);
    }
  };

  return (
    <Box position="relative" {...rest}>
      <SearchField
        w="480px"
        placeholder={placeholder}
        onChange={searchHandler}
      />
      {isShowResult && (
        <SearchResult>
          {data?.map((searchBlock) => (
            <SearchResultList key={searchBlock.title} {...searchBlock} />
          )) ?? <Text>No results found</Text>}
        </SearchResult>
      )}
    </Box>
  );
}
