import { transformFieldsLangsFormat } from "utils/transformFieldsLangsFormat";

export const transformReceivedAttractionGeneralInfo = ({
  responseData,
  translatedValues,
  categoryOptions,
  languages,
}) => {
  if (responseData) {
    const services = responseData.services?.map((serviceObj) => ({
      value: Number(serviceObj.id),
      label: serviceObj.name,
    }));

    const restrictions = responseData.restrictions?.map((restrictionObj) => ({
      value: Number(restrictionObj.id),
      label: restrictionObj.name,
    }));

    const currentCategory = categoryOptions.find(
      (it) => it.value === responseData?.categoryId
    );

    const contentValues = transformFieldsLangsFormat(
      languages,
      responseData.tr,
      ["title", "description"]
    )?.reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;
        acc.description[valueObj.language.code] = valueObj.description;

        return acc;
      },
      {
        title: {},
        description: {},
      }
    );

    const contentSeoValues = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
    };

    if (responseData.attractionSeoAdmin) {
      responseData.attractionSeoAdmin.forEach((valueObj) => {
        contentSeoValues.metaTitle[valueObj.language.code] = valueObj.metaTitle;
        contentSeoValues.metaDescription[valueObj.language.code] =
          valueObj.metaDescription;
      });
    }

    return {
      id: responseData.id,
      generalInfo: {
        title: contentValues?.title,
        status: {
          value: responseData.status.toUpperCase(),
          label: responseData.status.toUpperCase(),
        },
        language: { label: "English", value: { id: 1, code: "en-US" } },
        isAddToSlider: responseData.isAddToSlider,
        attractionCategory: currentCategory,
        coordinates: {
          description: responseData?.placeDetails?.address || "",
          place_id: responseData.geolocation || "",
        },
        location: {
          value: Number(responseData.locationAdmin?.id),
          label: responseData.locationAdmin?.title,
        },
        description: contentValues?.description,
        restrictions,
        services,
        url: responseData.url,
        main_img: {
          preview: `${process.env.REACT_APP_API_URL}/${responseData.image}`,
        },
        slider_photo: responseData?.photo?.map((photo) => ({
          preview: `${process.env.REACT_APP_API_URL}/${photo}`,
        })),
        meta_title: contentSeoValues.metaTitle,
        meta_description: contentSeoValues.metaDescription,
      },
    };
  }

  return {};
};
