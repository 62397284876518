import { usePaginationReducer } from "hooks/usePaginationReducer";
import TableProvider from "contexts/table/table.provider";
import { useGetAllEventsWithoutVenueId } from "graphql/hooks/useQueries";
import { transformGetAllEventsWithoutVenueId } from "connectors";
import { COLUMNS } from "components/Modals/AddNewEntertainment/table.config";
import { useDebounce } from "hooks/useDebounce";
import { AddNewEntertainment } from "./AddNewEntertainment";

export default function AddNewEntertainmentWrapper(props) {
  const { onSubmit, cancelHandler, name, venueId } = props;

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const { data, loading } = useGetAllEventsWithoutVenueId({
    variables: {
      options: {
        venueId: venueId || null,
        limit: queryPageSize,
        offset: offsetData,
        search: useDebounce(name) || null,
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformGetAllEventsWithoutVenueId(
          data?.getAllEventsWithoutVenueId
        ),
        initialState: {
          pageSize: queryPageSize,
          pageIndex: queryPageIndex,
        },
        pageCount: pagesQuantity,
        manualPagination: true,
      }}
      dataCount={data?.getAllEventsWithoutVenueId?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      isRowSelectionEnabled
    >
      <AddNewEntertainment
        onSubmit={onSubmit}
        cancelHandler={cancelHandler}
        loading={loading}
        venueId={venueId}
        hasData={data?.getAllEventsWithoutVenueId?.rows?.length}
      />
    </TableProvider>
  );
}
