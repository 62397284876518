import {
  MenuDivider,
  MenuButton,
  Button,
  Menu,
  MenuList,
} from "@chakra-ui/react";
import {
  ArrowDownIcon,
  UserIcon,
  SupportIcon,
  SignOutIcon,
} from "components/Icons";
import { useNavigate } from "react-router-dom";
import CustomMenuItem from "components/CustomMenuItem";
import { useContext } from "react";
import AuthContext from "contexts/auth/auth.context";

export default function ProfileMenu() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const personInfoHandler = () => navigate("/users/me");
  const supportHandler = () => {
    console.log("Support");
  };
  const signOutHandler = () => {
    logout();
  };

  return (
    <Menu>
      <MenuButton as={Button} size="xs" variant="brandRotate">
        <ArrowDownIcon stroke="white.900" w="2" h="3" />
      </MenuButton>
      <MenuList>
        <CustomMenuItem
          icon={
            <UserIcon
              w="20px"
              h="20px"
              fill="dark.500"
              _groupHover={{ fill: "blue.500" }}
            />
          }
          onMenuItemClick={personInfoHandler}
        >
          Personal Information
        </CustomMenuItem>
        <CustomMenuItem
          icon={
            <SupportIcon
              w="17px"
              h="18px"
              fill="dark.500"
              _groupHover={{ fill: "blue.500" }}
            />
          }
          onMenuItemClick={supportHandler}
        >
          Support
        </CustomMenuItem>
        <MenuDivider />
        <CustomMenuItem
          icon={
            <SignOutIcon
              w="20px"
              h="20px"
              fill="none"
              stroke="dark.500"
              _groupHover={{ stroke: "blue.500" }}
            />
          }
          onMenuItemClick={signOutHandler}
        >
          Sign Out
        </CustomMenuItem>
      </MenuList>
    </Menu>
  );
}
