export const transformAttractions = (data = []) => {
  if (data) {
    return data?.map((attraction) => ({
      id: attraction.id,
      name: attraction.title,
      url: attraction.url,
      address: attraction.placeDetails?.address || attraction.geolocation,
      category: attraction?.attractionCategory?.name,
      status: attraction.status,
      isCompleted: attraction.isCompleted,
    }));
  }
  return [];
};
