import { gql } from "@apollo/client";

export const ADMIN_SIGN_IN = gql`
  mutation AdminSignIn(
    $email: String!
    $password: String!
    $rememberMe: Boolean
  ) {
    adminSignIn(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      user {
        firstName
        email
      }
    }
  }
`;
