import { Box } from "@chakra-ui/react";
import Options from "./Options";
import ParentType from "./ParentType";
import Status from "../../components/TableComponents/Status";
import CHS from "./chakra.styles";

export const COLUMNS = [
  {
    Header: "Report text",
    accessor: "text",
    Cell: ({ value }) => {
      return <Box sx={CHS.reportTextSplit}> {value} </Box>;
    },
  },
  {
    Header: "Parent/Type",
    accessor: "type",
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return <ParentType original={original} />;
    },
  },
  {
    Header: "Author",
    accessor: "author",
    disableSortBy: true,
  },
  {
    Header: "Reported by",
    accessor: "reportedBy",
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <Status value={value.toLowerCase()} />;
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return <Options report={original} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
