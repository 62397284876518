import TableDescription from "components/TableComponents/Description";
import dayjs from "dayjs";
import LongDash from "components/TableComponents/LongDash";
import { Box, Text } from "@chakra-ui/react";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";
import { capitalize } from "utils/capitalize";
import { getAuthorFormat } from "utils/getAuthorFormat";
import Options from "./Options/Options";

export const COLUMNS = [
  {
    Header: "Parent Title",
    accessor: "parent",
    Cell: ({ value }) => {
      if (value && value.parent) {
        return (
          <Box>
            <Text>{value.parent.title || "EMPTY TITLE"}</Text>
            <Text size="label" color="dark.300" mt="6px">
              {value.parent.__typename}
            </Text>
          </Box>
        );
      }
      if (value && value.modelName) {
        return (
          <Box>
            <Text color="dark.300" mt="6px">
              Entry removed
            </Text>
            <Text size="label" color="dark.300" mt="6px">
              {capitalize(value.modelName)}
            </Text>
          </Box>
        );
      }
      return <LongDash />;
    },
  },
  {
    Header: "Comment",
    accessor: "comment",
    Cell: ({ value }) => {
      if (value) {
        const stripValue = cleanTextFromHTML(value);
        return <TableDescription text={stripValue} />;
      }
      return <LongDash />;
    },
  },
  {
    Header: "Author",
    accessor: "author",
    Cell: ({ value }) => {
      if (value) {
        return getAuthorFormat(value);
      }
      return <LongDash />;
    },
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }) => {
      if (value) {
        return dayjs(value).format("DD/MM/YYYY, hh:mm");
      }
      return <LongDash />;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options comment={row.original} />;
    },
  },
];
