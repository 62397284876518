import { gql } from "@apollo/client";

export const GET_EVENT_SECTOR_BY_ID = gql`
  query getEventSectorById($id: ID!) {
    getEventSectorById(id: $id) {
      id
      sector
      shortDescription
      image
      eventId
      eventSectorRow {
        id
        placeCount
        eventSectorRowPlace {
          id
          place
        }
      }
    }
  }
`;
