import { MenuButton, Menu, MenuList, useTheme } from "@chakra-ui/react";
import { ThreeDotsIcon } from "components/Icons";
import { useCallback, useState } from "react";

export default function TableOptions({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const menuToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <Menu variant="options" onOpen={menuToggle} onClose={menuToggle}>
      <MenuButton>
        <ThreeDotsIcon
          fill={isOpen ? theme.colors.blue["500"] : theme.colors.black["500"]}
        />
      </MenuButton>
      <MenuList height="auto">{children}</MenuList>
    </Menu>
  );
}
