import { Icon } from "@chakra-ui/react";

export default function GoToLastIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 8 10" {...props}>
      <path
        d="M1.71429 1.52277L5.14941 4.95789L1.71429 8.39378"
        stroke="#CACACA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1416 1.52277V8.3936"
        stroke="#CACACA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
}
