import { createContext } from "react";

const defaultValue = {
  countries: [],
  userPermisionRoles: [],
  userGenders: [],
  offerLevels: [],
  modelTypeOffers: [],
  typeOffers: [],
  influencerLevels: [],
  influencerStatuses: [],
  currencies: [],
  languages: [],
  categories: [],
  venueStatuses: [],
  venueCategories: [],
  venueRanks: [],
  venueServices: [],
  services: [],
  restrictions: [],
  eventStatuses: [],
  attractionStatuses: [],
  staticPagesStatuses: [],
  articleStatuses: [],
  adStatuses: [],
  currentUser: {},
  updateOptionsState: () => {},
};

export default createContext(defaultValue);
