import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SET_NEW_PASSWORD_SCHEMA } from "schemas";
import { useResetPassword } from "graphql/hooks/useMutations";
import { ResetPassword } from "pages";
import { transformResetPassword } from "connectors";

function ResetPasswordContainer() {
  const methods = useForm({
    resolver: yupResolver(SET_NEW_PASSWORD_SCHEMA.schema),
  });

  const navigate = useNavigate();

  const [params] = useSearchParams();

  const getTokenFromUrl = useMemo(() => {
    return params.get("reset-password");
  }, [params]);

  const [resetPassword] = useResetPassword();

  const [view, setView] = useState("initialView");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  const onSubmit = (formData) => {
    const requestData = transformResetPassword(formData, getTokenFromUrl);

    resetPassword({
      variables: requestData,
    })
      .then((response) => {
        if (response?.data?.setNewPassword?.status === true) {
          setView("success");
        }
      })
      .catch((e) => {
        setApiErrorMessage(e.message);
        setView("error");
      });
  };

  return (
    <FormProvider {...methods}>
      <ResetPassword
        onSubmit={onSubmit}
        view={view}
        handleNavigateToLogin={handleNavigateToLogin}
        apiErrorMessage={apiErrorMessage}
      />
    </FormProvider>
  );
}

export default ResetPasswordContainer;
