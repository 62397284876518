import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  HStack,
  Button,
  Center,
} from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import AcceptedOffersList from "components/Modals/AcceptedOffers/AcceptedOffersList";

export default function AcceptedOffers() {
  const { modal, closeModal } = useModalContext("acceptedOffersModal");
  const { influencer } = modal;

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Accepted offers</ModalHeader>
        <ModalCloseButton />
        <ModalBody marginRight="-24px">
          <HStack spacing={6} paddingBottom={2}>
            <Text fontSize="md">{influencer?.fullName}</Text>
            <Flex>
              <Text color="dark.300">Influencer level:</Text>
              <Text color="dark.500" paddingLeft={1}>
                {influencer?.level}
              </Text>
            </Flex>
          </HStack>
          <AcceptedOffersList items={influencer?.user?.acceptOffers?.rows} />
        </ModalBody>
        <ModalFooter>
          <Center width="full">
            <Button variant="outline" minW="140px" onClick={closeModal}>
              Close
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
