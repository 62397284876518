const videoStyles = {
  width: "100%",
  paddingBottom: "56.25%",
  position: "relative",
  "& iframe": {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
};

export default { videoStyles };
