import { useForm } from "react-hook-form";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useDebounce } from "hooks/useDebounce";
import { useVenueContext } from "hooks/useVenueContext.hook";
import { useGetAllVenueEvents } from "graphql/hooks/useQueries";
import {
  transformGetAllVenueEvents,
  transformVenuesEntertainmentsSortState,
} from "connectors";
import TableProvider from "contexts/table/table.provider";
import Entertainments from "pages/Venues/VenueManagement/Entertainments";
import { COLUMNS } from "pages/Venues/VenueManagement/Entertainments/table.config";
import { useState } from "react";

function EntertainmentsContainer() {
  const [sortState, setSortState] = useState();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 7 } });

  const methods = useForm();
  const name = methods.watch("name");

  const { getVenueId } = useVenueContext();

  const { data, loading } = useGetAllVenueEvents({
    skip: !getVenueId(),
    variables: {
      options: {
        venueId: getVenueId(),
        limit: queryPageSize,
        offset: offsetData,
        search: useDebounce(name) || null,
        sort: transformVenuesEntertainmentsSortState(sortState),
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformGetAllVenueEvents(data?.getAllVenueEvents),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={data?.getAllVenueEvents?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <Entertainments
        methods={methods}
        loading={loading}
        venueId={getVenueId()}
        hasData={data?.getAllVenueEvents?.rows?.length}
      />
    </TableProvider>
  );
}

export default EntertainmentsContainer;
