import { Flex, Text, VStack } from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import ZoneTablesList from "./ZoneTablesList";

function ZoneTablesManagement() {
  const {
    modal: { hallName, zoneName },
  } = useModalContext("zoneTablesManagementModal");

  return (
    <VStack w="full">
      <Flex w="full" alignItems="flex-end">
        <Text as="span" mr={6} color="grey.700" minW="40px">
          Hall:
        </Text>
        <Text fontWeight="medium" fontSize="md" as="h3">
          {hallName}
        </Text>
      </Flex>
      <Flex w="full" alignItems="flex-end">
        <Text as="span" mr={6} color="grey.700" minW="40px">
          Zone:
        </Text>
        <Text fontWeight="medium" fontSize="md" as="h3">
          {zoneName}
        </Text>
      </Flex>
      <ZoneTablesList />
    </VStack>
  );
}

export default ZoneTablesManagement;
