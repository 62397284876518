import dayjs from "dayjs";
import { capitalize } from "utils/capitalize";

export const transformCreateAdData = (formData, adsPositionsData) => {
  if (!formData) return {};

  const positionIds = [];

  formData.block_ids.forEach((level) => {
    const position = level.value.split("_");

    adsPositionsData?.forEach((row) => {
      if (position.includes(row.page) && position.includes(row.level)) {
        positionIds.push(row.id);
      }
    });
  });

  const inputObject = {
    positionIds,
    showCount: Number(formData.display_count),
    startAt: dayjs(formData.rotation_start).format("YYYY-MM-DD"),
    stopAt: dayjs(formData.rotation_end).format("YYYY-MM-DD"),
    url: formData.banner_url,
  };

  if (formData.banner_image?.preview && formData.banner_image?.path) {
    Object.assign(inputObject, { image: formData.banner_image });
  }

  if (formData.status) {
    Object.assign(inputObject, {
      status: capitalize(formData.status?.value),
    });
  }

  return inputObject;
};

export const transformAdDataBeforeEdit = (adData) => {
  if (!adData) return {};

  return {
    page_name: { value: adData.pages[0], label: adData.pages[0] },
    block_ids: adData.adPositions,
    rotation_start: dayjs(adData.startAt)?.toDate() || null,
    rotation_end: dayjs(adData.stopAt)?.toDate() || null,
    display_count: adData.planned,
    banner_image: {
      preview: `${process.env.REACT_APP_API_URL}/${adData?.path}`,
    },
    banner_url: adData.url,
    status: {
      label: adData?.status.toUpperCase(),
      value: adData?.status.toUpperCase(),
    },
  };
};
