import { Icon } from "@chakra-ui/react";

export default function AlertIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M22.56 16.3026L14.89 3.58262C14.5799 3.09744 14.1526 2.69815 13.6475 2.42156C13.1424 2.14498 12.5758 2 12 2C11.4242 2 10.8576 2.14498 10.3525 2.42156C9.84745 2.69815 9.42015 3.09744 9.11 3.58262L1.44 16.3026C1.16905 16.7543 1.02179 17.2693 1.01301 17.7959C1.00424 18.3225 1.13426 18.8422 1.39 19.3026C1.68568 19.8209 2.11366 20.2514 2.6302 20.55C3.14674 20.8487 3.73333 21.0049 4.33 21.0026H19.67C20.2628 21.0089 20.8467 20.8588 21.3629 20.5674C21.8791 20.2759 22.3092 19.8534 22.61 19.3426C22.8733 18.8774 23.0074 18.3503 22.9986 17.8158C22.9898 17.2814 22.8384 16.7589 22.56 16.3026ZM20.86 18.3526C20.7398 18.5592 20.5654 18.729 20.3556 18.8436C20.1459 18.9581 19.9088 19.0131 19.67 19.0026H4.33C4.09122 19.0131 3.85412 18.9581 3.64436 18.8436C3.4346 18.729 3.26019 18.5592 3.14 18.3526C3.05223 18.2006 3.00603 18.0282 3.00603 17.8526C3.00603 17.6771 3.05223 17.5046 3.14 17.3526L10.82 4.62262C10.958 4.44033 11.1364 4.29249 11.3411 4.1907C11.5458 4.08891 11.7714 4.03593 12 4.03593C12.2286 4.03593 12.4542 4.08891 12.6589 4.1907C12.8636 4.29249 13.042 4.44033 13.18 4.62262L20.85 17.3426C20.9412 17.4949 20.9901 17.6688 20.9919 17.8463C20.9936 18.0238 20.9481 18.1985 20.86 18.3526Z"
        fill="white"
      />
      <path
        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        fill="white"
      />
      <path
        d="M12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
        fill="white"
      />
    </Icon>
  );
}
