import { Box, Text } from "@chakra-ui/react";
import CHS from "pages/Offers/OffersMainPage/OffersTable/chakra.styles";
import TableDescription from "components/TableComponents/Description";
import Options from "./Options/Options";

export const COLUMNS = [
  {
    Header: "Url",
    accessor: "url",
    disableSortBy: false,
    Cell: ({ value }) => {
      return (
        <Box maxW="245px">
          <TableDescription text={value} />
        </Box>
      );
    },
  },
  {
    Header: "Meta title",
    accessor: "metaTitle",
    disableSortBy: true, // todo true or false
    Cell: ({ value }) => {
      return <Text sx={CHS.titleSplit}>{value}</Text>;
    },
  },
  {
    Header: "Meta description",
    accessor: "metaDescription",
    disableSortBy: true, // todo true or false
    Cell: ({ value }) => {
      return <Text sx={CHS.titleSplit}>{value}</Text>;
    },
  },
  {
    Header: "Meta keywords",
    accessor: "metaKeywords",
    disableSortBy: true, // todo true or false
    Cell: ({ value }) => {
      return <Text sx={CHS.titleSplit}>{value}</Text>;
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options seo={row.original.seo} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
