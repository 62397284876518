import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import { CREATE_OFFER_SCHEMA, EDIT_OFFER_SCHEMA } from "schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect } from "react";
import {
  transformCreateOffer,
  transformOfferTypesOptions,
  transformLanguageSelect,
  transformCurrenciesOptions,
  transformInfluencerLevelsOptions,
  transformCategoriesOptions,
  transformLanguagesToCodeList,
  transformBeforeEditOffer,
  transformEditOffer,
  transformOfferStatusesOptions,
} from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { allCategories } from "constants/constants";
import { useCreateOffer, useEditOffer } from "graphql/hooks/useMutations";
import { GET_ALL_COMMENTS, GET_ALL_OFFERS_ADMIN } from "graphql/queries";
import { onLanguageChange } from "utils/onLanguageChange";
import { useToastContext } from "hooks/useToastContext";
import OfferCreateEditModal from "components/Modals/OfferCreateEdit";
import useGetCurrencies from "hooks/useGetCurrencies.hook";
import useGetOffersOptions from "hooks/useGetOffersOptions.hook";
import useGetInfluencerOptions from "hooks/useGetInfluencerOptions.hook";

export default function OfferCreateEditModalContainer() {
  const { modal, closeModal } = useModalContext("offerCreateEditModal");

  useGetCurrencies({ skip: !modal.isOpen });
  useGetOffersOptions({ skip: !modal.isOpen });
  useGetInfluencerOptions({ skip: !modal.isOpen });

  const {
    typeOffers,
    statusOffers,
    influencerLevels,
    currencies,
    languages,
    categories,
  } = useOptionsContext();
  const { showToast } = useToastContext();

  const codeList = transformLanguagesToCodeList(languages);
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});

  const INITIAL_VALUES = {
    userType: "Regular",
    title: translatedValues,
    isAddToSlider: false,
    status: "",
    address: { description: "", place_id: "" },
    category: "",
    shortDescription: translatedValues,
    description: translatedValues,
    mainImage: null,
    url: "",
    metaTitle: translatedValues,
    metaDescription: translatedValues,
    type: { value: "ONE_PLUS_ONE", label: "1+1" },
    discount: "0%",
    price: null,
    oldPrice: null,
    currency: "",
    language: { label: "English", value: { id: 1, code: "en-US" } },
    inf_rank: "",
    inf_numberOfUses: "",
    slider_photo: null,
  };

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode
        ? EDIT_OFFER_SCHEMA.schema(languages)
        : CREATE_OFFER_SCHEMA.schema(languages)
    ),
  });

  const { reset, setValue, watch } = methods;

  const [createOffer] = useCreateOffer();
  const [editOffer] = useEditOffer();

  const [userType, price, oldPrice, offerType, selectedLanguage] = watch([
    "userType",
    "price",
    "oldPrice",
    "type",
    "language",
  ]);

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  const changeOfferType = (option) => {
    if (option.value === "ONE_PLUS_ONE" && !modal.isEditMode) {
      setValue("oldPrice", "");
      setValue("discount", "0%");
    }
  };

  useEffect(() => {
    if (Number(oldPrice) > Number(price) && offerType?.value === "SALE") {
      const disc = (((oldPrice - price) / oldPrice) * 100).toFixed(2);
      setValue("discount", `${disc}%`);
    } else {
      setValue("discount", "0%");
    }
  }, [price, oldPrice, offerType]);

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(
        transformBeforeEditOffer(
          modal.editOffer,
          categories,
          typeOffers,
          currencies,
          translatedValues,
          languages,
          modal.editOfferFromComments
        )
      );
    } else {
      reset(INITIAL_VALUES);
    }
  }, [modal.isEditMode, typeOffers, currencies]);
  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      Object.assign(formData, { id: modal.editOffer?.id });
      editOffer({
        variables: {
          info: transformEditOffer({ formData, languages }),
        },
        refetchQueries: modal?.editOfferFromComments
          ? [GET_ALL_COMMENTS]
          : [GET_ALL_OFFERS_ADMIN],
        onCompleted: () => {
          showToast({
            description: "Offer successful updated",
          });
          closeModal();
        },
        onError: ({ graphQLErrors }) => {
          if (graphQLErrors) {
            switch (graphQLErrors?.[0]?.message) {
              case "Offer under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Offer under this URL already exists",
                });
                break;
              default:
                console.log(graphQLErrors?.[0]?.message);
            }
          }
        },
      });
    } else {
      createOffer({
        variables: {
          info: transformCreateOffer({
            formData,
            languages,
            model: modal.model,
          }),
        },
        refetchQueries: [GET_ALL_OFFERS_ADMIN],
        onCompleted: () => {
          showToast({
            description: "Offer successful created",
          });
          closeModal();
        },
        onError: ({ graphQLErrors }) => {
          if (graphQLErrors) {
            switch (graphQLErrors?.[0]?.message) {
              case "Offer under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Offer under this URL already exists",
                });
                break;
              default:
                console.log(graphQLErrors?.[0]?.message);
            }
          }
        },
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modal.isEditMode ? "Edit offer" : "Add offer"}
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <OfferCreateEditModal
            onSubmit={methods.handleSubmit(onSubmit)}
            cancelHandler={closeModal}
            isInfluencer={userType === "Influencer"}
            onChangeOfferType={changeOfferType}
            offerTypesOptions={transformOfferTypesOptions(typeOffers)}
            languagesOptions={transformLanguageSelect(languages)}
            currencyOptions={transformCurrenciesOptions(currencies)}
            influencerLevelsOptions={transformInfluencerLevelsOptions(
              influencerLevels
            )}
            categoriesOptions={transformCategoriesOptions(
              allCategories.offerCategory,
              categories
            )}
            statusesOptions={transformOfferStatusesOptions(statusOffers)}
            isDiscount={offerType?.value === "SALE"}
            isEdit={modal.isEditMode}
            selectedLanguage={selectedLanguage}
            languageChangeHandler={languageChangeHandler}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
