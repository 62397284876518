import { Flex, Box } from "@chakra-ui/react";

import { ViewsIcon } from "components/Icons";

export default function HeaderWithEye({ title }) {
  return (
    <Flex
      justify="center"
      align="center"
      display="inline-flex"
      position="relative"
    >
      <ViewsIcon
        width="16px"
        height="17px"
        position="absolute"
        top="50%"
        left={0}
        transform="translate(-20px, -50%)"
      />
      <Box>{title}</Box>
    </Flex>
  );
}
