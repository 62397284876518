/*
https://github.com/chakra-ui/chakra-ui/issues/4695
https://codesandbox.io/s/cool-newton-xw6kie?file=/src/App.js
https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/popover.ts
*/
import { cssVar } from "@chakra-ui/react";

const $arrowBg = cssVar("popper-arrow-bg");

export default {
  parts: ["arrow", "content"],
  baseStyle: {
    popper: {
      bgColor: "white.900",
    },
    content: {
      [$arrowBg.variable]: "#ffffff",
      w: "190px",
      boxShadow:
        "0px 4px 20px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(177, 177, 177, 0.16)",

      _focus: {
        boxShadow:
          "0px 4px 20px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(177, 177, 177, 0.16)",
      },
    },
    header: {},
    body: {},
    footer: {},
    arrow: {
      bgColor: "white.900", // rewritten by chakra styles, see above
    },
    closeButton: {},
  },
};
