import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RECOVER_PASSWORD_SCHEMA } from "schemas";
import { useRecoverPassword } from "graphql/hooks/useMutations";
import { RecoverPassword } from "pages";
import { transformRecoverPassword } from "connectors";

function RecoverPasswordContainer() {
  const methods = useForm({
    resolver: yupResolver(RECOVER_PASSWORD_SCHEMA.schema),
  });

  const navigate = useNavigate();

  const [recoverPassword] = useRecoverPassword();

  const [view, setView] = useState("initialView");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  const onSubmit = (formData) => {
    const requestData = transformRecoverPassword(formData);

    recoverPassword({ variables: requestData })
      .then((response) => {
        if (response?.data?.resetPassword?.status === true) {
          setView("success");
        }
      })
      .catch((e) => {
        setApiErrorMessage(e.message);
        setView("error");
      });
  };

  return (
    <FormProvider {...methods}>
      <RecoverPassword
        view={view}
        onSubmit={onSubmit}
        apiErrorMessage={apiErrorMessage}
        handleNavigateToLogin={handleNavigateToLogin}
      />
    </FormProvider>
  );
}

export default RecoverPasswordContainer;
