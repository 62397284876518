export default {
  sizes: {
    title: {
      fontFamily: "normal",
      fontWeight: "normal", // 400
      fontSize: "2xl", // 45px
      lineHeight: "15", // 3.75rem
    },
    h1: {
      fontFamily: "medium",
      fontWeight: "medium", // 450
      fontSize: "xl", // 26px
      lineHeight: "8", // 2rem
    },
    h2: {
      fontFamily: "medium",
      fontWeight: "medium", // 450
      fontSize: "lg", // 22px
      lineHeight: "7", // 1.75rem
    },
    h3: {
      fontFamily: "medium",
      fontWeight: "medium", // 450
      fontSize: "md", // 20px
      lineHeight: "tall", // 1.625
    },
    menuItem: {
      fontFamily: "medium",
      fontWeight: "medium", // 450
      fontSize: "sm", // 16px
      lineHeight: "shorter",
    },
    textMain: {
      fontFamily: "normal",
      fontWeight: "normal", // 400
      fontSize: "sm", // 16px
      lineHeight: "shorter",
    },
    label: {
      fontFamily: "medium",
      fontWeight: "medium", // 450
      fontSize: "xs", // 14px
      lineHeight: "none",
    },
    labelThin: {
      fontFamily: "normal",
      fontWeight: "normal", // 400
      fontSize: "xs", // 14px
      lineHeight: "none",
    },
  },
  variants: {
    main: {
      color: "black.900",
    },
    text: {
      color: "grey.700",
    },
    error: {
      color: "red.500",
    },
    success: {
      color: "green.500",
    },
  },
  defaultProps: {
    size: "h1",
    variant: "main",
  },
};
