import { Grid, GridItem } from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { FormProvider } from "react-hook-form";
import TablePageSetter from "components/Table/TablePageSetter";
import { GlassIcon } from "components/Icons";
import { ROWS_PER_PAGE } from "../UsersCompaniesTable/table.config";

function UsersCompaniesFilters({ methods }) {
  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    console.log(data, "onSubmit");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          templateColumns="repeat(10, 1fr)"
          gap={8}
          alignItems="center"
          mt={8}
        >
          <GridItem colSpan={3}>
            <TextFieldRHF
              name="company"
              inputType="text"
              inputPlaceholder="Search company"
              inputLeftElement={
                <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
              }
            />
          </GridItem>
          <GridItem colSpan={5} />
          <GridItem alignSelf="end" colSpan={2}>
            <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          </GridItem>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default UsersCompaniesFilters;
