export const transformGetAllComments = (responseData) => {
  if (responseData) {
    return responseData.map((item) => ({
      id: item.id,
      parent: { parent: item.parent, modelName: item.modelName },
      comment: item.text,
      author: item.commentAuthor,
      date: item.createdAt,
    }));
  }
  return [];
};
