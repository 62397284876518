import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Flex, Spinner } from "@chakra-ui/react";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Events/EventsMainPage/EventsTable/table.config";
import {
  transformEvents,
  transformEventOptions,
  transformCategoriesOptions,
  transformEventListSortState,
} from "connectors";
import { allCategories } from "constants/constants";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import {
  useGetAllEventOptions,
  useGetAllEvents,
} from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import EventsFilters from "pages/Events/EventsMainPage/EventsFilters";
import EventsTable from "pages/Events/EventsMainPage/EventsTable";
import { useOptionsContext } from "hooks/useOptionsContext";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function EventsListContainer(props) {
  const { accessViewEvents } = props;
  const [sortState, setSortState] = useState();

  const methods = useForm();
  const { categories } = useOptionsContext();
  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const [category, status, name] = methods.watch([
    "category",
    "status",
    "name",
  ]);

  const { data: eventOptions } = useGetAllEventOptions();

  const { data: eventsData, loading: eventsLoading } = useGetAllEvents({
    variables: {
      search: useDebounce(name) || null,
      categoryId: Number(category?.value) || null,
      status: status?.value || null,
      limit: queryPageSize,
      offset: offsetData,
      sort: transformEventListSortState(sortState),
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformEvents(eventsData?.getAllEventsAdmin?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={eventsData?.getAllEventsAdmin?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <FormProvider {...methods}>
        <EventsFilters
          categoryOptions={transformCategoriesOptions(
            allCategories.eventCategory,
            categories
          )}
          statusOptions={transformEventOptions(
            eventOptions?.getAllOptionsEvent?.eventStatus
          )}
          methods={methods}
        />
      </FormProvider>
      {eventsLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : eventsData?.getAllEventsAdmin?.rows.length && accessViewEvents ? (
        <EventsTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default EventsListContainer;
