import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useDeleteUser } from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";
import { useContext } from "react";
import { useOptionsContext } from "hooks/useOptionsContext";
import AuthContext from "contexts/auth/auth.context";
import CHS from "./chakra.styles";

export default function Options({ user }) {
  const { showToast } = useToastContext();
  const { openModal: openCreateModal } = useModalContext("createNewUserModal");
  const { currentUser } = useOptionsContext();
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const openModalHandler = () => {
    openCreateModal({ editUser: user, isEditMode: true });
  };

  const { logout } = useContext(AuthContext);

  const [removeUser] = useDeleteUser({
    deleteYourSelf: user.id === currentUser.currentUser?.id,
    variables: {
      id: user.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      if (user.id === currentUser.currentUser.id) {
        logout();
      } else {
        showToast({
          description: "The user has been successfully deleted",
        });
      }
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: user.fullName,
      type: "user",
      onDelete: removeUser,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openModalHandler}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
