import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import { CREATE_LOCATION_SCHEMA, EDIT_LOCATION_SCHEMA } from "schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import {
  transformLanguageSelect,
  transformLanguagesToCodeList,
  transformCountryOptions,
  transformCityOptions,
  transformAddNewLocation,
} from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useCreateLocation, useEditLocation } from "graphql/hooks/useMutations";
import AddLocationModal from "components/Modals/AddLocation";
import { useEffect } from "react";
import { transformBeforeEditLocation } from "connectors/Locations/transformBeforeEditLocation";
import { useToastContext } from "hooks/useToastContext";

export default function AddLocationContainer() {
  const { languages, countries } = useOptionsContext();

  const { showToast } = useToastContext();

  const { modal, closeModal } = useModalContext("createLocation");

  const codeList = transformLanguagesToCodeList(languages);

  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});

  const INITIAL_VALUES = {
    title: translatedValues,
    description: translatedValues,
    image: null,
    address: null,
    cityId: null,
    language: { label: "English", value: { id: 1, code: "en-US" } },
    country: null,
    city: null,
  };

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode
        ? EDIT_LOCATION_SCHEMA.schema(languages)
        : CREATE_LOCATION_SCHEMA.schema(languages)
    ),
  });

  const { reset, watch } = methods;
  const [createLocation] = useCreateLocation();
  const [editLocation] = useEditLocation();
  const [selectedLanguage] = watch(["language"]);

  const closeHandler = () => {
    reset(INITIAL_VALUES);
    closeModal();
  };
  const countryChangeHandler = () => {
    methods.setValue("city", null);
  };

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      Object.assign(formData, { id: modal.editLocation?.id });
      editLocation({
        variables: {
          input: transformAddNewLocation(formData, languages),
        },
        onCompleted: () => {
          showToast({
            description: "The location has been successfully updated",
          });
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      createLocation({
        variables: {
          input: transformAddNewLocation(formData, languages),
        },
        onCompleted: () => {
          showToast({
            description: "The location has been successfully created",
          });
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(transformBeforeEditLocation(modal.editLocation, countries));
    } else {
      methods.reset(INITIAL_VALUES);
    }
  }, [modal.isEditMode]);

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modal.isEditMode ? "Edit location" : "Add Location"}
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <AddLocationModal
            onSubmit={methods.handleSubmit(onSubmit)}
            cancelHandler={closeHandler}
            languagesOptions={transformLanguageSelect(languages)}
            countriesOptions={transformCountryOptions(countries)}
            citiesOptions={transformCityOptions(
              methods.watch("country"),
              countries
            )}
            selectedLanguage={selectedLanguage}
            isEdit={modal.isEditMode}
            countryChangeHandler={countryChangeHandler}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
