import dayjs from "dayjs";
import { getDefaultCategory } from "utils/getDefaultCategory";

export const transformReceivedUpdateAttractionsGallery = ({
  responseData,
  allCategories,
  categories,
}) => {
  if (responseData) {
    return {
      galleryAttraction: {
        id: responseData.id,
        title: responseData.title,
        url: responseData.url,
        date: dayjs(responseData.date)?.toDate() || "",
        category: getDefaultCategory(
          responseData.categoryId,
          allCategories,
          categories
        ),
        description: responseData.description,
        photos: responseData.galleryPhoto
          .map((photo) => {
            return {
              altTag: photo.altTag,
              description: photo.description,
              order: photo.order,
              image: {
                id: photo.id,
                preview: `${process.env.REACT_APP_API_URL}/${photo.path}`,
              },
            };
          })
          .sort((a, b) => a.order - b.order),
      },
    };
  }

  return {};
};
