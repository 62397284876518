import { Icon } from "@chakra-ui/react";

export default function ArrowDownIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 8 6" {...props}>
      <path
        d="M1 1.59L3.81799 4.408L6.63599 1.59"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
