import AddNewCompany from "components/Modals/AddNewCompany";
import AddOwnerInfo from "components/Modals/AddOwnerInfo";
import AddUsersToNewCompany from "components/Modals/AddUsersToNewCompany";
import CompanyProvider from "contexts/company/company.provider";

export default function AddNewCompanyContainer() {
  return (
    <CompanyProvider>
      <AddNewCompany />
      <AddOwnerInfo />
      <AddUsersToNewCompany />
    </CompanyProvider>
  );
}
