export const transformOfferTypesOptions = (data = []) => {
  if (data) {
    return data.map((option) => {
      if (option === "ONE_PLUS_ONE") {
        return { value: option, label: "1+1" };
      }

      if (option === "SALE") {
        return { value: option, label: "Discount" };
      }

      return { value: option, label: option };
    });
  }
  return [];
};
