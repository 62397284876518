import {
  Box,
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  Spacer,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TableContext from "contexts/table/table.context";
import {
  useAddNewUsersToCompany,
  useEditNewUsersToCompany,
} from "graphql/hooks/useMutations";
import { GET_ALL_COMPANIES } from "graphql/queries";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { ArrowLeftIcon, GlassIcon } from "components/Icons";
import { useCompanyContext } from "hooks/useCompanyContext.hook";
import { initialValue } from "contexts/company/company.provider";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { useToastContext } from "hooks/useToastContext";
import Table from "./Table";

export default function AddUsers({
  onSubmit,
  cancelHandler,
  closeHandler,
  loading,
  methods,
  data,
}) {
  const { getCompanyId } = useCompanyContext();

  const { showToast } = useToastContext();

  const { handleSubmit } = useFormContext();

  const { selectedRowIds, setSelectedRowIds } = useContext(TableContext);

  const { getUsersCompany } = useCompanyContext();

  const [addNewUsers] = useAddNewUsersToCompany();
  const [editCompanyUsers] = useEditNewUsersToCompany();

  const handleWrapper = (e) => {
    e.preventDefault();
    if (!selectedRowIds) {
      addNewUsers({
        variables: { companyId: getCompanyId(), userIds: selectedRowIds },
        refetchQueries: [GET_ALL_COMPANIES],
        onCompleted: () => {
          closeHandler();
        },
      });
    } else {
      editCompanyUsers({
        variables: {
          info: { companyId: getCompanyId(), userIds: selectedRowIds },
        },
        refetchQueries: [GET_ALL_COMPANIES],
        onCompleted: () => {
          showToast({
            description: "Successfully completed!",
          });
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
    return handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (selectedRowIds) {
      methods.reset(setSelectedRowIds(getUsersCompany().usersCompany));
    } else {
      methods.reset(initialValue);
    }
  }, []);

  return (
    <form onSubmit={handleWrapper}>
      <ModalBody>
        <VStack spacing={2} mt={12}>
          <TextFieldRHF
            name="name"
            inputType="text"
            inputPlaceholder="Start typing"
            inputRightElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
          />
          {loading ? (
            <Flex alignItems="center" justify="center" h="100px">
              <Spinner size="xl" />
            </Flex>
          ) : data?.getAllUsers?.rows?.length ? (
            <Table />
          ) : (
            <Flex alignItems="center" justify="center" h="100px">
              <NoContentMessage />
            </Flex>
          )}
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%" px={6}>
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            <Box mr={4}>
              <ArrowLeftIcon stroke="currentColor" />
            </Box>
            Back
          </Button>
          <Spacer />
          <Button type="submit">Finish</Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
