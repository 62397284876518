import { Box, Button } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import { AddIcon } from "components/Icons";
import { useNavigate } from "react-router-dom";
import { PATHS, SUB_PATHS } from "constants/constants";
import EventsListContainer from "containers/events/eventsList.container";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function EventsMainPage() {
  const navigate = useNavigate();
  const navToNewEvent = () =>
    navigate(`${PATHS.newEvents}/${SUB_PATHS.general}`);
  const { accessCreateEvents, accessViewEvents } = getAccessScopesControl();

  const button = (
    <Button w="154px" onClick={navToNewEvent}>
      <Box mr={2}>
        <AddIcon fill="white.900" />
      </Box>
      Add Event
    </Button>
  );
  return (
    <Box px={10} py={6} w="full">
      <PageHeader button={accessCreateEvents ? button : null} mb={6}>
        Events
      </PageHeader>
      <EventsListContainer accessViewEvents={accessViewEvents} />
    </Box>
  );
}
