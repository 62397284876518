import { gql } from "@apollo/client";

export const GET_SEO_DATA_BY_URL = gql`
  query GetSeoData($url: String!) {
    getSeoData(url: $url) {
      url
      metaTitle
      metaKeywords
      metaDescription
      languageId
      language {
        code
        id
        name
      }
    }
  }
`;
