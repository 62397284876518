export const transformBeforeEditSeo = (editSeo, translatedValues) => {
  if (editSeo) {
    const seoData = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
      metaKeywords: { ...translatedValues },
    };

    editSeo?.tr.forEach((valueObj) => {
      seoData.metaTitle[valueObj.language.code] = valueObj.metaTitle;
      seoData.metaDescription[valueObj.language.code] =
        valueObj.metaDescription;
      seoData.metaKeywords[valueObj.language.code] = valueObj.metaKeywords;
    });

    return {
      language: { label: "English", value: { id: 1, code: "en-US" } },
      url: editSeo.url,
      metaTitle: seoData?.metaTitle,
      metaKeywords: seoData?.metaKeywords,
      metaDescription: seoData?.metaDescription,
    };
  }

  return {};
};
