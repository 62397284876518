export const transformAllInfluencersList = (data = []) => {
  if (data) {
    return data?.map((influencer) => {
      let imgSrc = null;
      let fullInfluencerName = null;

      if (influencer.user.photo) {
        if (influencer.user.photo.includes("https")) {
          imgSrc = influencer.user.photo;
        } else {
          imgSrc = `${process.env.REACT_APP_API_URL}/${influencer.user.photo}`;
        }
      }

      if (influencer) {
        if (influencer.user?.firstName && !influencer.user?.lastName) {
          fullInfluencerName = influencer.user?.firstName;
        }
        if (!influencer.user?.firstName && influencer.user?.lastName) {
          fullInfluencerName = influencer.user?.lastName;
        }
        if (influencer.user?.firstName && influencer.user?.lastName) {
          fullInfluencerName = `${influencer.user?.firstName} ${influencer.user?.lastName}`;
        }
      }

      return {
        id: Number(influencer?.id),
        fullName: fullInfluencerName,
        photo: imgSrc
          ? {
              preview: imgSrc,
            }
          : null,
        level: influencer?.level,
        status: influencer?.status,
        user: influencer?.user,
        socialAccount1: influencer?.socialAccount1,
        socialAccount2: influencer?.socialAccount2,
        socialAccount3: influencer?.socialAccount3,
      };
    });
  }
  return [];
};
