export const getAdsPositions = (positionsArray, pageName) => {
  if (!positionsArray) return [];

  const arrayWithAllPages = positionsArray.map((item) => item.page);
  const uniqPages = [...new Set(arrayWithAllPages)].sort().map((item) => ({
    value: item,
    label: item,
  }));

  const arrayWithLevelsOfSelectedPage = positionsArray.reduce((acc, item) => {
    if (item.page === pageName) {
      acc.push({
        value: `${item.page}_${item.level}`,
        label: `${item.page}_${item.level}`,
      });
    }
    return acc;
  }, []);

  return { pages: uniqPages, levels: arrayWithLevelsOfSelectedPage };
};
