import { gql } from "@apollo/client";

export const ADD_EVENT_SECTOR = gql`
  mutation addEventSector($input: AddEventSector!) {
    addEventSector(input: $input) {
      id
      sector
      shortDescription
      image
      eventId
      eventSectorRow {
        id
        placeCount
        eventSectorRowPlace {
          id
          place
        }
      }
    }
  }
`;
