import * as yup from "yup";

export const CREATE_ADVERTISEMENT_SCHEMA = yup.object().shape({
  page_name: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("This field is required!")
    .nullable(),

  block_ids: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .required("This field is required!")
    .nullable(),

  rotation_start: yup.date().required("This field is required!"),

  rotation_end: yup
    .date()
    .required("This field is required!")
    .min(
      yup.ref("rotation_start"),
      "Should be later than the rotation start date"
    ),

  display_count: yup
    .string()
    .required("")
    .test("Is positive?", "Only positive number!", (value) => value >= 0),

  banner_image: yup
    .mixed()
    .nullable()
    .test("preview", "has preview", (value) => {
      return typeof value?.preview === "string";
    }),

  banner_url: yup
    .string()
    .required("")
    .test("Is url format?", "Invalid format of URL address", (value) =>
      value.match(
        // eslint-disable-next-line no-useless-escape
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
      )
    ),

  status: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(),
});

export const EDIT_ADVERTISEMENT_SCHEMA = yup.object().shape({
  page_name: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(),

  block_ids: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .nullable(),

  rotation_start: yup.string(),

  rotation_end: yup.string(),

  display_count: yup.string(),

  banner_image: yup
    .mixed()
    .nullable()
    .test("preview", "has preview", (value) => {
      return typeof value?.preview === "string";
    }),

  banner_url: yup.string(),

  status: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(),
});
