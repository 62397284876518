import { gql } from "@apollo/client";

export const GET_ALL_ARTICLE_ADMIN = gql`
  query GetAllArticleAdmin(
    $limit: Int
    $sort: Sort
    $search: String
    $offset: Int
  ) {
    getAllArticleAdmin(
      limit: $limit
      sort: $sort
      search: $search
      offset: $offset
    ) {
      count
      rows {
        id
        url
        showOnMain
        promoImage
        mainImage
        categoryId
        viewsCount
        status
        publicatedAt
        authorName
        authorAvatar
        articleSEO {
          id
          languageId
          language {
            id
            code
            name
          }
          metaTitle
          metaDescription
        }
        tags {
          id
          name
          viewsCount
        }
        tr {
          promoText
          mainText
          title
          languageId
          language {
            id
            code
            name
          }
        }
      }
    }
  }
`;
