import { Box } from "@chakra-ui/react";
import TableLink from "components/TableComponents/Link";
import Status from "components/TableComponents/Status";
import URLHeader from "components/TableComponents/URLHeader";
import Options from "./Options";
import OrderSetter from "./OrderSetter";

const staticPagesAlreadyExist = [
  "about",
  "privacy-policy",
  "terms-and-conditions",
];

export const COLUMNS = [
  {
    Header: "Order",
    accessor: "order",
    Cell: ({ row }) => {
      return <OrderSetter row={row} />;
    },
  },
  {
    Header: "Name",
    accessor: "title",
  },
  {
    Header: () => <URLHeader title="URL" />,
    accessor: "url",
    Cell: ({ row }) => {
      if (row?.original?.status === "Hidden") {
        return (
          <TableLink
            width="300px"
            variant="tableLink3"
            value={`${process.env.REACT_APP_API_URL}/${row?.original.url}`}
            path={`${process.env.REACT_APP_API_URL}/404`}
          />
        );
      }
      return (
        <TableLink
          width="300px"
          variant="tableLink3"
          value={`${process.env.REACT_APP_API_URL}/${row?.original.url}`}
          path={
            staticPagesAlreadyExist.includes(row?.original.url)
              ? `${process.env.REACT_APP_API_URL}/${row?.original.url}`
              : `${process.env.REACT_APP_API_URL}/static/${row?.original.url}`
          }
        />
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <Status value={value.toLowerCase()} />;
    },
  },

  {
    Header: () => {
      return <Box pr="20px">Options</Box>;
    },
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options page={row.original} />;
    },
  },
];
