import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: ProfileInput!, $photo: Upload) {
    updateProfile(input: $input, photo: $photo) {
      id
      firstName
      lastName
      email
      cityId
      companyId
      photo
    }
  }
`;
