import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "components/Icons";
import CHS from "./chakra.styles";

const CustomInput = forwardRef((props, ref) => {
  const { onClick, hasError, ...rest } = props;
  return (
    <Flex ref={ref} onClick={onClick}>
      <InputGroup>
        <InputRightElement mr={3}>
          <IconButton
            variant="iconButton"
            icon={<CalendarIcon w="6" h="6" />}
          />
        </InputRightElement>
        <Input borderColor={hasError ? "red.500" : "white.500"} {...rest} />
      </InputGroup>
    </Flex>
  );
});

const DatePickerCustom = forwardRef((props, ref) => {
  const {
    label,
    selected,
    onChange,
    placeholder,
    isDisabled,
    errorMessage,
    hasError,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={hasError} sx={CHS.datePickerStyles}>
      {label && <FormLabel>{label}</FormLabel>}
      <DatePicker
        ref={ref}
        selected={selected}
        onChange={onChange}
        placeholderText={placeholder}
        disabled={isDisabled}
        dateFormat="dd/MM/yyyy"
        customInput={<CustomInput hasError={hasError} disabled={isDisabled} />}
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={50}
        scrollableYearDropdown
        popperPlacement="bottom"
        {...rest}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default DatePickerCustom;
