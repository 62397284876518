import { gql } from "@apollo/client";

export const GET_ALL_SERVICES = gql`
  query getAllServices($sort: Sort) {
    getAllServices(sort: $sort) {
      count
      rows {
        id
        name
        image
      }
    }
  }
`;
