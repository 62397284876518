import { useEffect, useCallback, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";
import InfluencerReview from "components/Modals/InfluencerReview";
import { EDIT_INFLUENCER } from "schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEditInfluencer } from "graphql/hooks/useMutations";
import {
  transformEditInfluencer,
  transformInfluencerStatus,
  transformInfluencerLevel,
} from "connectors";
import optionsContext from "contexts/options/options.context";
import { GET_ALL_INFLUENCER } from "graphql/queries";
import { useToastContext } from "hooks/useToastContext";

export default function InfluencerReviewModalContainer() {
  const { modal, closeModal } = useModalContext("influencerReviewModal");

  const { showToast } = useToastContext();

  const { allInfluencerOptions } = useContext(optionsContext);

  const EDIT_DEFAULT_VALUES = {
    socialAccount1: modal.socialAccount1,
    socialAccount2: modal?.socialAccount2,
    socialAccount3: modal?.socialAccount3,
  };

  const methods = useForm({
    resolver: yupResolver(EDIT_INFLUENCER.schema),
    defaultValues: EDIT_DEFAULT_VALUES,
  });

  const [editInfluencer] = useEditInfluencer();

  const closeModalHandler = () => {
    methods.reset(EDIT_DEFAULT_VALUES);
    closeModal();
  };

  const cancelHandler = () => {
    methods.reset(EDIT_DEFAULT_VALUES);
    closeModal();
  };

  const resetAsyncForm = useCallback(async () => {
    methods.reset(EDIT_DEFAULT_VALUES);
  }, [methods.reset, EDIT_DEFAULT_VALUES]);

  useEffect(() => {
    resetAsyncForm();
  }, [methods.reset, modal.isOpen]);

  const onSubmit = (data) => {
    editInfluencer({
      variables: {
        input: transformEditInfluencer({ ...data, id: modal.id }),
      },
      refetchQueries: [GET_ALL_INFLUENCER],
      onCompleted: () => {
        showToast({
          description: "The influencer has been successfully updated!",
        });
        closeModalHandler();
      },
      onError: (error) => {
        console.log(error?.message);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <InfluencerReview
        isOpen={modal.isOpen}
        onClose={closeModal}
        onSubmit={onSubmit}
        cancelHandler={cancelHandler}
        name={`${modal.user?.firstName} ${modal.user?.lastName}`}
        status={modal.status}
        level={modal.level}
        levelsOptions={transformInfluencerLevel(
          allInfluencerOptions?.influencerLevel
        )}
        statusOptions={transformInfluencerStatus(
          allInfluencerOptions?.influencerStatus
        )}
      />
    </FormProvider>
  );
}
