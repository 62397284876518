import { Box } from "@chakra-ui/react";
import { LocationIcon } from "components/Icons";
import GoogleMapReact from "google-map-react";
import { useCallback, useEffect, useState } from "react";

const containerStyle = {
  width: "100%",
  height: "50vh",
};

function Marker() {
  return (
    <Box sx={{ marginTop: -10, marginLeft: -5 }}>
      <LocationIcon width={10} height={10} fill="red.500" />
    </Box>
  );
}

export default function GoogleMap({ placeCoordinates, getLocationFromMap }) {
  const [center, setCenter] = useState();

  useEffect(() => {
    if (placeCoordinates) setCenter(placeCoordinates);
  }, [placeCoordinates]);

  const handleMapClick = useCallback((event) => {
    const coordinates = { lat: event?.lat, lng: event?.lng };
    setCenter(coordinates);

    fetch(
      `${process.env.REACT_APP_GOOGLE_GEOCODE}?latlng=${event?.lat},${event?.lng}`
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.results[0]) {
          getLocationFromMap({
            description: response.results[0].formatted_address,
            place_id: response.results[0].place_id,
          });
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div style={containerStyle}>
      <GoogleMapReact center={center} zoom={10} onClick={handleMapClick}>
        <Marker lat={center?.lat} lng={center?.lng} />
      </GoogleMapReact>
    </div>
  );
}
