import { useContext, useMemo } from "react";
import { useGetCurrencies as useFetchCurrencies } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetCurrencies = ({ skip } = {}) => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, currencies } = useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return !isAuth || !!currencies.length || skip;
  }, [isAuth, currencies.length, skip]);

  useFetchCurrencies({
    onCompleted: (response) => {
      updateOptionsState({
        currencies: response?.getCurrency,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetCurrencies;
