import { useMemo } from "react";
import { usePagination, useSortBy } from "react-table";
import VENUES_VOUCHERS_TABLE_DATA from "mock/VENUES_VOUCHERS_TABLE_DATA";
import { useModalContext } from "hooks/useModalContext";
import TableProvider from "contexts/table/table.provider";
import VouchersTable from "pages/Venues/VenueManagement/TicketsAndVouchers/VouchersTable";
import { COLUMNS } from "pages/Venues/VenueManagement/TicketsAndVouchers/VouchersTable/table.config";

function VouchersContainer() {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => VENUES_VOUCHERS_TABLE_DATA, []);

  const { openModal } = useModalContext("addVenuesVoucherModal");

  return (
    <TableProvider
      options={{ columns, data, initialState: { pageSize: 3, pageIndex: 0 } }}
      useSortBy={useSortBy}
      usePagination={usePagination}
    >
      <VouchersTable openModal={openModal} />
    </TableProvider>
  );
}

export default VouchersContainer;
