export const transformAllUsersAddToCompany = (data = []) => {
  if (data?.rows) {
    return data?.rows?.map((item) => ({
      id: item?.id,
      photo: item?.photo,
      name: `${item?.firstName} ${item?.lastName}`,
    }));
  }
  return [];
};
