import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "contexts/auth/auth.context";
import { PATHS } from "constants/constants";

function PrivateRoute({ children }) {
  const auth = useContext(AuthContext);

  if (auth.isAuth) return children;

  return <Navigate to={PATHS.login} />;
}

export default PrivateRoute;
