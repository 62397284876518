import { gql } from "@apollo/client";

export const EDIT_USER = gql`
  mutation editUser($input: EditUser) {
    editUser(input: $input) {
      id
      firstName
      photo
      lastName
      gender
      phone
      email
      cityId
      roleId
      role {
        id
        name
      }
    }
  }
`;
