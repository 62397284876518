import dayjs from "dayjs";

export const transformCreatePost = (formData, id) => {
  if (formData) {
    const requestObj = {
      input: {
        title: formData.title,
      },
    };

    if (formData.main_text) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          text: formData.main_text,
        },
      });
    }

    if (formData.tags) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          postTags: formData.tags
            ?.split(",")
            .map((item) => item.trim())
            .filter((item) => item),
        },
      });
    }

    if (formData.publication_date) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          publicationDate: dayjs(formData.publication_date).format(
            "YYYY-MM-DD"
          ),
        },
      });
    }

    if (id) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          companyId: id,
        },
      });
    }

    if (formData.main_image) {
      Object.assign(requestObj, { image: formData.main_image });
    }

    if (formData.video) {
      Object.assign(requestObj, { video: formData.video });
    }

    return requestObj;
  }

  return {};
};
