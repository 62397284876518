import Status from "components/TableComponents/Status";
import { Box } from "@chakra-ui/react";
import TableLink from "components/TableComponents/Link";
import TableDescription from "components/TableComponents/Description";
import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value }) => {
      return <TableLink value={value} path="#" />;
    },
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <Box maxW="245px">
          <TableDescription text={value} />
        </Box>
      );
    },
  },
  {
    Header: "User Type",
    accessor: "userType",
    disableSortBy: false,
    Cell: ({ value }) => {
      return (
        <Box maxW="245px">
          <TableDescription text={value} />
        </Box>
      );
    },
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: ({ value }) => {
      return value === "SALE" ? "discount" : value;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <Status value={value.toLowerCase()} />;
    },
  },
  {
    Header: "Used",
    accessor: "countUsedOffer",
  },
  {
    Header: "Total",
    accessor: "total",
    Cell: ({ value }) => {
      return value ?? "-";
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options offer={row.original} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
