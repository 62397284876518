import {
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { useModalContext } from "hooks/useModalContext";
import AddEditPromo from "components/Modals/AddEditPromo";

function PromoAddEditModalContainer() {
  const { modal, closeModal } = useModalContext("promoAddEditModal");

  const methods = useForm();

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Add promo</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <AddEditPromo />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}

export default PromoAddEditModalContainer;
