import { Box } from "@chakra-ui/react";

function ArrowDownThinIcon({ ...rest }) {
  return (
    <Box {...rest}>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.59L3.81799 4.408L6.63599 1.59"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

export default ArrowDownThinIcon;
