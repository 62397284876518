export const getDefaultCategory = (
  categoryId,
  categoryGroupName,
  categories = []
) => {
  let defaultCategory = null;

  const groupCategories = categories.filter(
    (category) => category.modelName === categoryGroupName
  );

  groupCategories?.forEach((category) => {
    if (category.id === categoryId) {
      defaultCategory = { value: categoryId, label: category.name };
    }
  });
  return defaultCategory;
};
