import { gql } from "@apollo/client";

export const GET_ONE_ATTRACTION = gql`
  query getOneAttraction($id: Int!) {
    getOneAttraction(id: $id) {
      id
      url
      status
      isCompleted
      isAddToSlider
      image
      geolocation
      currencyId
      categoryId
      photo
      location {
        id
        title
      }
      locationAdmin {
        id
        title
      }
      attractionCategory {
        id
        modelName
        name
      }
      tr {
        id
        attractionId
        languageId
        language {
          id
          code
          name
        }
        title
        description
      }
      attractionSeoAdmin {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      placeDetails {
        address
      }
      restrictions {
        id
        name
        image
      }
      services {
        id
        name
        image
      }
      gallery {
        id
        title
        modelName
        modelId
        description
        url
        categoryId
        date
        galleryPhoto {
          id
          modelName
          modelId
          path
          order
          description
          altTag
          isLiked
          photoAuthor {
            id
          }
        }
        galleryPhotoCount
      }
    }
  }
`;
