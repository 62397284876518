import { useState, useEffect, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EDIT_USER_PERSONAL_INFO } from "schemas";
import { Profile } from "pages";
import { getDefaultLocation } from "utils/getDefaultLocation";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useUpdateProfile } from "graphql/hooks/useMutations";
import {
  transformCityOptions,
  transformCountryOptions,
  transformUpdateProfile,
} from "connectors";
import isObjectEmpty from "../utils/isObjectEmpty";

export default function ProfileContainer() {
  const [isEditProfile, setIsEditProfile] = useState(false);
  const { countries, currentUser: userInfo } = useOptionsContext();

  const INITIAL_VALUES = {
    id: userInfo?.currentUser?.id,
    firstName: userInfo?.currentUser?.firstName,
    lastName: userInfo?.currentUser?.lastName,
    role: userInfo?.currentUser?.role?.name,
    email: userInfo?.currentUser?.email,
    company: userInfo?.currentUser?.company?.companyName,
    phone: userInfo?.currentUser?.phone,
    description: userInfo?.currentUser?.description,
    country: getDefaultLocation(countries, userInfo?.currentUser?.cityId)
      .defaultCountry,
    city: getDefaultLocation(countries, userInfo?.currentUser?.cityId)
      .defaultCity,
    photo: userInfo?.currentUser?.photo
      ? {
          preview: `${process.env.REACT_APP_API_URL}/${userInfo.currentUser.photo}`,
        }
      : null,
  };

  const [updateProfile] = useUpdateProfile();

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(EDIT_USER_PERSONAL_INFO.schema),
  });

  const photo = methods.watch("photo");

  useEffect(() => {
    methods.reset(INITIAL_VALUES);
  }, [isEditProfile]);

  const countryChangeHandler = () => {
    methods.setValue("city", null);
  };

  const onEditFunc = () => {
    setIsEditProfile(true);
  };

  const onDiscardChanges = () => {
    setIsEditProfile(false);
    methods.reset();
  };

  const onSubmit = (formData) => {
    setIsEditProfile(false);
    Object.assign(formData, { id: userInfo?.currentUser.id });

    updateProfile({
      variables: {
        input: transformUpdateProfile(formData),
        photo:
          photo?.preview === INITIAL_VALUES.photo?.preview ? undefined : photo,
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const resetAsyncForm = useCallback(async () => {
    methods.reset(INITIAL_VALUES);
  }, [methods.reset, userInfo]);

  useEffect(() => {
    resetAsyncForm();
  }, [methods.reset, userInfo]);

  if (isObjectEmpty(userInfo)) return <div>Loading...</div>;

  return (
    <FormProvider {...methods}>
      <Profile
        onSubmit={methods.handleSubmit(onSubmit)}
        isEditProfile={isEditProfile}
        onEditFunc={onEditFunc}
        onDiscardChanges={onDiscardChanges}
        optionsCountry={transformCountryOptions(countries)}
        optionsCity={transformCityOptions(methods.watch("country"), countries)}
        initialValues={INITIAL_VALUES}
        countryChangeHandler={countryChangeHandler}
      />
    </FormProvider>
  );
}
