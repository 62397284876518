export const transformVenuesEntertainmentsSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    field: "title",
    type: state.desc ? "DESC" : "ASC",
  };
};
