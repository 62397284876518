import Textarea from "components/Textarea";
import { useFormContext, useWatch } from "react-hook-form";
import { getFieldName } from "utils/getFieldName";

export default function TextareaRHF(props) {
  const { name, maxLength, ...rest } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext(name);

  const val = useWatch({
    name,
  });

  return (
    <Textarea
      {...rest}
      {...register(name)}
      value={val}
      maxLength={maxLength}
      hasError={getFieldName(errors, name)}
    />
  );
}
