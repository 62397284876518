import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { useModalContext } from "hooks/useModalContext";
import GoogleMap from "components/GoogleMap";
import { useCallback, useEffect, useRef, useState } from "react";
import { INITIAL_COORDINATES } from "constants/constants";
import { debounce } from "hooks/useDebounce";

export default function GoogleMapModal() {
  const { modal, closeModal } = useModalContext("googleMapModal");

  const [placeCoordinates, setPlaceCoordinates] = useState();

  const cacheCoordinates = useRef({});

  const getPlaceDetails = useCallback(
    debounce((placeId) => {
      if (cacheCoordinates.current[placeId]) {
        setPlaceCoordinates(cacheCoordinates.current[placeId]);
      } else {
        fetch(
          `${process.env.REACT_APP_GOOGLE_PLACE_DETAILS}?place_id=${placeId}&fields=geometry`
        )
          .then((response) => response.json())
          .then((data) => {
            const coordinates = {
              lat: data.result.geometry.location.lat,
              lng: data.result.geometry.location.lng,
            };
            cacheCoordinates.current[placeId] = coordinates;
            setPlaceCoordinates(coordinates);
          })
          .catch((error) => console.log(error));
      }
    }),
    []
  );

  useEffect(() => {
    if (modal.place_id) {
      getPlaceDetails(modal.place_id);
    } else {
      setPlaceCoordinates(INITIAL_COORDINATES);
    }
  }, [getPlaceDetails, modal.place_id]);

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody sx={{ p: 0 }}>
          <GoogleMap
            placeCoordinates={placeCoordinates}
            getLocationFromMap={modal.getLocationFromMap}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
