import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Box,
  Grid,
  GridItem,
  Flex,
  Spacer,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import { ArrowLeftIcon } from "components/Icons";
import { useModalContext } from "hooks/useModalContext";
import {
  useCreateCompany,
  useDeletePhotosCompany,
  useEditCompany,
} from "graphql/hooks/useMutations";
import { GET_ALL_COMPANIES } from "graphql/queries";
import {
  transformAddCompanyData,
  transformCityOptions,
  transformCountryOptions,
  transformEditCompanyData,
  transformReceivedCompanyModalData,
  transformReceivedCreateCompany,
} from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useCompanyContext } from "hooks/useCompanyContext.hook";
import { useEffect } from "react";
import { initialValue } from "contexts/company/company.provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADD_NEW_COMPANY_SCHEMA } from "schemas";

export default function AddNewCompany() {
  const {
    company,
    updateCompanyState,
    getAddNewCompany,
    getCompanyId,
    clearCompanyState,
  } = useCompanyContext();

  const { countries } = useOptionsContext();

  const { modal: newCompany, closeModal: closeNewCompany } = useModalContext(
    "createNewCompanyModal"
  );

  const { openModal: openOwnerInfo } = useModalContext("addOwnerInfo");

  const methods = useForm({
    defaultValues: newCompany.isEditMode
      ? getAddNewCompany()
      : initialValue.addNewCompany,
    resolver: yupResolver(ADD_NEW_COMPANY_SCHEMA),
  });

  const countriesOptions = transformCountryOptions(countries);
  const citiesOptions = transformCityOptions(
    methods.watch("country"),
    countries
  );

  const countryChangeHandler = () => {
    methods.setValue("city", null);
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const [createCompany] = useCreateCompany();
  const [editCompany] = useEditCompany();
  const [deletePhotoCompany] = useDeletePhotosCompany();

  const onDeleteCallback = ({ id }) => {
    if (id) {
      deletePhotoCompany({
        variables: {
          photoId: id,
        },
      });
    }
  };

  const closeNewCompanyHandler = () => {
    clearCompanyState();
    methods.reset(initialValue.addNewCompany);
    closeNewCompany();
  };

  const cancelNewCompanyHandler = () => {
    clearCompanyState();
    closeNewCompany();
  };

  const newCompanySubmit = (newCompanyData) => {
    if (!getCompanyId()) {
      createCompany({
        variables: { info: transformAddCompanyData(newCompanyData) },
        refetchQueries: [GET_ALL_COMPANIES],
        onCompleted: (response) => {
          updateCompanyState(
            transformReceivedCreateCompany({
              responseData: response?.createCompany,
              countries,
            })
          );
          openOwnerInfo();
          closeNewCompany();
        },
      });
    } else {
      editCompany({
        variables: {
          info: {
            id: getCompanyId(),
            ...transformEditCompanyData(newCompanyData),
          },
        },
        refetchQueries: [GET_ALL_COMPANIES],
        onCompleted: (response) => {
          updateCompanyState(
            transformReceivedCreateCompany({
              responseData: response?.editCompany,
              countries,
            })
          );
          openOwnerInfo();
          closeNewCompany();
        },
      });
    }
  };

  useEffect(() => {
    if (company.id) {
      methods.reset(getAddNewCompany());
    } else if (newCompany.isAddMode) {
      methods.reset(initialValue);
    }
  }, [company.id, newCompany.isOpen]);

  useEffect(() => {
    if (newCompany.isEditMode) {
      updateCompanyState(
        transformReceivedCompanyModalData({
          responseData: newCompany.company,
          countries,
        })
      );
    }
  }, [newCompany.isEditMode]);

  return (
    <FormProvider {...methods}>
      <Modal isOpen={newCompany.isOpen} onClose={closeNewCompanyHandler}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Heading>
                {newCompany.isEditMode || company.id
                  ? "Edit company information"
                  : "Add new company"}
              </Heading>
              <Spacer />
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(newCompanySubmit)}>
            <ModalBody>
              <VStack spacing={6}>
                <Grid
                  templateColumns="repeat(3, 1fr)"
                  templateRows="repeat(2, 1fr)"
                  columnGap={6}
                  rowGap={4}
                  w="100%"
                >
                  <GridItem colSpan={2} rowSpan={1}>
                    <TextFieldRHF
                      id="companyName"
                      name="companyName"
                      inputType="text"
                      label="Company name"
                      inputPlaceholder="Add company name"
                      errorMessage={errors?.companyName?.message}
                    />
                  </GridItem>
                  <GridItem colSpan={1} rowSpan={2}>
                    <Box>
                      <DropzoneFieldRHF
                        width="160px"
                        height="160px"
                        name="companyLogo"
                        label="Upload logo"
                        acceptStr="image/jpeg,image/png"
                        onDeleteCallback={onDeleteCallback}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={2} rowSpan={1}>
                    <SelectRHF
                      name="country"
                      label="Country"
                      options={countriesOptions}
                      placeholder="Select country"
                      onChangeHandler={countryChangeHandler}
                      errorMessage={errors?.country?.value?.message}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  templateColumns="repeat(3, 1fr)"
                  templateRows="repeat(2, auto)"
                  columnGap={6}
                  rowGap={4}
                  w="100%"
                >
                  <GridItem rowSpan={1} colSpan={3}>
                    <SelectRHF
                      name="city"
                      label="City"
                      options={citiesOptions}
                      placeholder="Select city"
                      isDisabled={!watch("country")}
                      errorMessage={errors?.city?.value?.message}
                    />
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={3}>
                    <TextFieldRHF
                      id="address"
                      name="address"
                      inputType="text"
                      label="Address"
                      inputPlaceholder="Add address"
                      errorMessage={errors?.address?.message}
                    />
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={3}>
                    <TextFieldRHF
                      id="license"
                      name="license"
                      inputType="text"
                      label="License #"
                      inputPlaceholder="Add license number"
                      errorMessage={errors?.license?.message}
                    />
                  </GridItem>
                  <GridItem colSpan={3} rowSpan={1}>
                    <DropzoneFieldRHF
                      width="100%"
                      height="100%"
                      name="license_photo"
                      label="License photo"
                      acceptStr="image/jpeg,image/jpg,image/png"
                      multiple
                      onDeleteCallback={onDeleteCallback}
                    />
                  </GridItem>
                </Grid>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Flex w="100%" px={6}>
                <Button
                  onClick={cancelNewCompanyHandler}
                  variant="outline"
                  colorScheme="blue.500"
                >
                  Cancel
                </Button>
                <Spacer />
                <Button type="submit">
                  Owner info{" "}
                  <Box ml={4} transform="rotate(180deg)">
                    <ArrowLeftIcon stroke="currentColor" />
                  </Box>
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
