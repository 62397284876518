import { allCategories } from "constants/constants";
import { transformReceivedAddVenueMenu } from "./transformReceivedAddVenueMenu";
import { transformReceivedUpdateVenueGallery } from "./transformReceivedUpdateVenueGallery";
import { transformReceivedVenueGeneralInfo } from "./transformReceivedVenueGeneralInfo";

export const transformFullVenue = ({
  responseData,
  countries,
  translatedValues,
  categories,
  languages,
}) => {
  if (responseData) {
    const newContext = {
      id: responseData.id,
      generalInfo: transformReceivedVenueGeneralInfo({
        responseData,
        countries,
        translatedValues,
        languages,
      })?.generalInfo,
    };

    if (responseData.venueMenu) {
      Object.assign(newContext, {
        venueMenu: transformReceivedAddVenueMenu(responseData.venueMenu)
          ?.venueMenu,
      });
    }

    if (responseData.galleryVenue) {
      Object.assign(newContext, {
        galleryVenue: transformReceivedUpdateVenueGallery({
          responseData: responseData.galleryVenue,
          allCategories: allCategories.galleryCategory,
          categories,
        })?.galleryVenue,
      });
    }

    return newContext;
  }
  return {};
};
