export const transformVenueTableData = (data) => {
  if (!data) return {};
  return {
    amount: data.tableCount,
    seats: data.seatsPerTable,
    table: data.tableName,
  };
};

export const transformVenueTableFormData = (data) => {
  if (!data) return {};
  return {
    table_name: data.table,
    tables_count: data.amount,
    seats_per_table: data.seats,
  };
};
