import { gql } from "@apollo/client";

export const GET_ALL_POSITIONS = gql`
  query getAllPositions($options: getPositions) {
    getAllPositions(options: $options) {
      count
      rows {
        id
        page
        level
      }
    }
  }
`;
