import { useCallback } from "react";
import { HStack, VStack, Divider } from "@chakra-ui/react";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { useRemoveGalleryPhoto } from "graphql/hooks/useMutations";
import Actions from "./Actions";
import TextareaRHF from "../TextareaRHF";

function PhotoItem(props) {
  const {
    index,
    name,
    remove,
    showActions,
    isFirst,
    isLast,
    swap,
    dropzoneCallback,
    field,
  } = props;

  const [removeGalleryPhoto] = useRemoveGalleryPhoto({
    variables: {
      photoId: field?.image?.id,
    },
  });

  const handleDelete = useCallback(() => {
    remove(index);
    if (field?.image?.id) {
      removeGalleryPhoto();
    }
  }, [remove, index]);

  const handleSwap = useCallback(
    (direction) => () => {
      const dir = direction === "down" ? 1 : -1;
      swap(index, index + dir);
    },
    [swap, index]
  );

  return (
    <>
      <HStack spacing={4} w="full" alignItems="flex-start">
        <HStack spacing={6} w="full">
          <DropzoneFieldRHF
            name={`${name}.${index}.image`}
            width="148px"
            height="148px"
            containerStyles={{ w: "initial" }}
            small
            onChangeCallback={dropzoneCallback}
            acceptStr="image/jpeg,image/jpg,image/png"
          />
          <VStack spacing={4} w="full">
            <TextFieldRHF
              inputPlaceholder="Add alt tag"
              label="Img alt tag"
              name={`${name}.${index}.altTag`}
              inputType="text"
            />
            <TextareaRHF
              name={`${name}.${index}.description`}
              placeholder="Photo description"
              width="full"
              height="56px"
              maxLength={140}
            />
          </VStack>
        </HStack>
        <Actions
          handleSwap={handleSwap}
          handleDelete={handleDelete}
          isFirst={isFirst}
          isLast={isLast}
          showActions={showActions}
        />
      </HStack>

      <Divider />
    </>
  );
}

export default PhotoItem;
