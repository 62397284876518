import { transformFieldsLangsFormat } from "utils/transformFieldsLangsFormat";

export const transformBeforeEditStaticPage = (
  editStaticPage,
  translatedValues,
  languages
) => {
  if (editStaticPage) {
    const textData = transformFieldsLangsFormat(
      languages,
      editStaticPage.tr,
      ["title", "content"],
      "content"
    )?.reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;

        acc.description[valueObj.language.code] =
          (valueObj?.content && JSON.parse(valueObj?.content)) || "";

        return acc;
      },
      {
        title: {},
        description: {},
      }
    );

    const staticPageSEO = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
    };

    if (editStaticPage?.staticPageSEOAdmin) {
      editStaticPage?.staticPageSEOAdmin.forEach((valueObj) => {
        staticPageSEO.metaTitle[valueObj.language.code] = valueObj.metaTitle;
        staticPageSEO.metaDescription[valueObj.language.code] =
          valueObj.metaDescription;
      });
    }

    return {
      language: { label: "English", value: { id: 1, code: "en-US" } },
      title: textData?.title,
      meta_title: staticPageSEO?.metaTitle,
      meta_description: staticPageSEO?.metaDescription,
      description: textData?.description,
      url: editStaticPage?.url,
      content: textData?.description,
      status: {
        label: editStaticPage?.status.toUpperCase(),
        value: editStaticPage?.status.toUpperCase(),
      },
    };
  }
  return {};
};
