export const transformCommentsListSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    field:
      {
        parent: "parentTitle",
        comment: "text",
        author: "commentAuthor",
        date: "createdAt",
      }[state.id] || state.id,
    type: state.desc ? "DESC" : "ASC",
  };
};
