import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import CHS from "./chakra.styles";

function RoundedRadioItem({
  isActive,
  value,
  children,
  onChange,
  isFirst,
  isLast,
}) {
  const styles = useMemo(() => {
    let sx = CHS.item;

    if (isActive) {
      sx = { ...sx, ...CHS.activeItem };
    }

    if (isFirst) {
      sx = { ...sx, ...CHS.firstItem };
    }

    if (isLast) {
      sx = { ...sx, ...CHS.lastItem };
    }

    return sx;
  }, [isActive, isFirst, isLast]);

  return (
    <Box data-value={value} sx={styles} onClick={onChange}>
      {children}
    </Box>
  );
}

export default RoundedRadioItem;
