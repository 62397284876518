import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
} from "@chakra-ui/react";
import AcceptedOffersItem from "components/Modals/AcceptedOffers/AcceptedOffersItem";
import CHS from "./chakra.styles";

export default function AcceptedOffersList({ items }) {
  return items?.length ? (
    <TableContainer sx={CHS.offersContainer}>
      <Table variant="modalTable">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Accepted offer</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(({ id, date, product }) => (
            <AcceptedOffersItem key={id} date={date} title={product?.title} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Text sx={CHS.offersText}>History is empty</Text>
  );
}
