import {
  EditOptionIcon,
  DeleteOptionIcon,
  EyeOpenIcon,
} from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useRemoveStaticPage } from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";
import { previewUnpublishedEntity } from "utils/previewUnpublishedEntity";
import { RESERVED_STATIC_PAGES } from "constants/constants";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ page }) {
  const { showToast } = useToastContext();
  const { openModal: openCreateModal } = useModalContext(
    "staticPageCreateEditModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessUpdateContent, accessDeleteContent } = getAccessScopesControl();

  const editStaticPage = () => {
    openCreateModal({
      editStaticPage: page,
      isEditMode: true,
    });
  };

  const [removeStaticPage] = useRemoveStaticPage({
    variables: {
      id: page.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "Page has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: page.title,
      type: "page",
      onDelete: removeStaticPage,
    });
  };

  const openPreview = () => {
    if (RESERVED_STATIC_PAGES.includes(page?.url)) {
      previewUnpublishedEntity("", page?.url);
    } else {
      previewUnpublishedEntity("static", page?.url);
    }
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EyeOpenIcon sx={CHS.eyeIcon} />}
        onMenuItemClick={openPreview}
      >
        Preview
      </CustomMenuItem>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={editStaticPage}
        isDisabled={Boolean(!accessUpdateContent)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteContent)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
