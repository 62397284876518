import { Icon } from "@chakra-ui/react";

export default function TrashIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M9.33301 9.42878H10.666V17.1429H9.33301V9.42878Z" />
      <path d="M13.333 9.42878H14.666V17.1429H13.333V9.42878Z" />
      <path d="M4 5.5717V6.85707H5.333V19.7146C5.333 19.8835 5.36751 20.0507 5.43456 20.2067C5.50162 20.3628 5.59989 20.5045 5.72378 20.6239C5.84767 20.7432 5.99473 20.8379 6.15658 20.9024C6.31842 20.967 6.49186 21.0001 6.667 21H17.333C17.6865 21 18.0256 20.8646 18.2756 20.6235C18.5256 20.3825 18.666 20.0555 18.666 19.7146V6.85707H20V5.5717H4ZM6.667 19.7146V6.85707H17.333V19.7146H6.667Z" />
      <path d="M9.33301 3H14.666V4.28537H9.33301V3Z" />
    </Icon>
  );
}
