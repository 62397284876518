import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";

export const transformGetAllArticleAdmin = (data = []) => {
  if (data) {
    return data?.map((item) => ({
      id: item.id,
      title: item.tr.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.title,
      authorName: item.authorName,
      url: item.url,
      viewsCount: item.viewsCount,
      publicatedAt: item.publicatedAt,
      tags: item.tags,
      status: item.status,
      showOnMain: item.showOnMain,
      // --- for moderating article ---
      authorAvatar: item.authorAvatar,
      articleSEO: item.articleSEO,
      categoryId: item.categoryId,
      mainImage: item.mainImage,
      promoImage: item.promoImage,
      tr: item.tr,
    }));
  }
  return [];
};
