import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";

export const transformGetAllStaticPages = (data = []) => {
  if (data) {
    return data?.map((item) => ({
      id: item.id,
      title: item.tr.find(
        (obj) => obj.languageId === DEFAULT_ENGLISH_LANGUAGE_ID
      ).title,
      order: item.id,
      url: item.url,
      status: item.status,
      ...item,
    }));
  }
  return [];
};
