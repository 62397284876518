import { useModalContext } from "hooks/useModalContext";
import { FormProvider, useForm } from "react-hook-form";
import StaticPageCreateEditModal from "components/Modals/StaticPageCreateEdit";
import {
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  transformBeforeEditStaticPage,
  transformCreateStaticPage,
  transformEditStaticPage,
  transformLanguageSelect,
  transformLanguagesToCodeList,
} from "connectors";
import { onLanguageChange } from "utils/onLanguageChange";
import { useOptionsContext } from "hooks/useOptionsContext";
import { GET_ALL_STATIC_PAGES } from "graphql/queries";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CREATE_STATIC_PAGE_SCHEMA, EDIT_STATIC_PAGE_SCHEMA } from "schemas";
import { transformStatusOptions } from "connectors/transformStatusOptions";
import {
  useCreateStaticPage,
  useEditStaticPage,
} from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";
import useGetStaticPagesOptions from "hooks/useGetStaticPagesOptions.hook";

export default function StaticPageCreateEditModalContainer() {
  const { modal, closeModal } = useModalContext("staticPageCreateEditModal");

  useGetStaticPagesOptions({ skip: !modal.isOpen });

  const { showToast } = useToastContext();

  const { languages, staticPagesStatuses } = useOptionsContext();

  const codeList = transformLanguagesToCodeList(languages);
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});

  const INITIAL_VALUES = {
    language: { label: "English", value: { id: 1, code: "en-US" } },
    title: translatedValues,
    meta_title: translatedValues,
    meta_description: translatedValues,
    url: "",
    main_text: translatedValues,
    status: null,
  };

  const methods = useForm({
    defaultValues: modal.isEditMode
      ? transformBeforeEditStaticPage(
          modal.editStaticPage,
          translatedValues,
          languages
        )
      : INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode
        ? EDIT_STATIC_PAGE_SCHEMA.schema(languages)
        : CREATE_STATIC_PAGE_SCHEMA.schema(languages)
    ),
  });

  const { watch } = methods;

  const [createStaticPage] = useCreateStaticPage();
  const [editStaticPage] = useEditStaticPage();

  const closeHandler = () => {
    closeModal();
  };

  const selectedLanguage = watch("language");

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      editStaticPage({
        variables: {
          input: transformEditStaticPage(
            formData,
            modal.editStaticPage?.id,
            languages
          ),
        },
        refetchQueries: [GET_ALL_STATIC_PAGES],
        onCompleted: () => {
          showToast({
            description: "Page has been successfully updated",
          });
          closeHandler();
        },
        onError: (error) => {
          if (error) {
            switch (error.message) {
              case "SequelizeUniqueConstraintError: Validation error":
                methods.setError("url", {
                  type: "custom",
                  message: "Page under this URL already exists",
                });
                break;
              default:
                console.log(error.message);
            }
          }
        },
      });
    } else {
      createStaticPage({
        variables: { input: transformCreateStaticPage(formData, languages) },
        refetchQueries: [GET_ALL_STATIC_PAGES],
        onCompleted: () => {
          showToast({
            description: "Page has been successfully created",
          });
          closeHandler();
        },
        onError: (error) => {
          if (error) {
            switch (error.message) {
              case "SequelizeUniqueConstraintError: Validation error":
                methods.setError("url", {
                  type: "custom",
                  message: "Page under this URL already exists",
                });
                break;
              default:
                console.log(error.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(
        transformBeforeEditStaticPage(
          modal.editStaticPage,
          translatedValues,
          languages
        )
      );
    } else if (modal.isAddMode) {
      methods.reset(INITIAL_VALUES);
    }
  }, [modal.isEditMode, modal.isAddMode]);

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>
              {modal.isEditMode ? "Page moderation" : "Add page"}
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <StaticPageCreateEditModal
            onSubmit={methods.handleSubmit(onSubmit)}
            cancelHandler={closeHandler}
            modal={modal}
            selectedLanguage={selectedLanguage}
            languageOptions={transformLanguageSelect(languages)}
            statusOptions={transformStatusOptions(staticPagesStatuses)}
            languageChangeHandler={languageChangeHandler}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
