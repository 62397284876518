import {
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  Spacer,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import TableContext from "contexts/table/table.context";
import { useAddVenueEvents } from "graphql/hooks/useMutations";
import { GET_ALL_VENUE_EVENTS } from "graphql/queries";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import Table from "./Table";

export function AddNewEntertainment({
  onSubmit,
  cancelHandler,
  loading,
  venueId,
  hasData,
}) {
  const { handleSubmit } = useFormContext();

  const { selectedRowIds } = useContext(TableContext);

  const [addVenueEvents] = useAddVenueEvents();

  const handleWrapper = (e) => {
    e.preventDefault();
    addVenueEvents({
      variables: {
        input: { venueId: venueId || null, events: selectedRowIds },
      },
      refetchQueries: [GET_ALL_VENUE_EVENTS],
    });
    return handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleWrapper}>
      <ModalBody>
        <VStack spacing={2}>
          <TextFieldRHF
            name="name"
            inputType="text"
            label="Select Event"
            inputPlaceholder="Start typing"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
          />
          {loading ? (
            <Flex alignItems="center" justify="center" h="100px">
              <Spinner size="xl" />
            </Flex>
          ) : hasData ? (
            <Table />
          ) : (
            <Flex alignItems="center" justify="center" h="100px">
              <NoContentMessage />
            </Flex>
          )}
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%" px={6}>
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            Cancel
          </Button>
          <Spacer />
          <Button type="submit" px={7} w={44}>
            Add entertainment
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
