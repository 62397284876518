import * as yup from "yup";

const schema = yup.object().shape({
  owners: yup.array().of(
    yup.object().shape({
      owner: yup.string().required("Owner name is required"),
      birthDate: yup.string().nullable().required("Birth date is required"),
      share: yup
        .number()
        .typeError("Share must be a number")
        .required("Share is required"),
      passport: yup.string().required("Passport is required"),
      passportPhoto: yup
        .mixed()
        .nullable()
        .test("fileFormat", "JPEG, JPG or PNG only", (value) => {
          if (!value) {
            return true;
          }
          return value?.every((item) => {
            return item?.preview;
          });
        }),
    })
  ),
});

export default schema;
