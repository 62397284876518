import { useMemo } from "react";
import { usePagination, useSortBy } from "react-table";
import { useModalContext } from "hooks/useModalContext";
import TableProvider from "contexts/table/table.provider";
import TicketsTable from "pages/Venues/VenueManagement/TicketsAndVouchers/TicketsTable";
import { COLUMNS } from "pages/Venues/VenueManagement/TicketsAndVouchers/TicketsTable/table.config";
import VENUES_TICKETS_TABLE_DATA from "mock/VENUES_TICKETS_TABLE_DATA";

function TicketsContainer() {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => VENUES_TICKETS_TABLE_DATA, []);

  const { openModal } = useModalContext("addVenuesTicketModal");

  return (
    <TableProvider
      options={{ columns, data, initialState: { pageSize: 3, pageIndex: 0 } }}
      useSortBy={useSortBy}
      usePagination={usePagination}
    >
      <TicketsTable openModal={openModal} />
    </TableProvider>
  );
}

export default TicketsContainer;
