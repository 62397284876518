import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation ResetPassword($passwordResetToken: String!, $newPassword: String!) {
    setNewPassword(
      passwordResetToken: $passwordResetToken
      newPassword: $newPassword
    ) {
      status
    }
  }
`;
