import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";
import * as yup from "yup";

const schema = (languages) => {
  const multiLanguageObjectValidationRequired = languages.reduce(
    (acc, language) => {
      acc[language.code] =
        language?.id === DEFAULT_ENGLISH_LANGUAGE_ID
          ? yup.string().required("Location name is required!")
          : yup.string();

      return acc;
    },
    {}
  );

  return yup.object().shape({
    title: yup.object().shape(multiLanguageObjectValidationRequired),
    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),
    address: yup.string().required("Address is required!"),
    country: yup.mixed().required("City is required").nullable(true),
    city: yup.mixed().required("City is required").nullable(true),
    image: yup
      .mixed()
      .nullable()
      .test("preview", "has preview", (value) => {
        return typeof value?.preview === "string";
      }),
  });
};

export default { schema };
