import { gql } from "@apollo/client";

export const GET_ALL_VENUE_OPTIONS = gql`
  query getAllOptionsVenues {
    getAllOptionsVenues {
      venueStatus
      venueRanks
      services {
        id
        name
      }
      categories {
        id
        name
      }
    }
  }
`;
