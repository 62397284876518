import React, { useEffect, useState } from "react";
import { usePagination, useSortBy, useTable, useRowSelect } from "react-table";
import { TableCheckbox } from "components/TableComponents/Checkbox";
import TableContext from "./table.context";

function TableProvider({
  children,
  options,
  dataCount,
  onPageChanged,
  onPageSizeChanged,
  onTotalCountChanged,
  isRowSelectionEnabled,
  onSetSortState,
}) {
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSelectRow = (id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const tableInstance = useTable(
    options,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isRowSelectionEnabled) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: "",
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }) => {
              return (
                <TableCheckbox
                  {...row.getToggleRowSelectedProps()}
                  row={row}
                  onChangeHandler={handleSelectRow}
                />
              );
            },
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    onPageChanged?.({
      pageCount: tableInstance.pageCount,
      pageIndex: tableInstance.state.pageIndex,
    });
  }, [tableInstance.state.pageIndex, tableInstance.pageCount]);

  useEffect(() => {
    onPageSizeChanged?.({
      pageSize: tableInstance.state.pageSize,
      gotoPage: tableInstance.gotoPage,
    });
  }, [tableInstance.state.pageSize, tableInstance.gotoPage]);

  useEffect(() => {
    onTotalCountChanged?.(dataCount);
  }, [dataCount]);

  useEffect(() => {
    onSetSortState?.(tableInstance.state.sortBy[0]);
  }, [tableInstance.state.sortBy]);

  return (
    // this spread is a must
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TableContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ ...tableInstance, selectedRowIds, setSelectedRowIds }}
    >
      {children}
    </TableContext.Provider>
  );
}

export default TableProvider;
