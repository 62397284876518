import { transformLanguagesToCodeList } from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useReducer } from "react";
import { attractionReducer } from "reducers";
import AttractionContext from "./attraction.context";

const initialValue = (codeList) => {
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});
  return {
    id: null,
    generalInfo: {
      title: translatedValues,
      status: null,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      isAddToSlider: false,
      attractionCategory: null,
      coordinates: { description: "", place_id: "" },
      location: null,
      description: translatedValues,
      restrictions: null,
      services: null,
      url: "",
      main_img: null,
      slider_photo: null,
      meta_title: translatedValues,
      meta_description: translatedValues,
    },
    galleryAttraction: {
      title: "",
      url: "",
      date: "",
      category: null,
      galleryPhoto: [],
    },
  };
};

export default function AttractionProvider({ children }) {
  const { languages } = useOptionsContext();

  const [attraction, dispatch] = useReducer(
    attractionReducer,
    initialValue(transformLanguagesToCodeList(languages))
  );

  const updateAttractionState = (data) => {
    dispatch({
      type: "UPDATE_ATTRACTION_STATE",
      payload: data,
    });
  };

  const getAttractionId = () => attraction.id;

  const getGeneralInfo = () => ({
    id: attraction.id,
    ...attraction.generalInfo,
  });

  const getGallery = () => ({
    ...attraction.galleryAttraction,
  });

  return (
    <AttractionContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        attraction,
        getAttractionId,
        updateAttractionState,
        getGeneralInfo,
        getGallery,
      }}
    >
      {children}
    </AttractionContext.Provider>
  );
}
