import { Box, Text } from "@chakra-ui/react";

import { capitalize } from "utils/capitalize";
import CHS from "./chakra.styles";

export default function ParentType({ original }) {
  const { parent, type } = original;
  return (
    <Box>
      {parent ? (
        <>
          <Text>{parent}</Text>
          <Text sx={CHS.reportType}>{capitalize(type)}</Text>
        </>
      ) : (
        <>
          <Text sx={CHS.removedEntry}>Entry removed</Text>
          <Text sx={CHS.reportType}>{capitalize(type)}</Text>
        </>
      )}
    </Box>
  );
}
