import { gql } from "@apollo/client";

export const ADD_ARTICLE_TAG = gql`
  mutation AddArticleTag($articleId: Int!, $name: String!) {
    addArticleTag(articleId: $articleId, name: $name) {
      id
      name
      viewsCount
    }
  }
`;
