import { useContext, useMemo } from "react";
import { useGetAdStatuses as useFetchAdStatuses } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

const useGetAdStatuses = () => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, adStatuses } = useOptionsContext();
  const { accessReadBanners } = getAccessScopesControl();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return !isAuth || !!adStatuses.length || !accessReadBanners;
  }, [isAuth, adStatuses.length, accessReadBanners]);

  useFetchAdStatuses({
    onCompleted: (response) => {
      updateOptionsState({
        adStatuses: response?.getAllOptionsAd?.adStatus,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetAdStatuses;
