import { allCategories } from "constants/constants";
import { getDefaultCategory } from "utils/getDefaultCategory";
import dayjs from "dayjs";
import { transformFieldsLangsFormat } from "utils/transformFieldsLangsFormat";

export const transformBeforeEditArticle = (
  editArticle,
  categories,
  translatedValues,
  languages
) => {
  if (editArticle) {
    const textData = transformFieldsLangsFormat(languages, editArticle.tr, [
      "title",
      "promoText",
      "mainText",
    ]).reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;
        acc.promoText[valueObj.language.code] = valueObj?.promoText || "";
        acc.mainText[valueObj.language.code] = valueObj.mainText || "";
        return acc;
      },
      {
        title: {},
        promoText: {},
        mainText: {},
      }
    );

    const articleSEO = {
      metaTitle: translatedValues,
      metaDescription: { ...translatedValues },
    };

    if (editArticle?.articleSEO) {
      editArticle?.articleSEO.forEach((valueObj) => {
        articleSEO.metaTitle[valueObj.language.code] = valueObj.metaTitle;
        articleSEO.metaDescription[valueObj.language.code] =
          valueObj.metaDescription;
      });
    }

    const tagsArr = editArticle.tags.map((item) => {
      return { value: item.id, label: item.name };
    });

    return {
      title: textData?.title,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      url: editArticle.url,
      show_on_main: editArticle.showOnMain,
      meta_title: articleSEO?.metaTitle,
      meta_description: articleSEO?.metaDescription,
      promo_image: {
        preview: `${process.env.REACT_APP_API_URL}/${editArticle?.promoImage}`,
      },
      promo_text: textData.promoText,
      tagsEdit: tagsArr,
      category: getDefaultCategory(
        editArticle?.categoryId,
        allCategories.articleCategory,
        categories
      ),
      main_image: {
        preview: `${process.env.REACT_APP_API_URL}/${editArticle?.mainImage}`,
      },
      main_text: textData.mainText,
      author_name: editArticle.authorName,
      author_avatar: editArticle.authorAvatar
        ? {
            preview: `${process.env.REACT_APP_API_URL}/${editArticle.authorAvatar}`,
          }
        : null,
      publication_date: dayjs(editArticle.publicatedAt)?.toDate() || "",
      status: {
        label: editArticle?.status.toUpperCase(),
        value: editArticle?.status.toUpperCase(),
      },
    };
  }
  return {};
};
