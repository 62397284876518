import { Button, Divider, VStack, Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { AddIcon } from "components/Icons";
import { useModalContext } from "hooks/useModalContext";
import { useGetAreasByHallId } from "graphql/hooks/useQueries";
import { useRemoveVenueArea } from "graphql/hooks/useMutations";
import { transformEditZoneData } from "connectors";
import ZoneRow from "./ZoneRow";

function Zones({ hallId }) {
  const { getValues } = useFormContext();

  const { data: zones } = useGetAreasByHallId({
    variables: {
      options: {
        venueHallId: hallId,
      },
    },
  });

  const [removeArea] = useRemoveVenueArea({
    variables: {
      hallId,
    },
  });

  const { closeModal: closeCurrentModal } = useModalContext(
    "hallManagementModal"
  );
  const { openModal: openAddEditZoneModal } =
    useModalContext("addEditZoneModal");

  const { openModal: openZoneTablesManagementModal } = useModalContext(
    "zoneTablesManagementModal"
  );

  const handleRemoveZone = useCallback(
    (id) => () => {
      removeArea({
        variables: {
          id,
        },
      });
    },
    [hallId]
  );

  const openAddZoneModal = useCallback(() => {
    openAddEditZoneModal({
      hallName: getValues("hall_name"),
      hallId,
    });
    closeCurrentModal();
  }, [hallId]);

  const openEditZoneModal = useCallback(
    (data) => () => {
      openAddEditZoneModal({
        hallName: getValues("hall_name"),
        hallId,
        ...data,
      });
      closeCurrentModal();
    },
    [hallId]
  );

  const openManageTablesModal = useCallback(
    (data) => () => {
      openZoneTablesManagementModal({
        hallName: getValues("hall_name"),
        hallId,
        ...data,
      });
      closeCurrentModal();
    },
    [hallId]
  );

  return (
    <VStack w="full" alignItems="flex-start">
      <Box w="full">
        {zones?.areasData.rows.map((zone, index) => {
          return (
            <Box key={zone.id} w="full">
              <ZoneRow
                zoneName={zone.area}
                sequenceNumber={index + 1}
                handleRemove={handleRemoveZone(zone.id)}
                handleEdit={openEditZoneModal({
                  defaultValues: transformEditZoneData(zone),
                  zoneId: zone.id,
                })}
                handleManageTables={openManageTablesModal({
                  zoneName: zone.area,
                  zoneId: zone.id,
                })}
              />
              <Divider mt={4} mb={4} />
            </Box>
          );
        })}
      </Box>
      <Button minW="140px" onClick={openAddZoneModal} mt={8}>
        <Box mr={2}>
          <AddIcon fill="white.900" />
        </Box>
        Add Zone
      </Button>
    </VStack>
  );
}
export default Zones;
