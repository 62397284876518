import { useFormContext } from "react-hook-form";
import TextField from "components/TextField";
import { getFieldName } from "utils/getFieldName";

function TextFieldRHF(props) {
  const { name, inputProps, ...rest } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <TextField
      inputProps={{ ...inputProps, ...register(name) }}
      hasError={getFieldName(errors, name)}
      {...rest}
    />
  );
}

export default TextFieldRHF;
