import { useContext, useMemo } from "react";
import { useGetAttractionTicketTypes as useFetchAttractionTicketTypes } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetAttractionTicketTypes = ({ skip } = {}) => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, attractionTicketTypes } = useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return !isAuth || !!attractionTicketTypes.length || skip;
  }, [isAuth, attractionTicketTypes.length, skip]);

  useFetchAttractionTicketTypes({
    onCompleted: (response) => {
      updateOptionsState({
        attractionTicketTypes: response?.getAttractionTicketTypes,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetAttractionTicketTypes;
