import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveSeo } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { useToastContext } from "hooks/useToastContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ seo }) {
  const { openModal: openEditModal } = useModalContext("seoCreateEditModal");
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const { accessDeleteSeo, accessUpdateSeo } = getAccessScopesControl();

  const { showToast } = useToastContext();

  const editSeo = () => {
    openEditModal({
      editSeo: seo,
      isEditMode: true,
    });
  };

  const [removeSeo] = useRemoveSeo({
    variables: {
      url: seo.url,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The seo has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: seo.name,
      type: "seo", // todo what is that ??
      onDelete: removeSeo,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={editSeo}
        isDisabled={Boolean(!accessUpdateSeo)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteSeo)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
