import { gql } from "@apollo/client";

export const GET_ALL_VENUES = gql`
  query GetAllVenues($options: GetAllVenues) {
    getAllVenues(options: $options) {
      count
      rows {
        id
        image
        promoImage
        title
        status
        url
        price
        isAddToSlider
        description
        geolocation
        infoTitle
        infoDescription
        policyTitle
        policyDescription
        areaId
        cityId
        createdBy
        categoryId
        createdAt
        updatedAt
        photo
        isLiked
        rank
        isCompleted
        placeDetails {
          address
          hours
          rating
          ratingCountUsers
          reviews {
            authorName
            authorUrl
            photoUrl
            rating
            text
            time
          }
          latitude
          longitude
        }
        venueCategory {
          id
          modelName
          name
        }
        venueMenu {
          id
          tr {
            name
            language {
              id
              name
              code
            }
            description
            shortDescription
          }
          fullMenu
          image
          createdAt
          updatedAt
        }
        tr {
          language {
            id
            code
            name
          }
          title
          description
          infoTitle
          infoDescription
          policyTitle
          policyDescription
        }
        restrictions {
          venueId
          restrictionId
          restriction {
            id
            name
          }
        }
        services {
          venueId
          serviceId
          service {
            id
            name
          }
        }
        days {
          dayId
          startTime
          endTime
        }
      }
    }
  }
`;
