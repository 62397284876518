export const transformAllCompanyList = (data = []) => {
  if (data) {
    return data?.map((company) => {
      let imgSrc = null;

      if (company.image) {
        imgSrc = company.image.includes("https")
          ? company.image
          : `${process.env.REACT_APP_API_URL}/${company.image}`;
      }

      return {
        id: company?.id,
        image: imgSrc
          ? {
              preview: imgSrc,
            }
          : null,
        adress: company?.adress,
        company: company?.companyName,
        cityId: company?.cityId,
        licenceNumber: company?.licenceNumber,
        photos: company?.photos,
        owners: company?.owners,
        users: company?.users,
      };
    });
  }
  return [];
};
