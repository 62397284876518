import { Link, Box } from "@chakra-ui/react";
import { NavLink as ReachLink } from "react-router-dom";
import CHS from "./chakra.styles";

export default function NavItem(prop) {
  const { icon, text, path } = prop;
  return (
    <Box>
      <Link as={ReachLink} to={`${path}`} {...CHS.linkWrapper}>
        <Box {...CHS.iconWrapper}>{icon}</Box>
        {text}
      </Link>
    </Box>
  );
}
