import {
  EditOptionIcon,
  DeleteOptionIcon,
  EyeOpenIcon,
} from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveAttraction } from "graphql/hooks/useMutations";
import { useNavigate } from "react-router-dom";
import { PATHS, SUB_PATHS } from "constants/constants";
import { useToastContext } from "hooks/useToastContext";
import { useModalContext } from "hooks/useModalContext";
import { previewUnpublishedEntity } from "utils/previewUnpublishedEntity";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ row }) {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext("deleteConfirmationModal");
  const { accessUpdateAttractions, accessDeleteAttractions } =
    getAccessScopesControl();

  const navToEditAttraction = () =>
    navigate(`${PATHS.attractions}/${row.original.id}/${SUB_PATHS.general}`);

  const [removeAttraction] = useRemoveAttraction({
    variables: {
      id: row.original.id,
    },

    onCompleted: () => {
      closeModal();
      showToast({
        description: "The attraction has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openModal({
      name: row.original.name,
      type: "attraction",
      onDelete: removeAttraction,
    });
  };

  const openPreview = () => {
    previewUnpublishedEntity("attractions", row?.original?.url);
  };

  const isCompleted = row?.original?.isCompleted;

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EyeOpenIcon sx={CHS.eyeIcon} />}
        onMenuItemClick={openPreview}
        isDisabled={Boolean(!isCompleted)}
      >
        Preview
      </CustomMenuItem>

      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={navToEditAttraction}
        isDisabled={Boolean(!accessUpdateAttractions)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteAttractions)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
