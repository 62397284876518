import { capitalize } from "utils/capitalize";

export const transformOfferStatusesOptions = (data = []) => {
  if (data) {
    return data.map((option) => {
      const labelOption = capitalize(option).split("_").join(" ");
      return {
        value: option,
        label: labelOption,
      };
    });
  }

  return [];
};
