import { useMutation } from "@apollo/client";
import * as MUTATIONS from "graphql/mutations";
import * as QUERIES from "graphql/queries";

export const useAdminSignIn = (options) => {
  return useMutation(MUTATIONS.ADMIN_SIGN_IN, options);
};

export const useRecoverPassword = (options) => {
  return useMutation(MUTATIONS.RECOVER_PASSWORD, options);
};

export const useResetPassword = (options) => {
  return useMutation(MUTATIONS.RESET_PASSWORD, options);
};

export const useAddNewVenueMenu = (options) => {
  return useMutation(MUTATIONS.ADD_NEW_VENUE_MENU, options);
};

export const useAddGallery = (options) => {
  return useMutation(MUTATIONS.ADD_GALLERY, options);
};

export const useCreateLocation = (options) => {
  return useMutation(MUTATIONS.CREATE_LOCATION, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_LOCATIONS],
  });
};

export const useEditLocation = (options) => {
  return useMutation(MUTATIONS.EDIT_LOCATION, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_LOCATIONS],
  });
};

export const useUpdateVenueMenu = (options) => {
  return useMutation(MUTATIONS.UPDATE_VENUE_MENU, options);
};

export const useAddVenueGeneralInfo = (options) => {
  return useMutation(MUTATIONS.ADD_VENUE_GENERAL_INFO, options);
};

export const useAddEventGeneralInfo = (options) => {
  return useMutation(MUTATIONS.ADD_EVENT_GENERAL_INFO, options);
};

export const useAddAttractionGeneralInfo = (options) => {
  return useMutation(MUTATIONS.ADD_ATTRACTION_GENERAL_INFO, options);
};

export const useUpdateAttractionGeneralInfo = (options) => {
  return useMutation(MUTATIONS.UPDATE_ATTRACTION_GENERAL_INFO, options);
};

export const useCreateOffer = (options) => {
  return useMutation(MUTATIONS.CREATE_OFFER, options);
};

export const useCreateSeo = (options) => {
  return useMutation(MUTATIONS.CREATE_SEO, options);
};

export const useEditSeo = (options) => {
  return useMutation(MUTATIONS.EDIT_SEO, options);
};

export const useCreateStaticPage = (options) => {
  return useMutation(MUTATIONS.CREATE_STATIC_PAGE, options);
};

export const useEditStaticPage = (options) => {
  return useMutation(MUTATIONS.EDIT_STATIC_PAGE, options);
};

export const useSetOrderStaticPage = (options) => {
  return useMutation(MUTATIONS.SET_ORDER_STATIC_PAGE, options);
};

export const useRemoveStaticPage = (options) => {
  return useMutation(MUTATIONS.REMOVE_STATIC_PAGE, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_STATIC_PAGES],
  });
};

export const useAddNewUser = (options) => {
  return useMutation(MUTATIONS.ADD_NEW_USER, options);
};

export const useDeleteInfluencer = (options) => {
  return useMutation(MUTATIONS.DELETE_INFLUENCER, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_INFLUENCER],
  });
};

export const useEditInfluencer = (options) => {
  return useMutation(MUTATIONS.EDIT_INFLUENCER, { ...options });
};

export const useDeleteUser = ({ deleteYourSelf, ...options } = {}) => {
  return useMutation(MUTATIONS.DELETE_USER, {
    ...options,
    refetchQueries: deleteYourSelf ? undefined : [QUERIES.GET_USERS],
  });
};

export const useEditOffer = (options) => {
  return useMutation(MUTATIONS.EDIT_OFFER, options);
};

export const useDeleteOffer = (options) => {
  return useMutation(MUTATIONS.DELETE_OFFER, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_OFFERS_ADMIN],
  });
};

export const useAddVenueEvents = (options) => {
  return useMutation(MUTATIONS.ADD_VENUE_EVENTS, options);
};

export const useRemoveVenueEvent = (options) => {
  return useMutation(MUTATIONS.REMOVE_VENUE_EVENT, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_VENUE_EVENTS],
  });
};

export const useEditEvent = (options) => {
  return useMutation(MUTATIONS.EDIT_EVENT, options);
};

export const useEditUser = (options) => {
  return useMutation(MUTATIONS.EDIT_USER, options);
};

export const useRemoveVenue = (options) => {
  return useMutation(MUTATIONS.REMOVE_VENUE, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_VENUES],
  });
};

export const useAddVenueHall = (input) =>
  useMutation(MUTATIONS.ADD_VENUE_HALL, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_HALLS],
  });

export const useUpdateVenueHall = (input) =>
  useMutation(MUTATIONS.UPDATE_VENUE_HALL, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_HALLS],
  });

export const useRemoveVenueHall = (input) =>
  useMutation(MUTATIONS.REMOVE_VENUE_HALL, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_HALLS],
  });

export const useAddVenueArea = (input) =>
  useMutation(MUTATIONS.ADD_VENUE_AREA, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_HALLS,
      {
        query: QUERIES.GET_AREAS_BY_HALL_ID,
        variables: {
          options: {
            venueHallId: input.variables.hallId,
          },
        },
      },
    ],
  });

export const useRemoveVenueArea = (input) =>
  useMutation(MUTATIONS.REMOVE_VENUE_AREA, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_HALLS,
      {
        query: QUERIES.GET_AREAS_BY_HALL_ID,
        variables: {
          options: {
            venueHallId: input.variables.hallId,
          },
        },
      },
    ],
  });

export const useUpdateVenueArea = (input) =>
  useMutation(MUTATIONS.UPDATE_VENUE_AREA, {
    ...input,
    refetchQueries: [
      {
        query: QUERIES.GET_AREAS_BY_HALL_ID,
        variables: {
          options: {
            venueHallId: input.variables.hallId,
          },
        },
      },
    ],
  });

export const useAddVenueAreaTable = (input) =>
  useMutation(MUTATIONS.ADD_VENUE_AREA_TABLE, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_HALLS,
      {
        query: QUERIES.GET_TABLES_BY_AREA_ID,
        variables: {
          options: {
            venueAreaId: input.variables.zoneId,
          },
        },
      },
    ],
  });

export const useRemoveVenueAreaTable = (input) =>
  useMutation(MUTATIONS.REMOVE_VENUE_AREA_TABLE, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_HALLS,
      {
        query: QUERIES.GET_TABLES_BY_AREA_ID,
        variables: {
          options: {
            venueAreaId: input.variables.zoneId,
          },
        },
      },
    ],
  });

export const useUpdateVenueAreaTable = (input) =>
  useMutation(MUTATIONS.UPDATE_VENUE_AREA_TABLE, {
    ...input,
    refetchQueries: [
      {
        query: QUERIES.GET_TABLES_BY_AREA_ID,
        variables: {
          options: {
            venueAreaId: input.variables.zoneId,
          },
        },
      },
    ],
  });

export const useUpdateVenueGeneralInfo = (options) => {
  return useMutation(MUTATIONS.UPDATE_VENUE_GENERAL_INFO, options);
};

export const useRemoveGalleryPhoto = (options) => {
  return useMutation(MUTATIONS.REMOVE_GALLERY_PHOTO, options);
};

export const useUpdateGallery = (options) => {
  return useMutation(MUTATIONS.UPDATE_GALLERY, options);
};

export const useRemoveEvent = (options) => {
  return useMutation(MUTATIONS.REMOVE_EVENT, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_EVENTS],
  });
};

export const useRemoveAttraction = (options) => {
  return useMutation(MUTATIONS.REMOVE_ATTRACTION, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_ATTRACTIONS],
  });
};

export const useRemoveLocation = (options) => {
  return useMutation(MUTATIONS.REMOVE_LOCATION, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_LOCATIONS],
  });
};

export const useCreateArticle = (options) => {
  return useMutation(MUTATIONS.CREATE_ARTICLE, options);
};

export const useEditArticle = (options) => {
  return useMutation(MUTATIONS.EDIT_ARTICLE, options);
};

export const useDeleteArticle = (options) => {
  return useMutation(MUTATIONS.DELETE_ARTICLE, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_ARTICLE_ADMIN],
  });
};

export const useSwitchArticleShowOnMain = (options) => {
  return useMutation(MUTATIONS.SWITCH_ARTICLE_SHOW_ON_MAIN, options);
};

export const useAddArticleTag = (options) => {
  return useMutation(MUTATIONS.ADD_ARTICLE_TAG, options);
};

export const useDeleteArticleTag = (options) => {
  return useMutation(MUTATIONS.DELETE_ARTICLE_TAG, options);
};

export const useSwitchLocationIsActive = (options) => {
  return useMutation(MUTATIONS.SWITCH_LOCATION_IS_ACTIVE, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_LOCATIONS],
  });
};

export const useAddEventSector = (input) =>
  useMutation(MUTATIONS.ADD_EVENT_SECTOR, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID],
  });

export const useUpdateEventSector = (input) =>
  useMutation(MUTATIONS.UPDATE_EVENT_SECTOR, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID],
  });

export const useRemoveEventSector = (input) =>
  useMutation(MUTATIONS.REMOVE_EVENT_SECTOR, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID],
  });

export const useSetEventSectorRowsCount = (input) =>
  useMutation(MUTATIONS.SET_EVENT_SECTOR_ROWS_COUNT, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID,
      QUERIES.GET_EVENT_SECTOR_ROWS_BY_SECTOR_ID,
    ],
  });

export const useUpdateEventSectorRow = (input) =>
  useMutation(MUTATIONS.UPDATE_EVENT_SECTOR_ROW, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID,
      QUERIES.GET_EVENT_SECTOR_ROWS_BY_SECTOR_ID,
    ],
  });

export const useRemoveEventSectorRow = (input) =>
  useMutation(MUTATIONS.REMOVE_EVENT_SECTOR_ROW, {
    ...input,
    refetchQueries: [
      QUERIES.GET_ALL_EVENT_SECTORS_BY_EVENT_ID,
      QUERIES.GET_EVENT_SECTOR_ROWS_BY_SECTOR_ID,
    ],
  });

export const useUpdateVenueCompleteStatus = (options) => {
  return useMutation(MUTATIONS.UPDATE_VENUE_COMPLETE_STATUS, options);
};

export const useCreateCompany = (options) => {
  return useMutation(MUTATIONS.CREATE_COMPANY, options);
};

export const useDeleteCompany = (options) => {
  return useMutation(MUTATIONS.DELETE_COMPANY, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_COMPANIES],
  });
};

export const useEditCompany = (options) => {
  return useMutation(MUTATIONS.EDIT_COMPANY, options);
};

export const useCreateOwners = (options) => {
  return useMutation(MUTATIONS.CREATE_OWNERS, options);
};

export const useAddNewUsersToCompany = (options) => {
  return useMutation(MUTATIONS.ADD_NEW_USERS_TO_COMPANY, options);
};

export const useEditOwners = (options) => {
  return useMutation(MUTATIONS.EDIT_OWNERS, options);
};

export const useEditNewUsersToCompany = (options) => {
  return useMutation(MUTATIONS.EDIT_NEW_USERS_TO_COMPANY, options);
};

export const useDeletePhotosCompany = (options) => {
  return useMutation(MUTATIONS.DELETE_PHOTOS_COMPANY, options);
};

export const useDeletePhotosOwners = (options) => {
  return useMutation(MUTATIONS.DELETE_PHOTOS_OWNERS, options);
};

export const useCreateAttractiontTicket = (input) => {
  return useMutation(MUTATIONS.CREATE_ATTRACTION_TICKET, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ATTRACTION_TICKETS],
  });
};

export const useRemoveAttractiontTicket = (input) => {
  return useMutation(MUTATIONS.REMOVE_ATTRACTION_TICKET, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ATTRACTION_TICKETS],
  });
};

export const useUpdateAttractionTicket = (input) => {
  return useMutation(MUTATIONS.UPDATE_ATTRACTION_TICKET, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ATTRACTION_TICKETS],
  });
};

export const useSetCompletedEventsStatus = (options) => {
  return useMutation(MUTATIONS.SET_COMPLETED_EVENTS, options);
};

export const useRemoveReport = (input) => {
  return useMutation(MUTATIONS.REMOVE_REPORT, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_REPORTS],
  });
};

export const useUpdateReport = (input) => {
  return useMutation(MUTATIONS.UPDATE_REPORT, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_REPORTS],
  });
};

export const useRemoveComment = (options) => {
  return useMutation(MUTATIONS.REMOVE_COMMENT, options);
};

export const useRemovePost = (options) => {
  return useMutation(MUTATIONS.REMOVE_POST, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_POSTS],
  });
};

export const useRemoveSeo = (options) => {
  return useMutation(MUTATIONS.REMOVE_SEO, {
    ...options,
    refetchQueries: [QUERIES.GET_SEO_DATA_URLS],
  });
};

export const useCreateAttractionSchedule = (input) => {
  return useMutation(MUTATIONS.CREATE_ATTRACTION_SCHEDULE, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ATTRACTION_SCHEDULES],
  });
};

export const useUpdateAttractionSchedule = (input) => {
  return useMutation(MUTATIONS.UPDATE_ATTRACTION_SCHEDULE, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ATTRACTION_SCHEDULES],
  });
};

export const useRemoveAd = (input) => {
  return useMutation(MUTATIONS.REMOVE_AD, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ADS],
  });
};

export const useCreateAd = (input) => {
  return useMutation(MUTATIONS.CREATE_AD, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ADS],
  });
};

export const useUpdateAd = (input) => {
  return useMutation(MUTATIONS.UPDATE_AD, {
    ...input,
    refetchQueries: [QUERIES.GET_ALL_ADS],
  });
};

export const useUpdateAttractionCompleteStatus = (input) => {
  return useMutation(MUTATIONS.UPDATE_ATTRACTION_COMPLETE_STATUS, input);
};

export const useCreatePost = (options) => {
  return useMutation(MUTATIONS.CREATE_POST, options);
};

export const useEditPost = (options) => {
  return useMutation(MUTATIONS.EDIT_POST, options);
};

export const useDeleteCommentAdmin = (options) => {
  return useMutation(MUTATIONS.DELETE_COMMENT_ADMIN, {
    ...options,
    refetchQueries: [QUERIES.GET_ALL_COMMENTS],
  });
};

export const useEditCommentAdmin = (options) => {
  return useMutation(MUTATIONS.EDIT_COMMENT_ADMIN, options);
};

export const useCreateNewUser = (input) => {
  return useMutation(MUTATIONS.CREATE_NEW_USER, input);
};

export const useUpdateProfile = (options) => {
  return useMutation(MUTATIONS.UPDATE_PROFILE, {
    ...options,
    refetchQueries: [QUERIES.GET_CURRENT_USER],
  });
};
