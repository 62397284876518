import { gql } from "@apollo/client";

export const UPDATE_VENUE_MENU = gql`
  mutation UpdateVenueMenu($input: UpdateVenueMenu) {
    UpdateVenueMenu: updateVenueMenu(input: $input) {
      id
      tr {
        language {
          id
          code
          name
        }
        name
        shortDescription
        description
      }
      fullMenu
      image
    }
  }
`;
