import { transformLanguagesToCodeList } from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useReducer } from "react";
import { venueReducer } from "reducers";
import VenueContext from "./venue.context";

const initialValue = (codeList) => {
  // translatedValues example: {en-US: "", ar:""}
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});

  return {
    id: null,
    generalInfo: {
      title: translatedValues,
      status: null,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      country: null,
      city: null,
      location: null,
      locationAdmin: null,
      price: null,
      coordinates: { description: "", place_id: "" },
      category: null,
      rank: null,
      description: translatedValues,
      restrictions: null,
      services: null,
      policy_title: translatedValues,
      policy_description: translatedValues,
      url: "",
      main_img: null,
      info_title: translatedValues,
      info_description: translatedValues,
      slider_photo: null,
      meta_title: translatedValues,
      meta_description: translatedValues,
      isAddToSlider: false,
    },
    venueMenu: {
      name: translatedValues,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      short_description: translatedValues,
      file: null,
      image: null,
      description: translatedValues,
    },
    galleryVenue: {
      title: "",
      url: "",
      date: "",
      category: null,
      galleryPhoto: [],
    },
  };
};

// При добавлении нового venue после заполнения каждого шага и отправки если Success -> получаем данные и используем updateVenueState(data)
// с использованием коннектора и перезаписываем контекст
// в последующих шагах можем использовать данные из контекста (можно использовать getGeneralInfo/getMenu/getGallery)
export default function VenueProvider({ children }) {
  const { languages } = useOptionsContext();

  const [venue, dispatch] = useReducer(
    venueReducer,
    initialValue(transformLanguagesToCodeList(languages))
  );

  const updateVenueState = (data) => {
    dispatch({
      type: "UPDATE_VENUE_STATE",
      payload: data,
    });
  };

  const getVenueId = () => venue.id;

  const getGeneralInfo = () => ({
    id: venue.id,
    ...venue.generalInfo,
  });

  const getMenu = () => ({
    ...venue.venueMenu,
  });

  const getGallery = () => ({
    ...venue.galleryVenue,
  });

  return (
    <VenueContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        venue,
        getVenueId,
        updateVenueState,
        getGeneralInfo,
        getMenu,
        getGallery,
      }}
    >
      {children}
    </VenueContext.Provider>
  );
}
