import { Box, Button, Flex, HStack } from "@chakra-ui/react";

import { USERS_COMPANIES_SEARCH_DATA } from "mock/USERS_COMPANIES_SEARCH_DATA"; // todo - add correct data
import TableSearch from "components/Table/TableSearch";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import { AddIcon } from "components/Icons";
import { ROWS_PER_PAGE } from "./table.config";

function VouchersTable({ openModal }) {
  return (
    <>
      <Flex w="full" justify="space-between" align="center" mb={7} mt={5}>
        <TableSearch
          data={USERS_COMPANIES_SEARCH_DATA}
          placeholder="Search voucher"
          mr={4}
        />
        <HStack spacing={4}>
          <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          <Button minW="140px" onClick={openModal}>
            <Box mr={2}>
              <AddIcon fill="white.900" />
            </Box>
            Add voucher
          </Button>
        </HStack>
      </Flex>
      <Box w="full">
        <TableBody hasSorting={true} />
        <TablePagination />
      </Box>
    </>
  );
}

export default VouchersTable;
