import { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import { EyeOpenIcon } from "components/Icons";
import {
  useLazyGetArticleById,
  useLazyGetOfferById,
  useLazyGetPostById,
} from "graphql/hooks/useQueries";
import { PATHS, SUB_PATHS } from "constants/constants";
import { useOptionsContext } from "hooks/useOptionsContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function RedirectFromCommentEdit(props) {
  const { parentInstance } = props;
  const { currentUser } = useOptionsContext();

  const navToEditEvent = (eventId) =>
    window.open(`${PATHS.events}/${eventId}`, "_blank");
  const navToEditAttraction = (attractionId) =>
    window.open(
      `${PATHS.attractions}/${attractionId}/${SUB_PATHS.general}`,
      "_blank"
    );
  const navToEditVenue = (venueId) =>
    window.open(`${PATHS.venues}/${venueId}`, "_blank");

  const [viewButtonIsDisabled, setViewButtonIsDisabled] = useState(true);

  const {
    accessReadArticles,
    accessReadOffers,
    accessReadEvents,
    accessReadAttractions,
    accessReadVenues,
  } = getAccessScopesControl();

  const accessRedirect = [
    accessReadArticles,
    accessReadOffers,
    accessReadEvents,
    accessReadAttractions,
    accessReadVenues,
  ].includes(true);

  useEffect(() => {
    if (parentInstance.parent && accessRedirect) {
      setViewButtonIsDisabled(false);
    } else {
      setViewButtonIsDisabled(true);
    }
  }, [parentInstance.parent]);

  const { openModal: openArticleModal } = useModalContext(
    "articleCreateEditModal"
  );
  const { openModal: openPostModal } = useModalContext("feedCreateEditModal");
  const { openModal: openOfferModal } = useModalContext("offerCreateEditModal");

  let viewHandler = () => {};

  switch (parentInstance?.parent?.__typename) {
    case "Article":
      const [getArticle] = useLazyGetArticleById({
        variables: {
          id: parentInstance?.parent?.articleId,
        },
      });
      viewHandler = () => {
        setViewButtonIsDisabled(true);
        getArticle()
          .then((response) => {
            if (response.data) {
              setViewButtonIsDisabled(false);
              openArticleModal({
                editArticleFromComments: true,
                editArticle: response.data?.getArticleById,
                isEditMode: true,
              });
            }
          })
          .catch((error) => {
            setViewButtonIsDisabled(false);
            console.log(error);
          });
      };
      break;

    case "Post":
      const [getPost] = useLazyGetPostById({
        variables: {
          id: parentInstance?.parent?.postId,
        },
      });
      viewHandler = () => {
        setViewButtonIsDisabled(true);
        getPost()
          .then((response) => {
            if (response.data) {
              setViewButtonIsDisabled(false);
              openPostModal({
                editPostFromComments: true,
                editPost: response.data?.getPostById,
                isEditMode: true,
                editPostReadOnly:
                  response.data.getPostById.postAuthor.id !==
                  currentUser.currentUser.id,
              });
            }
          })
          .catch((error) => {
            setViewButtonIsDisabled(false);
            console.log(error);
          });
      };
      break;

    case "Offer":
      const [getOffer] = useLazyGetOfferById({
        variables: {
          id: parentInstance?.parent?.offerId,
        },
      });

      viewHandler = () => {
        setViewButtonIsDisabled(true);
        getOffer()
          .then((response) => {
            if (response.data) {
              setViewButtonIsDisabled(false);
              openOfferModal({
                editOfferFromComments: true,
                editOffer: response.data?.getOfferById,
                isEditMode: true,
              });
            }
          })
          .catch((error) => {
            setViewButtonIsDisabled(false);
            console.log(error);
          });
      };
      break;

    //  ---***---
    case "Event":
      viewHandler = () => {
        setViewButtonIsDisabled(true);
        navToEditEvent(parentInstance?.parent?.eventId);
      };
      break;

    case "Attraction":
      viewHandler = () => {
        setViewButtonIsDisabled(true);
        navToEditAttraction(parentInstance?.parent?.attractionId);
      };
      break;

    case "Venue":
      viewHandler = () => {
        setViewButtonIsDisabled(true);
        navToEditVenue(parentInstance?.parent?.venueId);
      };
      break;

    default:
      console.log("Other `__typename` or entry removed");
  }

  return (
    <Button
      onClick={viewHandler}
      variant="outline"
      colorScheme="blue.500"
      marginTop={6}
      isDisabled={viewButtonIsDisabled}
    >
      <EyeOpenIcon
        fill="white.900"
        stroke="blue.500"
        width="22px"
        height="20px"
        mr="9px"
      />
      View
    </Button>
  );
}
