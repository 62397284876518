import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModalContext } from "hooks/useModalContext";
import CreateEditTicketsModal from "components/Modals/CreateEditTickets";
import {
  transformCurrenciesOptions,
  transformAttractionTicketTypesOptions,
  transformCreateEditAttractionTicket,
} from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import {
  useCreateAttractiontTicket,
  useUpdateAttractionTicket,
} from "graphql/hooks/useMutations";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";

import {
  CREATE_ATTARCTION_TICKET_SCHEMA,
  UPDATE_ATTARCTION_TICKET_SCHEMA,
} from "schemas";
import useGetCurrencies from "hooks/useGetCurrencies.hook";
import useGetAttractionTicketTypes from "hooks/useGetAttractionTicketTypes.hook";

function CreateEditTicketsModalContainer() {
  const { modal, closeModal } = useModalContext("createEditTicketsModal");

  useGetCurrencies({ skip: !modal.isOpen });
  useGetAttractionTicketTypes({ skip: !modal.isOpen });

  const { currencies, attractionTicketTypes } = useOptionsContext();

  const INITIAL_VALUES = {
    name: "",
    bestPrice: false,
    type: null,
    currency: null,
    price: null,
    oldPrice: null,
  };

  const EDIT_VALUES = {
    name: modal?.editTicket?.name,
    bestPrice: modal?.editTicket?.bestPrice,
    type: modal?.editTicket?.type,
    currency: modal?.editTicket?.currency,
    price: modal?.editTicket?.price,
    oldPrice: modal?.editTicket?.oldPrice,
  };

  const methods = useForm({
    resolver: yupResolver(
      !modal.isEditMode
        ? CREATE_ATTARCTION_TICKET_SCHEMA
        : UPDATE_ATTARCTION_TICKET_SCHEMA
    ),
    defaultValues: INITIAL_VALUES,
  });

  const { reset } = methods;

  const [createAttractiontTicket, { loading: createLoading }] =
    useCreateAttractiontTicket();
  const [updateAttractionTicket, { loading: updateLoading }] =
    useUpdateAttractionTicket();

  const closeHandler = () => {
    reset(INITIAL_VALUES);
    closeModal();
  };

  const onSubmit = (formData) => {
    if (!modal.isEditMode) {
      createAttractiontTicket({
        variables: {
          input: transformCreateEditAttractionTicket({
            id: modal.attractionId,
            formData,
          }),
        },
        onCompleted: () => {
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      updateAttractionTicket({
        variables: {
          input: transformCreateEditAttractionTicket({
            id: modal.editTicket.id,
            formData,
            isEdit: true,
          }),
        },
        onCompleted: () => {
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(EDIT_VALUES);
    }
  }, [modal.isEditMode]);

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modal.isEditMode ? "Edit tickets" : "Add tickets"}
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <CreateEditTicketsModal
            cancelHandler={closeHandler}
            onSubmit={methods.handleSubmit(onSubmit)}
            currencyOptions={transformCurrenciesOptions(currencies)}
            ticketTypesOptions={transformAttractionTicketTypesOptions(
              attractionTicketTypes
            )}
            loading={createLoading || updateLoading}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}

export default CreateEditTicketsModalContainer;
