import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";

export const transformSeoList = (data = []) => {
  if (data) {
    return data?.map((seo, idx) => ({
      id: seo.url + idx,
      metaTitle: seo.datas?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.metaTitle,
      metaDescription: seo.datas?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.metaDescription,
      metaKeywords: seo.datas?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.metaKeywords,
      url: seo.url,
      seo: { url: seo.url, tr: seo.datas },
    }));
  }
  return [];
};
