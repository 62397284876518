const linkWrapper = {
  position: "relative",
  py: "13.5px",
  pl: "32px",
  display: "flex",
  opacity: "0.3",
  alignItems: "center",
  fontSize: "sm",
  fontWeight: "medium",
  color: "white.900",
  border: "none",
  _before: {
    content: "' '",
    position: "absolute",
    display: "none",
    top: "0",
    left: "0",
    width: "4px",
    height: "100%",
    bgColor: "blue.500",
    borderRadius: "0px 3px 3px 0px",
  },
  _hover: {
    bg: "linear-gradient(90deg, #1F2B3A 0%, #131923 98.25%)",
    opacity: "1",
    cursor: "pointer",
    textDecoration: "none",
    _before: { display: "block" },
  },
  _focus: {
    outline: "none",
  },
  _activeLink: {
    opacity: "1",
    bg: "linear-gradient(90deg, #1F2B3A 0%, #131923 98.25%)",
    _before: {
      display: "block",
    },
  },
};

const iconWrapper = {
  w: "24px",
  h: "24px",
  mr: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default { linkWrapper, iconWrapper };
