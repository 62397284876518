import { gql } from "@apollo/client";

export const ADD_NEW_VENUE_MENU = gql`
  mutation AddNewVenueMenu($input: AddNewVenueMenuInput) {
    addNewVenueMenu(input: $input) {
      id
      tr {
        language {
          id
          code
          name
        }
        name
        shortDescription
        description
      }
      fullMenu
      image
    }
  }
`;
