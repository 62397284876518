import { gql } from "@apollo/client";

export const GET_ALL_INFLUENCER = gql`
  query GetAllInfluencer($options: OptionInfluencer) {
    getAllInfluencer(options: $options) {
      count
      rows {
        id
        socialAccount1
        socialAccount2
        socialAccount3
        user {
          id
          firstName
          lastName
          email
          phone
          gender
          status
          photo
          languageId
          currencyId
          cityId
          roleId
          role {
            id
            name
            visibility
            scopes {
              id
              scope
            }
          }
          address
          description
          acceptOffers {
            count
            rows {
              id
              date
              product {
                title
              }
            }
          }
        }
        level
        status
      }
    }
  }
`;
