import { useTheme } from "@chakra-ui/react";

export const useCreatableMultiSelectInputStyle = (hasError, styles) => {
  const theme = useTheme();
  return {
    container: (provided) => {
      return {
        ...provided,
        width: "100%",
        ":before": {
          position: "absolute",
          zIndex: "5",
          left: "20px",
          top: "10px",
          color: theme.colors.dark[300],
        },
        ...styles?.container,
      };
    },
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.white[900],
      borderRadius: "22px",
      borderColor: hasError ? theme.colors.red[500] : theme.colors.white[500],
      ":hover": {
        borderColor: hasError ? theme.colors.red[500] : theme.colors.white[500],
        boxShadow: "none",
      },
      ":focus": {
        borderColor: theme.colors.blue[500],
      },
      ...styles?.control,
    }),
    indicatorSeparator: () => ({
      display: "none",
      ...styles?.indicatorSeparator,
    }),
    placeholder: (provided) => ({
      ...provided,
      margin: "0",
      display: "none",
      ...styles?.placeholder,
    }),
    valueContainer: (provided) => ({
      ...provided,
      top: "5px",
      ...styles?.valueContainer,
    }),
    multiValue: (provided) => ({
      ...provided,
      position: "relative",
      minHeight: "30px",
      margin: "0 8px 8px 0",
      paddingLeft: "3px",
      border: "1px solid",
      borderColor: theme.colors.grey[500],
      borderRadius: "15px",
      backgroundColor: theme.colors.white[500],
      ...styles?.multiValue,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      marginRight: "20px",
      ...styles?.multiValueLabel,
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      position: "absolute",
      top: "6px",
      right: "5px",
      padding: 0,
      ":hover": {
        backgroundColor: "transparent",
      },
      ...styles?.multiValueRemove,
    }),
    input: (provided) => ({
      ...provided,
      margin: "4px",
      height: "30px",
      width: "114px",
      display: "block",
      flex: "initial",
      paddingRight: "10px",
      paddingLeft: "4px",
      paddingBottom: "10px",
      paddingTop: "0px",
      ":after": {
        display: "none",
      },
      ...styles?.input,
    }),
  };
};
