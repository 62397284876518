import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";

export const transformOffersList = (data = []) => {
  if (data) {
    return data?.map((offer) => ({
      id: offer.id,
      name: offer?.tr?.find(
        (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.title,
      description: cleanTextFromHTML(
        offer?.tr?.find(
          (obj) => obj.language.id === DEFAULT_ENGLISH_LANGUAGE_ID
        )?.description
      ),
      userType: offer.isForInfluencerOnly ? "Influencer" : "Regular",
      type: offer.type,
      status: offer.status,
      countUsedOffer: offer.countUsedOffer,
      total: offer.forInfluencerCount ? offer.forInfluencerCount : "∞",
      // для пробрасывания данных в модалку для редактирования оффера
      url: offer.url,
      isAddToSlider: offer.isAddToSlider,
      image: offer.image,
      geolocation: offer.geolocation,
      address: offer.placeDetails?.address,
      price: offer.price,
      oldPrice: offer.oldPrice,
      currencyId: offer.currencyId,
      categoryId: offer.categoryId,
      offerSEO: offer.offerSEO,
      tr: offer.tr,
      isForInfluencerOnly: offer.isForInfluencerOnly,
      influencerLevel: offer.influencerLevel,
      forInfluencerCount: offer.forInfluencerCount,
      photo: offer.photo,
    }));
  }
  return [];
};
