import { gql } from "@apollo/client";

export const GET_HALL_BY_ID = gql`
  query getHallById($id: Int!) {
    hallData: getHallById(id: $id) {
      id
      hall
      image
      description
      venueHallArea {
        id
        area
        venueHallId
        ticketType
        venueAreaTable {
          id
          table
          venueAreaId
          seats
        }
      }
      venueId
      areas
      tables
      seats
    }
  }
`;
