import { Container, Box } from "@chakra-ui/react";

function AddEditLayout({ children }) {
  return (
    <Container
      layerStyle="fill"
      maxW="full"
      p="24px 40px 58px"
      position="relative"
      centerContent
    >
      <Box w="full">{children}</Box>
    </Container>
  );
}

export default AddEditLayout;
