import { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";
import { Box } from "@chakra-ui/react";
import CHS from "./chakra.styles";

function Image({ src }) {
  return <img src={src} alt="#" />;
}

function Video({ src }) {
  return (
    <Box sx={CHS.videoStyles}>
      <iframe src={src} title=" " allowFullScreen />
    </Box>
  );
}

const Media = (props) => {
  let media = null;
  if (!props.block.getEntityAt(0)) {
    return media;
  }
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  const type = entity.getType();

  if (type === "IMAGE") {
    media = <Image src={src} />;
  }

  if (type === "EMBEDDED_LINK") {
    media = <Video src={src} />;
  }

  return media;
};

function mediaBlockRenderer(block) {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }

  return null;
}

const getInitialContentState = (htmlString) => {
  const cBlock = htmlToDraftjs(htmlString);
  if (cBlock) return ContentState.createFromBlockArray(cBlock.contentBlocks);
  return EditorState.createEmpty();
};
const getInitialEditorState = (htmlString = "") => {
  const contentState = getInitialContentState(htmlString);
  return EditorState.createWithContent(contentState);
};

function DraftEditor({ value, onChange, ...rest }) {
  const [editorState, setEditorState] = useState(getInitialEditorState(value));

  const onEditorStateChange = (eState) => {
    setEditorState(eState);
    onChange(draftToHtml(convertToRaw(eState.getCurrentContent())));
  };

  useEffect(() => {
    if (value !== draftToHtml(convertToRaw(editorState.getCurrentContent())))
      setEditorState(getInitialEditorState(value));
  }, [value]);

  return (
    <Editor
      blockRendererFn={mediaBlockRenderer}
      editorState={editorState}
      toolbarClassName="hypt-draft-toolbar"
      wrapperClassName="hypt-draft-wrapper"
      editorClassName="hypt-draft-editor"
      onEditorStateChange={onEditorStateChange}
      {...rest}
    />
  );
}
export default DraftEditor;
