import { DEFAULT_REPORT_STATUS } from "constants/constants";

export const transformReportStatuses = ({ data, isModal }) => {
  if (!data) {
    return [];
  }

  const transformedData = data.map((status) => ({
    value: status.toUpperCase(),
    label: status,
  }));

  if (isModal) return transformedData;

  return [DEFAULT_REPORT_STATUS, ...transformedData];
};
