export default {
  variants: {
    statusGreen: {
      color: "green.500",
      bgColor: "opacityGreen.500",
    },
    statusYellow: {
      color: "yellow.500",
      bgColor: "opacityYellow.500",
    },
    statusRed: {
      color: "red.500",
      bgColor: "opacityRed.500",
    },
    statusGrey: {
      color: "grey.500",
      bgColor: "opacityGrey.500",
    },
    otherStatus: {
      color: "black.900",
    },
  },
  sizes: {
    status: {
      h: 6,
      maxW: "max",
      px: 3,
      pb: 0.5,
      borderRadius: "lg",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "normal",
      fontSize: "sm",
      lineHeight: 5,
      textTransform: "lower",
    },
  },
  defaultProps: {
    variant: "statusGreen",
    size: "status",
  },
};
