export default {
  variants: {
    base: {
      track: {
        p: "1px",
        w: 10.5,
        h: 7,
        borderWidth: "1px",
        bg: "transparent",
        _focus: {
          boxShadow: "none",
        },
        _disabled: {
          borderColor: "white.500",
          cursor: "not-allowed",
        },
        _checked: {
          bg: "transparent",
        },
      },
      thumb: {
        w: 7,
        h: 7,
        bg: "grey.600",
        _checked: {
          bg: "green.500",
        },
      },
    },
  },

  defaultProps: {
    variant: "base",
  },
};
