export const transformBannersListSortState = (state) => {
  if (!state) {
    return undefined;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };

  if (state.id === "rotation") requestObj.field = "startAt";
  if (state.id === "planned") requestObj.field = "showCount";
  if (state.id === "current") requestObj.field = "currentShowCount";

  return requestObj;
};
