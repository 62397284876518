import { useContext, useEffect } from "react";
import stepsContext from "contexts/steps/steps.context";
import GalleryEvents from "pages/Events/EventsManagement/Gallery";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  transformAddEventGallery,
  transformCategoriesOptions,
  transformReceivedAddEventGallery,
  transformReceivedUpdateEventGallery,
  transformUpdateEventGallery,
} from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { FormProvider, useForm } from "react-hook-form";
import { GALLERY_ADD_SCHEMA, GALLERY_EDIT_SCHEMA } from "schemas";
import { allCategories } from "constants/constants";
import { useEventContext } from "hooks/useEventContext.hook";
import { useAddGallery, useUpdateGallery } from "graphql/hooks/useMutations";

function GalleryContainerEvents() {
  const { event, updateEventState, getGallery, getEventId } = useEventContext();
  const { categories } = useOptionsContext();

  const methods = useForm({
    defaultValues: getGallery(),
    resolver: yupResolver(
      !getGallery()?.id ? GALLERY_ADD_SCHEMA : GALLERY_EDIT_SCHEMA
    ),
  });

  const { nextStep } = useContext(stepsContext);

  const [createGallery] = useAddGallery();

  const [updateGallery] = useUpdateGallery();

  const onSubmit = (formData) => {
    if (!getGallery()?.id) {
      const requestObj = {
        modelId: getEventId(),
        modelName: "EVENT",
        ...transformAddEventGallery(formData),
      };

      createGallery({
        variables: {
          input: requestObj,
        },
        onCompleted: (response) => {
          updateEventState(
            transformReceivedAddEventGallery({
              responseData: response?.createGallery,
              allCategories: allCategories?.galleryCategory,
              categories,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Gallery under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Gallery under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      const requestObj = {
        id: getGallery()?.id,
        ...transformUpdateEventGallery(formData, getGallery),
      };
      updateGallery({
        variables: {
          input: requestObj,
        },
        onCompleted: (response) => {
          updateEventState(
            transformReceivedUpdateEventGallery({
              responseData: response?.updateGallery,
              allCategories: allCategories?.galleryCategory,
              categories,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Gallery under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Gallery under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (event.id) {
      methods.reset(getGallery());
    }
  }, [event.id]);

  return (
    <FormProvider {...methods}>
      <GalleryEvents
        onSubmit={methods.handleSubmit(onSubmit)}
        categoryOptions={transformCategoriesOptions(
          allCategories.galleryCategory,
          categories
        )}
      />
    </FormProvider>
  );
}

export default GalleryContainerEvents;
