import dayjs from "dayjs";
import { getFullName } from "utils/getFullName";

export const transformAdsData = (data = []) => {
  if (!data) return [];

  return data.map((ad) => ({
    id: ad.id,
    path: ad.path,
    url: ad.url,
    alt: ad.alt,
    startAt: ad.startAt,
    stopAt: ad.stopAt,
    rotation: `${dayjs(ad.startAt).format("DD/MM")} - ${dayjs(ad.stopAt).format(
      "DD/MM"
    )}`,
    planned: ad.showCount,
    current: ad.currentShowCount,
    status: ad.status,
    client: getFullName(ad.adAuthor),
    avatar: ad.adAuthor.photo,
    pages: ad.adPositions.map((position) => position.page),
    blockIDs: ad.adPositions.map((position) => position.level),
    adPositions: ad.adPositions.map((position) => ({
      value: `${position.page}_${position.level}`,
      label: `${position.page}_${position.level}`,
    })),
  }));
};
