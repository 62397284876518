import * as yup from "yup";

export const CREATE_ATTARCTION_TICKET_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  type: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required()
    .nullable(),
  price: yup.number().min(0).required(),
  oldPrice: yup.number().min(0).nullable(),
  currency: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required()
    .nullable(),
  bestPrice: yup.boolean(),
});

export const UPDATE_ATTARCTION_TICKET_SCHEMA = yup.object().shape({
  name: yup.string(),
  type: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .nullable(),
  price: yup.number().min(0),
  oldPrice: yup.number().min(0).nullable(),
  currency: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(),
  bestPrice: yup.boolean(),
});

export default {
  CREATE_ATTARCTION_TICKET_SCHEMA,
  UPDATE_ATTARCTION_TICKET_SCHEMA,
};
