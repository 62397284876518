import { useContext, useMemo } from "react";
import { useGetAllInfluencerOptions } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetInfluencerOptions = ({ skip } = {}) => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, influencerLevels, influencerStatuses } =
    useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return (
      !isAuth ||
      !!influencerLevels.length ||
      !!influencerStatuses.length ||
      skip
    );
  }, [isAuth, influencerLevels.length, influencerStatuses.length, skip]);

  useGetAllInfluencerOptions({
    onCompleted: (response) => {
      updateOptionsState({
        influencerLevels: response?.getAllInfluencerOptions?.influencerLevel,
        influencerStatuses: response?.getAllInfluencerOptions?.influencerStatus,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetInfluencerOptions;
