import { gql } from "@apollo/client";

export const ADD_EVENT_GENERAL_INFO = gql`
  mutation createEvent($info: CreateEventInput!) {
    createEvent(info: $info) {
      id
      status
      isAddToSlider
      isFeatured
      isUpcoming
      isTop
      price
      currencyId
      geolocation
      startAt
      url
      photo
      location {
        id
        title
      }
      categoryId
      image
      placeDetails {
        address
      }
      eventSEO {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      eventVenue {
        id
        title
      }
      restrictions {
        id
        name
        image
      }
      services {
        id
        name
        image
      }
      tr {
        id
        title
        description
        languageId
        language {
          id
          code
          name
        }
      }
    }
  }
`;
