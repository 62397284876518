import { createContext } from "react";

const defaultValue = {
  venue: {},
  getVenueId: () => {},
  updateVenueState: () => {},
  getGeneralInfo: () => {},
  getMenu: () => {},
  getGallery: () => {},
};

export default createContext(defaultValue);
