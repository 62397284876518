import { Box, VStack, Text } from "@chakra-ui/react";
import CHS from "./chakra.styles";

function Results({ placePredictions, areResultsOpen, handleResultClick }) {
  if (areResultsOpen) {
    if (placePredictions?.length > 0) {
      return (
        <VStack sx={CHS.resultsWrapper}>
          {placePredictions.map((item) => {
            return (
              <Box
                key={item.place_id}
                sx={CHS.resultItem}
                onClick={handleResultClick(item)}
              >
                {item.description}
              </Box>
            );
          })}
        </VStack>
      );
    }
    return (
      <VStack sx={CHS.resultsWrapper}>
        <Text>No result found</Text>
      </VStack>
    );
  }

  return null;
}

export default Results;
