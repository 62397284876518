import * as yup from "yup";

import { multiLangValidate } from "utils/multiLangValidate";

const schema = (languages) => {
  return yup.object().shape({
    title: yup.object().shape(multiLangValidate(languages, ["main_text"])),

    status: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
    }),

    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),

    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),

    url: yup.string().required("Url is required!"),

    main_text: yup.object().shape(multiLangValidate(languages, ["title"])),
  });
};

export default { schema };
