import { Box } from "@chakra-ui/react";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";

function UsersCompaniesTable() {
  return (
    <Box mt={6}>
      <TableBody hasSorting={true} />
      <TablePagination />
    </Box>
  );
}

export default UsersCompaniesTable;
