import { Text, Box } from "@chakra-ui/react";
import CHS from "./chakra.styles";

export default function Tag({ tags, isOpen = false }) {
  return (
    <Box sx={isOpen ? CHS.tagWrapperOpen : CHS.tagWrapper}>
      <Text sx={isOpen ? CHS.tagItemOpen : CHS.tagItem}>
        {tags.length} {tags.length > 1 ? "tags" : "tag"}
      </Text>
    </Box>
  );
}
