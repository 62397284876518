import { Text, Image, Flex } from "@chakra-ui/react";
import { UserIcon } from "components/Icons";
import EmailLink from "components/TableComponents/EmailLink";
import Options from "./Options";
import CHS from "./chakra.styles";

export const COLUMNS = [
  {
    Header: "User",
    accessor: "fullName",
    Cell: ({ row }) => {
      return (
        <Flex alignItems="center">
          <Image
            sx={CHS.userImage}
            src={row.original.photo?.preview}
            fallback={<UserIcon sx={CHS.userIcon} />}
          />
          <Text>{row.original.fullName}</Text>
        </Flex>
      );
    },
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return <EmailLink email={value} />;
    },
  },
  {
    Header: "Phone number",
    accessor: "phone",
    disableSortBy: true,
    Cell: ({ value }) => {
      return `+${new Intl.NumberFormat().format(value)}` ?? "—";
    },
  },
  {
    Header: "Options",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options user={row.original} />;
    },
  },
];
