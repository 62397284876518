import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Flex, Spinner } from "@chakra-ui/react";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Venues/VenuesMainPage/VenuesTable/table.config";
import VenuesTable from "pages/Venues/VenuesMainPage/VenuesTable";
import VenuesFilters from "pages/Venues/VenuesMainPage/VenuesFilters";
import {
  transformVenueOptions,
  transformVenues,
  transformVenuesListSortState,
} from "connectors";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import {
  useGetAllVenueOptions,
  useGetAllVenues,
} from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function VenuesListContainer(props) {
  const { accessViewVenues } = props;
  const [sortState, setSortState] = useState();

  const methods = useForm();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const [category, status, rank, name] = methods.watch([
    "category",
    "status",
    "rank",
    "name",
  ]);

  const { data: venueOptions } = useGetAllVenueOptions();
  const { data: venuesData, loading: venuesLoading } = useGetAllVenues({
    variables: {
      options: {
        search: useDebounce(name) || null,
        categoryId: Number(category?.value) || null,
        rank: rank?.value || null,
        status: status?.value || null,
        limit: queryPageSize,
        offset: offsetData,
        sort: transformVenuesListSortState(sortState),
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS(),
        data: transformVenues(venuesData?.getAllVenues?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={venuesData?.getAllVenues?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <FormProvider {...methods}>
        <VenuesFilters
          categoryOptions={transformVenueOptions(
            venueOptions?.getAllOptionsVenues?.categories
          )}
          rankOptions={transformVenueOptions(
            venueOptions?.getAllOptionsVenues?.venueRanks
          )}
          statusOptions={transformVenueOptions(
            venueOptions?.getAllOptionsVenues?.venueStatus
          )}
          methods={methods}
        />
      </FormProvider>
      {venuesLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : venuesData?.getAllVenues?.rows.length && accessViewVenues ? (
        <VenuesTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default VenuesListContainer;
