import { useEffect, useState } from "react";

export const useDebounce = (value, delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const delayCallback = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(delayCallback);
  }, [delay, value]);
  return debouncedValue;
};

export function debounce(func, timeout = 400) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
