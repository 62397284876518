const backLink = {
  position: "absolute",
  top: 35,
  justifyContent: "left",
  _active: {
    backgroundColor: "white.900",
  },
  _hover: {
    backgroundColor: "white.900",
  },
  _focus: {
    boxShadow: "none",
  },
};

export default { backLink };
