import { StepsStyleConfig as S } from "chakra-ui-steps";

export default {
  ...S,
  baseStyle: (props) => {
    return {
      ...S.baseStyle(props),
      stepIconContainer: {
        ...S.baseStyle(props).stepIconContainer,
        "&[data-clickable]:hover": {
          borderColor: "blue.500",
        },
        borderColor: "white.500",
        bg: "white.900",
        _activeStep: {
          borderColor: "blue.500",
          bg: "blue.500",
        },
      },
      iconLabel: {
        fontWeight: "bold",
        fontSize: "0.875rem", // 14px
      },
      label: {
        ...S.baseStyle(props).label,
        color: "grey.600",
      },
      connector: {
        ...S.baseStyle(props).connector,
        borderWidth: 1,
        margin: "-2px -13px 0",
        zIndex: -1,
      },
    };
  },
};
