import Status from "components/TableComponents/Status";
import { Text, Image, Flex } from "@chakra-ui/react";
import { UserIcon } from "components/Icons";
import Options from "./Options";
import Offers from "./Offers";
import CHS from "./chakra.styles";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "fullName",
    Cell: ({ row }) => {
      return (
        <Flex alignItems="center">
          <Image
            sx={CHS.userImage}
            src={row.original.photo?.preview}
            fallback={<UserIcon sx={CHS.userIcon} />}
          />
          <Text>{row.original.fullName ?? "—"}</Text>
        </Flex>
      );
    },
  },
  {
    Header: "Level",
    accessor: "level",
  },
  {
    Header: "Offers",
    accessor: "offers",
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Offers influencer={row?.original} />;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
    Cell: ({ value }) => {
      return <Status value={value.toLowerCase()} />;
    },
  },
  {
    Header: "Options",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options row={row} />;
    },
  },
];

// how many rows to show
export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
