import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { COLUMNS } from "pages/Feeds/table.config";
import { ROWS_PER_PAGE_DEFAULT } from "constants/constants";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import TableProvider from "contexts/table/table.provider";
import { useDebounce } from "hooks/useDebounce";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllPosts } from "graphql/hooks/useQueries";
import { transformGetAllPosts } from "connectors/Feed/transformGetAllPosts";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { useState } from "react";
import { transformFeedsListSortState } from "connectors";

function FeedsListContainer(props) {
  const { accessViewFeeds } = props;
  const [sortState, setSortState] = useState();

  const methods = useForm();

  const searchPosts = methods.watch("search_posts");

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const { data, loading } = useGetAllPosts({
    variables: {
      limit: queryPageSize,
      search: useDebounce(searchPosts) || null,
      offset: offsetData,
      sort: transformFeedsListSortState(sortState),
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformGetAllPosts(data?.getAllPosts?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={data?.getAllPosts?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <Flex justify="space-between" align="center" mb={7} w="full">
        <FormProvider {...methods}>
          <TextFieldRHF
            name="search_posts"
            inputType="text"
            inputPlaceholder="Search posts"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
            mr={4}
            maxWidth="500px"
          />
        </FormProvider>
        <Flex justify="space-between" align="center">
          <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE_DEFAULT} />
        </Flex>
      </Flex>
      <Box width="full">
        {loading ? (
          <Flex alignItems="center" justify="center" h="100px">
            <Spinner size="xl" />
          </Flex>
        ) : data?.getAllPosts?.rows?.length && accessViewFeeds ? (
          <>
            <TableBody hasSorting={true} />
            <TablePagination />
          </>
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </Box>
    </TableProvider>
  );
}
export default FeedsListContainer;
