import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LOGIN_SCHEMA } from "schemas";
import { useAdminSignIn } from "graphql/hooks/useMutations";
import {
  DEFAULT_TOKEN_LIFETIME,
  REMEMBER_TOKEN_LIFETIME,
} from "constants/local-storage";
import { Login } from "pages";
import { useContext } from "react";
import AuthContext from "contexts/auth/auth.context";
import { transformLogin } from "connectors";

function LoginContainer() {
  const methods = useForm({
    resolver: yupResolver(LOGIN_SCHEMA.schema),
  });

  const { login } = useContext(AuthContext);

  const [adminSignIn] = useAdminSignIn();

  const onSubmit = (formData) => {
    const tokenExpiryTime = formData.rememberMe
      ? REMEMBER_TOKEN_LIFETIME
      : DEFAULT_TOKEN_LIFETIME;

    const requestData = transformLogin(formData);

    adminSignIn({ variables: requestData })
      .then((response) => {
        if (response?.data?.adminSignIn?.token) {
          login(response.data.adminSignIn.token, tokenExpiryTime);
        }
      })
      .catch(() => {
        methods.setError("password");
        methods.setError("email");
      });
  };
  return (
    <FormProvider {...methods}>
      <Login onSubmit={onSubmit} />
    </FormProvider>
  );
}

export default LoginContainer;
