export const transformLogin = (data) => {
  if (data) {
    return {
      email: data.email,
      password: data.password,
      rememberMe: data.rememberMe,
    };
  }
  return {};
};
