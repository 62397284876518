import { gql } from "@apollo/client";

export const UPDATE_ATTRACTION_SCHEDULE = gql`
  mutation updateAttractionSchedule($input: [UpdateAttractionSchedule]) {
    updateAttractionSchedule(input: $input) {
      id
      attractionId
      dayOfWeek
      status
      upTime
      startTime
      stopTime
    }
  }
`;
