import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { COLUMNS, ROWS_PER_PAGE } from "pages/Seo/table.config";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import TableProvider from "contexts/table/table.provider";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetSeoDataUrls } from "graphql/hooks/useQueries";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { transformSeoList, transformSeoListSortState } from "connectors";

function SeoListContainer(props) {
  const { accessViewSeo } = props;

  const [sortState, setSortState] = useState();

  const methods = useForm();

  const searchSeo = methods.watch("searchSeo");

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const { data, loading } = useGetSeoDataUrls({
    variables: {
      limit: queryPageSize,
      offset: offsetData,
      search: useDebounce(searchSeo) || null,
      sort: transformSeoListSortState(sortState),
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformSeoList(data?.getSeoDataGroupedByUrl?.rows), // todo перевірити цю функцію для відображення і що вона повертає
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={data?.getSeoDataGroupedByUrl?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <Flex justify="space-between" align="center" mb={7} w="full">
        <FormProvider {...methods}>
          <TextFieldRHF
            name="searchSeo"
            inputType="text"
            inputPlaceholder="Search seo data"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
            mr={4}
            maxWidth="500px"
          />
        </FormProvider>
        <Flex justify="space-between" align="center">
          <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
        </Flex>
      </Flex>

      <Box width="full">
        {loading ? (
          <Flex alignItems="center" justify="center" h="100px">
            <Spinner size="xl" />
          </Flex>
        ) : data?.getSeoDataGroupedByUrl?.rows?.length && accessViewSeo ? (
          <>
            <TableBody hasSorting={true} />
            <TablePagination />
          </>
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </Box>
    </TableProvider>
  );
}
export default SeoListContainer;
