import { gql } from "@apollo/client";

export const ADD_VENUE_GENERAL_INFO = gql`
  mutation createVenueGeneralInfo($input: CreateVenueGeneralInfo, $width: Int) {
    createVenueGeneralInfo(input: $input) {
      id
      status
      cityId
      geolocation
      categoryId
      price
      photo
      location {
        id
        title
      }
      locationAdmin {
        id
        title
      }
      placeDetails {
        address
      }
      venueCategory {
        id
        name
      }
      rank
      restrictions {
        venueId
        restrictionId
        restriction {
          id
          name
        }
      }
      services {
        venueId
        serviceId
        service {
          id
          name
        }
      }
      url
      image(width: $width)
      venueSeoAdmin {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      tr {
        language {
          id
          name
          code
        }
        title
        description
        infoTitle
        infoDescription
        policyTitle
        policyDescription
      }
    }
  }
`;
