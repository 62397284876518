import { gql } from "@apollo/client";

export const GET_ALL_REPORTS = gql`
  query getAllReports($params: GetAllReports!) {
    getAllReports(params: $params) {
      count
      rows {
        id
        text
        modelName
        modelId
        status
        category
        createdBy
        reportedBy {
          ... on Company {
            __typename
            id
            companyName
            image
          }
          ... on User {
            __typename
            id
            firstName
            lastName
            photo
            company {
              companyName
            }
          }
        }
        createdAt
        updatedAt
        reportedEntity {
          __typename
          ... on User {
            firstName
            lastName
            company {
              companyName
            }
          }
          ... on Post {
            title
            postAuthor {
              ... on Company {
                __typename
                id
                companyName
                image
              }
              ... on User {
                __typename
                id
                firstName
                lastName
                photo
                company {
                  companyName
                }
              }
            }
          }
          ... on Comment {
            text
            commentAuthor {
              ... on Company {
                __typename
                id
                companyName
                image
              }
              ... on User {
                __typename
                id
                firstName
                lastName
                photo
                company {
                  companyName
                }
              }
            }
          }
          ... on Company {
            companyName
          }
        }
      }
    }
  }
`;
