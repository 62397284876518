import { BrowserRouter } from "react-router-dom";
import Navigation from "navigation/Navigation";
import ModalProvider from "contexts/modal/modal.provider";
import AuthProvider from "contexts/auth/auth.provider";
import AddNewUserModal from "containers/addNewUser.container";
import AddNewCompanyModal from "containers/addNewCompany.container";
import InfluencerReviewModal from "containers/influencerReview.container";
import AddNewEntertainmentContainer from "containers/venues/addNewEntertainment.container";
import AddNewTicketsContainer from "containers/venues/addNewTickets.container";
import AddNewVouchersContainer from "containers/venues/addNewVouchers.container";
import AcceptedOffers from "components/Modals/AcceptedOffers";
import FloorMapManagementContainer from "containers/venues/floorMapManagement.container";
import OptionsProvider from "contexts/options/options.provider";
import EventFloorMapManagementContainer from "containers/events/floorMapManagement.container";
import ArticleCreateEditModalContainer from "containers/articles/articleCreateEditModal.container";
import OfferCreateEditModalContainer from "containers/offers/offerCreateEditModal.container";
import AddLocationContainer from "containers/locations/addLocation.container";
import ReportReviewModalContainer from "containers/reports/reportReviewModal.container";
import CreateEditTicketsModalContainer from "containers/createEditTicketsModal.container";
import AdAddEditModalContainer from "containers/banners/adAddEditModal.container";
import PromoAddEditModalContainer from "containers/banners/promoAddEditModal.container";
import CreateEditScheduleModalContainer from "containers/createEditScheduleModal.container";
import CommentCreateEditModalContainer from "containers/comments/commentCreateEditModal.container";
import GoogleMapModal from "components/Modals/GoogleMap";
import StaticPageCreateEditModalContainer from "containers/content/staticPageCreateEditModal.container";
import FeedCreateEditModalContainer from "containers/feeds/feedCreateEditModal.container";
import SeoCreateEditModalContainer from "containers/seo/seoCreateEditModal.container";
import DeleteConfirmationModal from "./Modals/DeleteConfirmation";
import EnsureLanguage from "./EnsureLanguage/EnsureLanguage";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <OptionsProvider>
          <ModalProvider>
            <Navigation />
            {/* Modals */}
            <EnsureLanguage>
              <AddNewUserModal />
              <AddNewCompanyModal />
              <InfluencerReviewModal />
              <AcceptedOffers />
              <AddNewEntertainmentContainer />
              <AddNewTicketsContainer />
              <AddNewVouchersContainer />
              <FloorMapManagementContainer />
              <EventFloorMapManagementContainer />
              <ArticleCreateEditModalContainer />
              <StaticPageCreateEditModalContainer />
              <OfferCreateEditModalContainer />
              <CreateEditTicketsModalContainer />
              <CreateEditScheduleModalContainer />
              <CommentCreateEditModalContainer />
              <FeedCreateEditModalContainer />
              <SeoCreateEditModalContainer />

              <AddLocationContainer />
            </EnsureLanguage>
            <ReportReviewModalContainer />
            <AdAddEditModalContainer />
            <PromoAddEditModalContainer />
            <GoogleMapModal />
            <DeleteConfirmationModal />
          </ModalProvider>
        </OptionsProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
