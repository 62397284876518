import { Button } from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import AddIcon from "components/Icons/AddIcon";
import { EditIcon } from "components/Icons";
import Heading from "components/Heading";

export default function ModalButton({
  modalName,
  label,
  data,
  isEdit = false,
  ...rest
}) {
  const { openModal } = useModalContext(modalName);

  const openModalHandler = () => {
    openModal(data);
  };

  return (
    <Button onClick={openModalHandler} {...rest}>
      {!isEdit ? (
        <AddIcon fill="white.900" w={5} h={5} mr={2.5} />
      ) : (
        <EditIcon fill="white.900" mr={2.5} />
      )}
      <Heading size="menuItem" color="white.900">
        {label}
      </Heading>
    </Button>
  );
}
