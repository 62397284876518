import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import LocationsListContainer from "containers/locations/locationsList.container";
import ModalButton from "components/ModalButton";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function LocationsMainPage() {
  const button = (
    <ModalButton modalName="createLocation" label="Add Location" />
  );
  const { accessCreateLocations, accessViewLocations } =
    getAccessScopesControl();

  return (
    <Box px={10} py={6} w="full">
      <PageHeader button={accessCreateLocations ? button : null} mb={6}>
        Locations
      </PageHeader>
      <LocationsListContainer accessViewLocations={accessViewLocations} />
    </Box>
  );
}
