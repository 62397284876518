import BackLink from "components/BackLink";
import Heading from "components/Heading";
import Stepper from "components/Stepper";
import AddEditLayout from "layouts/AddEditLayout";
import { useParams, useNavigate } from "react-router-dom";
import { PATHS, SUB_PATHS, allCategories } from "constants/constants";
import { useMemo } from "react";
import { useOptionsContext } from "hooks/useOptionsContext";
import { Flex, Spinner } from "@chakra-ui/react";
import { useGetEventById } from "graphql/hooks/useQueries";
import { useEventContext } from "hooks/useEventContext.hook";
import withEventProvider from "HOCs/withEventProvider";
import EnsureOptions from "components/EnsureOptions";
import {
  transformFullEvent,
  transformLanguagesToCodeList,
  transformCategoriesOptions,
  transformCurrenciesOptions,
} from "connectors";
import useGetCurrencies from "hooks/useGetCurrencies.hook";
import CHS from "./chakra.styles";

const steps = [
  { label: "General information", path: SUB_PATHS.general },
  { label: "Gallery", path: SUB_PATHS.gallery },
  { label: "Offers", path: SUB_PATHS.offers },
  { label: "Floor Map", path: SUB_PATHS.floorMap },
];

function EventManagement() {
  useGetCurrencies();

  const navigate = useNavigate();
  const params = useParams();
  const { languages, categories, currencies } = useOptionsContext();
  const { updateEventState, getEventId } = useEventContext();

  const isEditMode = useMemo(() => Boolean(params.id), [params.id]);

  const translatedValues = transformLanguagesToCodeList(languages).reduce(
    (acc, code) => {
      acc[code] = "";
      return acc;
    },
    {}
  );

  const categoryOptions = transformCategoriesOptions(
    allCategories.eventCategory,
    categories
  );
  const currenciesOptions = transformCurrenciesOptions(currencies);

  const { loading } = useGetEventById({
    variables: {
      id: Number(params.id),
    },
    skip: !params.id,
    onCompleted: (response) => {
      updateEventState(
        transformFullEvent({
          responseData: response?.getEventsById,
          categoryOptions,
          currenciesOptions,
          translatedValues,
          categories,
          languages,
        })
      );
    },
  });

  const rootPath = useMemo(() => {
    return isEditMode ? `${PATHS.events}/${params.id}/` : `${PATHS.newEvents}/`;
  }, [isEditMode, params.id]);

  const handleBackToList = () => {
    navigate(PATHS.events);
  };

  if (!languages.length || loading)
    return (
      <Flex justify="center" marginTop={100} h="100vh">
        <Spinner size="xl" />
      </Flex>
    );

  return (
    <AddEditLayout>
      <BackLink styles={CHS.backLink} onClick={handleBackToList}>
        Back to list
      </BackLink>
      <Heading showAs="h1" size="title" textAlign="center" mb={9.5}>
        {isEditMode ? "Edit Event" : "Add new Event"}
      </Heading>
      <Stepper
        maxWidth="800px"
        steps={steps}
        mb={10}
        rootPath={rootPath}
        isEntityId={getEventId()}
      />
    </AddEditLayout>
  );
}

const EnhancedEventManagement = withEventProvider(EventManagement);

function EnsuredEventManagement() {
  return (
    <EnsureOptions options={["countries", "languages", "categories"]} spinner>
      <EnhancedEventManagement />
    </EnsureOptions>
  );
}
export default EnsuredEventManagement;
