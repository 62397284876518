import LongDash from "components/TableComponents/LongDash";
import TableLink from "components/TableComponents/Link";
import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "sector",
    Cell: ({ value }) => {
      return <TableLink value={value} path="#" />;
    },
  },
  {
    Header: "Description",
    accessor: (originalRow) => originalRow.shortDescription || <LongDash />,
    disableSortBy: true,
  },
  {
    Header: "Rows",
    accessor: "eventSectorRowCount",
  },
  {
    Header: "Seats total",
    accessor: "eventSectorRowPlaceCount",
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return <Options sectorId={original.id} {...original} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
