import { gql } from "@apollo/client";

export const GET_OFFERS = gql`
  query getOffers(
    $categoryId: Int
    $limit: Int
    $offset: Int
    $sort: Sort
    $search: String
  ) {
    getOffers(
      categoryId: $categoryId
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
    ) {
      count
      rows {
        id
        type
        title
        url
        isAddToSlider
        description
        shortDescription
        image
        geolocation
        price
        oldPrice
        currencyId
        categoryId
        offerSEO {
          id
          languageId
          metaTitle
          metaDescription
        }
        placeDetails {
          address
        }
        total
        status
        countUsedOffer
      }
    }
  }
`;
