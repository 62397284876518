import { gql } from "@apollo/client";

export const GET_REPORT_BY_ID = gql`
  query getReport($id: Int!) {
    getReport(id: $id) {
      id
      text
      modelName
      modelId
      status
      category
      createdBy
      reportedBy {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
          company {
            companyName
          }
        }
      }
      createdAt
      updatedAt
      reportedEntity {
        __typename
        ... on Company {
          companyName
          id
        }
        ... on User {
          id
          firstName
          lastName
          email
          phone
          gender
          status
          photo
          languageId
          currencyId
          cityId
          roleId
          role {
            id
            name
            visibility
            scopes {
              id
              scope
            }
          }
          address
          description
          influencer {
            socialAccount1
            socialAccount2
            socialAccount3
            userId
            level
            status
            user {
              id
              firstName
              lastName
            }
          }
          acceptOffers {
            count
            rows {
              id
            }
          }
          companyId
          company {
            id
            image
            adress
            companyName
            cityId
            licenceNumber
          }
        }
        ... on Post {
          id
          title
          createdAt
          postAuthor {
            ... on Company {
              __typename
              id
              companyName
              image
            }
            ... on User {
              __typename
              id
              firstName
              lastName
              photo
              company {
                companyName
              }
            }
          }
          publisher {
            ... on Company {
              __typename
              id
              companyName
              image
            }
            ... on User {
              __typename
              id
              firstName
              lastName
              photo
            }
          }
          publicationDate
          image
          video
          viewsCount
          commentCount
          likeCount
          # --- for editing post ---
          id
          text
          postTags {
            id
            name
          }
        }
        ... on Comment {
          id
          text
          createdAt
          commentAuthor {
            ... on Company {
              __typename
              id
              companyName
              image
            }
            ... on User {
              __typename
              id
              firstName
              lastName
              photo
            }
          }
        }
      }
    }
  }
`;
