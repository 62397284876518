import { useForm, FormProvider } from "react-hook-form";
import { useContext, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Gallery from "pages/Venues/VenueManagement/Gallery";
import {
  transformCategoriesOptions,
  transformAddVenuesGallery,
  transformReceivedAddVenueGallery,
  transformUpdateVenuesGallery,
  transformReceivedUpdateVenueGallery,
} from "connectors";
import { useAddGallery, useUpdateGallery } from "graphql/hooks/useMutations";
import { GALLERY_ADD_SCHEMA, GALLERY_EDIT_SCHEMA } from "schemas";
import stepsContext from "contexts/steps/steps.context";
import { useVenueContext } from "hooks/useVenueContext.hook";
import { useOptionsContext } from "hooks/useOptionsContext";
import { allCategories } from "constants/constants";

function GalleryContainer() {
  const { venue, updateVenueState, getGallery, getVenueId } = useVenueContext();
  const { categories } = useOptionsContext();

  const methods = useForm({
    defaultValues: getGallery(),
    resolver: yupResolver(
      !getGallery()?.id ? GALLERY_ADD_SCHEMA : GALLERY_EDIT_SCHEMA
    ),
  });

  const { nextStep } = useContext(stepsContext);

  const [createGallery] = useAddGallery();

  const [updateGallery] = useUpdateGallery();

  const onSubmit = (formData) => {
    if (!getGallery()?.id) {
      const requestObj = {
        modelId: getVenueId(),
        modelName: "VENUE",
        ...transformAddVenuesGallery(formData),
      };

      createGallery({
        variables: {
          input: requestObj,
        },
        onCompleted: (response) => {
          updateVenueState(
            transformReceivedAddVenueGallery({
              responseData: response?.createGallery,
              allCategories: allCategories?.galleryCategory,
              categories,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Gallery under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Gallery under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      const requestObj = {
        id: getGallery()?.id,
        ...transformUpdateVenuesGallery(formData, getGallery),
      };

      updateGallery({
        variables: {
          input: requestObj,
        },
        onCompleted: (response) => {
          updateVenueState(
            transformReceivedUpdateVenueGallery({
              responseData: response?.updateGallery,
              allCategories: allCategories?.galleryCategory,
              categories,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Gallery under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Gallery under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (venue.id) {
      methods.reset(getGallery());
    }
  }, [venue.id]);

  return (
    <FormProvider {...methods}>
      <Gallery
        onSubmit={methods.handleSubmit(onSubmit)}
        categoryOptions={transformCategoriesOptions(
          allCategories.galleryCategory,
          categories
        )}
      />
    </FormProvider>
  );
}

export default GalleryContainer;
