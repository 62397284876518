import { VStack, Button } from "@chakra-ui/react";
import { ArrowDownThinIcon, TrashIcon } from "components/Icons";
import { useCallback } from "react";
import CHS from "./chakra.styles";

function Actions({ handleDelete, handleSwap, isFirst, isLast, showActions }) {
  return (
    <VStack spacing={5}>
      {showActions && (
        <Controls handleSwap={handleSwap} isFirst={isFirst} isLast={isLast} />
      )}
      <Button
        variant="outline"
        size="actionsBtns"
        sx={CHS.delBtn}
        onClick={handleDelete}
      >
        <TrashIcon fill="red.500" />
      </Button>
    </VStack>
  );
}

export default Actions;

function Controls({ handleSwap, isFirst, isLast, ...rest }) {
  const handleUp = useCallback(
    () => (!isFirst ? handleSwap("up")() : undefined),
    [handleSwap, isFirst]
  );

  const handleDown = useCallback(
    () => (!isLast ? handleSwap("down")() : undefined),
    [handleSwap, isLast]
  );

  return (
    <VStack {...rest} spacing={2}>
      <Button
        variant="outline"
        size="actionsBtns"
        borderRadius="50%"
        onClick={handleUp}
        sx={CHS.controlBtn}
      >
        <ArrowDownThinIcon transform="rotate(180deg)" color="blue.500" />
      </Button>
      <Button
        variant="outline"
        size="actionsBtns"
        borderRadius="50%"
        onClick={handleDown}
        sx={CHS.controlBtn}
      >
        <ArrowDownThinIcon color="blue.500" />
      </Button>
    </VStack>
  );
}
