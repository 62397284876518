import { Button, Grid, GridItem, VStack } from "@chakra-ui/react";
import { LocationIcon } from "components/Icons";
import LanguageIndicators from "components/LanguageIndicators";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import GoogleAutocompleteRHF from "components/react-hook-form/GoogleAutocompleteRHF";
import MultiSelectRHF from "components/react-hook-form/MultiSelectRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import SwitchRHF from "components/react-hook-form/SwitchRHF";
import TextEditorRHF from "components/react-hook-form/TextEditorRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import Actions from "components/Stepper/Actions";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";
import { useFormContext } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";

function GeneralInformationAttractions(props) {
  const {
    onSubmit,
    selectedLanguage,
    statusOptions,
    languageChangeHandler,
    languageOptions,
    restrictionOptions,
    serviceOptions,
    categoriesOptions,
    locationOptions,
  } = props;

  const { openModal } = useModalContext("googleMapModal");
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const coordinates = watch("coordinates");

  const openGoogleMap = () => {
    openModal({
      place_id: coordinates.place_id,
      description: coordinates.description,
      getLocationFromMap: (geolocation) => {
        setValue("coordinates", {
          description: geolocation.description,
          place_id: geolocation.place_id,
        });
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={16} w="xl">
        <Grid
          templateColumns="repeat(3, 1fr)"
          gridAutoRows="auto"
          gap={6}
          w="full"
        >
          <GridItem colSpan={2} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="title"
              name={`title.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Attraction Name"
              inputPlaceholder="Title goes here"
            />
          </GridItem>

          <GridItem colSpan={1}>
            <SelectRHF
              name="status"
              label="Status"
              options={statusOptions}
              placeholder="Select status"
            />
          </GridItem>

          <GridItem colSpan={3}>
            <SelectRHF
              name="language"
              label="Content language"
              options={languageOptions}
              placeholder="Select content language"
              onChangeHandler={languageChangeHandler}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <SelectRHF
              name="attractionCategory"
              label="Attraction category"
              options={categoriesOptions}
              placeholder="Select category"
            />
          </GridItem>

          <GridItem colSpan={1}>
            <SwitchRHF
              name="isAddToSlider"
              label="Show on slider"
              labelColor="dark.500"
              sx={{ height: "100%" }}
              vertical
            />
          </GridItem>

          <GridItem colSpan={2}>
            <GoogleAutocompleteRHF
              name="coordinates"
              label="Coordinates"
              inputPlaceholder="Enter address"
            />
          </GridItem>

          <GridItem colSpan={1} marginTop={6}>
            <Button
              variant="outline"
              w="full"
              leftIcon={
                <LocationIcon fill="blue.500" width="24px" height="24px" />
              }
              onClick={openGoogleMap}
            >
              Select on map
            </Button>
          </GridItem>

          <GridItem colSpan={3}>
            <SelectRHF
              name="location"
              label="Location"
              options={locationOptions}
              placeholder="Select location"
            />
          </GridItem>

          <GridItem colSpan={3} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextEditorRHF
              name={`description.${useGetLanguageKey(selectedLanguage)}`}
              label="Description"
            />
          </GridItem>

          <GridItem
            colSpan={3}
            sx={{
              marginBottom: watch("restrictions")?.length > 0 ? 15 : 0,
            }}
          >
            <MultiSelectRHF
              name="restrictions"
              label="Restrictions"
              options={restrictionOptions}
              placeholder="Select restrictions or features"
            />
          </GridItem>

          <GridItem
            colSpan={3}
            sx={{
              marginBottom: watch("services")?.length > 0 ? 15 : 0,
            }}
          >
            <MultiSelectRHF
              name="services"
              label="Services"
              options={serviceOptions}
              placeholder="Select services"
            />
          </GridItem>

          <GridItem colSpan={3}>
            <TextFieldRHF
              id="url"
              name="url"
              inputType="text"
              label="Custom URL - hypt.com/..."
              inputPlaceholder="Set some nice URL address"
              errorMessage={errors?.url?.message}
            />
          </GridItem>

          <GridItem colSpan={3}>
            <DropzoneFieldRHF
              width="100%"
              height="170px"
              name="main_img"
              label="Main image"
              acceptStr="image/jpeg,image/jpg,image/png"
            />
          </GridItem>

          <GridItem colSpan={3}>
            <DropzoneFieldRHF
              width="100%"
              height="170px"
              name="slider_photo"
              label="Slider photo(s)"
              acceptStr="image/jpeg,image/jpg,image/png"
              multiple
            />
          </GridItem>

          <GridItem colSpan={3} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="meta_title"
              name={`meta_title.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta title"
              inputPlaceholder="Enter meta title"
            />
          </GridItem>

          <GridItem colSpan={3} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="meta_description"
              name={`meta_description.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta description"
              inputPlaceholder="Enter meta description"
            />
          </GridItem>
        </Grid>

        <Actions />
      </VStack>
    </form>
  );
}

export default GeneralInformationAttractions;
