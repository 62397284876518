export default {
  variants: {
    base: {
      tablist: {
        borderBottomWidth: "px",
        borderBottomColor: "white.500",
        borderBottomStyle: "solid",
      },
      tab: {
        minWidth: 28,
        fontWeight: "medium",
        fontSize: "sm",
        lineHeight: 5,
        color: "dark.400",
        paddingTop: "none",
        paddingBottom: "3.25",
        borderBottomWidth: 0.5,
        borderBottomColor: "transparent",
        borderBottomStyle: "solid",
        _selected: {
          color: "black.900",
          borderBottomColor: "blue.500",
        },
        _focus: {
          boxShadow: "none",
        },
      },
      tabpanel: {
        px: "none",
      },
    },
  },
  defaultProps: {
    variant: "base",
  },
};
