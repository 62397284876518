import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveEventSector } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import CHS from "./chakra.styles";

export default function Options({ sectorId, ...original }) {
  const { openModal: openSectorManagementModal } = useModalContext(
    "sectorManagementModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const [removeEventSector] = useRemoveEventSector({
    variables: {
      id: sectorId,
    },
    onCompleted: closeDeleteModal,
  });

  const openModalHandler = () => {
    openSectorManagementModal({ sector: original, sectorId });
  };

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: original.sector,
      type: "sector",
      onDelete: removeEventSector,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openModalHandler}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
