import { useEffect } from "react";
import ToggleGroup from "components/ToggleGroup";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

function ToggleGroupRHF({ children, name, wrapperProps, label }) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const toggleGroupValue = watch(name);

  const handleChange = (value) => {
    setValue(name, value);
  };

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <FormControl isInvalid={errors[name]} {...wrapperProps}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <ToggleGroup value={toggleGroupValue} onChange={handleChange}>
        {children}
      </ToggleGroup>
    </FormControl>
  );
}

export default ToggleGroupRHF;
