import { gql } from "@apollo/client";

export const GET_ALL_USER_OPTIONS = gql`
  query getAllUserOptions {
    getAllUserOptions {
      userGender
      roles {
        id
        name
        visibility
        scopes {
          id
          scope
        }
      }
    }
  }
`;
