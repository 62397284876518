import { gql } from "@apollo/client";

export const EDIT_SEO = gql`
  mutation editSeoDatas($url: String!, $datas: [SEODataInput]!) {
    updateSeoDatas(url: $url, datas: $datas) {
      url
      metaTitle
      metaDescription
      metaKeywords
      language {
        name
        id
        code
        __typename
      }
      languageId
      __typename
    }
  }
`;
