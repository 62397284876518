import { onError } from "@apollo/client/link/error";
import create from "zustand";

export const useErrorsStore = create(() => ({
  hasError: false,
  error: undefined,
  date: undefined,
}));

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors,
      date: Date.now(),
    });
  }
});

export default errorLink;
