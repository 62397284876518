import { EXPIRY, HYPT_ADMIN } from "constants/local-storage";

export function setItem(key, data) {
  localStorage.setItem(
    HYPT_ADMIN + key, // "HYPT_ADMIN_" +
    data
  );
}

export function getItem(key) {
  return localStorage.getItem(HYPT_ADMIN + key);
}

export function removeItem(key) {
  return localStorage.removeItem(HYPT_ADMIN + key);
}

export function setTokenWithExpiry(key, token, hours) {
  const now = new Date();
  const expiry = now.getTime() + hours * 60 * 60 * 1000;
  setItem(key, token); // set token into LS
  setItem(key + EXPIRY, expiry); // set token`s expiry into LS;  // + "_EXPIRY"
}

export function checkToken() {
  const now = new Date();
  const tokenExpiry = +getItem("TOKEN_EXPIRY");
  return now.getTime() <= tokenExpiry;
}
