import { Link, Text, VStack } from "@chakra-ui/react";
import { NavLink as ReactLink } from "react-router-dom";

export default function SearchResultItem(props) {
  const { link, path } = props;
  return (
    <VStack role="group" spacing={1} alignItems="flex-start">
      <Link
        as={ReactLink}
        to={`${path}`}
        color="black.500"
        border="none"
        _groupHover={{ color: "blue.500" }}
      >
        {link}
      </Link>
      <Text fontSize="xs" color="dark.500">
        {path}
      </Text>
    </VStack>
  );
}
