import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query getAllCompanies(
    $limit: Int
    $sort: Sort
    $search: String
    $offset: Int
  ) {
    getAllCompanies(
      limit: $limit
      sort: $sort
      search: $search
      offset: $offset
    ) {
      count
      rows {
        id
        image
        adress
        companyName
        cityId
        city {
          id
          name
          country {
            id
            code
            name
            Cities {
              id
              name
            }
          }
          cityArea {
            id
            name
            isLiked
          }
        }
        licenceNumber
        photos {
          id
          photo
          companyId
        }
        owners {
          id
          dateBirth
          ownerName
          companyShare
          passport
          companyId
          company {
            id
            image
            adress
            companyName
            cityId
            city {
              id
              name
            }
            licenceNumber
            photos {
              id
              photo
              companyId
            }
            owners {
              id
              dateBirth
              ownerName
              companyShare
              passport
              companyId
              photos {
                id
                photo
                ownerId
              }
            }
          }
          photos {
            id
            photo
            ownerId
          }
        }
        users {
          id
        }
      }
    }
  }
`;
