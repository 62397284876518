import { gql } from "@apollo/client";

export const GET_ARTICLE_BY_ID = gql`
  query GetArticleById($id: Int!) {
    getArticleById(id: $id) {
      id
      url
      showOnMain
      promoImage
      mainImage
      categoryId
      viewsCount
      status
      publicatedAt
      authorName
      articleSEO {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      tags {
        id
        name
        viewsCount
      }
      tr {
        promoText
        mainText
        title
        languageId
        language {
          id
          code
          name
        }
      }
    }
  }
`;
