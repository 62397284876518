import { Tr, Td } from "@chakra-ui/react";
import dayjs from "dayjs";

export default function AcceptedOffersItem({ date, title }) {
  return (
    <Tr>
      <Td>{date ? dayjs(date).format("DD.MM.YYYY") : "-"}</Td>
      <Td>{title ?? "-"}</Td>
    </Tr>
  );
}
