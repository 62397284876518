import { Flex, HStack, IconButton, Text } from "@chakra-ui/react";
import { EditIcon, TrashIcon } from "components/Icons";
import CHS from "./chakra.styles";

function SectorRow({ seats, sequenceNumber, onEdit, onRemove }) {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      paddingBottom={4}
      paddingTop={4}
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Text mr="auto" ml="2">
        {sequenceNumber}
      </Text>

      <Text mr="20">{seats}</Text>

      <HStack spacing={4}>
        <IconButton
          variant="outline"
          sx={CHS.icon}
          aria-label="edit row"
          onClick={onEdit}
        >
          <EditIcon fill="blue.500" />
        </IconButton>

        <IconButton
          variant="outline"
          sx={CHS.icon}
          borderColor="red.500"
          aria-label="remove row"
          onClick={onRemove}
        >
          <TrashIcon fill="red.500" />
        </IconButton>
      </HStack>
    </Flex>
  );
}

export default SectorRow;
