import { ChangeStatusOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useDeleteInfluencer } from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";
import CHS from "./chakra.styles";

export default function Options({ row }) {
  const { showToast } = useToastContext();
  const { openModal: openReviewModal } = useModalContext(
    "influencerReviewModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const openModalHandler = () => {
    openReviewModal(row?.original);
  };

  const [removeInfluencer] = useDeleteInfluencer({
    variables: {
      id: row.original.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The influencer has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: row.original.fullName,
      type: "influencer",
      onDelete: removeInfluencer,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<ChangeStatusOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openModalHandler}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Remove influencer
      </CustomMenuItem>
    </TableOptions>
  );
}
