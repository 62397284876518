import dayjs from "dayjs";

export const transformReceivedCreateOwners = ({ responseData }) => {
  if (responseData) {
    return {
      owners: responseData.map((item) => {
        return {
          id: item.id,
          owner: item.ownerName,
          birthDate: dayjs(new Date(Number(item.dateBirth)))?.toDate() || "",
          share: item.companyShare,
          passport: item.passport,
          passportPhoto: item.photos.map((photo) => {
            return {
              id: photo.id,
              preview: `${process.env.REACT_APP_API_URL}/${photo.photo}`,
            };
          }),
        };
      }),
    };
  }

  return {};
};
