import {
  EditOptionIcon,
  DeleteOptionIcon,
  EyeOpenIcon,
} from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveEvent } from "graphql/hooks/useMutations";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import { useModalContext } from "hooks/useModalContext";
import { useToastContext } from "hooks/useToastContext";
import { previewUnpublishedEntity } from "utils/previewUnpublishedEntity";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ row }) {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext("deleteConfirmationModal");
  const { accessUpdateEvents, accessDeleteEvents } = getAccessScopesControl();

  const navToEditEvent = () => navigate(`${PATHS.events}/${row.original.id}`);

  const [removeEvent] = useRemoveEvent({
    variables: {
      id: row.original.id,
    },
    onCompleted: () => {
      closeModal();
      showToast({
        description: "The event has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const openDeleteConfirmationModal = () => {
    openModal({
      name: row.original.name,
      type: "event",
      onDelete: removeEvent,
    });
  };

  const openPreview = () => {
    previewUnpublishedEntity("events", row?.original?.url);
  };

  const isCompleted = row?.original?.isCompleted;

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EyeOpenIcon sx={CHS.eyeIcon} />}
        onMenuItemClick={openPreview}
        isDisabled={Boolean(!isCompleted)}
      >
        Preview
      </CustomMenuItem>

      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={navToEditEvent}
        isDisabled={Boolean(!accessUpdateEvents)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteEvents)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
