import { Text } from "@chakra-ui/react";

export const COLUMNS = [
  {
    Header: "Day",
    disableSortBy: true,
    accessor: "day",
    Cell: ({ value }) => {
      return <Text color={value.color}>{value.text}</Text>;
    },
  },
  {
    Header: "Start time",
    disableSortBy: true,
    accessor: "start",
    Cell: ({ value }) => {
      return <Text color={value.color}>{value.text}</Text>;
    },
  },
  {
    Header: "End time",
    disableSortBy: true,
    accessor: "end",
    Cell: ({ value }) => {
      return <Text color={value.color}>{value.text}</Text>;
    },
  },
  {
    Header: "Up time",
    disableSortBy: true,
    accessor: "duration",
    Cell: ({ value }) => {
      return <Text color={value.color}>{value.text}</Text>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
    Cell: ({ value }) => {
      return <Text color={value.color}>{value.text}</Text>;
    },
  },
];
