export const transformSeoListSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    field: "url",
    type: state.desc ? "DESC" : "ASC",
  };
};
