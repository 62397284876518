import Status from "components/TableComponents/Status";
import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "tableType",
  },
  {
    Header: "Price",
    accessor: "tablePrice",
  },
  {
    Header: "Old price",
    accessor: "tableOldPrice",
  },
  {
    Header: "Best price",
    accessor: "bestPrice",
    Cell: ({ value }) => {
      if (value) return <Status value="best price" />;
      return "-";
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options ticket={row.original} />;
    },
  },
];
