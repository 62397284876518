import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useContext } from "react";
import TableContext from "contexts/table/table.context";
import { TableSortedIcon as Icon } from "../TableSortedIcon";

function Sorting({ columnIsSorted, columnIsSortedDesc }) {
  let SortingIcon = <Icon isSortedDesc={false} isSortedAsc={false} />;
  if (columnIsSorted) {
    if (columnIsSortedDesc) {
      SortingIcon = <Icon isSortedDesc isSortedAsc={false} />;
    } else {
      SortingIcon = <Icon isSortedDesc={false} isSortedAsc />;
    }
  }
  return (
    <Box display="inline-block" ml={2.5} position="relative">
      {SortingIcon}
    </Box>
  );
}

function TableBody(props) {
  const { hasSorting = true, variant = "base" } = props; // pass `hasSorting={false}` if we don`t need sorting columns

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
  } = useContext(TableContext);

  return (
    <Table {...getTableProps()} variant={variant}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <Th
                  /* sorting table`s columns or not */
                  {...column.getHeaderProps(
                    hasSorting && column.getSortByToggleProps()
                  )}
                  isNumeric={column.isNumeric}
                >
                  {column.render("Header")}
                  {hasSorting && !column.disableSortBy && (
                    <Sorting
                      columnIsSorted={column.isSorted}
                      columnIsSortedDesc={column.isSortedDesc}
                    />
                  )}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                  >
                    {cell.render("Cell")}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default TableBody;
