import {
  ModalBody,
  ModalFooter,
  HStack,
  VStack,
  Flex,
  Button,
  Spacer,
  Wrap,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import LanguageIndicators from "components/LanguageIndicators";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";

export default function SeoCreateEditModal(props) {
  const {
    onSubmit,
    cancelHandler,
    languagesOptions,
    isEdit,
    selectedLanguage,
    languageChangeHandler,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <Wrap position="relative" w="full">
            <TextFieldRHF
              id="url"
              name="url"
              inputType="text"
              inputPlaceholder="Url goes here"
              label="Seo url"
            />
          </Wrap>
          <HStack w="full" spacing={6}>
            <SelectRHF
              name="language"
              label="Content language"
              placeholder="Select content language"
              options={languagesOptions}
              onChangeHandler={languageChangeHandler}
            />
          </HStack>
          <Wrap position="relative" w="full">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="metaTitle"
              name={`metaTitle.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta title"
              inputPlaceholder="Enter meta title"
            />
          </Wrap>
          <Wrap position="relative" w="full">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="metaKeywords"
              name={`metaKeywords.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta keywords"
              inputPlaceholder="Enter meta keywords"
            />
          </Wrap>
          <Wrap position="relative" w="full">
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="metaDescription"
              name={`metaDescription.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Meta description"
              inputPlaceholder="Enter meta description"
            />
          </Wrap>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Flex w="100%" px={6}>
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            Cancel
          </Button>
          <Spacer />
          <Button type="submit">{isEdit ? "Edit seo" : "Create seo"}</Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
