import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";

function DeleteConfirmation() {
  const { modal, closeModal } = useModalContext("deleteConfirmationModal");

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Heading color="blue.600">Please confirm your action</Heading>
        </ModalHeader>
        <ModalBody>
          <Text fontSize="lg" color="dark.700">
            Are you sure you want to delete {modal.type} <i>{modal.name}</i> ?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex w="full" justifyContent="space-between">
            <Button variant="outline" minW="140px" onClick={closeModal}>
              Cancel
            </Button>
            <Button minW="180px" onClick={modal.onDelete}>
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteConfirmation;
