import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import ValidationNotification from "components/ValidationNotification";
import SelectRHF from "components/react-hook-form/SelectRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { useFormContext } from "react-hook-form";

function AddVenuesVoucherModal(props) {
  const {
    isOpen,
    onClose,
    cancelHandler,
    onSubmit,
    eventOptions,
    onChangeEventHandler,
    voucherTypeOptions,
    onChangeVoucherTypeHandler,
    offerTypeOptions,
    onChangeOfferTypeHandler,
  } = props;

  const {
    handleSubmit,
    formState: { errors = {} },
  } = useFormContext();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Add voucher</Heading>
          <ModalCloseButton />
          <ValidationNotification errors={errors} icon={false} m={0}>
            <Text fontSize="md" color="red.500">
              {Object.values(errors)[0]?.message}
            </Text>
          </ValidationNotification>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <TextFieldRHF
                id="name"
                name="name"
                label="Name"
                inputPlaceholder="Enter name"
              />
              <HStack spacing={6} w="full">
                <SelectRHF
                  name="event"
                  label="Event"
                  options={eventOptions}
                  placeholder="Select event..."
                  onChangeHandler={onChangeEventHandler}
                />
                <SelectRHF
                  name="voucher_type"
                  label="Voucher type"
                  options={voucherTypeOptions}
                  placeholder="Select type..."
                  onChangeHandler={onChangeVoucherTypeHandler}
                />
              </HStack>
              <HStack spacing={6} w="full">
                <TextFieldRHF
                  id="price"
                  name="price"
                  label="Price, AED"
                  inputPlaceholder="Enter price"
                />
                <TextFieldRHF
                  id="special_price"
                  name="special_price"
                  label="Special price, AED"
                  inputPlaceholder="Enter price"
                />
              </HStack>
              <HStack spacing={6} w="full">
                <SelectRHF
                  name="offer_type"
                  label="Offer type"
                  options={offerTypeOptions}
                  placeholder="Select type..."
                  onChangeHandler={onChangeOfferTypeHandler}
                />
                <TextFieldRHF
                  id="quota"
                  name="quota"
                  label="Quota"
                  inputPlaceholder="Start typing..."
                />
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing="auto" w="full">
              <Button
                variant="outline"
                colorScheme="blue.500"
                onClick={cancelHandler}
              >
                Cancel
              </Button>
              <Button type="submit">Add voucher</Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default AddVenuesVoucherModal;
