import { Box, Flex } from "@chakra-ui/react";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import { ROWS_PER_PAGE_DEFAULT } from "constants/constants";

function LocationsTable() {
  return (
    <>
      <Flex justify="flex-end" align="center" mb={7}>
        <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE_DEFAULT} />
      </Flex>
      <Box>
        <TableBody hasSorting={true} />
        <TablePagination />
      </Box>
    </>
  );
}

export default LocationsTable;
