import { gql } from "@apollo/client";

export const GET_VENUE_BY_URL = gql`
  query getVenue($url: String!) {
    getVenue(url: $url) {
      id
      promoImage
      title
      status
      url
      price
      isAddToSlider
      description
      geolocation
      infoTitle
      infoDescription
      policyTitle
      policyDescription
      areaId
      cityId
      createdBy
      categoryId
      createdAt
      updatedAt
      photo
      comment {
        count
        rows {
          text
        }
      }
      isLiked
      venueSEO {
        id
        languageId
        metaTitle
        metaDescription
      }
      rank
      offerVenue {
        id
        type
        title
        url
        isAddToSlider
        description
        shortDescription
        geolocation
        price
        oldPrice
        currencyId
        createdBy
        categoryId
        createdAt
        photo
        offerSEO {
          id
          languageId
          metaTitle
          metaDescription
        }
        placeDetails {
          address
        }
      }
      eventVenue {
        id
        title
        url
        isAddToSlider
        description
        geolocation
        price
        currencyId
        createdBy
        categoryId
        startAt
        createdAt
        photo
        isLiked
        eventSEO {
          id
          languageId
          metaTitle
          metaDescription
        }
        eventVenue {
          id
        }
        placeDetails {
          address
          hours
          rating
          ratingCountUsers
          reviews {
            authorName
            authorUrl
          }
          latitude
          longitude
        }
      }
      galleryVenue {
        title
        url
        date
        galleryPhoto {
          id
          path
          photoAuthor {
            id
          }
          isLiked
        }
        galleryPhotoCount
      }
      placeDetails {
        address
        hours
        rating
        ratingCountUsers
        reviews {
          authorName
          authorUrl
          photoUrl
          rating
          text
          time
        }
        latitude
        longitude
      }
      venueCategory {
        id
        modelName
        name
      }
      venueMenu {
        id
        name
        shortDescription
        description
        fullMenu
        image
        createdAt
        updatedAt
      }
      offerVenueForInfluencer {
        id
        type
        title
        url
        isAddToSlider
        description
        shortDescription
        geolocation
        price
        oldPrice
        currencyId
        createdBy
        categoryId
        createdAt
        photo
        offerSEO {
          id
          languageId
          metaTitle
          metaDescription
        }
        placeDetails {
          address
        }
      }
    }
  }
`;
