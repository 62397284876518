import authContext from "contexts/auth/auth.context";
import {
  useGetCountries,
  useGetLanguages,
  useGetCategories,
  useGetCurrentUser,
} from "graphql/hooks/useQueries";
import { useState, useMemo, useContext } from "react";
import OptionsContext from "./options.context";

function OptionsProvider({ children }) {
  const [value, setValue] = useState({
    countries: [],
    userPermisionRoles: [],
    userGenders: [],
    languages: [],
    venueStatuses: [],
    venueCategories: [],
    venueRanks: [],
    venueServices: [],
    venueList: [],
    offerLevels: [],
    modelTypeOffers: [],
    typeOffers: [],
    statusOffers: [],
    influencerLevels: [],
    influencerStatuses: [],
    currencies: [],
    categories: [],
    services: [],
    restrictions: [],
    attractionTicketTypes: [],
    attractionStatuses: [],
    eventStatuses: [],
    staticPagesStatuses: [],
    articleStatuses: [],
    adStatuses: [],
    currentUser: {},
  });

  const auth = useContext(authContext);

  useGetCurrentUser({
    onCompleted: (response) => {
      setValue((prev) => ({ ...prev, currentUser: response }));
    },
    skip: !auth.isAuth,
  });

  useGetCountries({
    onCompleted: (response) => {
      setValue((prev) => ({ ...prev, countries: response?.getCountries }));
    },
    skip: !auth.isAuth,
  });

  useGetLanguages({
    onCompleted: (response) => {
      setValue((prev) => ({
        ...prev,
        languages: response?.getLanguage,
      }));
    },
    skip: !auth.isAuth,
  });

  useGetCategories({
    onCompleted: (response) => {
      setValue((prev) => ({
        ...prev,
        categories: response?.getCategories,
      }));
    },
    skip: !auth.isAuth,
  });

  const handleUpdateState = (data) => {
    setValue((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const _value = useMemo(
    () => ({ ...value, updateOptionsState: handleUpdateState }),
    [value, handleUpdateState]
  );

  return (
    <OptionsContext.Provider value={_value}>{children}</OptionsContext.Provider>
  );
}

export default OptionsProvider;
