import { gql } from "@apollo/client";

export const GET_ALL_OFFER_OPTIONS = gql`
  query getAllOfferOptions {
    getAllOfferOptions {
      offerLevel
      modelTypeOffer
      typeOffer
      offerStatus
    }
  }
`;
