import { useCallback, useMemo } from "react";
import {
  checkToken,
  removeItem,
  setTokenWithExpiry,
} from "utils/local-storage-utils";
import useOnLocationChange from "hooks/useOnLocationChange";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import AuthContext from "contexts/auth/auth.context";

function AuthProvider({ children }) {
  const isAuthenticated = useOnLocationChange(checkToken);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    removeItem("TOKEN");
    removeItem("TOKEN_EXPIRY");
    navigate(PATHS.login);
  }, [navigate]);

  const handleLogin = useCallback(
    (token, tokenExpiryTime) => {
      setTokenWithExpiry("TOKEN", token, tokenExpiryTime);
      navigate(PATHS.home);
    },
    [navigate]
  );

  const authData = useMemo(
    () => ({
      isAuth: isAuthenticated,
      logout: handleLogout,
      login: handleLogin,
    }),
    [handleLogout, handleLogin, isAuthenticated]
  );

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
