import { Box, Divider } from "@chakra-ui/react";
import NavItem from "components/NavList/NavItem";
import { LINKS as links } from "constants/constants";
import { useOptionsContext } from "hooks/useOptionsContext";

export default function Navlist() {
  const { currentUser } = useOptionsContext();

  const filterAccessPermission = links.filter((elem) => {
    if (elem.isDivider) return true;
    return currentUser?.currentUser?.role?.scopes
      ?.map((item) => item?.scope)
      .includes(elem.permission);
  });

  const isShowDividers = () => {
    if (
      (filterAccessPermission[0].isDivider &&
        filterAccessPermission.at(-1).isDivider) === true
    ) {
      filterAccessPermission.pop();
      return filterAccessPermission.splice(1);
    }
    if (filterAccessPermission[0].isDivider === true) {
      return filterAccessPermission.splice(1);
    }
    if (filterAccessPermission.at(-1).isDivider === true) {
      filterAccessPermission.pop();
    }
    return filterAccessPermission;
  };

  const accessPermissionLinks = isShowDividers();

  return (
    <Box display="flex" flexDir="column" pt="16px">
      {accessPermissionLinks?.map((link) => {
        if (link?.isDivider) {
          return (
            <Box key={link.dividerId} py="16px">
              <Divider borderColor="grey.500" opacity="0.15" />
            </Box>
          );
        }
        return (
          <NavItem
            key={link.text}
            icon={link.icon}
            text={link.text}
            path={link.path}
          />
        );
      })}
    </Box>
  );
}
