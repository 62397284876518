export const transformPermisionRoles = (data = []) => {
  if (data) {
    return data.map((option) =>
      option.visibility === "ANY"
        ? {
            value: option.id,
            label: `${option.name} (HYPT)`,
          }
        : {
            value: option.id,
            label: `${option.name} (Partner)`,
          }
    );
  }
  return [];
};
