import {
  Button,
  Divider,
  Flex,
  HStack,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
} from "@chakra-ui/react";

import DatePickerCustom from "components/DatePicker";
import SelectRHF from "components/react-hook-form/SelectRHF";
import Textarea from "components/Textarea";
import TextField from "components/TextField";
import { useState } from "react";
import RedirectFromReportsReview from "./RedirectFromReportsReview";

export default function ReportReview({
  onSubmit,
  onClose,
  report,
  statusOptions,
  actionOptions,
  parentInstance,
  status,
}) {
  const isCommentType = report.modelName === "Comment";
  const isPostType = report.modelName === "Post";

  const [viewButtonIsDisabled, setViewButtonIsDisabled] = useState(false);

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={4}>
          <HStack w="full">
            <TextField
              label="Category"
              value={report.category ?? "Category was not specifeid"}
              isReadOnly
            />
            <SelectRHF
              name="report_status"
              label="Status"
              options={statusOptions}
              isDisabled={!report?.parent}
            />
          </HStack>

          <HStack w="full">
            <TextField label="Parent" value={report.parent} isReadOnly />
            <RedirectFromReportsReview
              closePreviousModal={onClose}
              parentInstance={parentInstance}
              viewButtonIsDisabled={!report?.parent || viewButtonIsDisabled}
              setViewButtonIsDisabled={setViewButtonIsDisabled}
            />
          </HStack>

          {isPostType && (
            <HStack w="full">
              <TextField label="Author" value={report.postAuthor} isReadOnly />
              <DatePickerCustom
                label="Publication date"
                selected={report.publicationDate}
                dateFormat="dd/MM/yyy, hh:mm"
                readOnly
              />
            </HStack>
          )}

          <Textarea label="Report text" value={report.reportText} isReadOnly />

          <HStack w="full">
            <TextField
              label="Reported by"
              value={report.reportedBy}
              isReadOnly
            />
            <TextField label="Type" value={report.modelName} isReadOnly />
          </HStack>

          {isCommentType && <Divider />}

          {isCommentType && (
            <Textarea
              label="Comment text"
              value={report.commentText}
              isReadOnly
            />
          )}

          {isCommentType && (
            <HStack w="full">
              <TextField
                label="Author"
                value={report.commentAuthor}
                isReadOnly
              />
              <DatePickerCustom
                label="Publication date"
                selected={report.publicationDate}
                dateFormat="dd/MM/yyy, hh:mm"
                readOnly
              />
            </HStack>
          )}

          {report?.parent ? (
            <>
              <SelectRHF
                name="report_action"
                label="Action"
                options={actionOptions}
                isDisabled={status !== "REVIEWED"}
              />
              {status !== "REVIEWED" && (
                <Text w="full" fontSize="2xs" color="dark.300">
                  Change the status before you choose an action
                </Text>
              )}
            </>
          ) : (
            <Text>Entry removed</Text>
          )}
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%" justifyContent="space-between">
          <Button onClick={onClose} variant="outline" color="blue.500">
            Cancel
          </Button>
          {report?.parent && <Button type="submit">Save</Button>}
        </Flex>
      </ModalFooter>
    </form>
  );
}
