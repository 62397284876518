import { Button, Flex, useTheme } from "@chakra-ui/react";
import Heading from "components/Heading";
import ReactSelect, { components } from "react-select";
import React, { useMemo, useContext } from "react";
import { ArrowDownIcon } from "components/Icons";
import getActivePaginatorSetter from "utils/paginator-utils";
import TableContext from "contexts/table/table.context";

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Button size="tablePaginator" variant="tablePaginator">
        <ArrowDownIcon stroke="blue.500" w="10px" h="6px" fill="none" />
      </Button>
    </components.DropdownIndicator>
  );
}

function TablePageSetter(props) {
  const { arrayOfNumbers } = props;

  const {
    setPageSize: setNumberOfRows,
    state: { pageSize: numberOfRows },
  } = useContext(TableContext);

  const dropdownIndicator = useMemo(() => {
    return { DropdownIndicator };
  }, []);

  const changeNumberOfPage = (e) => {
    setNumberOfRows(e.value);
  };

  const theme = useTheme();
  const styles = useMemo(
    () => ({
      container: (provided) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        cursor: "pointer",
        height: "42px",
        width: "110px",
        backgroundColor: theme.colors.blue[500],
        borderRadius: "21px",
        borderColor: theme.colors.blue[500],
        borderWidth: "1px",
        borderStyle: "solid",
        boxShadow: "none",
        ":hover": {
          backgroundColor: theme.colors.blue[400],
          borderColor: theme.colors.blue[400],
          boxShadow: "none",
        },
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: theme.colors.white[900],
        fontFamily: theme.fonts.medium,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fontSizes.sm,
      }),
      placeholder: (provided) => ({
        ...provided,
        color: theme.colors.white[900],
        fontFamily: theme.fonts.medium,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fontSizes.sm,
        width: "30px",
      }),
      input: (provided) => ({
        ...provided,
        color: theme.colors.white[900],
        width: "30px",
      }),
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: "20px",
        paddingRight: "0px",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        marginRight: "15px",
        opacity: 1,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 3,
      }),
      menuList: (provided) => ({
        ...provided,
        "::-webkit-scrollbar": {
          width: "6px",
          backgroundColor: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: theme.colors.blue[500],
          borderRadius: "3px",
        },
        "::-webkit-scrollbar-button": {
          backgroundColor: "transparent",
          height: "4px",
        },
      }),
    }),
    [theme]
  );

  return (
    <Flex justify="end" align="center">
      <Heading size="label" variant="main" mr={4}>
        Rows per page
      </Heading>

      <ReactSelect
        name="row-per-page"
        value={getActivePaginatorSetter(arrayOfNumbers, numberOfRows)}
        onChange={changeNumberOfPage}
        options={arrayOfNumbers}
        defaultValue={getActivePaginatorSetter(arrayOfNumbers, numberOfRows)}
        placeholder={false}
        isSearchable={false}
        components={dropdownIndicator}
        styles={styles}
      />
    </Flex>
  );
}

export default TablePageSetter;
