import * as yup from "yup";

export const EDIT_POST_SCHEMA = yup.object().shape({
  title: yup.string().required("Title is required field"),

  tags: yup.string().nullable(),

  main_image: yup.mixed().nullable(),

  main_text: yup.string(),

  authorOnEdit: yup.string(),

  publication_date: yup.string().nullable(),
});
