import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";
import * as yup from "yup";
import { cleanTextFromHTML } from "./cleanTextFromHTML";

export const multiLangValidate = (languages, depsArr, seo) =>
  languages.reduce((acc, language) => {
    if (seo === "SEO") {
      acc[language.code] = yup
        .string()
        .test("check-deps", "This field is required!", (val, ctx) => {
          const values = ctx.from[1].value;
          const checkFields = depsArr?.reduce((fields, current) => {
            if (cleanTextFromHTML(values[current][language.code])) {
              fields.push(current);
            }
            return fields;
          }, []);

          if (!cleanTextFromHTML(val) && checkFields.length) {
            return false;
          }
          return true;
        });
    } else {
      acc[language.code] =
        language?.id === DEFAULT_ENGLISH_LANGUAGE_ID
          ? yup
              .string()
              .test("check-field", "This field is required!", (val) =>
                cleanTextFromHTML(val)
              )
          : yup
              .string()
              .test("check-deps", "This field is required!", (val, ctx) => {
                const values = ctx.from[1].value;

                const checkFields = depsArr?.reduce((fields, current) => {
                  if (cleanTextFromHTML(values[current][language.code])) {
                    fields.push(current);
                  }
                  return fields;
                }, []);

                if (!cleanTextFromHTML(val) && checkFields.length) {
                  return false;
                }
                return true;
              });
    }

    return acc;
  }, {});
