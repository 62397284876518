import { FormProvider, useForm } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";
import CreateEditScheduleModal from "components/Modals/CreateEditSchedule";
import {
  transformCreateEditScheduleAttraction,
  transformBeforeEditScheduleAttraction,
} from "connectors";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  useCreateAttractionSchedule,
  useUpdateAttractionSchedule,
} from "graphql/hooks/useMutations";
import { CREATE_EDIT_SCHEDULE_SCHEMA } from "schemas";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

function CreateEditScheduleModalContainer() {
  const { modal, closeModal } = useModalContext("createEditScheduleModal");

  const INITIAL_VALUES = {
    days: [],
    daySchedule: {
      MON: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
      TUE: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
      WED: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
      THU: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
      FRI: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
      SAT: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
      SUN: {
        from: null,
        to: null,
        duration: null,
        enabled: false,
      },
    },
  };

  const methods = useForm({
    resolver: yupResolver(CREATE_EDIT_SCHEDULE_SCHEMA.schema),
    defaultValues: INITIAL_VALUES,
  });

  const { reset } = methods;

  const [createSchedule] = useCreateAttractionSchedule();
  const [updateSchedule] = useUpdateAttractionSchedule();

  const closeHandler = () => {
    reset(INITIAL_VALUES);
    closeModal();
  };

  const onSubmit = (formData) => {
    if (!modal.isEditMode) {
      createSchedule({
        variables: {
          input: transformCreateEditScheduleAttraction({
            data: formData.daySchedule,
            attractionId: modal.attractionId,
          }),
        },
        onCompleted: () => {
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      updateSchedule({
        variables: {
          input: transformCreateEditScheduleAttraction({
            isEdit: true,
            data: formData.daySchedule,
          }),
        },
        onCompleted: () => {
          closeHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(
        transformBeforeEditScheduleAttraction({ data: modal?.editSchedule })
      );
    }
  }, [modal.isEditMode]);

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modal.isEditMode ? "Edit schedule" : "Create schedule"}
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <CreateEditScheduleModal
            cancelHandler={closeHandler}
            onSubmit={methods.handleSubmit(onSubmit)}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}

export default CreateEditScheduleModalContainer;
