import {
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useContext, useEffect, useState } from "react";

import { useModalContext } from "hooks/useModalContext";
import AddEditAd from "components/Modals/AddEditAd";
import { useCreateAd, useUpdateAd } from "graphql/hooks/useMutations";
import { transformAdDataBeforeEdit, transformCreateAdData } from "connectors";
import { useGetAllPositions } from "graphql/hooks/useQueries";
import { getAdsPositions } from "utils/getAdsPositions";
import {
  CREATE_ADVERTISEMENT_SCHEMA,
  EDIT_ADVERTISEMENT_SCHEMA,
} from "schemas";
import authContext from "contexts/auth/auth.context";
import { useToastContext } from "hooks/useToastContext";
import { useOptionsContext } from "hooks/useOptionsContext";
import { transformStatusOptions } from "connectors/transformStatusOptions";
import useGetAdStatuses from "hooks/useGetAdStatuses.hook";

const INITIAL_VALUES = {
  page_name: null,
  block_ids: null,
  rotation_start: null,
  rotation_end: null,
  display_count: "",
  banner_image: null,
  banner_url: "",
  status: null,
};

function AdAddEditModalContainer() {
  useGetAdStatuses();

  const { modal, closeModal } = useModalContext("adAddEditModal");

  const { showToast } = useToastContext();

  const { adStatuses } = useOptionsContext();

  const auth = useContext(authContext);

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode ? EDIT_ADVERTISEMENT_SCHEMA : CREATE_ADVERTISEMENT_SCHEMA
    ),
  });
  const [submitError, setSubmitError] = useState("");

  const [pageName, selectedBlocks] = methods.watch(["page_name", "block_ids"]);

  const { data: adsPositionsData } = useGetAllPositions({
    variables: {
      options: {
        page: "",
      },
    },
    skip: !auth.isAuth || !modal.isOpen,
  });
  const [createAd] = useCreateAd();
  const [updateAd] = useUpdateAd();

  const adsPositions = getAdsPositions(
    adsPositionsData?.getAllPositions?.rows,
    pageName?.value
  );

  const closeHandler = () => {
    methods.reset(INITIAL_VALUES);
    setSubmitError("");
    closeModal();
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(transformAdDataBeforeEdit(modal.ad));
    }
  }, [modal.isEditMode]);

  const onSubmit = (formData) => {
    if (modal?.isEditMode) {
      updateAd({
        variables: {
          id: modal.ad?.id,
          input: transformCreateAdData(
            formData,
            adsPositionsData?.getAllPositions?.rows
          ),
        },
        onCompleted: () => {
          showToast({
            description: "The advertisement has been successfully updated",
          });
          closeHandler();
        },
        onError: (response) => {
          if (response.message === "Validation error") {
            setSubmitError("This URL is already exist");
          }
        },
      });
    } else {
      createAd({
        variables: {
          input: transformCreateAdData(
            formData,
            adsPositionsData?.getAllPositions?.rows
          ),
        },
        onCompleted: () => {
          showToast({
            description: "The advertisement has been successfully created",
          });
          closeHandler();
        },
        onError: (response) => {
          if (response.message === "Validation error") {
            setSubmitError("This URL is already exist");
          }
        },
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>
            {modal.isEditMode ? "Edit advertisement" : "Add advertisement"}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <AddEditAd
            onSubmit={methods.handleSubmit(onSubmit)}
            onClose={closeHandler}
            isEditMode={modal.isEditMode}
            pagesOptions={adsPositions.pages}
            levelsOptions={adsPositions.levels}
            selectedBlocks={selectedBlocks}
            isPageSelected={pageName}
            submitError={submitError}
            statusOptions={transformStatusOptions(adStatuses)}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}

export default AdAddEditModalContainer;
