export default {
  sizes: {
    base: {
      container: {
        w: 32,
        h: 7,
      },
      control: {
        w: 6,
        h: 6,
      },
      icon: {
        w: 3,
        h: 3,
      },
    },
  },
  variants: {
    main: {
      container: {},
      control: {
        borderRadius: "sm",
        bgColor: "white.900",
        borderColor: "grey.600",
        _checked: {
          bgColor: "blue.500",
          borderColor: "blue.500",
          _hover: {
            bgColor: "blue.500",
            borderColor: "blue.500",
          },
        },
        _focus: {
          boxShadow: "none",
        },
      },
      label: {
        ml: 2,
        fontWeight: "normal",
        fontFamily: "normal",
        fontSize: "sm",
        lineHeight: 5,
      },
    },
  },
  defaultProps: {
    size: "base",
    variant: "main",
  },
};
