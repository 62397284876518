export default {
  variants: {
    main: {
      ml: "2",
      mb: "1.5",
      fontFamily: "medium",
      fontWeight: "medium",
      fontSize: "xs",
      lineHeight: "normal",
      color: "dark.500",
    },
  },
  defaultProps: {
    variant: "main",
  },
};
