const controlBtn = {
  _hover: {
    bg: "white.500",
  },
  _disabled: {
    cursor: "not-allowed",
  },
};

const delBtn = {
  color: "red.500",
  borderColor: "red.500",
  borderRadius: "50%",
  bgColor: "red.80",
};

export default { controlBtn, delBtn };
