import dayjs from "dayjs";

export const transformCreateArticle = (formData, languages) => {
  if (formData) {
    const multiTextformData = languages.reduce((lang, current) => {
      if (
        formData.main_text?.[current.code] &&
        formData.promo_text?.[current.code] &&
        formData.title?.[current.code]
      ) {
        lang.push({
          mainText: formData.main_text?.[current.code],
          promoText: formData.promo_text?.[current.code],
          title: formData.title?.[current.code],
          languageId: current?.id,
        });
      }

      return lang;
    }, []);

    const multiSeoContent = languages.reduce((lang, current) => {
      if (
        formData.meta_title?.[current.code] &&
        formData.meta_description?.[current.code]
      ) {
        lang.push({
          metaTitle: formData.meta_title?.[current.code] || "",
          metaDescription: formData.meta_description?.[current.code] || "",
          languageId: current?.id,
        });
      }
      return lang;
    }, []);

    const requestObj = {
      url: formData.url,
      status: formData.status?.value,
      showOnMain: formData.show_on_main,
      promoImage: formData.promo_image,
      mainImage: formData.main_image,
      categoryId: Number(formData.category.value),
      authorName: formData.author_name,
      authorAvatar: formData.author_avatar,
      textData: multiTextformData,
      seoContent: multiSeoContent,
      publicatedAt: dayjs(formData.publication_date).format("YYYY-MM-DD"),
      tags: formData.tagsCreate,
    };

    return requestObj;
  }
  return {};
};
