import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import Upload from "components/react-hook-form/UploadRHF";
import Actions from "components/Stepper/Actions";
import LanguageIndicators from "components/LanguageIndicators";
import SelectRHF from "components/react-hook-form/SelectRHF";

export default function Menu({
  onSubmit,
  languageOptions,
  selectedLanguage,
  languageChangeHandler,
}) {
  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={16} paddingBottom="20px" w="xl">
        <Grid
          templateColumns="repeat(3, 1fr)"
          gridAutoRows="auto"
          gap={6}
          w="full"
        >
          <GridItem colSpan={3} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextFieldRHF
              id="name"
              name={`name.${useGetLanguageKey(selectedLanguage)}`}
              inputType="text"
              label="Menu name"
              inputPlaceholder="Enter menu name"
            />
          </GridItem>

          <GridItem colSpan={3}>
            <SelectRHF
              name="language"
              label="Content language"
              options={languageOptions}
              placeholder="Select content language"
              onChangeHandler={languageChangeHandler}
            />
          </GridItem>

          <GridItem colSpan={3} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextareaRHF
              id="short_description"
              name={`short_description.${useGetLanguageKey(selectedLanguage)}`}
              label="Short description"
              width="100%"
              height="60px"
            />
          </GridItem>

          <GridItem colSpan={3}>
            <Upload id="file" name="file" label="Full menu, pdf" />
          </GridItem>

          <GridItem colSpan={1}>
            <DropzoneFieldRHF
              width="188px"
              height="188px"
              name="image"
              label="Menu image"
              acceptStr="image/jpeg,image/png"
            />
          </GridItem>

          <GridItem colSpan={2} sx={{ position: "relative" }}>
            <LanguageIndicators selectedLanguage={selectedLanguage} />
            <TextareaRHF
              id="description"
              name={`description.${useGetLanguageKey(selectedLanguage)}`}
              label="Description"
              width="380px"
              height="186px"
            />
          </GridItem>
        </Grid>

        <Actions previousLabel="Back" />
      </VStack>
    </form>
  );
}
