import { multiLangValidate } from "utils/multiLangValidate";
import * as yup from "yup";

const schema = (languages) => {
  return yup.object().shape({
    url: yup.string().required("Url is required!"),

    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),

    metaTitle: yup
      .object()
      .shape(
        multiLangValidate(languages, ["metaDescription", "metaKeywords"], "SEO")
      )
      .required("Title is required!"),

    metaKeywords: yup
      .object()
      .shape(
        multiLangValidate(languages, ["metaTitle", "metaDescription"], "SEO")
      )
      .required("Keywords is required!"),

    metaDescription: yup
      .object()
      .shape(multiLangValidate(languages, ["metaTitle", "metaKeywords"], "SEO"))
      .required("Description is required!"),
  });
};

export default { schema };
