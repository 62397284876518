const homeContainer = {
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "calc(100vh - 92px)",
};

const homeTitle = {
  fontSize: "56px",
  color: "dark.300",
  lineHeight: "65px",
};

const homeBackgroundImage = {
  width: "400px",
  height: "209px",
  marginTop: "24px",
  marginBottom: "48px",
};

export default {
  homeContainer,
  homeTitle,
  homeBackgroundImage,
};
