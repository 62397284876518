import * as yup from "yup";
import { TEL_REGEX } from "../constants/constants";

const schema = yup.object().shape({
  firstName: yup.string().max(30, "First name is too long!"),
  photo: yup.array().of(yup.mixed()).nullable(true),
  lastName: yup.string().max(30, "Last name is too long!"),
  password: yup
    .string()
    .min(0, "Password is too short!")
    .max(22, "Password is too long!"),
  phone: yup.string().matches(TEL_REGEX, "Invalid phone number format"),
  email: yup.string().email("Invalid email!"),
  country: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  city: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(true),
  company: yup.string().max(30, "Company name is too long!"),
  sendToEmail: yup.boolean(),
});

export default { schema };
