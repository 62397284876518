import { gql } from "@apollo/client";

export const GET_ALL_EVENTS_WITHOUT_VENUE_ID = gql`
  query GetAllEventsWithoutVenueId($options: GetAllVenueEvents) {
    getAllEventsWithoutVenueId(options: $options) {
      count
      rows {
        id
        image
        tr {
          language {
            id
          }
          title
        }
      }
    }
  }
`;
