import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { FormProvider } from "react-hook-form";
import TablePageSetter from "components/Table/TablePageSetter";
import { ROWS_PER_PAGE } from "pages/Venues/VenueManagement/Offers/OffersTable/table.config";
import { GlassIcon } from "components/Icons";
import { Flex, Box } from "@chakra-ui/react";

function OffersFilters({ methods }) {
  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    console.log(data, "onSubmit");
  };

  return (
    <FormProvider {...methods}>
      <Box w="full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex justify="space-between">
            <TextFieldRHF
              name="name"
              inputType="text"
              inputPlaceholder="Search offers"
              inputLeftElement={
                <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
              }
              width="480px"
            />
            <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          </Flex>
        </form>
      </Box>
    </FormProvider>
  );
}

export default OffersFilters;
