import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  HStack,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ArrowLeftIcon } from "components/Icons";
import { useFormContext } from "react-hook-form";

export default function Upload({ id, name, label }) {
  const [, setUploadFile] = useState(null);

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { watch } = useFormContext();

  const watchOfPath = watch(name);

  const onDrop = useCallback((acceptedFiles) => {
    setUploadFile(acceptedFiles[0]);
    setValue(name, acceptedFiles[0], {
      shouldValidate: true,
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    noDrag: true,
    onDrop,
  });

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <FormControl isInvalid={errors[name]} mb={0}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <HStack spacing={6}>
        <Input
          id={id}
          placeholder="Select menu file"
          value={watchOfPath?.path || ""}
          isDisabled
          borderColor={errors[name] ? "red.500" : "white.500"}
        />
        <Box {...getRootProps()}>
          <Input {...getInputProps()} />
          <Button variant="outline" w="170px">
            <Box mr={4} transform="rotate(90deg)">
              <ArrowLeftIcon stroke="currentColor" />
            </Box>
            Upload
          </Button>
        </Box>
      </HStack>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
}
