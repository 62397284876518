import { Text, HStack } from "@chakra-ui/react";
import { transformLanguageCode } from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import CHS from "./chakra.styles";

function LanguageIndicators({ selectedLanguage }) {
  const { languages } = useOptionsContext();

  return (
    <HStack spacing={2.5} sx={{ position: "absolute", top: 0, right: 5 }}>
      {transformLanguageCode(languages).map((language) => (
        <Text
          key={language.value.id}
          variant="language"
          size="language"
          sx={CHS.language(selectedLanguage.value.id, language.value.id)}
        >
          {language.label}
        </Text>
      ))}
    </HStack>
  );
}

export default LanguageIndicators;
