export default {
  variants: {
    solid: {
      color: "white.900",
    },
    brand: {
      background: "brand.gradient",
      color: "white.900",
      _hover: {
        filter: "brightness(80%)",
      },
      _active: {
        filter: "brightness(70%)",
      },
      _focus: {
        boxShadow: "none",
      },
    },
    // button`s variant for IconButton component
    iconButton: {
      _focus: {
        boxShadow: "none",
      },
    },
    brandRotate: {
      background: "brand.gradientRotate",
      color: "white.900",
      borderRadius: 3,
      _hover: {
        filter: "brightness(80%)",
      },
      _active: {
        filter: "brightness(70%)",
      },
    },
    outline: {
      background: "transparent",
      color: "blue.500",
      borderColor: "blue.500",
    },
    clear: {
      bgColor: "blue.500",
      color: "white.900",
      _disabled: {
        opacity: 1,
        color: "grey.500",
        bgColor: "white.500",
      },
      _hover: {
        _disabled: {
          bgColor: "white.500",
        },
      },
    },
    // button`s variant for table for options column (three dots)
    options: {
      color: "black.900",
      bgColor: "white.900",
      borderRadius: "xs",
      _focus: {
        boxShadow: "none",
      },
    },
    // button`s variant for table for selecting row per page
    tablePaginator: {
      bgColor: "white.900",
      borderRadius: "2xs",
    },
    // button`s variant for pagination: GoToFirst and GoToLast
    pagination: {
      bgColor: "white.500",
      borderRadius: "sm",
      mr: 2,
      _focus: {
        boxShadow: "none",
      },
      _disabled: {
        opacity: 1,
        cursor: "pointer",
      },
      _hover: {
        _disabled: {
          bgColor: "white.500",
        },
      },
    },
    // button`s variant for pagination: Previous and Next
    paginationInner: {
      bgColor: "black.900",
      borderRadius: "sm",
      mr: 2,
      _focus: {
        boxShadow: "none",
      },
      _disabled: {
        opacity: 1,
        cursor: "pointer",
        bgColor: "white.500",
      },
      _hover: {
        _disabled: {
          bgColor: "white.500",
        },
      },
    },
    languageButton: {
      width: "auto",
      height: "auto",
      fontWeight: "semibold",
      fontSize: "xs",
      color: "dark.300",
    },
  },
  sizes: {
    md: {
      h: 42,
      w: 35,
      borderRadius: 21,
      fontSize: "sm",
      fontWeight: "medium",
      lineHeight: "shorter",
    },
    xs: {
      minH: 6,
      px: 1.5,
    },
    // button`s size for table for options column (three dots)
    options: {
      h: 4,
      w: 1.25,
    },
    // button`s size for table for selecting row per page
    tablePaginator: {
      w: 4,
      h: 4,
    },
    // button`s size for IconButton component
    iconButton: {
      w: 5.5,
      h: 4,
      borderRadius: "none",
    },
    // button`s size for pagination
    pagination: {
      width: 8,
      height: 8,
    },
    actionsBtns: {
      width: 10,
      height: 10,
    },
  },
  defaultProps: {
    size: "md",
    variant: "brand",
  },
};
