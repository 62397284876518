import { gql } from "@apollo/client";

export const UPDATE_GALLERY = gql`
  mutation updateGallery($input: UpdateGallery) {
    updateGallery(input: $input) {
      id
      title
      modelName
      modelId
      description
      url
      categoryId
      date
      galleryPhotoCount
      galleryPhoto {
        id
        modelName
        modelId
        path
        order
        description
        altTag
      }
    }
  }
`;
