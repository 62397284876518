import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo } from "react";
import ToastContext from "contexts/toast/toast.context";
import Toast from "components/Toast";

import { useErrorsStore } from "graphql/links/error-link";

const toastConfig = {
  duration: 3000,
  isClosable: false,
};

export function ToastProvider({ children }) {
  const toast = useToast();
  const { hasError, error, date } = useErrorsStore((state) => state);

  const showToast = useCallback(
    ({
      status = "success",
      description = "",
      title = "",
      customIcon,
      ...rest
    }) => {
      toast({
        containerStyle: { margin: "0 8px 0 0" },
        ...toastConfig,
        render: () => (
          <Toast
            status={status}
            description={description}
            title={title}
            customIcon={customIcon}
          />
        ),
        ...rest,
      });
    },
    []
  );

  useEffect(() => {
    if (hasError) {
      showToast({
        status: "error",
        description: error?.message || error[0]?.message || "",
        ...toastConfig,
      });
    }
  }, [error, hasError, date]);

  return (
    <ToastContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={useMemo(() => ({ showToast }), [showToast])}
    >
      {children}
    </ToastContext.Provider>
  );
}
