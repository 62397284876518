import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  VStack,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import Zones from "pages/Venues/VenueManagement/FloorMap/Zones";
import { useFormContext } from "react-hook-form";

function HallManagement({ handleClose, hallId }) {
  const {
    formState: { isDirty },
  } = useFormContext();

  return (
    <VStack spacing={4} alignItems="flex-end">
      <TextFieldRHF
        id="hall_name"
        name="hall_name"
        inputType="text"
        label="Name"
      />
      <HStack spacing={6} w="full">
        <DropzoneFieldRHF
          width="188px"
          height="188px"
          name="map_image"
          label="Map Image"
          acceptStr="image/jpeg,image/png"
          containerStyles={{
            flex: 1,
          }}
        />
        <TextareaRHF
          name="short_description"
          height="188px"
          label="Short description"
          wrapperStyles={{ flex: 2 }}
        />
      </HStack>

      {hallId && (
        <Button minW="140px" type="submit">
          Save
        </Button>
      )}

      {hallId && (
        <FormControl>
          <FormLabel>Zones</FormLabel>
          <Zones hallId={hallId} />
        </FormControl>
      )}

      <Box w="full" pt={6} pb={10}>
        <Divider />
      </Box>

      <Flex
        w="full"
        justifyContent={hallId ? "center" : "space-between"}
        pb={7}
      >
        <Button variant="outline" minW="140px" onClick={handleClose}>
          Close
        </Button>

        {!hallId && (
          <Button minW="140px" type="submit" disabled={!isDirty}>
            Save
          </Button>
        )}
      </Flex>
    </VStack>
  );
}

export default HallManagement;
