import { gql } from "@apollo/client";

export const GET_ALL_OFFERS_ADMIN = gql`
  query getAllOffersAdmin(
    $limit: Int
    $offset: Int
    $sort: GetAllOffersSort
    $search: String
    $modelId: Int
    $modelName: ModelNameOffer
  ) {
    getAllOffersAdmin(
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
      modelId: $modelId
      modelName: $modelName
    ) {
      count
      rows {
        id
        type
        url
        isAddToSlider
        image
        geolocation
        price
        oldPrice
        currencyId
        createdBy
        categoryId
        createdAt
        photo
        isLiked
        placeDetails {
          address
        }
        status
        countUsedOffer
        isForInfluencerOnly
        forInfluencerCount
        influencerLevel
        tr {
          id
          languageId
          language {
            id
            code
            name
          }
          title
          description
          shortDescription
        }
        offerSEO {
          id
          languageId
          language {
            id
            code
            name
          }
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
