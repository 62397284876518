import { Text } from "@chakra-ui/react";
import CHS from "./chakra.styles";

export default function TableDescription({ text, ...rest }) {
  return (
    <Text sx={CHS.descriptionWrapper} {...rest}>
      {text}
    </Text>
  );
}
