export default {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 450,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};
