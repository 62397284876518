import Tag from "components/TableComponents/Tag";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import CreatableMultiSelect from "components/CreatableMultiSelect";
import {
  useAddArticleTag,
  useDeleteArticleTag,
} from "graphql/hooks/useMutations";
import { GET_ALL_ARTICLE_ADMIN } from "graphql/queries";
import { KEYBOARD_BUTTONS_KEYS } from "constants/constants";
import { useCreatableMultiSelectPopupStyle } from "hooks/useCreatableMultiSelectPopupStyle";
import { useToastContext } from "hooks/useToastContext";

export default function CustomTag({ data, rowId }) {
  const [isDisabled, setIsDisabled] = useState(false);

  const { showToast } = useToastContext();

  const [addArticleTag] = useAddArticleTag();
  const [deleteArticleTag] = useDeleteArticleTag();

  const [isOpen, setIsOpen] = useState(false);
  const menuToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const [multiSelectValue, setMultiSelectValue] = useState([]);

  useEffect(() => {
    setMultiSelectValue(
      data.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [data]);

  const [inputValue, setInputValue] = useState("");

  const [placeholder, setPlaceholder] = useState("Add new tag");

  useEffect(() => {
    if (inputValue) {
      setPlaceholder("");
    } else {
      setPlaceholder("Add new tag");
    }
  }, [inputValue]);

  const styles = useCreatableMultiSelectPopupStyle(null, placeholder);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    if (
      event.keyCode === KEYBOARD_BUTTONS_KEYS.ENTER ||
      event.keyCode === KEYBOARD_BUTTONS_KEYS.TAB
    ) {
      setIsDisabled(true);
      event.preventDefault();
      if (data.find((elem) => elem.name === inputValue)) {
        setIsDisabled(false);
        return;
      }
      addArticleTag({
        variables: {
          articleId: rowId,
          name: inputValue,
        },
        refetchQueries: [GET_ALL_ARTICLE_ADMIN],
        onCompleted: (response) => {
          if (response?.addArticleTag?.id) {
            setMultiSelectValue((prevState) => [
              ...prevState,
              {
                value: response?.addArticleTag?.id,
                label: response?.addArticleTag?.name,
              },
            ]);
            setInputValue("");
            setIsDisabled(false);
            showToast({
              description: "Tag has been successfully added",
            });
          }
        },
        onError: (error) => {
          setIsDisabled(false);
          console.log(error);
        },
      });
    }
  };

  const handleDelete = (value, info) => {
    deleteArticleTag({
      variables: {
        articleId: rowId,
        tagId: info?.removedValue?.value,
      },
      refetchQueries: [GET_ALL_ARTICLE_ADMIN],
      onCompleted: (response) => {
        if (response?.deleteArticleTag?.status === true) {
          setMultiSelectValue(value);
          showToast({
            description: "Tag has been successfully deleted",
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <Menu
      variant="tag"
      onOpen={menuToggle}
      onClose={menuToggle}
      placement="top"
      isLazy={true}
      lazyBehavior="unmount"
    >
      <MenuButton>
        <Tag tags={data} isOpen={isOpen} />
      </MenuButton>
      <MenuList>
        <CreatableMultiSelect
          name="tags"
          styles={styles}
          isDisabled={isDisabled}
          value={multiSelectValue}
          inputValue={inputValue}
          onInputChange={setInputValue}
          onKeyDown={handleKeyDown}
          onChange={handleDelete}
        />
      </MenuList>
    </Menu>
  );
}
