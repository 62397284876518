import { gql } from "@apollo/client";

export const EDIT_OWNERS = gql`
  mutation editOwners($companyId: Int!, $usersInfo: [EditOwners]) {
    editOwners(companyId: $companyId, usersInfo: $usersInfo) {
      id
      dateBirth
      ownerName
      companyShare
      passport
      companyId
      company {
        id
        image
        adress
        companyName
        cityId
        city {
          id
          name
          cityArea {
            id
            name
            isLiked
          }
          country {
            id
            code
            name
          }
        }
        licenceNumber
        photos {
          id
          photo
          companyId
        }
        owners {
          id
          dateBirth
          ownerName
          companyShare
          passport
          companyId
          company {
            id
            image
            adress
            companyName
            cityId
            city {
              id
              name
              country {
                id
                code
                name
              }
              cityArea {
                id
                name
                isLiked
              }
            }
            licenceNumber
            photos {
              id
              photo
              companyId
            }
            owners {
              id
              dateBirth
              ownerName
              companyShare
              passport
              companyId
              company {
                id
              }
              photos {
                id
                photo
                ownerId
              }
            }
          }
          photos {
            id
            photo
            ownerId
          }
        }
      }
      photos {
        id
        photo
        ownerId
      }
    }
  }
`;
