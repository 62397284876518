import { Box } from "@chakra-ui/react";
import SearchField from "components/Search/SearchField";
import SearchResult from "components/Search/SearchResult";
import SearchResultList from "components/Search/SearcResultList";
import { useState } from "react";
import { HEADER_SEARCH_RESULT } from "mock/HEADER_SEARCH";

export default function HeaderSearch() {
  const [isShowResult, setIsShowResult] = useState(false);

  const searchHandler = (e) => {
    if (e.target.value.length >= 3) {
      console.log("Поиск...");
      if (!isShowResult) setIsShowResult(true);
    } else {
      setIsShowResult(false);
    }
  };

  return (
    <Box position="relative">
      <SearchField w="480px" placeholder="Search" onChange={searchHandler} />
      {isShowResult && (
        <SearchResult>
          {HEADER_SEARCH_RESULT.map((searchBlock) => (
            <SearchResultList key={searchBlock.title} {...searchBlock} />
          ))}
        </SearchResult>
      )}
    </Box>
  );
}
