import { Box, Flex, HStack, Spinner } from "@chakra-ui/react";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import ModalButton from "components/ModalButton";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import { useVenueContext } from "hooks/useVenueContext.hook";
import { FormProvider } from "react-hook-form";
import Actions from "components/Stepper/Actions";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { ROWS_PER_PAGE } from "./table.config";

function FloorMap({
  isVenueId,
  methods,
  hallsLoading,
  completeStatus,
  hasData,
}) {
  const { venue } = useVenueContext();

  return (
    <>
      <Flex w="full" align="center" mb={7}>
        <FormProvider {...methods}>
          <TextFieldRHF
            name="search"
            inputType="text"
            inputPlaceholder="Search halls"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
            inputProps={{ maxW: "480px" }}
          />
        </FormProvider>

        <HStack spacing={4} width="100%" justifyContent="flex-end">
          <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          {isVenueId && (
            <ModalButton
              modalName="hallManagementModal"
              label="Add hall"
              minW="144px"
              data={{ id: venue.id }}
            />
          )}
        </HStack>
      </Flex>
      <Box w="full">
        {hallsLoading ? (
          <Flex alignItems="center" justify="center" h="100px">
            <Spinner size="xl" />
          </Flex>
        ) : hasData ? (
          <>
            <TableBody hasSorting />
            <TablePagination />
          </>
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </Box>
      <Actions
        handleNext={completeStatus}
        nextLabel="Finish"
        previousLabel="Back"
      />
    </>
  );
}

export default FloorMap;
