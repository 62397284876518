export const transformFeedsListSortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    field:
      {
        title: "title",
        author: "postAuthor",
        date: "publicationDate",
        views: "viewsCount",
        comments: "commentsCount",
        likes: "likesCount",
      }[state.id] || state.id,
    type: state.desc ? "DESC" : "ASC",
  };
};
