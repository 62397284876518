import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { RoundCancel } from "components/Icons";

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <RoundCancel
        fill="grey.500"
        w="14px"
        h="14px"
        _hover={{ fill: "red.500" }}
      />
    </components.MultiValueRemove>
  );
}

function CreatableMultiSelect(props) {
  const {
    name,
    styles,
    isDisabled,
    placeholder = "",
    value,
    inputValue,
    onInputChange,
    onKeyDown,
    onChange,
    isClearable = false,
    menuIsOpen = false,
    isMulti = true,
  } = props;

  // value: [{value: 28, label: 'tag`s label'}, .......]

  return (
    <CreatableSelect
      name={name}
      styles={styles}
      isDisabled={isDisabled}
      placeholder={placeholder}
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      onChange={onChange}
      components={{ DropdownIndicator: null, MultiValueRemove }}
      menuIsOpen={menuIsOpen}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  );
}

export default CreatableMultiSelect;
