import TicketsAndVouchers from "pages/Venues/VenueManagement/TicketsAndVouchers";

function TicketsAndVouchersContainer({ children }) {
  return (
    <>
      <TicketsAndVouchers />
      {children}
    </>
  );
}

export default TicketsAndVouchersContainer;
