const overrrides = {
  "& [aria-current='step']:first-of-type span": {
    color: "white.900",
  },
  "& [aria-current='step']:last-of-type p": {
    color: "black.900",
  },
  "& [data-highlighted] + div p": {
    color: "green.500",
  },
  "&:last-of-type > div:last-of-type": {
    borderWidth: 0,
  },
};

export default {
  overrrides,
};
