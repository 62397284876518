import * as yup from "yup";
import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";
import { multiLangValidate } from "utils/multiLangValidate";

const schema = (languages) => {
  const multiLanguageObjectValidation = languages.reduce((acc, language) => {
    acc[language.code] = yup.string();
    return acc;
  }, {});

  const multiLangValidateTitle = (depsArr) =>
    languages.reduce((acc, language) => {
      acc[language.code] =
        language?.id === DEFAULT_ENGLISH_LANGUAGE_ID
          ? yup.string().required("This field is required!")
          : yup
              .string()
              .test("check-deps", "This field is required!", (val, ctx) => {
                const values = ctx.from[1].value;

                const checkFields = depsArr?.reduce((fields, current) => {
                  if (cleanTextFromHTML(values[current][language.code].text)) {
                    fields.push(current);
                  }
                  return fields;
                }, []);

                if (!cleanTextFromHTML(val) && checkFields.length) {
                  return false;
                }
                return true;
              });

      return acc;
    }, {});

  return yup.object().shape({
    title: yup.object().shape(multiLangValidateTitle(["content"])),
    main_text: yup.object().shape(multiLanguageObjectValidation),

    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),

    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),

    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),

    url: yup.string().required(),

    content: yup.mixed().when(["language"], (language, _ctx, third) => {
      const lang = language.value.code;
      if (third.originalValue?.[lang]?.body) {
        return yup.object().shape({
          [lang]: yup.object().shape({
            header: yup.object().shape({
              title: yup.string().required(),
              text: yup.string().test((value) => cleanTextFromHTML(value)),
              description: yup.string().required(),
            }),
            body: yup.object().shape({
              team: yup.object().shape({
                text: yup.string().test((value) => cleanTextFromHTML(value)),
                title: yup.string().required(),
                description: yup.string().required(),
                peoples: yup.array().of(
                  yup.object().shape({
                    name: yup.string().required(),
                    image: yup.string().required(),
                    position: yup.string().required(),
                  })
                ),
              }),
              story: yup.object().shape({
                text: yup.string().test((value) => cleanTextFromHTML(value)),
                title: yup.string().required(),
              }),
              values: yup.object().shape({
                text: yup.string().test((value) => cleanTextFromHTML(value)),
                title: yup.string().required(),
              }),
            }),
          }),
        });
      }
      return yup.object().shape({
        [lang]: yup.object().shape({
          text: yup.string().test((value) => cleanTextFromHTML(value)),
        }),
      });
    }),
  });
};

export default { schema };
