import { DEFAULT_PAGINATION_STATE } from "constants/constants";
import { useReducer } from "react";
import {
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  paginationReducer,
  TOTAL_COUNT_CHANGED,
} from "reducers";

export const usePaginationReducer = ({ paginationState } = {}) => {
  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] = useReducer(
    paginationReducer,
    { ...DEFAULT_PAGINATION_STATE, ...paginationState }
  );

  const offsetData = queryPageIndex * queryPageSize;
  const pagesQuantity = Math.ceil(totalCount / queryPageSize);

  const onPageChanged = ({ pageCount, pageIndex }) => {
    dispatch({
      type: PAGE_CHANGED,
      payload: pageCount > pageIndex ? pageIndex : 0,
    });
  };

  const onPageSizeChanged = ({ pageSize, gotoPage }) => {
    dispatch({
      type: PAGE_SIZE_CHANGED,
      payload: pageSize,
    });
    gotoPage(0);
  };

  const onTotalCountChanged = (dataCount) => {
    if (dataCount) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: dataCount,
      });
    }
  };

  return {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  };
};
