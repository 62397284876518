import { Text, Flex, HStack, IconButton } from "@chakra-ui/react";
import { EditIcon, TableIcon, TrashIcon } from "components/Icons";
import CHS from "./chakra.styles";

function ZoneRow({
  zoneName,
  sequenceNumber = 1,
  handleRemove,
  handleEdit,
  handleManageTables,
}) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      {sequenceNumber} -
      <Text mr="auto" ml="2">
        {zoneName}
      </Text>
      <HStack spacing={4}>
        <IconButton
          variant="outline"
          sx={CHS.icon}
          onClick={handleEdit}
          aria-label="edit zone"
        >
          <EditIcon fill="blue.500" />
        </IconButton>
        <IconButton
          variant="outline"
          sx={CHS.icon}
          aria-label="add/edit tables"
          onClick={handleManageTables}
        >
          <TableIcon />
        </IconButton>
        <IconButton
          aria-label="remove zone"
          variant="outline"
          sx={CHS.icon}
          borderColor="red.500"
          onClick={handleRemove}
        >
          <TrashIcon fill="red.500" />
        </IconButton>
      </HStack>
    </Flex>
  );
}

export default ZoneRow;
