import { gql } from "@apollo/client";

export const GET_EVENT_SECTOR_ROWS_BY_SECTOR_ID = gql`
  query getEventSectorRowsByEventSectorId($eventSectorId: ID!) {
    getEventSectorRowsByEventSectorId(eventSectorId: $eventSectorId) {
      count
      rows {
        id
        placeCount
        eventSectorRowPlace {
          id
          place
        }
      }
    }
  }
`;
