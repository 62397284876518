import { useLocation } from "react-router-dom";
import { SERVICE_ROUTES } from "constants/constants";

const useCheckServiceRoutes = () => {
  const { pathname } = useLocation();

  return SERVICE_ROUTES.includes(pathname);
};

export default useCheckServiceRoutes;
