export const transformCreateStaticPage = (formData, languages) => {
  if (formData) {
    const multiTextformData = languages.reduce((lang, current) => {
      if (
        formData.title?.[current.code] &&
        formData.main_text?.[current.code]
      ) {
        lang.push({
          title: formData.title?.[current.code] || "",
          content: JSON.stringify({
            text: formData.main_text?.[current.code] || "",
          }),
          languageId: current?.id,
        });
      }
      return lang;
    }, []);

    const multiSeoContent = languages.reduce((lang, current) => {
      if (
        formData.meta_title?.[current.code] &&
        formData.meta_description?.[current.code]
      ) {
        lang.push({
          metaTitle: formData.meta_title?.[current.code] || "",
          metaDescription: formData.meta_description?.[current.code] || "",
          languageId: current?.id,
        });
      }
      return lang;
    }, []);

    return {
      url: formData.url,
      status: formData.status.value,
      content: multiTextformData,
      seoContent: multiSeoContent,
    };
  }
  return {};
};
