import { Flex } from "@chakra-ui/react";
import { ErrorIcon } from "components/Icons";
import Heading from "components/Heading";
import { useFormContext } from "react-hook-form";

function ValidationNotification({ children, icon = true, ...rest }) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Flex align="center" justify="center" mb={4} {...rest}>
      {Object.keys(errors).length > 0 && (
        <>
          {icon && <ErrorIcon h={2} mt="px" mr={0.75} />}
          <Heading size="labelThin" variant="error">
            {children}
          </Heading>
        </>
      )}
    </Flex>
  );
}
export default ValidationNotification;
