import { getAuthorFormat } from "utils/getAuthorFormat";
import { getAuthor, getParent } from "utils/getReportsData";

export const transformAllReportsData = (data = []) => {
  if (data) {
    return data?.map((report) => {
      return {
        id: report.id,
        text: report.text,
        type: report.modelName,
        parent: getParent(report),
        author: getAuthor(report),
        reportedBy: getAuthorFormat(report.reportedBy),
        category: report.category ?? "—",
        status: report.status,
      };
    });
  }
  return [];
};
