export const transformAttractionGeneralInfoData = ({
  formData,
  languages,
  isCreate,
}) => {
  if (formData) {
    const restrictionIds = formData.restrictions?.map((restriction) =>
      Number(restriction.value)
    );

    const serviceIds = formData.services?.map((service) =>
      Number(service.value)
    );

    const multiLanguageContent = languages.reduce((lang, current) => {
      if (
        formData.title?.[current.code] &&
        formData.description?.[current.code]
      ) {
        lang.push({
          languageId: Number(current?.id),
          title: formData.title?.[current.code],
          description: formData.description?.[current.code],
        });
      }
      return lang;
    }, []);

    const multiLanguageSeo = languages.reduce((lang, current) => {
      if (
        formData.meta_title?.[current.code] &&
        formData.meta_description?.[current.code]
      ) {
        lang.push({
          languageId: Number(current?.id),
          metaTitle: formData.meta_title?.[current.code],
          metaDescription: formData.meta_description?.[current.code],
        });
      }

      return lang;
    }, []);

    const oldPhotoArr =
      formData.slider_photo
        ?.filter((photo) => !photo.path)
        .map((photoObj) =>
          photoObj.preview.replace(`${process.env.REACT_APP_API_URL}/`, "")
        ) || [];
    const newPhotoArr =
      formData.slider_photo?.filter((photo) => photo.path) || [];

    const reqObj = {
      url: formData.url,
      isAddToSlider: formData.isAddToSlider,
      geolocation: formData.coordinates?.place_id,
      status: formData.status?.value,
      categoryId: Number(formData.attractionCategory?.value),
      content: multiLanguageContent,
      restrictions: restrictionIds,
      services: serviceIds,
      attractionSeoAdmin: multiLanguageSeo,
      photo: isCreate ? newPhotoArr : oldPhotoArr,
      locationId: Number(formData.location?.value),
    };

    if (formData.main_img?.preview && formData.main_img?.path) {
      Object.assign(reqObj, { image: formData.main_img });
    }

    if (newPhotoArr.length && !isCreate) {
      Object.assign(reqObj, { addPhoto: newPhotoArr });
    }

    if (formData.id) {
      return {
        ...reqObj,
        id: formData.id,
      };
    }

    return reqObj;
  }
  return {};
};
