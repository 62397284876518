import { getDayNameSwitch } from "utils/getDayNameSwitch";

export const transformAttractionSchedules = (data = []) => {
  if (data) {
    return data?.map((schedule) => {
      let color = "";

      if (schedule.status === "Enabled") {
        color = "black.500";
      } else {
        color = "dark.300";
      }

      return {
        day: { text: getDayNameSwitch(schedule?.dayOfWeek), color },
        start: { text: schedule.startTime, color },
        end: { text: schedule.stopTime, color },
        duration: { text: schedule.upTime, color },
        status: { text: schedule.status, color },
      };
    });
  }
  return [];
};
