import { Text } from "@chakra-ui/react";

export default function NoContentMessage({
  text = "No content found",
  ...rest
}) {
  return (
    <Text sx={{ textAlign: "center" }} fontSize={20} {...rest}>
      {text}
    </Text>
  );
}
