import { Icon } from "@chakra-ui/react";

export default function AcceptIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="m6.5 11.984 3.7 3.7 8.187-8.187"
        stroke="#ffffff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
