import { gql } from "@apollo/client";

export const CREATE_ARTICLE = gql`
  mutation CreateArticle($info: ArticleInput!) {
    createArticle(info: $info) {
      id
      url
      showOnMain
      viewsCount
      status
      publicatedAt
      authorName
      authorAvatar
      tags {
        id
        name
        viewsCount
      }
      tr {
        title
        language {
          id
        }
      }
    }
  }
`;
