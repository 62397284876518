export const modalReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_MODAL_STATE":
      return {
        ...state,
        [action.payload.modalName]: {
          ...action.payload.modalData,
          isOpen: true,
        },
      };
    case "OPEN_MODAL":
      return {
        ...state,
        [action.payload.modalName]: {
          ...action.payload.modalData,
          isOpen: true,
        },
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        [action.payload.modalName]: { isOpen: false },
      };
    default:
      return state;
  }
};
