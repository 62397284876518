import { Flex, Box } from "@chakra-ui/react";

import { LinkIcon } from "components/Icons";

export default function URLHeader({ title }) {
  return (
    <Flex
      justify="center"
      align="center"
      display="inline-flex"
      position="relative"
      paddingLeft={5}
    >
      <LinkIcon
        width="16px"
        height="17px"
        position="absolute"
        top="50%"
        left={0}
        transform="translateY(-50%)"
      />
      <Box>{title}</Box>
    </Flex>
  );
}
