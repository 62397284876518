import { Children, isValidElement, cloneElement, useCallback } from "react";
import { HStack, Flex, Text } from "@chakra-ui/react";
import CHS from "./chakra.styles";

export function ToggleItem({ children, isActive, onClick, ...rest }) {
  return (
    <Flex
      bg={isActive ? "blue.500" : "white.500"}
      onClick={onClick}
      _hover={{
        bg: isActive ? "blue.600" : "grey.500",
      }}
      {...CHS.toggleItem}
      {...rest}
    >
      <Text fontSize="xs" color={isActive ? "white.900" : "dark.300"}>
        {children}
      </Text>
    </Flex>
  );
}

const toggleArrayItem = (array, item) => {
  if (array.includes(item)) return array.filter((el) => el !== item);
  return [...array, item];
};

function ToggleGroup({ children, value = [], onChange }) {
  const handleToggleClick = useCallback(
    (e) => {
      const targetValue = e.currentTarget?.dataset.value;
      if (targetValue) onChange(toggleArrayItem(value, targetValue));
    },
    [value]
  );

  const toggleChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        isActive: value.includes(child.props["data-value"]),
        onClick: handleToggleClick,
      });
    }
    return child;
  });

  return <HStack spacing={6}>{toggleChildren}</HStack>;
}

export default ToggleGroup;
