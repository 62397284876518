import { Heading as ChakraHeading } from "@chakra-ui/react";
import { useMemo } from "react";

function Heading(props) {
  const { children, size, variant, ...rest } = props;

  const showAs = useMemo(() => {
    if (["h1", "h2", "h3"].includes(size)) {
      return size;
    }
    return "div";
  }, [size]);

  return (
    <ChakraHeading as={showAs} size={size} variant={variant} {...rest}>
      {children}
    </ChakraHeading>
  );
}
export default Heading;
