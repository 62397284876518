import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableMultiSelect from "components/CreatableMultiSelect";
import { KEYBOARD_BUTTONS_KEYS } from "constants/constants";
import { useCreatableMultiSelectInputStyle } from "hooks/useCreatableMultiSelectInputStyle";

export default function CreatableMultiSelectRHF(props) {
  const { name, label, placeholder, onChangeHandler, errorMessage } = props;

  const [inputValue, setInputValue] = useState("");

  const methods = useFormContext();
  const { setValue, getValues } = methods;

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    if (
      event.keyCode === KEYBOARD_BUTTONS_KEYS.ENTER ||
      event.keyCode === KEYBOARD_BUTTONS_KEYS.TAB
    ) {
      event.preventDefault();
      const tagsValue = getValues(name);
      setValue(name, [
        ...tagsValue,
        { value: new Date().getTime(), label: inputValue },
      ]);
      setInputValue("");
    }
  };

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useCreatableMultiSelectInputStyle(errors[name]);

  const handleChange = (hookChangeFunc) => (option) => {
    hookChangeFunc(option);
    onChangeHandler?.(option);
  };

  return (
    <FormControl isInvalid={errors[name]}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <InputGroup size="lg">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CreatableMultiSelect
              name={name}
              styles={styles}
              placeholder={placeholder}
              value={value}
              inputValue={inputValue}
              onInputChange={setInputValue}
              onKeyDown={handleKeyDown}
              onChange={handleChange(onChange)}
            />
          )}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
