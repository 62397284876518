import { Switch } from "@chakra-ui/react";
import { useSwitchLocationIsActive } from "graphql/hooks/useMutations";

export default function isLocationActive({ rowId, active }) {
  const [switchLocationIsActive] = useSwitchLocationIsActive();
  const changeHandler = (event) => {
    switchLocationIsActive({
      variables: {
        id: rowId,
        isActive: event.target.checked,
      },
    });
  };

  return <Switch onChange={changeHandler} isChecked={active} />;
}
