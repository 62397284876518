import { gql } from "@apollo/client";

export const GET_ALL_LOCATIONS = gql`
  query GetAllLocations($options: GetAllLocations) {
    getAllLocations(options: $options) {
      count
      rows {
        id
        title
        image
        createdBy
        tr {
          language {
            id
            code
            name
          }
          languageId
          title
          description
        }
        city {
          id
          name
          country {
            id
            name
          }
          cityArea {
            id
            name
          }
        }
        address
        isActive
      }
    }
  }
`;
