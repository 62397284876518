import { Icon } from "@chakra-ui/react";

export default function GoToPreviousIcon({ stroke, ...rest }) {
  return (
    <Icon viewBox="0 0 6 8" {...rest}>
      <path
        d="M4.40234 0.77832L1.18177 3.99888L4.40234 7.21945"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
