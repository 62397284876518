import { useContext, useEffect } from "react";
import stepsContext from "contexts/steps/steps.context";
import { useAttractionContext } from "hooks/useAttractionContext";
import {
  transformCategoriesOptions,
  transformCityOptions,
  transformLanguageSelect,
  transformLanguagesToCodeList,
  transformLocationOptions,
} from "connectors";
import { FormProvider, useForm } from "react-hook-form";
import { transformRestrictionsOptions } from "connectors/transformRestrictonsOptions";
import { transformServicesOptions } from "connectors/transformServicesOptions";
import { onLanguageChange } from "utils/onLanguageChange";
import { allCategories } from "constants/constants";
import { useOptionsContext } from "hooks/useOptionsContext";
import GeneralInformationAttractions from "pages/Attractions/AttractionsManagement/GeneralInformation";
import {
  useAddAttractionGeneralInfo,
  useUpdateAttractionGeneralInfo,
} from "graphql/hooks/useMutations";
import { transformStatusOptions } from "connectors/transformStatusOptions";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ADD_ATTRACTION_GENERAL_INFO_SCHEMA,
  EDIT_ATTRACTION_GENERAL_INFO_SCHEMA,
} from "schemas";
import useGetRestrictions from "hooks/useGetRestrictions.hook";
import useGetServices from "hooks/useGetServices.hook";
import useGetAttractionStatuses from "hooks/useGetAttractionStatuses.hook";
import { useGetAllLocations } from "graphql/hooks/useQueries";
import { transformAttractionGeneralInfoData } from "connectors/Attractions/transformAttractionGeneralInfoData";
import { transformReceivedAttractionGeneralInfo } from "connectors/Attractions/transformReceivedAttractionGeneralInfo";

function GeneralInformationContainerAttractions() {
  useGetRestrictions();
  useGetServices();
  useGetAttractionStatuses();

  const { attraction, getGeneralInfo, updateAttractionState, getAttractionId } =
    useAttractionContext();
  const [addAttractionGeneralInfo] = useAddAttractionGeneralInfo();
  const [updateAttractionGeneralInfo] = useUpdateAttractionGeneralInfo();

  const { data: allLocationsData } = useGetAllLocations({
    variables: {
      options: {
        isActive: true,
      },
    },
  });

  const {
    countries,
    languages,
    restrictions,
    services,
    categories,
    attractionStatuses,
  } = useOptionsContext();
  const { nextStep } = useContext(stepsContext);

  const methods = useForm({
    defaultValues: getGeneralInfo(),
    resolver: yupResolver(
      getAttractionId()
        ? EDIT_ATTRACTION_GENERAL_INFO_SCHEMA(languages)
        : ADD_ATTRACTION_GENERAL_INFO_SCHEMA(languages)
    ),
  });

  const [selectedLanguage, selectedCountry] = methods.watch([
    "language",
    "country",
  ]);

  const categoryOptions = transformCategoriesOptions(
    allCategories.attractionCategory,
    categories
  );

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  const translatedValues = transformLanguagesToCodeList(languages).reduce(
    (acc, code) => {
      acc[code] = "";
      return acc;
    },
    {}
  );

  const onSubmit = (formData) => {
    const attractionId = getAttractionId();
    if (attractionId) {
      Object.assign(formData, { id: attractionId });
      updateAttractionGeneralInfo({
        variables: {
          input: transformAttractionGeneralInfoData({ formData, languages }),
        },
        onCompleted: (response) => {
          updateAttractionState(
            transformReceivedAttractionGeneralInfo({
              responseData: response?.updateAttractionGeneralInfo,
              translatedValues,
              categoryOptions,
              languages,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Attraction under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Attraction under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      addAttractionGeneralInfo({
        variables: {
          input: transformAttractionGeneralInfoData({
            formData,
            languages,
            isCreate: true,
          }),
        },
        onCompleted: (response) => {
          updateAttractionState(
            transformReceivedAttractionGeneralInfo({
              responseData: response?.createAttractionGeneralInfo,
              translatedValues,
              categoryOptions,
              languages,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Attraction under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Attraction under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (attraction.id) {
      methods.reset(getGeneralInfo());
    }
  }, [attraction.id]);

  return (
    <FormProvider {...methods}>
      <GeneralInformationAttractions
        onSubmit={methods.handleSubmit(onSubmit)}
        citiesOptions={transformCityOptions(selectedCountry, countries)}
        restrictionOptions={transformRestrictionsOptions(restrictions)}
        statusOptions={transformStatusOptions(attractionStatuses)}
        languageOptions={transformLanguageSelect(languages)}
        serviceOptions={transformServicesOptions(services)}
        languageChangeHandler={languageChangeHandler}
        categoriesOptions={categoryOptions}
        selectedLanguage={selectedLanguage}
        locationOptions={transformLocationOptions(
          allLocationsData?.getAllLocations?.rows
        )}
      />
    </FormProvider>
  );
}

export default GeneralInformationContainerAttractions;
