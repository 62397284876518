import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { styles } from "./chakra.styles";

export default function SwitchRHF(props) {
  const {
    sx,
    name,
    label,
    isDisabled,
    isInvalid,
    isReadOnly,
    vertical = false,
    labelColor,
  } = props;

  const style = styles({ vertical, labelColor });

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const changeHandler = (hookChangeFunc) => (e) => {
    hookChangeFunc(e.target.checked);
  };

  return (
    <FormControl sx={{ ...style.switchControl, ...sx }}>
      <FormLabel
        htmlFor={name}
        sx={style.switchLabel}
        color={errors[name] ? "red.500" : "black.500"}
      >
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value = false } }) => (
          <Switch
            id={name}
            onChange={changeHandler(onChange)}
            isChecked={value}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            isReadOnly={isReadOnly}
          />
        )}
      />
    </FormControl>
  );
}
