import { gql } from "@apollo/client";

export const GET_ALL_EVENTS = gql`
  query GetAllEventsAdmin(
    $limit: Int
    $sort: GetAllEventAdminSort
    $search: String
    $offset: Int
    $status: EventStatus
    $categoryId: Int
  ) {
    getAllEventsAdmin(
      limit: $limit
      sort: $sort
      search: $search
      offset: $offset
      status: $status
      categoryId: $categoryId
    ) {
      count
      rows {
        id
        url
        image
        geolocation
        createdAt
        isCompleted
        category {
          id
          modelName
          name
        }
        photo
        status
        placeDetails {
          address
        }
        services {
          name
        }
        eventVenue {
          rank
          venueCategory {
            name
          }
        }
        tr {
          title
          language {
            id
            code
          }

          title
          description
          shortDescription
        }
      }
    }
  }
`;
