import { gql } from "@apollo/client";

export const GET_AREAS_BY_HALL_ID = gql`
  query getAreasByHallId($options: GetAreasByHallId!) {
    areasData: getAreasByHallId(options: $options) {
      count
      rows {
        id
        area
        venueHallId
        ticketType
        venueAreaTable {
          id
          table
          venueAreaId
          seats
        }
      }
    }
  }
`;
