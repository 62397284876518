import NumberFormat from "react-number-format";
import { forwardRef, useCallback } from "react";
import TextField from "components/TextField";
import { FormControl, FormLabel } from "@chakra-ui/react";

const NumberFormatInput = forwardRef(
  ({ value, onChange, label, hasError, wrapperProps, id, ...rest }, ref) => {
    const internalOnChange = useCallback(
      (data) => {
        return onChange(data.floatValue || "");
      },
      [onChange]
    );

    return (
      <FormControl isInvalid={hasError} {...wrapperProps}>
        {label ? <FormLabel htmlFor={id}>{label}</FormLabel> : null}
        <NumberFormat
          ref={ref}
          id={id}
          value={value}
          onValueChange={internalOnChange}
          allowedDecimalSeparators={[",", "."]}
          decimalSeparator="."
          thousandSeparator=" "
          isNumericString
          customInput={TextField}
          hasError={hasError}
          {...rest}
        />
      </FormControl>
    );
  }
);

export default NumberFormatInput;
