import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import ModalButton from "components/ModalButton";
import SeoListContainer from "containers/seo/seoList.container";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

const addSeoButton = (
  <ModalButton
    modalName="seoCreateEditModal"
    label="Add SEO"
    w={44}
    data={{ isEditMode: false }}
  />
);

function Seo() {
  const { accessCreateSeo, accessReadSeo } = getAccessScopesControl();

  return (
    <Box px={10} py={6} w="full">
      <PageHeader button={accessCreateSeo ? addSeoButton : null} mb={6}>
        SEO
      </PageHeader>
      <SeoListContainer accessViewSeo={accessReadSeo} />
    </Box>
  );
}
export default Seo;
