import { Box, Center, Flex } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import CheckboxRHF from "components/react-hook-form/CheckboxRHF";
import Heading from "components/Heading";
import ChakraLink from "components/Link";
import Button from "components/Button";
import ValidationNotification from "components/ValidationNotification";

function Login(props) {
  const { onSubmit } = props;
  const { handleSubmit } = useFormContext();

  return (
    <Center h="100vh" w="full" bg="brand.gradient">
      <Box layerStyle="box" width="500px">
        <Heading mb="6">Welcome! Please Login to continue</Heading>
        <ValidationNotification>
          Invalid email or password!
        </ValidationNotification>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldRHF
            id="email"
            name="email"
            label="Email address"
            marginBottom={4}
          />
          <TextFieldRHF
            id="password"
            name="password"
            inputType="password"
            label="Password"
            marginBottom={4}
            inputRightElement
          />
          <Flex align="center" justify="space-between" mb="12">
            <CheckboxRHF name="rememberMe">Remember me</CheckboxRHF>
            <ChakraLink path="recover-password">Forgot password</ChakraLink>
          </Flex>
          <Center>
            <Button type="submit">Login</Button>
          </Center>
        </form>
      </Box>
    </Center>
  );
}

export default Login;
