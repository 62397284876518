import Dropzone from "components/Dropzone";
import { useFormContext, Controller } from "react-hook-form";
import { getFieldName } from "utils/getFieldName";

export default function DropzoneFieldRHF({ name, onChangeCallback, ...rest }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      render={() => {
        const handleChange = (file) => {
          // Кастыль для Firefox для загрузки файла из FileDialog
          setTimeout(() => {
            setValue(name, file, { shouldValidate: true });
            onChangeCallback?.(file);
          }, 0);
        };
        return (
          <Dropzone
            {...rest}
            hasError={getFieldName(errors, name)}
            /* Ugly decision, but maybe we will refactor it in the future */
            value={getValues(name)}
            onChange={handleChange}
          />
        );
      }}
      control={control}
      name={name}
    />
  );
}
