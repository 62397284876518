import Tabs from "components/Tabs";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import getActiveTab from "utils/tabs-utils";
import { TabsConfig } from "./tabs.config";

const defaultTab = 0;

function TicketsAndVouchers() {
  const [params] = useSearchParams();

  const getDataFromUrl = useMemo(() => {
    const selectItem = getActiveTab(TabsConfig, params.get("tab"));
    let index = defaultTab;
    if (selectItem?.index !== undefined) {
      index = selectItem.index;
    }
    return { label: selectItem?.label, index };
  }, [params]);

  return (
    <Tabs
      tabsData={TabsConfig}
      defaultTab={defaultTab}
      index={getDataFromUrl.index}
      w="full"
      align="center"
      sx={{ "& .chakra-tabs__tablist": { borderBottom: 0 } }}
    />
  );
}

export default TicketsAndVouchers;
