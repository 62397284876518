export const transformEditSeo = ({ formData, languages }) => {
  if (formData) {
    const multiSeoContent = languages.reduce((lang, current) => {
      if (
        formData.metaTitle?.[current.code] &&
        formData.metaKeywords?.[current.code] &&
        formData.metaDescription?.[current.code]
      ) {
        lang.push({
          languageId: current?.id,
          metaTitle: formData.metaTitle?.[current.code] || "",
          metaKeywords: formData.metaKeywords?.[current.code] || "",
          metaDescription: formData.metaDescription?.[current.code] || "",
        });
      }
      return lang;
    }, []);

    return {
      url: formData.url,
      datas: multiSeoContent,
    };
  }
  return {};
};
