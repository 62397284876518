import { gql } from "@apollo/client";

export const CREATE_NEW_USER = gql`
  mutation createNewUser($input: CreateUserInput) {
    createUserCompany(input: $input) {
      id
      firstName
      photo
      lastName
      gender
      phone
      email
      cityId
      roleId
      role {
        id
        name
      }
    }
  }
`;
