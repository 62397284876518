import TimePicker from "components/TimePicker";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormLabel } from "@chakra-ui/react";

function TimePickerRHF(props) {
  const { name, wrapperProps, label, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl
      isInvalid={errors[name]}
      sx={{
        "& .react-datepicker-popper": { zIndex: 10 },
      }}
      {...wrapperProps}
    >
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const handleChange = (date) => {
            field.onChange(date);
          };

          return (
            <TimePicker
              {...field}
              {...rest}
              hasError={errors[name]}
              selected={field.value}
              onChange={handleChange}
            />
          );
        }}
      />
    </FormControl>
  );
}

export default TimePickerRHF;
