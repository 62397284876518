import { Icon } from "@chakra-ui/react";

export default function ViewsIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 16 17" {...props} fill="none">
      <g stroke="#00AEEF">
        <path d="M.667 8.278S3.333 2.944 8 2.944s7.333 5.334 7.333 5.334S12.667 13.61 8 13.61.667 8.278.667 8.278Z" />
        <path d="M8 10.944a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Z" />
      </g>
    </Icon>
  );
}
