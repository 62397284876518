import { Icon } from "@chakra-ui/react";

export default function GoToFirstIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 8 10" {...props}>
      <path
        d="M6.28571 1.29913L2.62695 4.95789L6.28571 8.61747"
        stroke="#CACACA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.57007 1.29913V8.61727"
        stroke="#CACACA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
}
