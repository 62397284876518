import { transformCategoriesOptions } from "connectors/transformCategoriesOptions";
import { allCategories } from "constants/constants";
import { transformReceivedAttractionGeneralInfo } from "./transformReceivedAttractionGeneralInfo";

import { transformReceivedUpdateAttractionsGallery } from "./transformReceivedUpdateAttractionsGallery";

export const transformFullAttraction = ({
  responseData,
  translatedValues,
  categories,
  languages,
}) => {
  const categoryOptions = transformCategoriesOptions(
    allCategories.attractionCategory,
    categories
  );

  if (responseData) {
    const newContext = {
      id: responseData.id,
      generalInfo: transformReceivedAttractionGeneralInfo({
        responseData,
        translatedValues,
        categoryOptions,
        languages,
      })?.generalInfo,
    };

    if (responseData.gallery) {
      Object.assign(newContext, {
        galleryAttraction: transformReceivedUpdateAttractionsGallery({
          responseData: responseData.gallery,
          allCategories: allCategories.galleryCategory,
          categories,
        })?.galleryAttraction,
      });
    }

    return newContext;
  }
  return {};
};
