const optionIcon = {
  w: "20px",
  h: "20px",
  fill: "dark.500",
  _groupHover: { fill: "blue.500" },
};

const addressSplit = {
  w: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const eyeIcon = {
  w: "20px",
  h: "20px",
  fill: "white.500",
  stroke: "dark.500",
  _groupHover: { stroke: "blue.500", fill: "blue.300" },
};

export default {
  optionIcon,
  addressSplit,
  eyeIcon,
};
