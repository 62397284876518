import {
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Button,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import { AddIcon, ArrowLeftIcon, EditIcon, TrashIcon } from "components/Icons";
import { useModalContext } from "hooks/useModalContext";
import { useGetTablesByAreaId } from "graphql/hooks/useQueries";
import { useRemoveVenueAreaTable } from "graphql/hooks/useMutations";
import { transformVenueTableFormData } from "connectors";
import CHS from "./chakra.styles";

function ZoneTablesList() {
  const {
    closeModal: closeCurrentModal,
    modal: { hallName, zoneId, zoneName, hallId },
  } = useModalContext("zoneTablesManagementModal");

  const { data: areaTables } = useGetTablesByAreaId({
    variables: {
      options: {
        venueAreaId: zoneId,
      },
    },
    skip: !zoneId,
  });

  const [removeTable] = useRemoveVenueAreaTable({
    variables: {
      zoneId,
    },
  });

  const { openModal: openAddEditZoneTablesModal } = useModalContext(
    "addEditZoneTablesModal"
  );

  const { openModal: openHallManagementModal } = useModalContext(
    "hallManagementModal"
  );

  const handleAddTableClick = () => {
    openAddEditZoneTablesModal({
      hallName,
      zoneName,
      zoneId,
    });
    closeCurrentModal();
  };

  const handleEdit = (table) => () => {
    openAddEditZoneTablesModal({
      defaultValues: transformVenueTableFormData(table),
      hallName,
      zoneName,
      zoneId,
      tableId: table.id,
    });
    closeCurrentModal();
  };

  const handleRemove = (id) => () => {
    removeTable({
      variables: {
        id,
      },
    });
  };

  const backToHallManagement = () => {
    openHallManagementModal({ hallId });
    closeCurrentModal();
  };

  return (
    <Box w="full" pt={15}>
      <TableContainer w="full">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th {...CHS.Th} width={175}>
                Tables
              </Th>
              <Th {...CHS.Th}>Tables count</Th>
              <Th {...CHS.Th}>Seats per table</Th>
              <Th {...CHS.Th} visibility="hidden">
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {areaTables?.zoneTables.rows.map((field) => {
              return (
                <Tr key={field.id}>
                  <Td {...CHS.Td}>
                    <Text isTruncated width={150}>
                      {field.table}
                    </Text>
                  </Td>
                  <Td {...CHS.Td}>{field.amount}</Td>
                  <Td {...CHS.Td}>{field.seats}</Td>
                  <Td {...CHS.TdAction}>
                    <HStack spacing={4} justifyContent="flex-end">
                      <IconButton
                        variant="outline"
                        sx={CHS.icon}
                        onClick={handleEdit(field)}
                        aria-label="edit zone"
                      >
                        <EditIcon fill="blue.500" />
                      </IconButton>

                      <IconButton
                        aria-label="remove zone"
                        variant="outline"
                        sx={CHS.icon}
                        borderColor="red.500"
                        onClick={handleRemove(field.id)}
                      >
                        <TrashIcon fill="red.500" />
                      </IconButton>
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Button mt={8} onClick={handleAddTableClick}>
        <Box mr={2}>
          <AddIcon fill="white.900" />
        </Box>
        Add Table
      </Button>
      <Box w="full" py={10}>
        <Divider />
      </Box>
      <Flex justifyContent="space-between" w="full" pb={7}>
        <Button variant="outline" minW="140px" onClick={backToHallManagement}>
          <Box mr={4}>
            <ArrowLeftIcon stroke="currentColor" />
          </Box>
          Back
        </Button>
      </Flex>
    </Box>
  );
}

export default ZoneTablesList;
