import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email!").required("Email is required!"),
  password: yup
    .string()
    .required("Password is required!")
    .max(22, "Password is too long!"),
  rememberMe: yup.boolean(),
});

export default { schema };
