export const transformServicesOptions = (data = []) => {
  if (data) {
    return data.map((service) => ({
      value: service.id,
      label: service.name,
      icon: service.image,
    }));
  }
  return [];
};
