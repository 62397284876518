import { gql } from "@apollo/client";

export const GET_ALL_ATTRACTIONS = gql`
  query GetAllAttractions($options: GetAllAttractions) {
    getAllAttractions(options: $options) {
      count
      rows {
        id
        title
        url
        status
        description
        geolocation
        currencyId
        createdBy
        categoryId
        isCompleted
        attractionCategory {
          id
          modelName
          name
        }
        photo
        isLiked
        placeDetails {
          address
        }
      }
    }
  }
`;
