import { useEffect, useState } from "react";
import { Switch } from "@chakra-ui/react";
import { useSwitchArticleShowOnMain } from "graphql/hooks/useMutations";
import { GET_ALL_ARTICLE_ADMIN } from "graphql/queries";
import { useToastContext } from "hooks/useToastContext";

export default function SwitchArticleShowOnMain({ rowId, showOnMain }) {
  const [switchArticleShowOnMain] = useSwitchArticleShowOnMain();

  const [switcherIsDisabled, setSwitcherIsDisabled] = useState(false);

  const [switcherValue, setSwitcherValue] = useState(null);

  const { showToast } = useToastContext();

  useEffect(() => {
    setSwitcherValue(showOnMain);
  }, [showOnMain]);

  const changeHandler = () => {
    setSwitcherIsDisabled(true);

    switchArticleShowOnMain({
      variables: {
        id: rowId,
        status: !switcherValue,
      },
      onCompleted: (response) => {
        if (response.switchArticleShowOnMain?.status) {
          setSwitcherValue((prev) => {
            return !prev;
          });
          showToast({
            description: showOnMain
              ? "The article is HIDDEN on the main page"
              : "The article is SHOWN on the main page",
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [GET_ALL_ARTICLE_ADMIN],
    }).finally(() => setSwitcherIsDisabled(false));
  };

  return (
    <Switch
      id="on_main_page"
      onChange={changeHandler}
      isChecked={switcherValue}
      isDisabled={switcherIsDisabled}
    />
  );
}
