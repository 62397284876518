import { Box, Flex } from "@chakra-ui/react";
import { UserIcon } from "components/Icons";
import TableImage from "components/TableComponents/Image";
import { getDefaultUserPhoto } from "utils/getDefaultUserPhoto";
import CHS from "./chakra.styles";

export const COLUMNS = [
  {
    Header: "",
    accessor: "photo",
    Cell: ({ value }) => {
      return (
        <Flex>
          <TableImage
            src={value ? getDefaultUserPhoto(value, true).preview : null}
            fallback={<UserIcon sx={CHS.userIcon} />}
            sx={CHS.userImage}
          />
        </Flex>
      );
    },
  },
  {
    Header: "",
    accessor: "name",
    Cell: ({ value }) => {
      return <Box>{value || "Not found"}</Box>;
    },
  },
];
