import { gql } from "@apollo/client";

export const UPDATE_REPORT = gql`
  mutation updateReport($input: UpdateReport) {
    updateReport(input: $input) {
      id
      text
      modelName
      modelId
      status
      category
      createdBy
      reportedBy {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
          company {
            companyName
          }
        }
      }
      createdAt
      updatedAt
      reportedEntity {
        __typename
        ... on User {
          id
          firstName
          lastName
          company {
            companyName
          }
        }
        ... on Post {
          id
          title
          createdAt
          postAuthor {
            ... on Company {
              __typename
              id
              companyName
              image
            }
            ... on User {
              __typename
              id
              firstName
              lastName
              photo
              company {
                companyName
              }
            }
          }
          publicationDate
          image
          video
          viewsCount
          commentCount
          likeCount
          # --- for editing post ---
          id
          text
          postTags {
            id
            name
          }
        }
        ... on Comment {
          id
          text
          createdAt
          commentAuthor {
            ... on Company {
              __typename
              id
              companyName
              image
            }
            ... on User {
              __typename
              id
              firstName
              lastName
              photo
              company {
                companyName
              }
            }
          }
        }
      }
    }
  }
`;
