import { Icon } from "@chakra-ui/react";

export default function MessageIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        d="M12.649 9.76c.067-.162.101-.335.101-.51V2.583a1.332 1.332 0 0 0-1.334-1.333H2.083A1.333 1.333 0 0 0 .75 2.583V13.25l2.667-2.667h8a1.332 1.332 0 0 0 1.232-.823Z"
        stroke="#00AEEF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
