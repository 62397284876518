export const transformLocationsSortTable = (state) => {
  if (!state) {
    return undefined;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };

  if (state.id === "name") requestObj.field = "title";
  if (state.id === "active") requestObj.field = "isActive";
  if (state.id === "cityTable") requestObj.field = "cityId";

  return requestObj;
};
