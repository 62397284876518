import dayjs from "dayjs";

export const transformBeforeEditComment = (
  editComment,
  editCommentReadOnly
) => {
  if (editComment) {
    if (editCommentReadOnly) {
      return {
        title: editComment.parent?.parent?.title || "",
        comment_text: editComment?.text,
        author: editComment?.commentAuthor,
        publication_date: dayjs(editComment?.updateAt).toDate(),
      };
    }
    return {
      title: editComment.parent?.parent?.title || "",
      comment_text: editComment.comment,
      author: editComment?.commentAuthor,
      publication_date: dayjs(editComment.date).toDate(),
    };
  }
  return {};
};
