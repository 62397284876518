import { extendTheme } from "@chakra-ui/react";
import FONTS from "./fonts";
import FONT_WEIGHT from "./font-weight";
import LINE_HEIGHT from "./line-height";
import COLORS from "./colors";
import GLOBAL from "./global";
import FONT_SIZES from "./font-sizes";
import SPACES from "./spaces";
import SIZES from "./sizes";
import RADII from "./radii";
import BORDER_WIDTHS from "./borderWidths";
import BORDER_STYLERS from "./borderStyles";
import LAYER_STYLES from "./layer-styles";
import {
  BUTTON,
  HEADING,
  FORM_LABEL,
  INPUT,
  CHECKBOX,
  LINK,
  TABS,
  MENU,
  FILTERS,
  MODAL,
  SWITCH,
  TABLE,
  TEXT,
  STEPS,
  BADGE,
  TEXTAREA,
  POPOVER,
} from "./components";

const theme = extendTheme({
  fonts: FONTS,
  fontSizes: FONT_SIZES,
  fontWeights: FONT_WEIGHT,
  lineHeights: LINE_HEIGHT,
  space: SPACES, // margins & paddings
  sizes: SIZES, // widths & heights
  radii: RADII,
  borderWidths: BORDER_WIDTHS,
  borderStyles: BORDER_STYLERS,
  layerStyles: LAYER_STYLES,
  components: {
    Button: BUTTON,
    Heading: HEADING,
    FormLabel: FORM_LABEL,
    Input: INPUT,
    Checkbox: CHECKBOX,
    Link: LINK,
    Menu: MENU,
    Tabs: TABS,
    Accordion: FILTERS,
    Modal: MODAL,
    Switch: SWITCH,
    Table: TABLE,
    Text: TEXT,
    Steps: STEPS,
    Badge: BADGE,
    Textarea: TEXTAREA,
    Popover: POPOVER,
  },
  colors: COLORS,
  styles: {
    global: GLOBAL,
  },
});

export default theme;
