const VENUES_TICKETS_TABLE_DATA = [
  {
    id: 1001,
    name: "Main event - adult",
    type: "Adult",
    price: "150 AED",
    old_price: "100 AED",
    bestPrice: true,
  },
  {
    id: 1002,
    name: "Main event - child",
    type: "Child",
    price: "150 AED",
    old_price: "100 AED",
    bestPrice: true,
  },
];

export default VENUES_TICKETS_TABLE_DATA;
