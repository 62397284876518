import dayjs from "dayjs";

export const transformEditArticle = (formData, articleId, languages) => {
  if (formData) {
    const multiTextformData = languages.reduce((lang, current) => {
      if (
        formData.main_text?.[current.code] &&
        formData.promo_text?.[current.code] &&
        formData.title?.[current.code]
      ) {
        lang.push({
          mainText: formData.main_text?.[current.code],
          promoText: formData.promo_text?.[current.code],
          title: formData.title?.[current.code],
          languageId: current?.id,
        });
      }

      return lang;
    }, []);

    const multiSeoContent = languages.reduce((lang, current) => {
      if (
        formData.meta_title?.[current.code] &&
        formData.meta_description?.[current.code]
      ) {
        lang.push({
          metaTitle: formData.meta_title?.[current.code] || "",
          metaDescription: formData.meta_description?.[current.code] || "",
          languageId: current?.id,
        });
      }
      return lang;
    }, []);

    const tagsData = formData.tagsEdit.map((item) => {
      return { name: item.label };
    });

    const reguestObj = {
      id: articleId,
      url: formData.url,
      showOnMain: formData.show_on_main,
      categoryId: Number(formData.category.value),
      authorName: formData.author_name,
      publicatedAt: dayjs(formData.publication_date).format("YYYY-MM-DD"),
      seoContent: multiSeoContent,
      textData: multiTextformData,
      tags: tagsData,
    };

    if (formData.status) {
      Object.assign(reguestObj, { status: formData.status.value });
    }
    if (!formData.author_avatar) {
      Object.assign(reguestObj, { authorAvatar: null });
    }
    if (formData.author_avatar?.preview && formData.author_avatar?.path) {
      Object.assign(reguestObj, { authorAvatar: formData.author_avatar });
    }

    if (formData.main_image?.preview && formData.main_image?.path) {
      Object.assign(reguestObj, { mainImage: formData.main_image });
    }

    if (formData.promo_image?.preview && formData.promo_image?.path) {
      Object.assign(reguestObj, { promoImage: formData.promo_image });
    }

    return reguestObj;
  }
  return {};
};
