import { gql } from "@apollo/client";

export const GET_POST_BY_ID = gql`
  query GetPostById($id: ID!) {
    getPostById(id: $id) {
      id
      title
      postAuthor {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
          company {
            companyName
          }
        }
      }
      publisher {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
        }
      }
      publicationDate
      image
      video
      viewsCount
      commentCount
      likeCount
      # --- for editing post ---
      id
      text
      postTags {
        id
        name
      }
    }
  }
`;
