import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Spacer,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useModalContext } from "hooks/useModalContext";
import { useCompanyContext } from "hooks/useCompanyContext.hook";
import { initialValue } from "contexts/company/company.provider";
import { useContext } from "react";
import tableContext from "contexts/table/table.context";
import AddNewUsersWrapper from "./AddUsersWrapper";

export default function AddUsersToNewCompany() {
  const { company, getUsersCompany, clearCompanyState } = useCompanyContext();

  const { selectedRowIds } = useContext(tableContext);

  const { companyName } = company.addNewCompany;

  const { openModal: openOwnerInfo } = useModalContext("addOwnerInfo");

  const { modal: addUsersToCompany, closeModal: closeAddUsersToCompany } =
    useModalContext("addUsersToNewCompany");

  const methods = useForm({
    defaultValues: selectedRowIds
      ? getUsersCompany()
      : initialValue.usersCompany,
  });

  const name = methods.watch("name");

  const closeAddUsersToCompanyHandler = () => {
    clearCompanyState();
    methods.reset(initialValue.usersCompany);
    closeAddUsersToCompany();
  };

  const cancelAddUsersToCompanyHandler = () => {
    methods.reset(getUsersCompany());
    openOwnerInfo();
    closeAddUsersToCompany();
  };

  const AddUsersToCompanySubmit = () => {
    methods.reset(initialValue);
    closeAddUsersToCompanyHandler();
  };

  return (
    <Modal
      isOpen={addUsersToCompany.isOpen}
      onClose={closeAddUsersToCompanyHandler}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>{`${companyName} - add users`}</Heading>
            <Spacer />
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <AddNewUsersWrapper
            onSubmit={AddUsersToCompanySubmit}
            cancelHandler={cancelAddUsersToCompanyHandler}
            closeHandler={closeAddUsersToCompanyHandler}
            methods={methods}
            name={name}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
