import { Icon } from "@chakra-ui/react";

export default function SignOutIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M9.083 19.75H5.528C5.05644 19.75 4.6042 19.5627 4.27076 19.2292C3.93732 18.8958 3.75 18.4436 3.75 17.972V5.528C3.75 5.05644 3.93732 4.6042 4.27076 4.27076C4.6042 3.93732 5.05644 3.75 5.528 3.75H9.083"
        // stroke="#131923"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.306 16.195L19.75 11.751L15.306 7.306"
        // stroke="#131923"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 11.75H8.75"
        // stroke="#131923"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
