import { Button, Divider, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SectorRows from "pages/Events/EventsManagement/FloorMap/SectorRows";
import { useFormContext } from "react-hook-form";

function SectorManagement({ handleClose, sectorId }) {
  const {
    formState: { isDirty },
  } = useFormContext();

  return (
    <VStack spacing={6} alignItems="flex-end">
      <TextFieldRHF
        id="sector_name"
        name="sector_name"
        inputType="text"
        label="Name"
      />
      <HStack spacing={6} w="full">
        <DropzoneFieldRHF
          width="188px"
          height="188px"
          name="map_image"
          label="Map Image"
          acceptStr="image/jpeg,image/png"
          containerStyles={{
            flex: 1,
          }}
        />
        <TextareaRHF
          name="short_description"
          height="188px"
          label="Short description"
          wrapperStyles={{ flex: 2 }}
        />
      </HStack>

      {!sectorId && (
        <VStack w="full" spacing={4}>
          <HStack spacing={6} w="full">
            <TextFieldRHF
              id="rows_count"
              name="rows_count"
              inputType="text"
              label="Rows"
            />
            <TextFieldRHF
              id="seats_per_row"
              name="seats_per_row"
              inputType="text"
              label="Seats per row by default"
            />
          </HStack>

          <Text alignSelf="flex-start" color="dark.300">
            Seats count can be edited for each separate row later
          </Text>
        </VStack>
      )}

      {sectorId && (
        <Button minW="140px" type="submit">
          Save
        </Button>
      )}

      {sectorId && (
        <VStack width="full" spacing={4}>
          <HStack pr="175" justify="space-between" width="full">
            <Text>Row #</Text>
            <Text>Seats</Text>
          </HStack>
          <SectorRows sectorId={sectorId} />
        </VStack>
      )}

      <VStack spacing={8} width="full" pt={2}>
        <Divider />

        <Flex
          w="full"
          justifyContent={sectorId ? "center" : "space-between"}
          pb={7}
        >
          <Button variant="outline" minW="140px" onClick={handleClose}>
            Cancel
          </Button>
          {!sectorId && (
            <Button minW="140px" type="submit" disabled={!isDirty}>
              Save
            </Button>
          )}
        </Flex>
      </VStack>
    </VStack>
  );
}

export default SectorManagement;
