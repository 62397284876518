export const parseJSONToArray = (obj) => {
  const isObject = (val) => val && typeof val === "object";

  const addDelimiter = (a, b) => {
    return a ? `${a}.${b}` : b;
  };

  // eslint-disable-next-line no-shadow
  const paths = (obj = {}, head = "") => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const fullPath = addDelimiter(head, key);
      return isObject(value)
        ? acc.concat(paths(value, fullPath))
        : acc.concat({ name: fullPath });
    }, []);
  };

  return paths(obj);
};
