import {
  HStack,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";

import { ArrowDownThinIcon } from "components/Icons";
import { capitalize } from "utils/capitalize";

const DEFAULT_ITEM_INDEX = 0;

export default function LocationList({ data }) {
  return (
    <Popover>
      <PopoverTrigger>
        <HStack
          spacing={2}
          align="center"
          cursor="pointer"
          role="group"
          display="inline-flex"
        >
          <Text>{capitalize(data[DEFAULT_ITEM_INDEX])}</Text>
          {data.length > 1 && (
            <>
              <Text
                color="grey.500"
                _groupHover={{ color: "blue.500", transform: "scale(1.05)" }}
              >
                (+{data.length - 1})
              </Text>
              <ArrowDownThinIcon />
            </>
          )}
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <List>
              {data.map((location, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index}>{capitalize(location)}</ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
