import { Box } from "@chakra-ui/react";
import TableBody from "components/Table/TableBody";

function TicketsTableAttractions() {
  return (
    <Box w="full">
      <TableBody hasSorting={true} />
    </Box>
  );
}

export default TicketsTableAttractions;
