import { gql } from "@apollo/client";

export const GET_CURRENCIES = gql`
  query getCurrency {
    getCurrency {
      id
      code
      name
    }
  }
`;
