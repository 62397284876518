const optionIcon = {
  w: "20px",
  h: "20px",
  fill: "dark.500",
  _groupHover: { fill: "blue.500" },
};

const addressSplitActiveTrue = {
  w: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const addressSplitActiveFalse = {
  w: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: "rgba(0, 0, 0, 0.3)",
};

const imageStyle = {
  w: "32px",
  h: "32px",
  borderRadius: "10px",
  mr: "16px",
};

export default {
  optionIcon,
  imageStyle,
  addressSplitActiveTrue,
  addressSplitActiveFalse,
};
