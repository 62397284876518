import React, { useState } from "react";
import { useSortBy } from "react-table";
import TableProvider from "contexts/table/table.provider";
import TicketsTableAttractions from "pages/Attractions/AttractionsManagement/TicketsAndSchedule/TicketsTable";
import { COLUMNS } from "pages/Attractions/AttractionsManagement/TicketsAndSchedule/TicketsTable/table.config";
import { useGetAllAttractionTickets } from "graphql/hooks/useQueries";
import { useAttractionContext } from "hooks/useAttractionContext";
import {
  transformAttractionsTicketsSortState,
  transformTickets,
} from "connectors";
import { Flex, Spinner } from "@chakra-ui/react";
import ModalButton from "components/ModalButton";
import { useOptionsContext } from "hooks/useOptionsContext";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import useGetCurrencies from "hooks/useGetCurrencies.hook";

function TicketsContainerAttractions() {
  useGetCurrencies();
  const [sortState, setSortState] = useState();

  const { getAttractionId } = useAttractionContext();
  const { currencies } = useOptionsContext();

  const { data, loading } = useGetAllAttractionTickets({
    skip: !getAttractionId(),
    variables: {
      params: {
        attractionId: getAttractionId(),
        order: transformAttractionsTicketsSortState(sortState),
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformTickets({
          data: data?.getAllAttractionTickets?.rows,
          currencies,
        }),
        manualSortBy: true,
      }}
      useSortBy={useSortBy}
      onSetSortState={setSortState}
    >
      <Flex w="full" justify="flex-end" align="center" mb={7} mt={5}>
        {getAttractionId() ? (
          <ModalButton
            modalName="createEditTicketsModal"
            label="Add tickets"
            minW="140px"
            data={{ attractionId: getAttractionId() }}
          />
        ) : null}
      </Flex>
      {loading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : data?.getAllAttractionTickets?.rows.length ? (
        <TicketsTableAttractions />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default TicketsContainerAttractions;
