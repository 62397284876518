import {
  EditOptionIcon,
  DeleteOptionIcon,
  EyeOpenIcon,
} from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useDeleteCommentAdmin } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { useToastContext } from "hooks/useToastContext";
import { useGetCurrentUser } from "graphql/hooks/useQueries";
import { useMemo } from "react";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ comment }) {
  const { showToast } = useToastContext();
  const { data } = useGetCurrentUser();
  const { openModal: openCreateModal } = useModalContext(
    "commentCreateEditModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessDeleteComments } = getAccessScopesControl();

  const editComment = () => {
    openCreateModal({
      editComment: comment,
      isEditMode: true,
    });
  };

  const viewComment = () => {
    openCreateModal({
      editCommentReadOnly: true,
      editComment: comment,
      isEditMode: true,
    });
  };

  const [removeComment] = useDeleteCommentAdmin({
    variables: {
      id: comment.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The comment has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: comment.comment,
      type: "comment",
      onDelete: removeComment,
    });
  };

  const hasEditingPermission = useMemo(() => {
    if (
      Number(comment?.author?.id) === Number(data?.currentUser?.companyId) ||
      Number(comment?.author?.id) === Number(data?.currentUser?.id)
    ) {
      return true;
    }
    if (
      !data?.currentUser?.company &&
      !data?.currentUser?.companyId &&
      Number(comment?.author?.id) === Number(data?.currentUser?.id)
    ) {
      return true;
    }
    return false;
  }, [
    comment?.author?.id,
    data?.currentUser?.id,
    data?.currentUser?.company,
    data?.currentUser?.companyId,
  ]);

  return (
    <TableOptions>
      <CustomMenuItem
        icon={
          hasEditingPermission ? (
            <EditOptionIcon sx={CHS.optionIcon} />
          ) : (
            <EyeOpenIcon sx={CHS.eyeIcon} />
          )
        }
        onMenuItemClick={hasEditingPermission ? editComment : viewComment}
      >
        {hasEditingPermission ? "Edit" : "Details"}
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteComments)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
