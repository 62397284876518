import { DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveVenueEvent } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { useToastContext } from "hooks/useToastContext";
import CHS from "./chakra.styles";

export default function Options({ row }) {
  const { showToast } = useToastContext();
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const [removeVenueEvent] = useRemoveVenueEvent({
    variables: {
      eventId: row.original.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The entertainment has been successfully deleted",
      });
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: row.original.name,
      type: "entertainment",
      onDelete: removeVenueEvent,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
