import { Icon } from "@chakra-ui/react";

export default function ServicesIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M15.9279 11.4283L15.9274 11.4288L11.4274 15.9288L11.427 15.9292C11.4038 15.9525 11.3762 15.971 11.3458 15.9836C11.3154 15.9962 11.2829 16.0027 11.25 16.0027C11.2171 16.0027 11.1846 15.9962 11.1542 15.9836C11.1238 15.971 11.0962 15.9525 11.073 15.9292L11.0726 15.9288L8.82255 13.6788C8.79925 13.6555 8.78077 13.6278 8.76816 13.5974C8.75554 13.5669 8.74905 13.5343 8.74905 13.5013C8.74905 13.4684 8.75554 13.4358 8.76816 13.4053C8.78077 13.3749 8.79925 13.3472 8.82255 13.3239C8.86962 13.2768 8.93345 13.2504 9 13.2504C9.03296 13.2504 9.06559 13.2569 9.09604 13.2695C9.12645 13.2821 9.1541 13.3006 9.17739 13.3238C9.17741 13.3239 9.17743 13.3239 9.17745 13.3239L10.8963 15.0442L11.2499 15.3982L11.6036 15.0443L15.5726 11.0738L15.573 11.0734C15.5962 11.0502 15.6238 11.0317 15.6542 11.0191C15.6846 11.0065 15.7171 11 15.75 11C15.7829 11 15.8154 11.0065 15.8458 11.0191C15.8762 11.0317 15.9038 11.0502 15.927 11.0734L16.2809 10.7204L15.9279 11.0743C15.9512 11.0976 15.9696 11.1251 15.9823 11.1555C15.9949 11.1859 16.0013 11.2185 16.0013 11.2513C16.0013 11.2842 15.9949 11.3168 15.9823 11.3472C15.9696 11.3775 15.9512 11.4051 15.9279 11.4283Z" />
      <path d="M6 2.25H4.5C3.70435 2.25 2.94129 2.56607 2.37868 3.12868C1.81607 3.69129 1.5 4.45435 1.5 5.25V21C1.5 21.7956 1.81607 22.5587 2.37868 23.1213C2.94129 23.6839 3.70435 24 4.5 24H19.5C20.2956 24 21.0587 23.6839 21.6213 23.1213C22.1839 22.5587 22.5 21.7956 22.5 21V5.25C22.5 4.45435 22.1839 3.69129 21.6213 3.12868C21.0587 2.56607 20.2956 2.25 19.5 2.25H18V3.75H19.5C19.8978 3.75 20.2794 3.90804 20.5607 4.18934C20.842 4.47064 21 4.85218 21 5.25V21C21 21.3978 20.842 21.7794 20.5607 22.0607C20.2794 22.342 19.8978 22.5 19.5 22.5H4.5C4.10218 22.5 3.72064 22.342 3.43934 22.0607C3.15804 21.7794 3 21.3978 3 21V5.25C3 4.85218 3.15804 4.47064 3.43934 4.18934C3.72064 3.90804 4.10218 3.75 4.5 3.75H6V2.25Z" />
      <path d="M14.25 1.5C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V3.75C15 3.94891 14.921 4.13968 14.7803 4.28033C14.6397 4.42098 14.4489 4.5 14.25 4.5H9.75C9.55109 4.5 9.36032 4.42098 9.21967 4.28033C9.07902 4.13968 9 3.94891 9 3.75V2.25C9 2.05109 9.07902 1.86032 9.21967 1.71967C9.36032 1.57902 9.55109 1.5 9.75 1.5H14.25ZM9.75 0C9.15326 0 8.58097 0.237053 8.15901 0.65901C7.73705 1.08097 7.5 1.65326 7.5 2.25V3.75C7.5 4.34674 7.73705 4.91903 8.15901 5.34099C8.58097 5.76295 9.15326 6 9.75 6H14.25C14.8467 6 15.419 5.76295 15.841 5.34099C16.2629 4.91903 16.5 4.34674 16.5 3.75V2.25C16.5 1.65326 16.2629 1.08097 15.841 0.65901C15.419 0.237053 14.8467 0 14.25 0L9.75 0Z" />
    </Icon>
  );
}
