import { Grid, GridItem } from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import Button from "components/Button";
import { FormProvider } from "react-hook-form";
import TablePageSetter from "components/Table/TablePageSetter";
import { GlassIcon } from "components/Icons";
import { ROWS_PER_PAGE_DEFAULT } from "constants/constants";

function UsersAPUsersFilters({ methods, permisisonOptions }) {
  const { control, reset, handleSubmit, formState } = methods;

  const onSubmit = (data) => {
    console.log(data, "onSubmit");
  };

  const onClearClick = () => {
    reset({
      name: "",
      role: "",
    });
  };

  // TODO: Временно пока не будет создан функционал с ролями
  const updatePermisionRoles = permisisonOptions.filter(
    (item) => item.label !== "USER"
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns="repeat(20, 1fr)" gap={8} alignItems="end" mt={8}>
          <GridItem colSpan={6}>
            <TextFieldRHF
              name="name"
              inputType="text"
              inputPlaceholder="Search users"
              inputLeftElement={
                <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
              }
            />
          </GridItem>
          <GridItem colSpan={5}>
            <SelectRHF
              control={control}
              name="role"
              label="Main role"
              placeholder="Select"
              options={updatePermisionRoles}
            />
          </GridItem>
          <GridItem colSpan={1} mt={5.5}>
            <Button
              type="reset"
              disabled={!formState.isDirty}
              variant="clear"
              onClick={onClearClick}
            >
              Clear
            </Button>
          </GridItem>
          <GridItem colSpan={4} />
          <GridItem colSpan={4} alignSelf="end">
            <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE_DEFAULT} />
          </GridItem>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default UsersAPUsersFilters;
