import { gql } from "@apollo/client";

export const GET_VENUES = gql`
  query getVenues($categoryId: Int, $limit: Int, $offset: Int, $sort: Sort) {
    getVenues(
      categoryId: $categoryId
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count
      rows {
        id
        title
        url
        placeDetails {
          address
        }
        venueCategory {
          name
        }
        status
        rank
      }
    }
  }
`;
