import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveVenueHall } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import CHS from "./chakra.styles";

export default function Options({ hallId, name, description, image, zones }) {
  const { openModal: openHallModal } = useModalContext("hallManagementModal");
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const [removeHall] = useRemoveVenueHall();

  const handleRemove = () => {
    removeHall({
      variables: {
        id: hallId,
      },
      onCompleted: closeDeleteModal,
    });
  };

  const handleEdit = () => {
    openHallModal({ name, description, image, hallId, zones });
  };

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name,
      type: "hall",
      onDelete: handleRemove,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={handleEdit}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
