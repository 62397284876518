import { gql } from "@apollo/client";

export const CREATE_POST = gql`
  mutation CreatePost($input: PostInput!, $image: Upload, $video: Upload) {
    createPost(input: $input, image: $image, video: $video) {
      id
      title
      postAuthor {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
          company {
            companyName
          }
        }
      }
      publisher {
        ... on Company {
          __typename
          id
          companyName
          image
        }
        ... on User {
          __typename
          id
          firstName
          lastName
          photo
        }
      }
      publicationDate
      likeCount
      commentCount
      viewsCount
      text
      postTags {
        name
      }
    }
  }
`;
