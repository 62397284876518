import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";
import { CREATE_SEO_SCHEMA, EDIT_SEO_SCHEMA } from "schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect } from "react";
import {
  transformLanguageSelect,
  transformLanguagesToCodeList,
  transformEditSeo,
  transformCreateSeo,
  transformBeforeEditSeo,
} from "connectors";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useCreateSeo, useEditSeo } from "graphql/hooks/useMutations";
import { GET_SEO_DATA_URLS } from "graphql/queries";
import { onLanguageChange } from "utils/onLanguageChange";
import { useToastContext } from "hooks/useToastContext";
import SeoCreateEditModal from "components/Modals/SeoCreateEdit";

export default function SeoCreateEditModalContainer() {
  const { modal, closeModal } = useModalContext("seoCreateEditModal");
  const { languages } = useOptionsContext();
  const { showToast } = useToastContext();

  const codeList = transformLanguagesToCodeList(languages);
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});

  const INITIAL_VALUES = {
    url: "",
    metaTitle: translatedValues,
    metaKeywords: translatedValues,
    metaDescription: translatedValues,
    language: { label: "English", value: { id: 1, code: "en-US" } },
  };

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode
        ? EDIT_SEO_SCHEMA.schema(languages)
        : CREATE_SEO_SCHEMA.schema(languages)
    ),
  });

  const { reset, watch, handleSubmit } = methods;

  const [createSeo] = useCreateSeo();
  const [editSeo] = useEditSeo();

  const selectedLanguage = watch("language");
  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(transformBeforeEditSeo(modal.editSeo, translatedValues));
    } else {
      reset(INITIAL_VALUES);
    }
  }, [modal.isEditMode]);

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      Object.assign(formData, { url: modal.editSeo?.url });
      editSeo({
        variables: transformEditSeo({ formData, languages }),
        refetchQueries: [GET_SEO_DATA_URLS],
        onCompleted: () => {
          showToast({
            description: "Seo data successful updated",
          });
          closeModal();
        },
        onError: ({ graphQLErrors }) => {
          if (graphQLErrors) {
            switch (graphQLErrors?.[0]?.message) {
              case "Seo data under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Seo data under this URL already exists",
                });
                break;
              default:
                console.log(graphQLErrors?.[0]?.message);
            }
          }
        },
      });
    } else {
      createSeo({
        variables: transformCreateSeo({
          formData,
          languages,
        }),
        refetchQueries: [GET_SEO_DATA_URLS],
        onCompleted: () => {
          showToast({
            description: "Seo data successful created",
          });
          closeModal();
        },
        onError: ({ graphQLErrors }) => {
          if (graphQLErrors) {
            switch (graphQLErrors?.[0]?.message) {
              case "Seo data under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Seo data under this URL already exists",
                });
                break;
              default:
                console.log(graphQLErrors?.[0]?.message);
            }
          }
        },
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modal.isEditMode ? "Edit seo" : "Add seo"}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <SeoCreateEditModal
            onSubmit={handleSubmit(onSubmit)}
            cancelHandler={closeModal}
            languagesOptions={transformLanguageSelect(languages)}
            isEdit={modal.isEditMode}
            selectedLanguage={selectedLanguage}
            languageChangeHandler={languageChangeHandler}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
