import { capitalize } from "utils/capitalize";

export const transformAllUsersList = (data = []) => {
  if (data) {
    return data?.map((user) => {
      let imgSrc = null;

      if (user.photo) {
        if (user.photo.includes("https")) {
          imgSrc = user.photo;
        } else {
          imgSrc = `${process.env.REACT_APP_API_URL}/${user.photo}`;
        }
      }

      return {
        fullName: `${user?.firstName} ${user?.lastName}`,
        mainRole: capitalize(user.role?.name),
        company: user.company?.companyName,
        photo: imgSrc
          ? {
              preview: imgSrc,
            }
          : null,
        id: user.id,
        email: user?.email,
        phone: user?.phone,
        firstName: user?.firstName,
        lastName: user?.lastName,
        gender: user?.gender,
        cityId: user?.cityId,
        role: { value: user.role?.id, label: user.role?.name },
        visibility: user?.role?.visibility,
      };
    });
  }
  return [];
};
