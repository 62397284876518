import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  ModalBody,
  ModalFooter,
  Spacer,
  VStack,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { MAX_VIDEO_SIZE } from "constants/constants";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import DatePickerRHF from "components/react-hook-form/DatePickerRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import { useFormContext } from "react-hook-form";
import RedirectFromFeedReview from "./RedirectFromFeedReview";

export default function FeedCreateEditModal(props) {
  const {
    onSubmit,
    cancelHandler,
    isEditMode,
    editPostReadOnly,
    currentUserCompanyName,
    loading,
    feedId,
  } = props;

  const { watch } = useFormContext();

  if (loading)
    return (
      <Center padding="50px 0">
        <Spinner size="xl" />
      </Center>
    );

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <Grid
            templateColumns="repeat(6, 1fr)"
            gridAutoRows="auto"
            gap={6}
            w="full"
          >
            <GridItem colSpan={isEditMode ? 5 : 6}>
              <TextFieldRHF
                id="title"
                name="title"
                inputType="text"
                label="Title"
                inputPlaceholder="Create post`s title"
                isDisabled={editPostReadOnly}
              />
            </GridItem>

            {isEditMode && (
              <GridItem colSpan={1} display="flex" alignItems="flex-end">
                <RedirectFromFeedReview feedId={feedId} />
              </GridItem>
            )}

            <GridItem colSpan={6}>
              <TextFieldRHF
                id="tags"
                name="tags"
                label="Tags"
                inputPlaceholder="Add tags, separate by comma"
                isDisabled={editPostReadOnly}
              />
            </GridItem>

            {!watch("video") && (
              <GridItem colSpan={6}>
                <DropzoneFieldRHF
                  width="100%"
                  height="170px"
                  name="main_image"
                  label="Main image"
                  acceptStr="image/jpeg,image/jpg,image/png"
                  isDisabled={editPostReadOnly}
                />
              </GridItem>
            )}
            <GridItem colSpan={6}>
              <Flex>
                {!watch("main_image") && (
                  <DropzoneFieldRHF
                    isVideo={true}
                    width="170px"
                    height="170px"
                    name="video"
                    label="Main video"
                    acceptStr="video/mp4,video/webm"
                    maxSize={MAX_VIDEO_SIZE}
                  />
                )}
                <TextareaRHF
                  name="main_text"
                  label="Main text"
                  placeholder="Main text..."
                  width={!watch("main_image") ? "340px" : "full"}
                  height="170px"
                />
              </Flex>
            </GridItem>
            <GridItem colSpan={3}>
              {isEditMode ? (
                <TextFieldRHF
                  id="author"
                  name="authorOnEdit"
                  inputType="text"
                  label="Author"
                  inputPlaceholder="Add post`s author"
                  isDisabled
                />
              ) : (
                <TextFieldRHF
                  id="author"
                  name="authorOnCreate"
                  inputType="text"
                  label="Author"
                  inputPlaceholder="Add post`s author"
                  value={currentUserCompanyName}
                  isDisabled
                />
              )}
            </GridItem>

            <GridItem colSpan={3}>
              <Box w="248px">
                <DatePickerRHF
                  name="publication_date"
                  label="Publication date"
                  minDate={new Date()}
                  placeholder="Add publication date"
                  isDisabled={editPostReadOnly}
                />
              </Box>
            </GridItem>
          </Grid>
        </VStack>
      </ModalBody>
      {!editPostReadOnly ? (
        <ModalFooter>
          <Flex w="100%">
            <Button
              onClick={cancelHandler}
              variant="outline"
              colorScheme="blue.500"
            >
              Cancel
            </Button>
            <Spacer />
            <Button type="submit">
              {isEditMode ? "Update" : "Create post"}
            </Button>
          </Flex>
        </ModalFooter>
      ) : (
        <Box width="full" height="40px" />
      )}
    </form>
  );
}
