import { Box } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import ReportsListContainer from "containers/reports/reportsList.container";

function Reports() {
  return (
    <Box px={10} py={6} w="full">
      <PageHeader mb={8}>Reports</PageHeader>
      <ReportsListContainer />
    </Box>
  );
}
export default Reports;
