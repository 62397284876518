import { gql } from "@apollo/client";

export const GET_ALL_ADS = gql`
  query getAllAds($options: getAllAds) {
    getAllAds(options: $options) {
      count
      rows {
        id
        path
        url
        alt
        startAt
        stopAt
        showCount
        currentShowCount
        status
        adAuthor {
          id
          firstName
          lastName
          photo
        }
        adPositions {
          id
          page
          level
        }
      }
    }
  }
`;
