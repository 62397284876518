import Status from "components/TableComponents/Status";
import ClientCell from "./ClientCell";
import HeaderWithEye from "./HeaderWithEye";
import LocationList from "./LocationList";
import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Client",
    accessor: "client",
    Cell: ({ row: { original } }) => {
      return <ClientCell data={original} />;
    },
  },
  {
    Header: "Rotation",
    accessor: "rotation",
  },
  {
    Header: "Block IDs",
    accessor: "blockIDs",
    disableSortBy: true,
    Cell: ({ value }) => {
      return <LocationList data={value} />;
    },
  },
  {
    Header: "Pages",
    accessor: "pages",
    disableSortBy: true,
    Cell: ({ value }) => {
      return <LocationList data={value} />;
    },
  },
  {
    Header: () => <HeaderWithEye title="Planned" />,
    accessor: "planned",
  },
  {
    Header: () => <HeaderWithEye title="Current" />,
    accessor: "current",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <Status value={value.toLowerCase()} />;
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return <Options ad={original} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
