import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Container,
} from "@chakra-ui/react";

function Filters(props) {
  const { title = "Filters", children, formSubmit, ...rest } = props;

  return (
    <Accordion allowToggle {...rest}>
      <AccordionItem>
        <AccordionButton mb={4}>
          {title}
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Container minW="full" p="none">
            <form onSubmit={formSubmit}>{children}</form>
          </Container>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default Filters;
