import React, { useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { transformOffersList, transformOffersListSortState } from "connectors";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Offers/OffersMainPage/OffersTable/table.config";
import { useForm } from "react-hook-form";
import { useDebounce } from "hooks/useDebounce";
import OffersFilters from "pages/Offers/OffersMainPage/OffersFilters";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllOffersAdmin } from "graphql/hooks/useQueries";
import OffersTable from "pages/Offers/OffersMainPage/OffersTable";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function OffersContainerMain(props) {
  const { accessViewOffers } = props;
  const [sortState, setSortState] = useState();

  const methods = useForm();

  const name = methods.watch("name");

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const { data, loading } = useGetAllOffersAdmin({
    variables: {
      limit: queryPageSize,
      offset: offsetData,
      search: useDebounce(name) || null,
      sort: transformOffersListSortState(sortState),
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformOffersList(data?.getAllOffersAdmin?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={data?.getAllOffersAdmin?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <Flex justify="space-between" align="center" mb={4} w="full">
        <OffersFilters methods={methods} />
      </Flex>
      {loading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : data?.getAllOffersAdmin?.rows?.length && accessViewOffers ? (
        <OffersTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default OffersContainerMain;
