import { getAdminsRoles } from "utils/getUserRoleName";
import { multiLangValidate } from "utils/multiLangValidate";
import * as yup from "yup";

export const ADD_VENUES_GENERAL_INFO_SCHEMA = (languages) => {
  return yup.object().shape({
    title: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "description",
          "info_title",
          "info_description",
          "policy_title",
          "policy_description",
        ])
      ),
    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),
    price: yup.number().required("Price is required"),
    country: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Country is required!")
      .nullable(),
    city: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("City is required!")
      .nullable(),
    location: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable(),
    coordinates: yup
      .object()
      .required("Coordinates is required")
      .nullable(true),
    category: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Category is required!")
      .nullable(),
    rank: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    description: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "info_title",
          "info_description",
          "policy_title",
          "policy_description",
        ])
      ),
    restrictions: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .nullable(),
    services: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .nullable(),
    policy_title: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "info_title",
          "info_description",
          "policy_description",
        ])
      ),
    policy_description: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "info_title",
          "info_description",
          "policy_title",
        ])
      ),
    url: yup.string().required("URL is required!"),
    main_img: yup
      .mixed()
      .nullable()
      .required("A file is required")
      .test("fileFormat", "JPEG, JPG or PNG only", (value) => {
        return (
          value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        );
      }),
    info_title: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "policy_description",
          "info_description",
          "policy_title",
        ])
      ),

    info_description: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "policy_description",
          "info_title",
          "policy_title",
        ])
      ),
    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),
  });
};

export const EDIT_VENUES_GENERAL_INFO_SCHEMA = (languages) => {
  const { isSuperAdmin, isAdmin } = getAdminsRoles();
  const accessRank = isSuperAdmin || isAdmin;

  return yup.object().shape({
    title: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "description",
          "info_title",
          "info_description",
          "policy_title",
          "policy_description",
        ])
      ),
    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),
    price: yup.number(),
    country: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    city: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    location: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable(),
    coordinates: yup.object().nullable(true),
    category: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    rank: accessRank
      ? yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .nullable()
      : null,
    description: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "info_title",
          "info_description",
          "policy_title",
          "policy_description",
        ])
      ),
    restrictions: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .nullable(),
    services: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .nullable(),
    policy_title: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "info_title",
          "info_description",
          "policy_description",
        ])
      ),
    policy_description: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "info_title",
          "info_description",
          "policy_title",
        ])
      ),
    url: yup.string(),
    main_img: yup
      .mixed()
      .nullable()
      .test("preview", "has preview", (value) => {
        return typeof value?.preview === "string";
      }),
    info_title: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "policy_description",
          "info_description",
          "policy_title",
        ])
      ),
    info_description: yup
      .object()
      .shape(
        multiLangValidate(languages, [
          "title",
          "description",
          "policy_description",
          "info_title",
          "policy_title",
        ])
      ),
    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),
  });
};
