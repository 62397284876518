import {
  transformCategoriesOptions,
  transformCityOptions,
  transformCurrenciesOptions,
  transformLanguageSelect,
  transformRestrictionsOptions,
  transformServicesOptions,
  transformLanguagesToCodeList,
  transformLocationOptions,
  transformVenueListOptions,
  transformEventGeneralInfoData,
  transformReceivedEventGeneralInfo,
} from "connectors";
import stepsContext from "contexts/steps/steps.context";
import {
  useAddEventGeneralInfo,
  useEditEvent,
} from "graphql/hooks/useMutations";
import { useEventContext } from "hooks/useEventContext.hook";
import { useOptionsContext } from "hooks/useOptionsContext";
import GeneralInformationEvents from "pages/Events/EventsManagement/GeneralInformation";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ADD_EVENTS_GENERAL_INFO_SCHEMA,
  EDIT_EVENTS_GENERAL_INFO_SCHEMA,
} from "schemas";
import { onLanguageChange } from "utils/onLanguageChange";
import { yupResolver } from "@hookform/resolvers/yup";
import { allCategories } from "constants/constants";
import { transformStatusOptions } from "connectors/transformStatusOptions";

import {
  useGetAllLocations,
  useGetAllVenuesLazy,
} from "graphql/hooks/useQueries";
import useGetEventStatuses from "hooks/useGetEventStatuses.hook";
import useGetRestrictions from "hooks/useGetRestrictions.hook";
import useGetServices from "hooks/useGetServices.hook";
import useGetCurrencies from "hooks/useGetCurrencies.hook";

function GeneralInformationContainer() {
  useGetEventStatuses();
  useGetRestrictions();
  useGetServices();
  useGetCurrencies();

  const { event, updateEventState, getGeneralInfo, getEventId } =
    useEventContext();
  const [addEventGeneralInfo] = useAddEventGeneralInfo();
  const [editEvent] = useEditEvent();

  const { data: allLocationsData } = useGetAllLocations({
    variables: {
      options: {
        isActive: true,
      },
    },
  });

  const {
    countries,
    eventStatuses,
    languages,
    restrictions,
    services,
    currencies,
    categories,
  } = useOptionsContext();
  const { nextStep } = useContext(stepsContext);

  const methods = useForm({
    defaultValues: getGeneralInfo(),
    resolver: yupResolver(
      getEventId()
        ? EDIT_EVENTS_GENERAL_INFO_SCHEMA(languages)
        : ADD_EVENTS_GENERAL_INFO_SCHEMA(languages)
    ),
  });

  const translatedValues = transformLanguagesToCodeList(languages).reduce(
    (acc, code) => {
      acc[code] = "";
      return acc;
    },
    {}
  );

  const [selectedLanguage, selectedCountry] = methods.watch([
    "language",
    "country",
  ]);

  const categoryOptions = transformCategoriesOptions(
    allCategories.eventCategory,
    categories
  );
  const currenciesOptions = transformCurrenciesOptions(currencies);

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  const onSubmit = (formData) => {
    const eventId = getEventId();
    if (eventId) {
      Object.assign(formData, { id: eventId });
      editEvent({
        variables: {
          info: transformEventGeneralInfoData({ formData, languages }),
        },
        onCompleted: (response) => {
          updateEventState(
            transformReceivedEventGeneralInfo({
              responseData: response?.editEvent,
              categoryOptions,
              currenciesOptions,
              translatedValues,
              languages,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Event under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Event under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      addEventGeneralInfo({
        variables: {
          info: transformEventGeneralInfoData({
            formData,
            languages,
            isCreate: true,
          }),
        },
        onCompleted: (response) => {
          updateEventState(
            transformReceivedEventGeneralInfo({
              responseData: response?.createEvent,
              categoryOptions,
              currenciesOptions,
              translatedValues,
              languages,
            })
          );
          nextStep();
        },
        onError: (error) => {
          if (error?.message) {
            switch (error?.message) {
              case "Event under this url already exists! If you don't find it listed, contact support":
                methods.setError("url", {
                  type: "custom",
                  message: "Event under this url already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (event.id) {
      methods.reset(getGeneralInfo());
    }
  }, [event.id]);

  const [query] = useGetAllVenuesLazy();

  const loadOptionsVenues = async (search, prevOptions, { page }) => {
    // async func for select pagination
    const response = await query({
      variables: {
        options: {
          // params for lazy Query or refetch (recomended lazy Query)
          ...(!search && { limit: 10 }),
          ...(!search && { offset: (page - 1) * 10 }),
          ...(search && { search }),
        },
      },
    });

    return {
      options: transformVenueListOptions(response?.data?.getAllVenues?.rows), // format respons for correct work
      hasMore: response?.data?.getAllVenues?.count >= page * 10 && !search, // format respons for correct work
      additional: {
        // required
        page: search ? 2 : page + 1,
      },
    };
  };

  return (
    <FormProvider {...methods}>
      <GeneralInformationEvents
        onSubmit={methods.handleSubmit(onSubmit)}
        citiesOptions={transformCityOptions(selectedCountry, countries)}
        restrictionOptions={transformRestrictionsOptions(restrictions)}
        statusOptions={transformStatusOptions(eventStatuses)}
        languageOptions={transformLanguageSelect(languages)}
        serviceOptions={transformServicesOptions(services)}
        loadOptionsVenues={loadOptionsVenues}
        locationOptions={transformLocationOptions(
          allLocationsData?.getAllLocations?.rows
        )}
        languageChangeHandler={languageChangeHandler}
        currencyOptions={currenciesOptions}
        categoriesOptions={categoryOptions}
        selectedLanguage={selectedLanguage}
      />
    </FormProvider>
  );
}

export default GeneralInformationContainer;
