import { Grid, GridItem } from "@chakra-ui/react";
import SelectRHF from "components/react-hook-form/SelectRHF";
import Button from "components/Button";
import Filters from "components/Filters";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import { useFormContext } from "react-hook-form";

const INITIAL_VALUES = {
  name: "",
  address: "",
  active: {
    value: "all",
    label: "All",
  },
  country: {
    value: "country",
    label: null,
  },
  city: {
    value: "city",
    label: null,
  },
};

function LocationsFilters({
  statusOptions,
  methods,
  countriesOptions,
  citiesOptions,
}) {
  const { reset, handleSubmit, formState } = methods;
  const { watch } = useFormContext();
  const onSubmit = (data) => {
    console.log("onSubmit", data);
  };

  const onClearClick = () => {
    reset(INITIAL_VALUES);
  };

  const countryChangeHandler = () => {
    methods.setValue("city", null);
  };

  return (
    <Filters formSubmit={handleSubmit(onSubmit)}>
      <Grid
        templateColumns="repeat(8, 1fr)"
        templateRows="repeat(2, auto )"
        columnGap={8}
        rowGap={3}
        alignItems="center"
        pb={8}
      >
        <GridItem colSpan={3} pt={6}>
          <TextFieldRHF
            id="name"
            name="name"
            inputType="text"
            inputPlaceholder="Search locations"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
          />
        </GridItem>

        <GridItem colSpan={3}>
          <SelectRHF
            name="active"
            label="Active"
            options={statusOptions}
            placeholder="Select Status"
            defaultValue={{
              value: "all",
              label: "All",
            }}
          />
        </GridItem>

        <GridItem colSpan={3}>
          <SelectRHF
            name="country"
            label="Country"
            options={countriesOptions}
            placeholder="Select country"
            onChangeHandler={countryChangeHandler}
          />
        </GridItem>
        <GridItem colSpan={3}>
          <SelectRHF
            name="city"
            label="City"
            options={citiesOptions}
            placeholder="Select city"
            isDisabled={!watch("country")}
          />
        </GridItem>

        <GridItem colSpan={1} mt={5.5}>
          <Button
            type="reset"
            disabled={!formState.isDirty}
            variant="clear"
            onClick={onClearClick}
          >
            Clear
          </Button>
        </GridItem>
      </Grid>
    </Filters>
  );
}

export default LocationsFilters;
