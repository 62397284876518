import React from "react";
import { PATHS, ACCESS, SUB_PATHS } from "constants/constants";
import ProfileContainer from "containers/profile.container";
import LoginContainer from "containers/login.container";
import RecoverPasswordContainer from "containers/recoverPassword.container";
import ResetPasswordContainer from "containers/resetPassword.container";
import VenueManagement from "pages/Venues/VenueManagement";
import {
  Home,
  Analytics,
  Payments,
  Articles,
  Content,
  Events,
  Venues,
  Attractions,
  Offers,
  Feeds,
  Users,
  Reports,
  Locations,
  Banners,
  Services,
  Comments,
  Seo,
} from "pages";
import GeneralInformationContainer from "containers/venues/genInformation.container";
import GalleryContainer from "containers/venues/gallery.container";
import EntertainmentsContainer from "containers/venues/entertainments.container";
import OffersContainerVenues from "containers/venues/offers.container";
import FloorMapContainer from "containers/venues/floorMap.container";
import MenuContainer from "containers/venues/menu.container";
import TicketsAndVouchersContainer from "containers/venues/ticketsAndVouchers.container";
import EventsManagement from "pages/Events/EventsManagement";
import GeneralInformationContainerEvents from "containers/events/genInformation.container";
import GalleryContainerEvents from "containers/events/gallery.container";
import OffersContainerEvents from "containers/events/offers.container";
import FloorMapContainerEvents from "containers/events/floorMap.container";
import AttractionsManagement from "pages/Attractions/AttractionsManagement";
import GeneralInformationContainerAttractions from "containers/attractions/generalinformation.container";
import GalleryContainerAttractions from "containers/attractions/gallery.container";
import OffersContainerAttractions from "containers/attractions/offers.container";
import TicketsAndScheduleContainerAttractions from "containers/attractions/ticketsAndSchedule.container";

const VENUES_STEPS = [
  {
    path: SUB_PATHS.general,
    component: GeneralInformationContainer,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.menu,
    component: MenuContainer,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.gallery,
    component: GalleryContainer,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.entertainments,
    component: EntertainmentsContainer,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.offers,
    component: OffersContainerVenues,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.floorMap,
    component: FloorMapContainer,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.ticketsAndVouchers,
    component: TicketsAndVouchersContainer,
    layout: React.Fragment,
  },
];

const EVENTS_STEPS = [
  {
    path: SUB_PATHS.general,
    component: GeneralInformationContainerEvents,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.gallery,
    component: GalleryContainerEvents,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.offers,
    component: OffersContainerEvents,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.floorMap,
    component: FloorMapContainerEvents,
    layout: React.Fragment,
  },
];

const ATTRACTIONS_STEPS = [
  {
    path: SUB_PATHS.general,
    component: GeneralInformationContainerAttractions,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.gallery,
    component: GalleryContainerAttractions,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.offers,
    component: OffersContainerAttractions,
    layout: React.Fragment,
  },
  {
    path: SUB_PATHS.ticketsAndSchedule,
    component: TicketsAndScheduleContainerAttractions,
    layout: React.Fragment,
  },
];

export const routes = [
  {
    path: PATHS.login,
    component: LoginContainer,
    layout: React.Fragment,
    access: ACCESS.public,
  },
  {
    path: PATHS.recoverPassword,
    component: RecoverPasswordContainer,
    layout: React.Fragment,
    access: ACCESS.public,
  },
  {
    path: PATHS.resetPassword,
    component: ResetPasswordContainer,
    layout: React.Fragment,
    access: ACCESS.public,
  },
  {
    path: PATHS.home,
    component: Home,
    access: ACCESS.private,
  },
  {
    path: PATHS.analytics,
    component: Analytics,
    access: ACCESS.private,
  },
  {
    path: PATHS.payments,
    component: Payments,
    access: ACCESS.private,
  },
  {
    path: PATHS.articles,
    component: Articles,
    access: ACCESS.private,
  },
  {
    path: PATHS.content,
    component: Content,
    access: ACCESS.private,
  },
  {
    path: PATHS.events,
    component: Events,
    access: ACCESS.private,
  },
  {
    path: PATHS.venues,
    component: Venues,
    access: ACCESS.private,
  },
  {
    path: PATHS.newVenues,
    component: VenueManagement,
    access: ACCESS.private,
    children: VENUES_STEPS,
  },
  {
    path: PATHS.editVenues,
    component: VenueManagement,
    access: ACCESS.private,
    children: VENUES_STEPS,
  },
  {
    path: PATHS.editEvents,
    component: EventsManagement,
    access: ACCESS.private,
    children: EVENTS_STEPS,
  },
  {
    path: PATHS.newEvents,
    component: EventsManagement,
    access: ACCESS.private,
    children: EVENTS_STEPS,
  },
  {
    path: PATHS.attractions,
    component: Attractions,
    access: ACCESS.private,
  },
  {
    path: PATHS.newAttractions,
    component: AttractionsManagement,
    access: ACCESS.private,
    children: ATTRACTIONS_STEPS,
  },
  {
    path: PATHS.editAttractions,
    component: AttractionsManagement,
    access: ACCESS.private,
    children: ATTRACTIONS_STEPS,
  },
  {
    path: PATHS.offers,
    component: Offers,
    access: ACCESS.private,
  },
  {
    path: PATHS.feeds,
    component: Feeds,
    access: ACCESS.private,
  },
  {
    path: PATHS.seo,
    component: Seo,
    access: ACCESS.private,
  },
  {
    path: PATHS.users,
    component: Users,
    access: ACCESS.private,
  },
  {
    path: PATHS.me,
    component: ProfileContainer,
    access: ACCESS.private,
  },
  {
    path: PATHS.reports,
    component: Reports,
    access: ACCESS.private,
  },
  {
    path: PATHS.locations,
    component: Locations,
    access: ACCESS.private,
  },
  {
    path: PATHS.banners,
    component: Banners,
    access: ACCESS.private,
  },
  {
    path: PATHS.services,
    component: Services,
    access: ACCESS.private,
  },
  {
    path: PATHS.comments,
    component: Comments,
    access: ACCESS.private,
  },
];
