import { useOptionsContext } from "hooks/useOptionsContext";
import { Flex, Spinner } from "@chakra-ui/react";

const destruct = (obj, ...keys) =>
  keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});

export default function EnsureOptions({
  children,
  options = [],
  spinner = false,
}) {
  const context = useOptionsContext();
  const neededOptions = Object.values(destruct(context, ...options));
  if (neededOptions.every((item) => item.length)) return children;

  if (spinner)
    return (
      <Flex justify="center" marginTop={100} h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  return null;
}
