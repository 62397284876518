import { getDefaultLocation } from "utils/getDefaultLocation";

export const transformBeforeEditLocation = (editLocation, countries) => {
  if (editLocation) {
    const textData = editLocation.tr?.reduce(
      (acc, valueObj) => {
        acc.title[valueObj.language.code] = valueObj.title;
        acc.description[valueObj.language.code] = valueObj.description;

        return acc;
      },
      {
        title: {},
        description: {},
      }
    );

    return {
      title: textData?.title,
      description: textData?.description,
      language: { label: "English", value: { id: 1, code: "en-US" } },
      address: editLocation.address,
      country: getDefaultLocation(countries, editLocation.city.id)
        .defaultCountry,
      city: getDefaultLocation(countries, editLocation.city.id).defaultCity,
      image: {
        preview: `${process.env.REACT_APP_API_URL}/${editLocation?.image}`,
      },
      isActive: editLocation.active,
    };
  }
  return {};
};
