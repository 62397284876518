import React, { useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import TableProvider from "contexts/table/table.provider";
import TablePageSetter from "components/Table/TablePageSetter";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import { COLUMNS, ROWS_PER_PAGE } from "pages/Banners/Ads/adsTable.config";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import { useGetAllAds } from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import { transformAdsData, transformBannersListSortState } from "connectors";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function AdsListContainer() {
  const [sortState, setSortState] = useState();

  const methods = useForm();
  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const search = methods.watch("search");

  const { data: adsData, loading } = useGetAllAds({
    variables: {
      options: {
        search: useDebounce(search) || null,
        limit: queryPageSize,
        offset: offsetData,
        order: transformBannersListSortState(sortState),
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAdsData(adsData?.getAllAds?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={adsData?.getAllAds?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <Flex justify="space-between" mb={6} mt={10}>
        <FormProvider {...methods}>
          <TextFieldRHF
            name="search"
            inputType="text"
            inputPlaceholder="Search client..."
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
            maxWidth="480px"
          />
        </FormProvider>
        <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
      </Flex>

      {loading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : adsData?.getAllAds?.rows?.length ? (
        <>
          <TableBody hasSorting={true} />
          <TablePagination />
        </>
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default AdsListContainer;
