import {
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Grid,
  GridItem,
  Flex,
  Spacer,
  HStack,
  Container,
  Box,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import SwitchRHF from "components/react-hook-form/SwitchRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextareaRHF from "components/react-hook-form/TextareaRHF";
import TextEditorRHF from "components/react-hook-form/TextEditorRHF";
import DatePickerRHF from "components/react-hook-form/DatePickerRHF";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";
import LanguageIndicators from "components/LanguageIndicators";
import CreatableMultiSelectRHF from "components/react-hook-form/CreatableMultiSelectRHF";
import { useFormContext } from "react-hook-form";

export default function ArticleCreateEditModal(props) {
  const {
    onSubmit,
    cancelHandler,
    isEditMode,
    languageChangeHandler,
    languageOptions,
    selectedLanguage,
    categoriesOptions,
    statusOptions,
  } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gridAutoRows="auto"
            gap={6}
            w="full"
          >
            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="title"
                name={`title.${useGetLanguageKey(selectedLanguage)}`}
                inputType="text"
                label="Title"
                inputPlaceholder="Create article`s title"
              />
            </GridItem>

            <GridItem colSpan={4}>
              <SelectRHF
                name="language"
                label="Content language"
                options={languageOptions}
                placeholder="English (en-US) - required"
                onChangeHandler={languageChangeHandler}
              />
            </GridItem>

            <GridItem colSpan={3} sx={{ position: "relative" }}>
              <TextFieldRHF
                id="url"
                name="url"
                inputType="text"
                label="Custom URL - hypt.com/..."
                inputPlaceholder="Create custom URL"
                errorMessage={errors?.url?.message}
              />
            </GridItem>

            <GridItem colSpan={1} sx={{ position: "relative" }}>
              <Flex justifyContent="center">
                <SwitchRHF
                  name="show_on_main"
                  label="Show on Main"
                  vertical
                  labelColor="dark.500"
                />
              </Flex>
            </GridItem>

            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="meta_title"
                name={`meta_title.${useGetLanguageKey(selectedLanguage)}`}
                inputType="text"
                label="Meta title"
                inputPlaceholder="Add meta title"
              />
            </GridItem>

            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="meta_description"
                name={`meta_description.${useGetLanguageKey(selectedLanguage)}`}
                inputType="text"
                label="Meta description"
                inputPlaceholder="Add meta description"
              />
            </GridItem>

            <GridItem colSpan={4}>
              <HStack>
                <DropzoneFieldRHF
                  width="188px"
                  height="188px"
                  name="promo_image"
                  label="Promo image"
                  acceptStr="image/jpeg,image/png"
                />
                <Container sx={{ position: "relative" }}>
                  <LanguageIndicators selectedLanguage={selectedLanguage} />
                  <TextareaRHF
                    id="promo_text"
                    name={`promo_text.${useGetLanguageKey(selectedLanguage)}`}
                    label="Promo Text"
                    width="308px"
                    height="186px"
                    placeholder="Add some text"
                  />
                </Container>
              </HStack>
            </GridItem>

            {isEditMode ? (
              <GridItem colSpan={4}>
                <CreatableMultiSelectRHF
                  id="tags"
                  name="tagsEdit"
                  label="Tags"
                  placeholder="Add tag"
                />
              </GridItem>
            ) : (
              <GridItem colSpan={4}>
                <TextFieldRHF
                  id="tags"
                  name="tagsCreate"
                  inputType="text"
                  label="Tags"
                  inputPlaceholder="add tags, separate by comma"
                />
              </GridItem>
            )}

            <GridItem colSpan={4}>
              <SelectRHF
                name="category"
                label="Article category"
                placeholder="Select category"
                options={categoriesOptions}
              />
            </GridItem>

            <GridItem colSpan={4}>
              <DropzoneFieldRHF
                width="100%"
                height="170px"
                name="main_image"
                label="Main image"
                acceptStr="image/jpeg,image/jpg,image/png"
              />
            </GridItem>
            <GridItem colSpan={4} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextEditorRHF
                name={`main_text.${useGetLanguageKey(selectedLanguage)}`}
                label="Main text"
              />
            </GridItem>

            <GridItem colSpan={4}>
              <Box>
                <DatePickerRHF
                  name="publication_date"
                  label="Publication date"
                  minDate={new Date()}
                  placeholder="Add publication date"
                />
              </Box>
            </GridItem>

            <GridItem colSpan={4}>
              <SelectRHF
                name="status"
                label="Status"
                options={statusOptions}
                placeholder="Select status"
              />
            </GridItem>

            <GridItem colSpan={1}>
              <DropzoneFieldRHF
                width="188px"
                height="188px"
                name="author_avatar"
                label="Author avatar"
                acceptStr="image/jpeg,image/png"
              />
            </GridItem>

            <GridItem colSpan={3}>
              <TextFieldRHF
                id="author"
                name="author_name"
                inputType="text"
                label="Author"
                inputPlaceholder="Add article`s author"
              />
            </GridItem>
          </Grid>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Flex w="100%">
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            Cancel
          </Button>
          <Spacer />
          <Button type="submit">
            {isEditMode ? "Update" : "Create Article"}
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
