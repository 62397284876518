import { gql } from "@apollo/client";

export const UPDATE_VENUE_HALL = gql`
  mutation UpdateVenueHall($input: UpdateVenueHall) {
    venueHall: updateVenueHall(input: $input) {
      id
      hall
      image
      description
      venueId
    }
  }
`;
