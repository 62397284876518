import VouchersContainer from "containers/venues/vouchers.container";
import TicketsContainer from "containers/venues/tickets.container";

export const TabsConfig = [
  {
    id: "one",
    index: 0,
    path: "tickets",
    label: "Tickets",
    content: TicketsContainer,
  },
  {
    id: "two",
    index: 1,
    path: "vouchers",
    label: "Vouchers",
    content: VouchersContainer,
  },
];
