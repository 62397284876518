export const transformVenueListOptions = (data = []) => {
  if (data) {
    return data?.map((option) => {
      return {
        value: option.id,
        label: option.title,
      };
    });
  }
  return [];
};
