export const transformFieldsLangsFormat = (
  languages,
  tr,
  fieldsArr,
  JSONField
) => {
  const sortedTr = [...tr].sort((a, b) => a.language.id - b.language.id);
  const multiTextArr = languages.map((currentLeng, index) => {
    const fie = fieldsArr.reduce((acc, field) => {
      acc[field] =
        sortedTr?.[index]?.[field] ||
        (field === JSONField ? JSON.stringify({ text: "" }) : "");
      return acc;
    }, {});

    return { ...fie, language: currentLeng };
  });

  return multiTextArr;
};
