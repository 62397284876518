import React from "react";
import TableProvider from "contexts/table/table.provider";
import ScheduleTableAttractions from "pages/Attractions/AttractionsManagement/TicketsAndSchedule/ScheduleTable";
import { COLUMNS } from "pages/Attractions/AttractionsManagement/TicketsAndSchedule/ScheduleTable/table.config";
import { useAttractionContext } from "hooks/useAttractionContext";
import ModalButton from "components/ModalButton";
import { useGetAllAttractionSchedules } from "graphql/hooks/useQueries";
import { Flex, Spinner } from "@chakra-ui/react";
import { transformAttractionSchedules } from "connectors";
import NoContentMessage from "components/TableComponents/NoContentMessage";

function ScheduleContainerAttractions() {
  const { getAttractionId } = useAttractionContext();

  const { data, loading } = useGetAllAttractionSchedules({
    skip: !getAttractionId(),
    variables: {
      attractionId: getAttractionId(),
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAttractionSchedules(data?.getAllAttractionSchedules),
      }}
    >
      <Flex w="full" justify="flex-end" align="center" mb={7} mt={5}>
        {getAttractionId() ? (
          <ModalButton
            modalName="createEditScheduleModal"
            label={
              data?.getAllAttractionSchedules.length
                ? "Edit schedule"
                : "Create schedule"
            }
            isEdit={!!data?.getAllAttractionSchedules.length}
            minW="157px"
            data={
              data?.getAllAttractionSchedules.length
                ? {
                    isEditMode: true,
                    editSchedule: data?.getAllAttractionSchedules,
                    attractionId: getAttractionId(),
                  }
                : { attractionId: getAttractionId() }
            }
          />
        ) : null}
      </Flex>
      {loading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : data?.getAllAttractionSchedules.length ? (
        <ScheduleTableAttractions />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default ScheduleContainerAttractions;
