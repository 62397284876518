export const transformReportsListSortState = (state) => {
  if (!state) {
    return null;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };

  if (state.id === "type") requestObj.field = "modelName";

  return requestObj;
};
