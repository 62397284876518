export const getDefaultLocation = (countries, cityId) => {
  let defaultCountry = null;
  let defaultCity = null;

  countries?.forEach((country) =>
    country?.Cities?.forEach((city) => {
      if (city?.id === cityId) {
        defaultCity = {
          value: city?.id,
          label: city?.name,
        };
        defaultCountry = {
          value: country.id,
          label: country.name,
        };
      }
    })
  );

  return {
    defaultCountry,
    defaultCity,
  };
};
