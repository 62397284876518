import { Flex, MenuItem } from "@chakra-ui/react";

const customMenuIconWrap = {
  justifyContent: "center",
  alignItems: "center",
  w: "10",
  h: "10",
  mr: "2.5",
  borderRadius: "xl",
  backgroundColor: "white.500",
  _groupHover: { backgroundColor: "blue.300" },
};

export default function CustomMenuItem({
  icon,
  onMenuItemClick,
  children,
  isDisabled = false,
}) {
  return (
    <MenuItem
      isDisabled={isDisabled}
      style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
    >
      <Flex
        alignItems="center"
        width="100%"
        role="group"
        onClick={isDisabled ? null : onMenuItemClick}
      >
        {icon && <Flex {...customMenuIconWrap}>{icon}</Flex>}
        {children}
      </Flex>
    </MenuItem>
  );
}
