import { getAuthorFormat } from "utils/getAuthorFormat";

export const transformGetAllPosts = (responseData = []) => {
  if (responseData) {
    const checkAttachments = (photo, video) => {
      const result = { hasPhoto: false, hasVideo: false };
      if (photo) {
        result.hasPhoto = true;
      }
      if (video) {
        result.hasVideo = true;
      }
      return result;
    };

    return responseData.map((item) => {
      return {
        title: item.title,
        author: getAuthorFormat(item.postAuthor),
        authorId: item.postAuthor?.id,
        date: item.publicationDate ? item.publicationDate : item.createdAt,
        attachment: checkAttachments(item.image, item.video),
        views: item.viewsCount,
        comments: item.commentCount,
        likes: item.likeCount,
        // --- for editing post ---
        companyId:
          item.publisher?.__typename === "Company" ? item.publisher?.id : null,
        id: item.id,
        text: item.text,
        image: item.image,
        video: item.video,
        tags: item.postTags,
      };
    });
  }
  return [];
};
