export const transformRestrictionsOptions = (data = []) => {
  if (data) {
    return data.map((restriction) => ({
      value: restriction.id,
      label: restriction.name,
      icon: restriction.image,
    }));
  }
  return [];
};
