import { Badge } from "@chakra-ui/react";
import { useMemo } from "react";

const variants = [
  { status: "active", variant: "statusGreen" },
  { status: "new", variant: "statusYellow" },
  { status: "pending", variant: "statusYellow" },
  { status: "paused", variant: "statusRed" },
  { status: "available", variant: "statusGreen" },
  { status: "scheduled", variant: "statusGreen" },
  { status: "finished", variant: "statusRed" },
  { status: "sold_out", variant: "statusYellow" },
  { status: "hidden", variant: "statusRed" },
  { status: "approve", variant: "statusGreen" },
  { status: "not_approve", variant: "statusRed" },
  { status: "moderation", variant: "statusYellow" },
  { status: "closed", variant: "statusGrey" },
  { status: "best price", variant: "statusGreen" },
  { status: "reviewed", variant: "statusGreen" },
  { status: "auto_approve", variant: "statusYellow" },
  { status: "auto_not_approve", variant: "statusYellow" },
];

export default function Status({ value }) {
  const variant = useMemo(() => {
    return variants.find((item) => item.status === value)?.variant;
  }, [value]);

  return (
    <Badge variant={variant || "otherStatus"}>
      {value.replace(/_/gi, " ")}
    </Badge>
  );
}
