import * as yup from "yup";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";

export const EDIT_COMMENT_SCHEMA = yup.object().shape({
  title: yup.string(),

  comment_text: yup
    .string()
    .required("Comment`s text is required")
    .test(
      "minLength",
      "Min 1",
      (value) => cleanTextFromHTML(value).length >= 1
    ),

  author: yup.string(),

  publication_date: yup.string().nullable(),
});
