import { Box, Flex, Image, Text } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import SearchField from "components/Search/SearchField";
import { useOptionsContext } from "hooks/useOptionsContext";
import { useMemo, useState } from "react";
import useGetRestrictions from "hooks/useGetRestrictions.hook";
import useGetServices from "hooks/useGetServices.hook";

export default function Services() {
  useGetRestrictions();
  useGetServices();

  const { restrictions, services } = useOptionsContext();
  const [searchValue, setSearchValue] = useState("");

  const searchHandler = (e) => {
    setSearchValue(e.target.value);
  };
  return (
    <Box px={10} py={6} w="full">
      <PageHeader mb={6}>Services and restrictions</PageHeader>
      <SearchField
        w="480px"
        placeholder="Search services and restrictions"
        onChange={searchHandler}
      />
      <Flex mt="40px">
        <ServicesColumn
          searchValue={searchValue}
          services={services}
          title="SERVICES"
          sx={{ mr: "188px" }}
        />
        <ServicesColumn
          searchValue={searchValue}
          services={restrictions}
          title="RESTRICTIONS"
        />
      </Flex>
    </Box>
  );
}

function ServicesColumn({ services = [], sx, title, searchValue }) {
  const filteredList = useMemo(() => {
    return services.filter((service) =>
      service.name.match(new RegExp(searchValue, "i"))
    );
  }, [services, searchValue]);
  return (
    <Box sx={sx}>
      <Text fontSize="md" fontWeight="medium">
        {title}
      </Text>
      <Box mt="25.5px">
        {filteredList.length ? (
          filteredList.map((service) => {
            return (
              <Flex mb="9.5px" key={service.id}>
                <Box mr="8px">
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/${service.image}`}
                    alt={service.name}
                  />
                </Box>
                <Text>{service.name}</Text>
              </Flex>
            );
          })
        ) : (
          <Box>Not found</Box>
        )}
      </Box>
    </Box>
  );
}
