import { Flex, Text, Image } from "@chakra-ui/react";
import CHS from "./chakra.styles";
import Options from "./Options";
import IsLocationActive from "./IsLocationActive";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ value, row: { original } }) => {
      return (
        <Flex sx={CHS.addressSplitActiveFalse}>
          {original.image && (
            <Image
              sx={CHS.imageStyle}
              src={`${process.env.REACT_APP_API_URL}/${original.image}`}
            />
          )}

          <Text
            sx={
              original.active
                ? CHS.addressSplitActiveTrue
                : CHS.addressSplitActiveFalse
            }
          >
            {value || "—"}
          </Text>
        </Flex>
      );
    },
  },
  {
    Header: "City",
    accessor: "cityTable",
    disableSortBy: false,
    Cell: ({ value, row: { original } }) => {
      return (
        <Flex>
          <Text
            sx={
              original.active
                ? CHS.addressSplitActiveTrue
                : CHS.addressSplitActiveFalse
            }
          >
            {value || "not available"}
          </Text>
        </Flex>
      );
    },
  },

  {
    Header: "Address",
    accessor: "address",
    disableSortBy: true,
    Cell: ({ value, row: { original } }) => {
      return (
        <Flex>
          <Text
            sx={
              original.active
                ? CHS.addressSplitActiveTrue
                : CHS.addressSplitActiveFalse
            }
          >
            {value || "not available"}
          </Text>
        </Flex>
      );
    },
  },
  {
    Header: "Active",
    accessor: "active",
    disableSortBy: false,
    Cell: ({ row }) => {
      return (
        <IsLocationActive
          rowId={row.original?.id}
          active={row.original?.active}
          {...row}
        />
      );
    },
  },
  {
    Header: "Options",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options row={row} />;
    },
  },
];
