import { DEFAULT_ENGLISH_LANGUAGE_ID } from "constants/constants";

export const transformGetAllVenueEvents = (data = []) => {
  if (data?.rows) {
    return data?.rows?.map((item) => ({
      id: item?.id,
      url: item?.url,
      image: item?.image,
      name: item?.tr.find(
        (object) => object.language?.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.title,
      description: item?.tr.find(
        (object) => object.language?.id === DEFAULT_ENGLISH_LANGUAGE_ID
      )?.description,
    }));
  }
  return [];
};
