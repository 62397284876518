import { Box, Flex, HStack } from "@chakra-ui/react";
import TablePageSetter from "components/Table/TablePageSetter";
import TableBody from "components/Table/TableBody";
import TablePagination from "components/Table/TablePagination";
import ModalButton from "components/ModalButton";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";
import { useEventContext } from "hooks/useEventContext.hook";
import React from "react";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { ROWS_PER_PAGE } from "./table.config";

function FloorMapEvents({ isEventId, hasData }) {
  const { event } = useEventContext();
  return (
    <>
      <Flex w="full" align="center" mb={7}>
        <TextFieldRHF
          name="search"
          inputType="text"
          inputPlaceholder="Search sector"
          inputLeftElement={
            <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
          }
          inputProps={{ maxW: "480px" }}
        />
        <HStack spacing={4} width="100%" justifyContent="flex-end">
          <TablePageSetter arrayOfNumbers={ROWS_PER_PAGE} />
          {isEventId && (
            <ModalButton
              modalName="sectorManagementModal"
              label="Add sector"
              minW="144px"
              data={{ id: event.id }}
            />
          )}
        </HStack>
      </Flex>

      <Box w="full">
        {hasData ? (
          <>
            <TableBody hasSorting />
            <TablePagination />
          </>
        ) : (
          <Flex alignItems="center" justify="center" h="100px">
            <NoContentMessage />
          </Flex>
        )}
      </Box>
    </>
  );
}

export default FloorMapEvents;
