import { gql } from "@apollo/client";

export const GET_ALL_HALLS = gql`
  query getAllHalls($options: GetAllHalls) {
    halls: getAllHalls(options: $options) {
      count
      rows {
        id
        name: hall
        image
        description
        venueId
        zonesCount: areas
        tables
        seats
        zones: venueHallArea {
          zoneId: id
          zoneName: area
          venueHallId
          ticketType
          venueZoneTable: venueAreaTable {
            tableId: id
            table
            seats
            venueZoneId: venueAreaId
          }
        }
      }
    }
  }
`;
