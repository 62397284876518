import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Box,
  Flex,
  Spacer,
  Text,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ValidationNotification from "components/ValidationNotification";
import { ArrowLeftIcon } from "components/Icons";
import { useCallback, useEffect } from "react";
import {
  useCreateOwners,
  useDeletePhotosOwners,
  useEditOwners,
} from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { GET_ALL_COMPANIES } from "graphql/queries";
import {
  transformAddCompanyOwners,
  transformEditCompanyOwners,
  transformReceivedCreateOwners,
} from "connectors";
import { useCompanyContext } from "hooks/useCompanyContext.hook";
import { initialValue } from "contexts/company/company.provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADD_NEW_OWNERS_SCHEMA } from "schemas";
import OwnerInfoFields from "./OwnerInfoFields";

export default function AddOwnerInfo() {
  const {
    company,
    updateCompanyState,
    getOwners,
    getCompanyId,
    clearCompanyState,
  } = useCompanyContext();

  const { modal: ownerInfo, closeModal: closeOwnerInfo } =
    useModalContext("addOwnerInfo");

  const { openModal: openModalCompany } = useModalContext(
    "createNewCompanyModal"
  );

  const { openModal: openAddUserModal } = useModalContext(
    "addUsersToNewCompany"
  );

  const { companyName } = company.addNewCompany;

  const ownerId = getOwners()?.owners.find((item) => item.id);

  const methods = useForm({
    defaultValues: ownerId ? getOwners() : initialValue.owners,
    resolver: yupResolver(ADD_NEW_OWNERS_SCHEMA),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const closeOwnerInfoHandler = () => {
    clearCompanyState();
    methods.reset(initialValue.owners);
    closeOwnerInfo();
  };

  const cancelOwnerInfoHandler = () => {
    methods.reset(getOwners());
    openModalCompany();
    closeOwnerInfo();
  };

  const { fields, append } = useFieldArray({ control, name: "owners" });

  const handleAddNewOwner = useCallback(() => {
    append({
      owner: "",
      birthDate: "",
      share: null,
      passport: "",
      passportPhoto: null,
    });
  }, [append]);

  const [createOwners] = useCreateOwners();
  const [editOwners] = useEditOwners();
  const [deletePhotoCompany] = useDeletePhotosOwners();

  const onDeleteCallback = ({ id }) => {
    if (id) {
      deletePhotoCompany({
        variables: {
          photoId: id,
        },
      });
    }
  };

  const ownerInfoSubmit = (ownerInfoData) => {
    if (!ownerId) {
      createOwners({
        variables: {
          companyId: getCompanyId(),
          usersInfo: transformAddCompanyOwners(ownerInfoData),
        },
        refetchQueries: [GET_ALL_COMPANIES],
        onCompleted: (response) => {
          updateCompanyState(
            transformReceivedCreateOwners({
              responseData: response?.createOwners,
            })
          );
          openAddUserModal();
          closeOwnerInfo();
        },
      });
    } else {
      editOwners({
        variables: {
          companyId: getCompanyId(),
          usersInfo: transformEditCompanyOwners(ownerInfoData),
        },
        refetchQueries: [GET_ALL_COMPANIES],
        onCompleted: (response) => {
          updateCompanyState(
            transformReceivedCreateOwners({
              responseData: response?.editOwners,
            })
          );
          openAddUserModal();
          closeOwnerInfo();
        },
      });
    }
  };

  useEffect(() => {
    if (company.id) {
      methods.reset(getOwners());
    }
  }, [company.id, ownerInfo.isOpen]);

  return (
    <FormProvider {...methods}>
      <Modal isOpen={ownerInfo.isOpen} onClose={closeOwnerInfoHandler}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Heading>{`${companyName} - owner info`}</Heading>
              <Spacer />
              <ValidationNotification icon={false} m={0}>
                <Text fontSize="md" color="red.500">
                  {Object.values(errors)[0]?.message}
                </Text>
              </ValidationNotification>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(ownerInfoSubmit)}>
            <ModalBody>
              <VStack spacing={6}>
                {fields.map((field, index, array) => {
                  return (
                    <OwnerInfoFields
                      key={field.id}
                      name="owners"
                      index={index}
                      field={field}
                      onDeleteCallback={onDeleteCallback}
                      isBorder={array.length - 1 !== index}
                    />
                  );
                })}
                <Button type="button" onClick={handleAddNewOwner}>
                  Add owner
                </Button>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Flex w="100%" px={6}>
                <Button
                  onClick={cancelOwnerInfoHandler}
                  variant="outline"
                  colorScheme="blue.500"
                >
                  <Box mr={4}>
                    <ArrowLeftIcon stroke="currentColor" />
                  </Box>
                  Back
                </Button>
                <Spacer />
                <Button type="submit">
                  Users{" "}
                  <Box ml={4} transform="rotate(180deg)">
                    <ArrowLeftIcon stroke="currentColor" />
                  </Box>
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}
