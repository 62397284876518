import { gql } from "@apollo/client";

export const GET_OFFER_BY_ID = gql`
  query getOfferById($id: Int!) {
    getOfferById(id: $id) {
      id
      type
      url
      isAddToSlider
      image
      geolocation
      price
      oldPrice
      currencyId
      createdBy
      categoryId
      createdAt
      photo
      isLiked
      placeDetails {
        address
      }
      status
      countUsedOffer
      isForInfluencerOnly
      forInfluencerCount
      influencerLevel
      tr {
        id
        languageId
        language {
          id
          name
          code
        }
        title
        description
        shortDescription
      }
      offerSEO {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
    }
  }
`;
