import { ModalBody, VStack } from "@chakra-ui/react";
import SelectRHF from "components/react-hook-form/SelectRHF";

function AddEditPromo() {
  return (
    <form>
      <ModalBody>
        <VStack>
          <SelectRHF
            name="client_name"
            label="Client Name"
            options={[
              { value: "Hermanos", label: "Los Pollos Hermanos" },
              { value: "Smith", label: "Adam Smith" },
            ]}
          />
        </VStack>
      </ModalBody>
    </form>
  );
}

export default AddEditPromo;
