import { Grid, GridItem } from "@chakra-ui/react";
import DatePickerRHF from "components/react-hook-form/DatePickerRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { useFormContext } from "react-hook-form";

function OwnerInfoFields(props) {
  const { index, name, onDeleteCallback, isBorder } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Grid
      templateColumns="repeat(6, 1fr)"
      templateRows="repeat(2, auto)"
      columnGap={6}
      rowGap={4}
      w="100%"
      borderBottom={isBorder ? `1px solid #EFEFEF` : "none"}
    >
      <GridItem rowSpan={1} colSpan={6}>
        <TextFieldRHF
          id="owner"
          name={`${name}.${index}.owner`}
          inputType="text"
          label="Owner name"
          inputPlaceholder="Add owner name"
          errorMessage={errors?.owners?.[index]?.owner?.message}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={3}>
        <DatePickerRHF
          name={`${name}.${index}.birthDate`}
          label="Birth date"
          placeholder="Select birth date"
          errorMessage={errors?.owners?.[index]?.birthDate?.message}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={3}>
        <TextFieldRHF
          id="share"
          name={`${name}.${index}.share`}
          inputType="text"
          label="Company share, %"
          inputPlaceholder="Add company share"
          errorMessage={errors?.owners?.[index]?.share?.message}
        />
      </GridItem>
      <GridItem rowSpan={1} colSpan={6}>
        <TextFieldRHF
          id="passport"
          name={`${name}.${index}.passport`}
          inputType="text"
          label="Passport #"
          inputPlaceholder="Add passport info"
          errorMessage={errors?.owners?.[index]?.passport?.message}
        />
      </GridItem>
      <GridItem colSpan={6}>
        <DropzoneFieldRHF
          width="100%"
          height="100%"
          name={`${name}.${index}.passportPhoto`}
          label="Passports photo(s)"
          acceptStr="image/jpeg,image/jpg,image/png"
          multiple
          onDeleteCallback={onDeleteCallback}
        />
      </GridItem>
    </Grid>
  );
}

export default OwnerInfoFields;
