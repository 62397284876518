export default {
  normal: "normal",
  none: 1,
  shorter: 1.28,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: "2",
  3: ".75rem", // 12px
  4: "1rem", // 16px
  5: "1.25rem", // 20px
  6: "1.5rem", // 24px
  7: "1.75rem", // 28px
  8: "2rem", // 32px
  9: "2.25rem", // 36px
  10: "2.5rem", // 40px
  11: "2.75rem", // 44px
  12: "3rem", // 48px
  13: "3.25rem", // 52px
  14: "3.5rem", // 56px
  15: "3.75rem", // 60px
};
