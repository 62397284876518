// import Button from "components/Button";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import HomeBackgroundImage from "assets/home-background.png";
import CHS from "./chakra.styles";
// import { useToastContext } from "hooks/useToastContext";
// import { FacebookIcon } from "components/Icons";

export default function Home() {
  // For testing TOASTS work
  // const { showToast } = useToastContext();

  // const showSuccess = () => {
  //   showToast({
  //     description: "Account information updated",
  //     status: "success",
  //     customIcon: <FacebookIcon fill="white" />,
  //     title: "Success title",
  //   });
  // };

  // const showError = () => {
  //   showToast({
  //     description: "Error description",
  //     status: "error",
  //     customIcon: <FacebookIcon fill="white" />,
  //     title: "Error title",
  //   });
  // };

  // const showWarning = () => {
  //   showToast({
  //     description: "Warning",
  //     status: "warning",
  //   });
  // };

  // const showInfo = () => {
  //   showToast({
  //     description:
  //       "Account information updatedAccountiion updatedAccount information updatedAccount information updated",
  //     status: "info",
  //   });
  // };

  return (
    <Box>
      {/* <Button onClick={showSuccess}>Success</Button>
      <Button onClick={showError}>Error</Button>
      <Button onClick={showWarning}>Warninig</Button>
      <Button onClick={showInfo}>Info</Button> */}
      <Flex sx={CHS.homeContainer}>
        <Text sx={CHS.homeTitle}>Welcome to</Text>
        <Image sx={CHS.homeBackgroundImage} src={HomeBackgroundImage} />
        <Text sx={CHS.homeTitle}>Admin dashboard</Text>
      </Flex>
    </Box>
  );
}
