export default {
  none: "0",
  px: "1px",
  0.5: "0.125rem", // 2px
  0.75: "0.188rem", // 3px
  1: "0.25rem", // 4px
  1.25: "0.313rem", // 5px
  1.5: "0.375rem", // 6px
  1.75: "0.438rem", // 7px
  2: "0.5rem", // 8px
  2.25: "0.563rem", // 9px
  2.5: "0.625rem", // 10px
};
