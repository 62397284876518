import dayjs from "dayjs";
import VideoPreviewSrc from "assets/images/video.png";
import { getAuthorFormat } from "utils/getAuthorFormat";

export const transformBeforeEditPost = (
  editPost,
  editPostFromComments = false
) => {
  if (editPost && !editPostFromComments) {
    return {
      title: editPost.title || "",
      tags: editPost.tags?.map((item) => item.name).join(",") || "",
      main_image: editPost.image
        ? {
            preview: `${process.env.REACT_APP_API_URL}/${editPost?.image}`,
          }
        : null,
      video: editPost.video
        ? {
            preview: VideoPreviewSrc,
          }
        : null,
      main_text: editPost.text || "",
      authorOnEdit: editPost.author || "",
      publication_date: editPost.date ? dayjs(editPost.date)?.toDate() : "",
    };
  }
  if (editPost && editPostFromComments) {
    return {
      title: editPost.title || "",
      tags: editPost.postTags?.map((item) => item.name).join(",") || "",
      main_image: editPost.image
        ? {
            preview: `${process.env.REACT_APP_API_URL}/${editPost?.image}`,
          }
        : null,
      video: editPost.video
        ? {
            preview: VideoPreviewSrc,
          }
        : null,
      main_text: editPost.text || "",
      authorOnEdit: getAuthorFormat(editPost.postAuthor),
      publication_date: editPost.publicationDate
        ? dayjs(editPost.publicationDate)?.toDate()
        : dayjs(editPost.createdAt)?.toDate(),
    };
  }
  return {};
};
