import TextField from "components/TextField";
import { useEffect, useState, useCallback, useRef } from "react";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { debounce } from "hooks/useDebounce";
import Results from "./Results";

function GoogleAutocomplete({
  autoCompleteValue,
  onChange,
  hasError,
  wrapperProps,
  label,
  inputPlaceholder,
}) {
  const [inputValue, setInputValue] = useState(
    autoCompleteValue?.description || ""
  );
  const [areResultsOpen, setResultsOpen] = useState(false);
  const [placePredictions, setPlacePredictions] = useState([]);

  const isMounted = useRef(false);

  const getPlace = useCallback(
    debounce((input) => {
      fetch(`${process.env.REACT_APP_GOOGLE_PLACE_AUTOCOMPLETE}?input=${input}`)
        .then((response) => response.json())
        .then((data) => {
          setPlacePredictions(data.predictions);
        })
        .catch((error) => console.log(error));
    }),
    []
  );

  useEffect(() => {
    if (isMounted.current === true) {
      getPlace(inputValue);
    }
  }, [getPlace, inputValue]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const handleChange = ({ currentTarget: { value } }) => {
    const shouldOpenResults = !areResultsOpen && Boolean(value);

    setInputValue(value);
    if (shouldOpenResults) setResultsOpen(true);

    // if there is no value => close results
    if (!value) {
      setResultsOpen(false);
      onChange(undefined);
    }
  };

  const handleResultClick = (item) => () => {
    setInputValue(item?.description);
    setResultsOpen(false);
    onChange(item);
  };

  useEffect(() => {
    setInputValue(autoCompleteValue?.description || "");
  }, [autoCompleteValue?.description]);

  return (
    <Box position="relative" w="full">
      <FormControl isInvalid={hasError} {...wrapperProps}>
        {label ? <FormLabel>{label}</FormLabel> : null}
        <TextField
          value={inputValue}
          onChange={handleChange}
          zIndex={2}
          hasError={hasError}
          inputPlaceholder={inputPlaceholder}
        />
        <Results
          areResultsOpen={areResultsOpen}
          placePredictions={placePredictions}
          handleResultClick={handleResultClick}
        />
      </FormControl>
    </Box>
  );
}

export default GoogleAutocomplete;
