import * as yup from "yup";

const schema = yup.object().shape({
  title: yup.string().required("Gallery name is required"),
  url: yup.string().required("Url is required"),
  category: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Category is required!")
    .nullable(),
  date: yup.string().required("Date is required"),
  photos: yup
    .array()
    .of(
      yup.object().shape({
        image: yup.mixed().required("A file is required"),
      })
    )
    .min(1, "A file is required")
    .test("preview", "has preview", (value) => {
      return value?.every((item) => {
        return typeof item?.image?.preview === "string";
      });
    }),
});

export default schema;
