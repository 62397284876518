import { gql } from "@apollo/client";

export const GET_ALL_POSTS = gql`
  query GetAllPosts($search: String, $limit: Int, $offset: Int, $sort: Sort) {
    getAllPosts(search: $search, limit: $limit, offset: $offset, sort: $sort) {
      count
      rows {
        id
        title
        postAuthor {
          ... on Company {
            __typename
            id
            companyName
            image
          }
          ... on User {
            __typename
            id
            firstName
            lastName
            photo
            company {
              companyName
            }
          }
        }
        publisher {
          ... on Company {
            __typename
            id
            companyName
            image
          }
          ... on User {
            __typename
            id
            firstName
            lastName
            photo
          }
        }
        createdAt
        publicationDate
        image
        video
        viewsCount
        commentCount
        likeCount
        # --- for editing post ---
        id
        text
        postTags {
          id
          name
        }
      }
    }
  }
`;
