import { gql } from "@apollo/client";

export const GET_ALL_EVENT_SECTORS_BY_EVENT_ID = gql`
  query getAllEventSectorsByEventId($options: GetEventSectorsByEventId) {
    sectors: getAllEventSectorsByEventId(options: $options) {
      rows {
        id
        sector
        shortDescription
        image
        eventId
        eventSectorRowCount
        eventSectorRowPlaceCount
        eventSectorRow {
          id
          placeCount
          eventSectorRowPlace {
            id
            place
          }
        }
      }
      count
    }
  }
`;
