import { Box, Flex, Text } from "@chakra-ui/react";
import { ViewsIcon } from "components/Icons";
import dayjs from "dayjs";
import Status from "components/TableComponents/Status";
import TableLink from "components/TableComponents/Link";
import Options from "./Options/Options";
import SwitchArticleShowOnMain from "./SwitchArticleShowOnMain";
import CustomTag from "./CustomTag";
import CHS from "./Options/chakra.styles";

export const COLUMNS = [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row }) => {
      if (row.values.status === "AVAILABLE") {
        return (
          <TableLink
            value={row?.original.title}
            path={`${process.env.REACT_APP_API_URL}/articles/${row?.original.url}`}
            variant="tableLink2"
            sx={CHS.urlSplit}
          />
        );
      }
      return (
        <Text color="blue.500" sx={CHS.urlSplit}>
          {row?.original.title}
        </Text>
      );
    },
  },
  {
    Header: "Author",
    accessor: "authorName",
    Cell: ({ value }) => {
      return <Box sx={CHS.urlSplit}>{value}</Box>;
    },
  },
  {
    Header: "URL",
    accessor: "url",
    Cell: ({ value }) => {
      return <Box sx={CHS.urlSplit}>{value}</Box>;
    },
  },
  {
    Header: () => {
      return (
        <Flex
          justify="center"
          align="start"
          display="inline-flex"
          position="relative"
        >
          <ViewsIcon
            width="15px"
            height="15px"
            mr={1.25}
            position="absolute"
            top="2px"
            left="-20px"
          />
          <Box>Views</Box>
        </Flex>
      );
    },
    accessor: "viewsCount",
  },
  {
    Header: "Published",
    accessor: "publicatedAt",
    Cell: ({ value }) => {
      return dayjs(value).format("DD/MM/YYYY hh:mm");
    },
  },
  {
    Header: "Tags",
    accessor: "tags",
    disableSortBy: true,
    Cell: ({ value, row }) => {
      return <CustomTag data={value} rowId={row?.original?.id} />;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      return <Status value={value.toLowerCase()} />;
    },
  },
  {
    Header: "Main page",
    accessor: "showOnMain",
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <SwitchArticleShowOnMain
          rowId={row.original?.id}
          showOnMain={row.original?.showOnMain}
        />
      );
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options article={row.original} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
