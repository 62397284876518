export default {
  variants: {
    brand: {
      list: {
        w: "206px",
        py: "3",
        px: "5",
        bgColor: "white.900",
        boxShadow:
          "0px 4px 20px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(177, 177, 177, 0.16)",
      },
      item: {
        display: "flex",
        alignItems: "center",
        w: "100%",
        py: "2",
        px: "0",
        fontFamily: "medium",
        color: "dark.500",
        _hover: {
          bg: "transparent",
          cursor: "pointer",
          color: "blue.500",
        },
        _focus: {
          bg: "transparent",
        },
      },
      divider: {
        my: "2",
      },
    },

    options: {
      list: {
        minW: "max",
        w: "170px",
        h: "auto",
        p: "3",
        px: "5",
        bgColor: "white.900",
        boxShadow:
          "0px 4px 20px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(177, 177, 177, 0.16)",
      },
      item: {
        display: "flex",
        alignItems: "center",
        w: "100%",
        py: "2",
        px: "0",
        fontFamily: "medium",
        color: "dark.500",
        _hover: {
          bg: "transparent",
          cursor: "pointer",
          color: "blue.500",
        },
        _focus: {
          bg: "transparent",
        },
      },
      divider: {
        my: "2",
      },
    },

    tag: {
      list: {
        zIndex: 10,
        w: "355px",
        height: "116px",
        p: 3,
        bgColor: "white.900",
        boxShadow:
          "0px 4px 20px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(177, 177, 177, 0.16)",
      },
      item: {},
      divider: {},
    },
  },

  defaultProps: {
    variant: "brand",
  },
};
