import { Button } from "@chakra-ui/react";
import { EyeOpenIcon } from "components/Icons";
import { PATHS } from "constants/constants";

export default function RedirectFromFeedReview(props) {
  const { feedId } = props;

  const viewHandler = () =>
    window.open(`${process.env.REACT_APP_API_URL}${PATHS.feed}/${feedId}`);

  return (
    <Button
      onClick={viewHandler}
      variant="outline"
      alignSelf="flex-end"
      leftIcon={
        <EyeOpenIcon
          fill="blue.500"
          stroke="white.900"
          width="22px"
          height="22px"
        />
      }
    >
      View
    </Button>
  );
}
