import { multiLangValidate } from "utils/multiLangValidate";
import * as yup from "yup";

const schema = (languages) => {
  return yup.object().shape({
    title: yup
      .object()
      .shape(multiLangValidate(languages, ["description", "shortDescription"])),
    isAddToSlider: yup.boolean(),
    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),
    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Status is required!")
      .nullable(),
    address: yup.object().required("Address is required").nullable(),
    category: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Category is required!")
      .nullable(),
    shortDescription: yup
      .object()
      .shape(multiLangValidate(languages, ["description", "title"])),
    description: yup
      .object()
      .shape(multiLangValidate(languages, ["shortDescription", "title"])),
    mainImage: yup
      .mixed()
      .nullable()
      .required("A image is required")
      .test("fileFormat", "JPEG, JPG or PNG only", (value) => {
        return (
          value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        );
      }),
    url: yup.string().required("URL is required!"),

    metaTitle: yup
      .object()
      .shape(multiLangValidate(languages, ["metaDescription"], "SEO")),

    metaDescription: yup
      .object()
      .shape(multiLangValidate(languages, ["metaTitle"], "SEO")),
    type: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    price: yup.number().required("Price is required"),
    oldPrice: yup.mixed().when("type", {
      is: (type) => type.value === "SALE",
      then: yup
        .number()
        .min(yup.ref("price"))
        .required("Old price is required"),
    }),
    currency: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Currency is required"),
    inf_rank: yup
      .object()
      .nullable()
      .when("userType", {
        is: "influencer",
        then: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .required("Influencer level is required"),
      }),
  });
};

export default { schema };
