// Utility to get the number of displayed rows of the table.
// Pass the number and an array of possible values.
// Returns an object from a value and label.

const getActivePaginatorSetter = (arr, number) => {
  const result = arr.find((item) => item.value === number);
  return result;
};

export default getActivePaginatorSetter;
