import dayjs from "dayjs";

export const transformEditPost = (postId, formData) => {
  if (formData) {
    const requestObj = {
      input: {
        id: postId,
        title: formData.title,
      },
    };

    if (formData.main_text) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          text: formData.main_text,
        },
      });
    }

    if (formData.tags) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          postTags: formData.tags
            ?.split(",")
            .map((item) => item.trim())
            .filter((item) => item),
        },
      });
    }

    if (formData.publication_date) {
      Object.assign(requestObj, {
        input: {
          ...requestObj.input,
          publicationDate: dayjs(formData.publication_date).format(
            "YYYY-MM-DD"
          ),
        },
      });
    }

    if (formData.main_image && Object.keys(formData.main_image).length > 1) {
      Object.assign(requestObj, {
        input: { ...requestObj.input, image: formData.main_image },
      });
    }

    if (formData.video && Object.keys(formData.video).length > 1) {
      Object.assign(requestObj, {
        input: { ...requestObj.input, video: formData.video },
      });
    }

    return requestObj;
  }
  return {};
};
