export const getDefaultOfferType = (offerType, typeOffers = []) => {
  let defaultOfferType = null;

  typeOffers?.forEach(() => {
    if (offerType === "SALE" || offerType === "discount") {
      defaultOfferType = { value: "SALE", label: "Discount" };
    } else if (offerType === "ONE_PLUS_ONE" || offerType === "1+1") {
      defaultOfferType = { value: "ONE_PLUS_ONE", label: "1+1" };
    }
  });
  return defaultOfferType;
};
