import { useContext, useMemo } from "react";
import { useGetAttractionStatuses as useFetchAttractionStatuses } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetAttractionStatuses = () => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, attractionStatuses } = useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return !isAuth || !!attractionStatuses.length;
  }, [isAuth, attractionStatuses.length]);

  useFetchAttractionStatuses({
    onCompleted: (response) => {
      updateOptionsState({
        attractionStatuses: response?.getAttractionStatuses,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetAttractionStatuses;
