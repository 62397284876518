export const transformEditOffer = ({ formData, languages, model }) => {
  if (formData) {
    const multiTextformData = languages.reduce((lang, current) => {
      if (
        formData.title?.[current.code] &&
        formData.description?.[current.code] &&
        formData.shortDescription?.[current.code]
      ) {
        lang.push({
          languageId: current?.id,
          title: formData.title?.[current.code],
          description: formData.description?.[current.code],
          shortDescription: formData.shortDescription?.[current.code],
        });
      }

      return lang;
    }, []);

    const multiSeoContent = languages.reduce((lang, current) => {
      if (
        formData.metaTitle?.[current.code] &&
        formData.metaDescription?.[current.code]
      ) {
        lang.push({
          metaTitle: formData.metaTitle?.[current.code] || "",
          metaDescription: formData.metaDescription?.[current.code] || "",
          languageId: current?.id,
        });
      }
      return lang;
    }, []);

    const oldPhotoArr = formData.slider_photo
      ?.filter((photo) => !photo.path)
      .map((photoObj) =>
        photoObj.preview.replace(`${process.env.REACT_APP_API_URL}/`, "")
      );
    const newPhotoArr = formData.slider_photo?.filter((photo) => photo.path);

    const reguestObj = {
      id: formData.id,
      type: formData.type?.value,
      status: formData.status?.value,
      url: formData.url,
      isAddToSlider: formData.isAddToSlider,
      price: formData.price,
      currencyId: Number(formData.currency.value),
      categoryId: Number(formData.category.value),
      textData: multiTextformData,
      seoContent: multiSeoContent,
      geolocation: formData.address?.place_id,
      influencer: {
        isForInfluencerOnly: formData.userType === "Influencer",
        forInfluencerCount:
          formData.userType === "Influencer"
            ? Number(formData.inf_numberOfUses)
            : 0,
        influencerLevel: formData.inf_rank?.value,
      },
      photo: oldPhotoArr,
    };

    if (formData.mainImage?.preview && formData.mainImage?.path) {
      Object.assign(reguestObj, { image: formData.mainImage });
    }

    if (model) {
      Object.assign(reguestObj, { model });
    }

    if (formData.type.value === "SALE") {
      Object.assign(reguestObj, { oldPrice: Number(formData.oldPrice) });
    }

    if (newPhotoArr.length) {
      Object.assign(reguestObj, { addPhoto: newPhotoArr });
    }

    return reguestObj;
  }
  return {};
};
