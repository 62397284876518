import ScheduleContainer from "containers/attractions/schedule.container";
import TicketsContainer from "containers/attractions/tickets.container";

export const TabsConfig = [
  {
    id: "one",
    index: 0,
    path: "schedule",
    label: "Schedule",
    content: ScheduleContainer,
  },
  {
    id: "two",
    index: 1,
    path: "tickets",
    label: "Tickets",
    content: TicketsContainer,
  },
];
