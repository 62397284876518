import { Grid, GridItem } from "@chakra-ui/react";
import SelectRHF from "components/react-hook-form/SelectRHF";
import Button from "components/Button";
import Filters from "components/Filters";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { GlassIcon } from "components/Icons";

const INITIAL_VALUES = {
  name: "",
  url: "",
  address: "",
  category: "",
  status: "",
};

function EventsFilters({ categoryOptions, statusOptions, methods }) {
  const { reset, handleSubmit, formState } = methods;

  const onSubmit = (data) => {
    console.log("onSubmit", data);
  };

  const onClearClick = () => {
    reset(INITIAL_VALUES);
  };
  return (
    <Filters formSubmit={handleSubmit(onSubmit)}>
      <Grid
        templateColumns="repeat(10, 1fr)"
        templateRows="repeat(2, auto )"
        columnGap={8}
        rowGap={3}
        alignItems="center"
        pb={8}
      >
        <GridItem colSpan={3} pt={6}>
          <TextFieldRHF
            id="name"
            name="name"
            inputType="text"
            inputPlaceholder="Search events"
            inputLeftElement={
              <GlassIcon stroke="dark.300" w="24px" h="24px" fill="none" />
            }
          />
        </GridItem>

        <GridItem colSpan={3}>
          <SelectRHF
            name="category"
            label="Category"
            options={categoryOptions}
            placeholder="Select Category"
            isClearable
          />
        </GridItem>
        <GridItem colSpan={3}>
          <SelectRHF
            name="status"
            label="Status"
            options={statusOptions}
            placeholder="Select Status"
            isClearable
          />
        </GridItem>

        <GridItem colSpan={1} mt={5.5}>
          <Button
            type="reset"
            disabled={!formState.isDirty}
            variant="clear"
            onClick={onClearClick}
          >
            Clear
          </Button>
        </GridItem>
      </Grid>
    </Filters>
  );
}

export default EventsFilters;
