import AttractionProvider from "contexts/attraction/attraction.provider";

const withAttractionProvider = (Component) => {
  // eslint-disable-next-line react/function-component-definition
  return (props) => {
    return (
      <AttractionProvider>
        <Component {...props} />
      </AttractionProvider>
    );
  };
};

export default withAttractionProvider;
