import { Box, Button, Flex } from "@chakra-ui/react";
import { ArrowLeftIcon } from "components/Icons";
import { useContext } from "react";
import stepsContext from "contexts/steps/steps.context";
import { useLocation, useNavigate } from "react-router-dom";

function Actions({ nextLabel = "Next", previousLabel = "Cancel", handleNext }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { prevStep, activeStep } = useContext(stepsContext);

  const handleBackToList = () => {
    navigate(location.pathname.match(/\/(.+?)\//g)[0]);
  };

  return (
    <Flex maxW="xl" w="full" justify="space-between">
      <Button
        variant="outline"
        onClick={activeStep === 0 ? handleBackToList : prevStep}
      >
        {previousLabel !== "Cancel" && (
          <Box mr={4}>
            <ArrowLeftIcon stroke="currentColor" />
          </Box>
        )}
        {previousLabel}
      </Button>

      <Button
        type={typeof handleNext === "function" ? "button" : "submit"}
        onClick={handleNext}
      >
        {nextLabel}
        {nextLabel !== "Finish" && (
          <Box ml={4} transform="rotate(180deg)">
            <ArrowLeftIcon stroke="currentColor" />
          </Box>
        )}
      </Button>
    </Flex>
  );
}

export default Actions;
