import dayjs from "dayjs";

export const transformEventGeneralInfoData = ({
  formData,
  languages,
  isCreate,
}) => {
  if (formData) {
    const validateCorrectTime = () => {
      const day = dayjs(formData?.date).format("YYYY-MM-DD");
      const time = dayjs(formData?.startHour).format(`hh:mm:ssZZ`);
      return `${day}T${time}`;
    };

    const restrictionIds = formData.restrictions?.map((restriction) =>
      Number(restriction.value)
    );

    const serviceIds = formData.services?.map((service) =>
      Number(service.value)
    );

    const multiLanguageContent = languages.reduce((lang, current) => {
      if (
        formData.title?.[current.code] &&
        formData.description?.[current.code]
      ) {
        lang.push({
          languageId: Number(current?.id),
          title: formData.title?.[current.code],
          description: formData.description?.[current.code],
        });
      }

      return lang;
    }, []);

    const multiLanguageSeo = languages.reduce((lang, current) => {
      if (
        formData.meta_title?.[current.code] &&
        formData.meta_description?.[current.code]
      ) {
        lang.push({
          languageId: Number(current?.id),
          metaTitle: formData.meta_title?.[current.code],
          metaDescription: formData.meta_description?.[current.code],
        });
      }

      return lang;
    }, []);

    const oldPhotoArr = formData.slider_photo
      ?.filter((photo) => !photo.path)
      .map((photoObj) =>
        photoObj.preview.replace(`${process.env.REACT_APP_API_URL}/`, "")
      );
    const newPhotoArr = formData.slider_photo?.filter((photo) => photo.path);

    const reqObj = {
      venueId: Number(formData.venue?.value),
      categoryId: Number(formData.eventCategory?.value),
      isTop: formData.isTop,
      isFeatured: formData.isFeatured,
      isUpcoming: formData.isUpcoming,
      isAddToSlider: formData.isAddToSlider,
      textData: multiLanguageContent,
      seoContent: multiLanguageSeo,
      url: formData.url,
      startAt: validateCorrectTime(),
      price: Number(formData.price),
      currencyId: Number(formData.currency?.value),
      geolocation: formData.coordinates.place_id,
      restrictions: restrictionIds,
      services: serviceIds,
      status: formData.status?.value,
      locationId: Number(formData.location?.value),
      photo: isCreate ? newPhotoArr : oldPhotoArr,
    };

    if (newPhotoArr?.length && !isCreate) {
      Object.assign(reqObj, { addPhoto: newPhotoArr });
    }

    if (formData.main_img?.preview && formData.main_img?.path) {
      Object.assign(reqObj, { image: formData.main_img });
    }

    if (formData.id) {
      return {
        ...reqObj,
        id: formData.id,
      };
    }

    return reqObj;
  }
  return {};
};
