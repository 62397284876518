import React, { useState } from "react";
import TableProvider from "contexts/table/table.provider";
import { Flex, Spinner } from "@chakra-ui/react";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetUsers } from "graphql/hooks/useQueries";
import { useForm } from "react-hook-form";
import { useDebounce } from "hooks/useDebounce";
import UsersAPUsersFilters from "pages/Users/APUsers/UsersAPUsersFilters";
import { COLUMNS } from "pages/Users/APUsers/UsersAPUsersTable/table.config";
import UsersAPUsersTable from "pages/Users/APUsers/UsersAPUsersTable";
import { useOptionsContext } from "hooks/useOptionsContext";
import {
  transformPermisionRoles,
  transformAllUsersList,
  transformUserAdminListSortState,
} from "connectors";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import useGetUserOptions from "hooks/useGetUserOptions.hook";

function APUsersContainer() {
  useGetUserOptions();

  const { userPermisionRoles } = useOptionsContext();
  const [sortState, setSortState] = useState();
  const methods = useForm();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const [name, role] = methods.watch(["name", "role"]);

  const { data, loading } = useGetUsers({
    variables: {
      options: {
        search: useDebounce(name) || null,
        offset: offsetData,
        limit: queryPageSize,
        isUser: false,
        roleId: role?.value || null,
        sort: transformUserAdminListSortState(sortState),
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAllUsersList(data?.getAllUsers?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={data?.getAllUsers?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <UsersAPUsersFilters
        methods={methods}
        permisisonOptions={transformPermisionRoles(userPermisionRoles)}
      />
      {loading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : data?.getAllUsers?.rows.length ? (
        <UsersAPUsersTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default APUsersContainer;
