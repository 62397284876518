import { useContext, useMemo } from "react";
import { useGetAllEventOptions } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

const useGetEventStatuses = () => {
  const { isAuth } = useContext(AuthContext);
  const { updateOptionsState, eventStatuses } = useOptionsContext();
  const { accessReadEvents } = getAccessScopesControl();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return !accessReadEvents || !isAuth || !!eventStatuses.length;
  }, [isAuth, eventStatuses.length, accessReadEvents]);

  useGetAllEventOptions({
    onCompleted: (response) => {
      updateOptionsState({
        eventStatuses: response?.getAllOptionsEvent?.eventStatus,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetEventStatuses;
