import { Flex } from "@chakra-ui/react";
import TableLink from "components/TableComponents/Link";
import TableImage from "components/TableComponents/Image";
import TableDescription from "components/TableComponents/Description";
import { cleanTextFromHTML } from "utils/cleanTextFromHTML";
import Options from "./Options";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => {
      return (
        <Flex>
          <TableImage
            src={`${process.env.REACT_APP_API_URL}/${row.original.image}`}
          />
          <TableLink
            value={row.original.name || "Not found"}
            path={`${process.env.REACT_APP_API_URL}/${row.original.url}`}
          />
        </Flex>
      );
    },
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
        <TableDescription
          text={cleanTextFromHTML(value) || "Description is not found"}
        />
      );
    },
  },
  {
    Header: "",
    accessor: "options",
    isNumeric: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <Options row={row} />;
    },
  },
];

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
