import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Flex, Spinner } from "@chakra-ui/react";
import { COLUMNS } from "pages/Locations/LocationsMainPage/LocationsTable/table.config";
import TableProvider from "contexts/table/table.provider";
import LocationsTable from "pages/Locations/LocationsMainPage/LocationsTable";
import LocationsFilters from "pages/Locations/LocationsMainPage/LocationsFilters";
import {
  transformCityOptions,
  transformCountryOptions,
  transformLocations,
  transformLocationsSortTable,
} from "connectors";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllLocations } from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import { useOptionsContext } from "hooks/useOptionsContext";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { locationStatusOptions } from "./locationConstans";

function LocationsListContainer(props) {
  const { accessViewLocations } = props;
  const methods = useForm();
  const { countries } = useOptionsContext();
  const [sortState, setSortState] = useState();

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 5 } });

  const [name, active, selectedCountry, selectedCity] = methods.watch([
    "name",
    "active",
    "country",
    "city",
  ]);
  const { data: locationsData, loading: locationsLoading } = useGetAllLocations(
    {
      variables: {
        options: {
          search: useDebounce(name) || null,
          isActive: active?.value === "all" ? null : active?.value,
          limit: queryPageSize,
          offset: offsetData,
          cityId: Number(selectedCity?.value),
          countryId: Number(selectedCountry?.value),
          order: transformLocationsSortTable(sortState),
        },
      },
    }
  );
  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformLocations(locationsData?.getAllLocations?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={locationsData?.getAllLocations?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <FormProvider {...methods}>
        <LocationsFilters
          countriesOptions={transformCountryOptions(countries)}
          citiesOptions={transformCityOptions(selectedCountry, countries)}
          statusOptions={locationStatusOptions}
          methods={methods}
        />
      </FormProvider>

      {locationsLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : locationsData?.getAllLocations?.rows?.length &&
        accessViewLocations ? (
        <LocationsTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default LocationsListContainer;
