import { createContext } from "react";

const defaultValue = {
  attraction: {},
  getAttractionId: () => {},
  updateAttractionState: () => {},
  getGeneralInfo: () => {},
  getGallery: () => {},
};

export default createContext(defaultValue);
