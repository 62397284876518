import { Icon } from "@chakra-ui/react";

export default function AlertWarningIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8901 3.57872L22.5601 16.2987C22.8385 16.755 22.9899 17.2774 22.9987 17.8119C23.0075 18.3464 22.8734 18.8735 22.6101 19.3387C22.3093 19.8495 21.8792 20.272 21.363 20.5634C20.8468 20.8549 20.2629 21.005 19.6701 20.9987H4.3301C3.73343 21.001 3.14684 20.8448 2.6303 20.5461C2.11376 20.2475 1.68578 19.817 1.3901 19.2987C1.13436 18.8383 1.00434 18.3186 1.01311 17.792C1.02189 17.2654 1.16915 16.7504 1.4401 16.2987L9.1101 3.57872C9.42024 3.09353 9.84755 2.69424 10.3526 2.41766C10.8577 2.14107 11.4243 1.99609 12.0001 1.99609C12.5759 1.99609 13.1425 2.14107 13.6476 2.41766C14.1527 2.69424 14.58 3.09353 14.8901 3.57872ZM12.0001 8.99872C9.53162 8.99872 7.47262 10.7167 7.00012 12.9987C7.47262 15.2807 9.53162 16.9987 12.0001 16.9987C14.4686 16.9987 16.5276 15.2807 17.0001 12.9987C16.5276 10.7167 14.4686 8.99872 12.0001 8.99872ZM13.7679 14.7665C13.299 15.2353 12.6632 15.4987 12.0001 15.4987C11.3371 15.4987 10.7012 15.2353 10.2324 14.7665C9.76351 14.2976 9.50012 13.6618 9.50012 12.9987C9.50012 12.3357 9.76351 11.6998 10.2324 11.231C10.7012 10.7621 11.3371 10.4987 12.0001 10.4987C12.6632 10.4987 13.299 10.7621 13.7679 11.231C14.2367 11.6998 14.5001 12.3357 14.5001 12.9987C14.5001 13.6618 14.2367 14.2976 13.7679 14.7665ZM13.0608 14.0594C12.7795 14.3407 12.3979 14.4987 12.0001 14.4987C11.6023 14.4987 11.2208 14.3407 10.9395 14.0594C10.6582 13.7781 10.5001 13.3965 10.5001 12.9987C10.5001 12.6009 10.6582 12.2194 10.9395 11.9381C11.2208 11.6568 11.6023 11.4987 12.0001 11.4987C12.3979 11.4987 12.7795 11.6568 13.0608 11.9381C13.3421 12.2194 13.5001 12.6009 13.5001 12.9987C13.5001 13.3965 13.3421 13.7781 13.0608 14.0594Z"
      />
    </Icon>
  );
}
