import { useOptionsContext } from "./useOptionsContext";

export const useGetLanguageKey = (selectedLanguage) => {
  const { languages } = useOptionsContext();
  let key = null;

  languages.forEach((language) => {
    if (language.id === selectedLanguage?.value.id) {
      key = language.code;
    }
  });

  return key;
};
