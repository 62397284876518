const wrapper = {
  w: "218px",
  h: "42px",
  bg: "white.500",
  borderRadius: "4xl",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const valueContainer = {
  bg: "white.900",
  w: "130px",
  h: "40px",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const icon = {
  color: "dark.500",
  p: 3,
  _hover: { bg: "grey.600" },
  borderRadius: "4xl",
};

export default { wrapper, valueContainer, icon };
