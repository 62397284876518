import { multiLangValidate } from "utils/multiLangValidate";
import * as yup from "yup";

export const ADD_EVENTS_GENERAL_INFO_SCHEMA = (languages) => {
  return yup.object().shape({
    title: yup.object().shape(multiLangValidate(languages, ["description"])),
    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),
    coordinates: yup
      .object()
      .required("Coordinates is required")
      .nullable(true),
    location: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    eventCategory: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Category is required!")
      .nullable(true),
    currency: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required("Currency is required!")
      .nullable(),

    description: yup.object().shape(multiLangValidate(languages, ["title"])),

    restrictions: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .required("Restrictions is required!")
      .nullable(),
    services: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .required("Services is required!")
      .nullable(),
    url: yup.string().required("URL is required!"),
    date: yup.string().required("Date is required!"),
    startHour: yup.string().required("Time is required!"),
    price: yup.number().required("Price is required"),
    main_img: yup
      .mixed()
      .nullable()
      .required("A file is required")
      .test("fileFormat", "JPEG, JPG or PNG only", (value) => {
        if (typeof value === "object" && value?.preview) {
          return true;
        }
        return (
          value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
        );
      }),

    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),
  });
};

export const EDIT_EVENTS_GENERAL_INFO_SCHEMA = (languages) => {
  return yup.object().shape({
    title: yup.object().shape(multiLangValidate(languages, ["description"])),
    status: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    language: yup
      .object()
      .shape({
        value: yup
          .object({ id: yup.number().positive(), code: yup.string() })
          .required(),
        label: yup.string().required(),
      })
      .required("Language is required!"),
    coordinates: yup.object().nullable(true),
    location: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    eventCategory: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(true),
    currency: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    description: yup.object().shape(multiLangValidate(languages, ["title"])),
    restrictions: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .nullable(),
    services: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      )
      .nullable(),
    url: yup.string(),
    date: yup.string(),
    startHour: yup.string(),
    price: yup.number(),
    main_img: yup
      .mixed()
      .nullable()
      .test("preview", "has preview", (value) => {
        return typeof value?.preview === "string";
      }),
    meta_title: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_description"], "SEO")),

    meta_description: yup
      .object()
      .shape(multiLangValidate(languages, ["meta_title"], "SEO")),
  });
};
