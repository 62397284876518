import { useMemo } from "react";

export const useDropzoneStyles = (hasError, width, height) => {
  return {
    mainContainer: {
      position: "relative",
      width,
      maxWidth: "100%",
      height,
      padding: "12px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: hasError ? "red.500" : "white.500",
      borderRadius: "24px",
    },
    baseDropzone: {
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      paddingLeft: "16px",
      paddingRight: "16px",
      outline: "none",
      transition: "border .24s ease-in-out",
      borderWidth: "1px",
      borderStyle: "dashed",
      borderRadius: "16px",
      borderColor: "grey.600",
    },

    focusedStyle: {
      borderColor: "blue.500",
    },

    acceptStyle: {
      borderColor: "green.500",
    },

    rejectStyle: {
      borderColor: "red.500",
    },

    dropText: {
      fontSize: "xs",
      fontWeight: "medium",
      textAlign: "center",
      lineHeight: "16px",
    },

    dropSubText: {
      fontSize: "2xs",
      lineHeight: "xs",
      color: "dark.300",
    },

    dropTextColor: {
      display: "inline-block",
      color: "blue.500",
      paddingLeft: "4px",
    },

    previewContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      maxWidth: "100%",
      height: "100%",
      padding: 0,
      borderRadius: "24px",
      backgroundColor: "white.900",
      overflow: "hidden",
      _before: {
        content: "''",
        position: "absolute",
        display: "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "dark.700",
      },
      _hover: {
        _before: {
          display: "block",
        },
      },
    },

    previewMuiltiContainer: {
      position: "relative",
      marginBottom: "20px",
      top: "30px",
      left: 0,
      margin: 0,
      maxWidth: "120px",
      height: "120px",
      padding: 0,
      borderRadius: "24px",
      overflow: "hidden",
      ":last-child": {
        marginBottom: "64px",
      },
      _before: {
        content: "''",
        position: "absolute",
        display: "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "dark.700",
      },
      _hover: {
        _before: {
          display: "block",
        },
      },
    },

    previewImage: {
      width: "100%",
      height: "100%",
    },

    delBtn: {
      position: "absolute",
      display: "none",
      top: "50%",
      left: "50%",
      width: "40px",
      height: "40px",
      backgroundColor: "white.900",
      borderRadius: "8px",
      transform: "translate(-50%, -50%)",
      _groupHover: {
        display: "block",
      },
    },

    smallText: {
      fontSize: "10px",
    },

    smallImage: {
      maxHeight: "50px",
    },

    smallTextColor: {
      fontSize: "10px",
    },
  };
};

export const useDropzoneDropStyles = ({
  styles,
  isFocused,
  isDragAccept,
  isDragReject,
}) => {
  return useMemo(
    () => ({
      ...styles.baseDropzone,
      ...(isFocused && styles.focusedStyle),
      ...(isDragAccept && styles.acceptStyle),
      ...(isDragReject && styles.rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject, styles]
  );
};
