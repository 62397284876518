import { gql } from "@apollo/client";

export const ADD_VENUE_AREA_TABLE = gql`
  mutation addVenueAreaTable($input: AddVenueAreaTable) {
    areaTable: addVenueAreaTable(input: $input) {
      id
      table
      venueAreaId
      amount
      seats
    }
  }
`;
