import { Controller, useFormContext } from "react-hook-form";
import DatePickerCustom from "components/DatePicker";
import { getFieldName } from "utils/getFieldName";

function DatePickerRHF(props) {
  const { name, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const handleChange = (date) => {
          field.onChange(date);
        };

        return (
          <DatePickerCustom
            {...field}
            {...rest}
            hasError={getFieldName(errors, name)}
            selected={field.value}
            onChange={handleChange}
          />
        );
      }}
    />
  );
}

export default DatePickerRHF;
