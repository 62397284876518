import { Box } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

import PageHeader from "components/PageHeader";
import Tabs from "components/Tabs";
import getActiveTab from "utils/tabs-utils";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import { TabsConfig } from "./tabs.config";

const defaultTab = 0;

function Banners() {
  const [params] = useSearchParams();
  const { accessCreateBanners, accessViewBanners } = getAccessScopesControl();

  // get Label, Button`s label and tab`s index from URL params
  const getDataFromUrl = useMemo(() => {
    const selectItem = getActiveTab(TabsConfig, params.get("tab"));
    let index = defaultTab;
    if (selectItem?.index !== undefined) {
      index = selectItem.index;
    }
    return [selectItem?.label, selectItem?.button, index];
  }, [params]);

  return (
    <Box px={10} py={6} w="full">
      <PageHeader
        mb={6}
        button={accessCreateBanners ? getDataFromUrl[1] : null}
      >
        {getDataFromUrl[0] || "Ads"}
      </PageHeader>
      {accessViewBanners ? (
        <Tabs
          tabsData={TabsConfig}
          defaultTab={defaultTab}
          index={getDataFromUrl[2]}
        />
      ) : (
        <NoContentMessage />
      )}
    </Box>
  );
}

export default Banners;
