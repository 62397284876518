import { getFullName } from "./getFullName";

export const getParent = (report) => {
  if (report.modelName === "COMMENT") {
    return report.reportedEntity?.text;
  }

  if (report.modelName === "COMPANY") {
    return report.reportedEntity?.companyName;
  }

  if (report.modelName === "POST") {
    return report.reportedEntity?.title;
  }

  return getFullName(report.reportedEntity) || "Entry removed";
};

export const getAuthor = (report) => {
  if (report.modelName === "COMPANY") {
    return report.reportedEntity?.companyName;
  }

  if (report.modelName === "COMMENT") {
    if (report.reportedEntity?.commentAuthor?.company?.companyName) {
      return `${
        report.reportedEntity?.commentAuthor?.company?.companyName
      } (${getFullName(report?.reportedEntity?.commentAuthor)})`;
    }
    return getFullName(report?.reportedEntity?.commentAuthor) || "—";
  }

  if (report.modelName === "POST") {
    if (report.reportedEntity?.postAuthor?.company?.companyName) {
      return `${
        report.reportedEntity?.postAuthor?.company?.companyName
      } (${getFullName(report?.reportedEntity?.postAuthor)})`;
    }
    return getFullName(report?.reportedEntity?.postAuthor) || "—";
  }

  return report.reportedEntity?.firstName !== undefined &&
    report.reportedEntity?.lastName !== undefined
    ? getFullName(report.reportedEntity)
    : "—";
};
