import { getFullName } from "./getFullName";

export const getAuthorFormat = (author) => {
  if (author?.__typename === "Company") {
    return author.companyName;
  }
  if (author?.company?.companyName) {
    return `${author?.company?.companyName} ${
      (author?.firstName || author?.firstName) && `(${getFullName(author)})`
    }`;
  }
  return getFullName(author);
};
