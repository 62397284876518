const optionIcon = {
  w: "20px",
  h: "20px",
  fill: "dark.500",
  _groupHover: { fill: "blue.500" },
};

const userImageWrap = {
  width: "32px",
  height: "32px",
  borderRadius: "10px",
  marginRight: "16px",
  overflow: "hidden",
};

const userImage = {
  maxWidth: "100%",
};

const userIcon = {
  width: "24px",
  height: "24px",
  fill: "blue.500",
};

export default {
  optionIcon,
  userImageWrap,
  userImage,
  userIcon,
};
