const optionIcon = {
  w: "20px",
  h: "20px",
  fill: "dark.500",
  _groupHover: { fill: "blue.500" },
};

const reportParent = {
  fontWeight: "normal",
  fontSize: "sm",
  lineHeight: "5",
  color: "black.500",
};

const reportType = {
  marginTop: 1,
  fontWeight: "medium",
  fontSize: "xs",
  lineHeight: "4",
  color: "dark.300",
};

const removedEntry = {
  fontWeight: "medium",
  lineHeight: "4",
  color: "dark.300",
};

const reportTextSplit = {
  w: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export default {
  optionIcon,
  reportParent,
  reportType,
  removedEntry,
  reportTextSplit,
};
