import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
  mutation createCompany($info: CreateCompany) {
    createCompany(info: $info) {
      id
      image
      adress
      companyName
      cityId
      licenceNumber
      photos {
        id
        photo
        companyId
      }
      owners {
        id
        dateBirth
        ownerName
        companyShare
        passport
        companyId
        company {
          id
          image
          adress
          companyName
          cityId
          city {
            id
            name
            country {
              id
              code
              name
            }
            cityArea {
              id
              name
              isLiked
            }
          }
          licenceNumber
          photos {
            id
            photo
            companyId
          }
          owners {
            id
            ownerName
          }
        }
      }
      city {
        id
        name
        country {
          id
          code
          name
        }
        cityArea {
          id
          name
          isLiked
        }
      }
    }
  }
`;
