export const locationStatusOptions = [
  {
    value: true,
    label: "True",
  },
  {
    value: false,
    label: "False",
  },
  {
    value: "all",
    label: "All",
  },
];
