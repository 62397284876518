import GoogleAutocomplete from "components/GoogleAutocomplete";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

function GoogleAutocompleteRHF({ name, ...rest }) {
  const {
    register,
    setValue,
    watch,
    formState: {
      errors: { [name]: fieldError },
    },
  } = useFormContext();

  const autocompleteValue = watch(name);

  const handleChange = (value) => {
    setValue(name, value || "");
  };

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <GoogleAutocomplete
      autoCompleteValue={autocompleteValue}
      onChange={handleChange}
      hasError={fieldError && !autocompleteValue?.description}
      {...rest}
    />
  );
}

export default GoogleAutocompleteRHF;
