import { gql } from "@apollo/client";

export const GET_ALL_ATTRACTION_SCHEDULES = gql`
  query getAllAttractionSchedules($attractionId: Int!) {
    getAllAttractionSchedules(attractionId: $attractionId) {
      id
      attractionId
      dayOfWeek
      status
      upTime
      startTime
      stopTime
    }
  }
`;
