import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useRemoveAttractiontTicket } from "graphql/hooks/useMutations";
import CHS from "./chakra.styles";

function Options({ ticket }) {
  const { openModal: openCreateModal } = useModalContext(
    "createEditTicketsModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const [removeTicket] = useRemoveAttractiontTicket({
    variables: {
      ticketId: ticket.id,
    },
    onCompleted: closeDeleteModal,
  });

  const editTicketHandler = () => {
    openCreateModal({
      editTicket: ticket,
      isEditMode: true,
    });
  };

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: ticket.name,
      type: "ticket",
      onDelete: removeTicket,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={editTicketHandler}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}

export default Options;
