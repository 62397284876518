import { Children, isValidElement, cloneElement, useCallback } from "react";
import { FormControl, FormLabel, HStack } from "@chakra-ui/react";

function RoundedRadioGroup({
  value,
  onChange,
  isSelectedFunc,
  children,
  hasError = false,
  wrapperProps,
  label,
}) {
  const internalIsSelectedFunc = useCallback(
    (val) => {
      if (typeof isSelectedFunc === "function") return isSelectedFunc(val);
      return val === value;
    },
    [isSelectedFunc, value]
  );

  const internalOnChange = useCallback(
    (val) => () => onChange(val),
    [onChange]
  );

  return (
    <FormControl isInvalid={hasError} {...wrapperProps}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <HStack spacing={0}>
        {Children.map(children, (child, index) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              onChange: internalOnChange(child.props.value),
              isActive: internalIsSelectedFunc(child.props.value),
              isFirst: index === 0,
              isLast: index === children.length - 1,
            });
          }
          return child;
        })}
      </HStack>
    </FormControl>
  );
}

export default RoundedRadioGroup;
