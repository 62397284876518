import { gql } from "@apollo/client";

export const ADD_GALLERY = gql`
  mutation CreateGallery($input: CreateGallery) {
    createGallery(input: $input) {
      id
      title
      modelName
      modelId
      description
      url
      categoryId
      date
      galleryPhoto {
        id
        modelName
        modelId
        path
        order
        description
        altTag
        isLiked
        createdBy
        createdAt
        updatedAt
      }
    }
  }
`;
