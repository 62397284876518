export default {
  sizes: {
    label: {
      fontFamily: "medium",
      fontWeight: "medium",
      fontSize: "xs",
      lineHeight: "none",
    },
    main: {
      fontFamily: "normal",
      fontWeight: "normal",
      fontSize: "sm",
      lineHeight: "shorter",
    },
    language: {
      fontFamily: "normal",
      fontWeight: "semibold",
      fontSize: "xs",
      lineHeight: "shorter",
    },
    modalContent: {
      fontFamily: "medium",
      fontWeight: "medium",
      fontSize: "md",
      lineHeight: "6",
    },
  },
  variants: {
    main: {
      color: "black.900",
    },
    text: {
      color: "grey.700",
    },
    error: {
      color: "red.500",
    },
    language: {
      color: "dark.300",
    },
    tableLink1: {
      color: "black.500",
      maxW: 36,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      borderBottomStyle: "solid",
      borderBottomColor: "black.500",
      borderBottomWidth: "px",
    },
    tableLink2: {
      color: "blue.500",
      maxW: 60,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      borderBottomStyle: "solid",
      borderBottomColor: "blue.500",
      borderBottomWidth: "px",
    },
    tableLink3: {
      color: "black.500",
      maxW: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      borderBottomStyle: "solid",
      borderBottomColor: "black.500",
      borderBottomWidth: "px",
    },
  },
  defaultProps: {
    size: "main",
    variant: "main",
  },
};
