import { Box } from "@chakra-ui/react";
import TableBody from "components/Table/TableBody";

function ScheduleTableAttractions() {
  return (
    <Box w="full">
      <TableBody hasSorting={true} />
    </Box>
  );
}

export default ScheduleTableAttractions;
