const datePickerStyles = {
  "& .react-datepicker-popper": { zIndex: 10 },
  "& .react-datepicker__header__dropdown": {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  "& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before":
    {
      borderWidth: "2px 2px 0 0",
      width: "7px",
      height: "7px",
    },
  "& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow":
    {
      transform: "rotate(135deg) translateX(50%)",
      WebkitTransformOrigin: "rotate(135deg) translateX(50%)",
      MozTransformOrigin: "rotate(135deg) translateX(50%)",
      msTransformOrigin: "rotate(135deg) translateX(50%)",
      OTransformOrigin: "rotate(135deg) translateX(50%)",
      right: "-16px",
      top: "calc(50% - 7px)",
    },
  "& .react-datepicker__navigation-icon--next::before": {
    transform: "rotate(45deg) translate(-50%, -50%)",
    WebkitTransformOrigin: "rotate(45deg) translate(-50%, -50%)",
    MozTransformOrigin: "rotate(45deg) translate(-50%, -50%)",
    msTransformOrigin: "rotate(45deg) translate(-50%, -50%)",
    OTransformOrigin: "rotate(45deg) translate(-50%, -50%)",
    left: "50%",
    top: "50%",
  },
  "& .react-datepicker__navigation-icon--previous::before": {
    transform: "rotate(225deg) translate(-50%, -50%)",
    WebkitTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
    MozTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
    msTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
    OTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
    right: "50%",
    bottom: "50%",
  },
  "& .react-datepicker__navigation .react-datepicker__navigation--years-upcoming":
    {
      transform: "rotate(225deg) translate(-50%, -50%)",
      WebkitTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
      MozTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
      msTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
      OTransformOrigin: "rotate(225deg) translate(-50%, -50%)",
      right: "50%",
      bottom: "50%",
      "&:before": { content: "''", width: "20px" },
    },
  "& .react-datepicker__navigation.react-datepicker__navigation--years-upcoming":
    {
      position: "relative",
      top: 0,
      height: "20px",

      "&:before": {
        content: "''",
        borderColor: "#ccc",
        borderStyle: "solid",
        borderWidth: "2px 2px 0 0",
        display: "block",
        height: "9px",
        width: "9px",
        position: "absolute",
        top: "30%",
        left: "calc(50% + 2px)",
        transform: "rotate(-45deg) translate(-50%, -50%)",
        WebkitTransformOrigin: "rotate(-45deg) translate(-50%, -50%)",
        MozTransformOrigin: "rotate(-45deg) translate(-50%, -50%)",
        msTransformOrigin: "rotate(-45deg) translate(-50%, -50%)",
        OTransformOrigin: "rotate(-45deg) translate(-50%, -50%)",
      },
    },
  "& .react-datepicker__navigation.react-datepicker__navigation--years-previous":
    {
      position: "relative",
      top: 0,
      height: "20px",

      "&:before": {
        content: "''",
        borderColor: "#ccc",
        borderStyle: "solid",
        borderWidth: "2px 2px 0 0",
        display: "block",
        height: "9px",
        width: "9px",
        position: "absolute",
        top: "15%",
        right: "calc(50% + 1px)",
        transform: "rotate(135deg) translate(-50%, -50%)",
        WebkitTransformOrigin: "rotate(135deg) translate(-50%, -50%)",
        MozTransformOrigin: "rotate(135deg) translate(-50%, -50%)",
        msTransformOrigin: "rotate(135deg) translate(-50%, -50%)",
        OTransformOrigin: "rotate(135deg) translate(-50%, -50%)",
      },
    },
};

export default { datePickerStyles };
