import DatePicker from "react-datepicker";
import { forwardRef, useMemo } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import CHS from "./chakra.styles";
import { ArrowDownThinIcon } from "../Icons";

const CustomInput = forwardRef((props, ref) => {
  const { value, onClick, suffix, hasError, size } = props;

  const displayValue = useMemo(() => {
    if (suffix && !value) {
      return (
        <Text as="p">
          <Text as="span" color="dark.300">
            {suffix}{" "}
          </Text>
          {value}
        </Text>
      );
    }

    return <Text as="p">{value}</Text>;
  }, [suffix, value]);

  return (
    <Flex
      {...CHS.wrapper}
      {...(size === "md" && { width: "180px" })}
      ref={ref}
      onClick={onClick}
    >
      <ArrowDownThinIcon {...CHS.icon} mr={size === "md" ? 0 : 1} />
      <Box
        {...CHS.valueContainer}
        {...(size === "md" && { width: "106px" })}
        {...(hasError && { border: "1px solid", borderColor: "red.500" })}
      >
        {displayValue}
      </Box>
      <ArrowDownThinIcon
        {...CHS.icon}
        ml={size === "md" ? 0 : 1}
        transform="rotate(180deg)"
      />
    </Flex>
  );
});

const TimePicker = forwardRef(
  ({ suffix, selected, onChange, hasError, size, ...rest }, ref) => {
    return (
      <DatePicker
        selected={selected}
        onChange={onChange}
        customInput={
          <CustomInput hasError={hasError} suffix={suffix} size={size} />
        }
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        ref={ref}
        {...rest}
      />
    );
  }
);

export default TimePicker;
