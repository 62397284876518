import * as yup from "yup";

const schema = yup.object().shape({
  title: yup.string().required("Gallery name is required"),
  url: yup.string().required("Url is required"),
  category: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Category is required!")
    .nullable(),
  date: yup.string().required("Date is required"),
  photos: yup
    .array()
    .of(
      yup.object().shape({
        image: yup.mixed().required("A file is required"),
      })
    )
    .min(1, "A file is required")
    .test("fileFormat", "JPEG, JPG or PNG only", (value) => {
      return value?.every((item) => {
        if (typeof value === "object" && item?.image?.preview) {
          return true;
        }
        return (
          item?.image?.preview &&
          ["image/jpeg", "image/jpg", "image/png"].includes(item?.image?.type)
        );
      });
    })
    .required("A file is required"),
});

export default schema;
