import { gql } from "@apollo/client";

export const UPDATE_ATTRACTION_GENERAL_INFO = gql`
  mutation updateAttractionGeneralInfo($input: UpdateAttraction) {
    updateAttractionGeneralInfo(input: $input) {
      id
      title
      url
      status
      isAddToSlider
      description
      image
      geolocation
      categoryId
      photo
      location {
        id
        title
      }
      attractionSeoAdmin {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      placeDetails {
        address
      }
      restrictions {
        id
        name
        image
      }
      services {
        id
        name
        image
      }
      tr {
        id
        languageId
        language {
          id
          code
          name
        }
        title
        description
      }
    }
  }
`;
