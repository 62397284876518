import { Box, Button } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import { AddIcon } from "components/Icons";
import { useNavigate } from "react-router-dom";
import { PATHS, SUB_PATHS } from "constants/constants";
import AttractionsContainer from "containers/attractions/attractionsList.container";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function AttractionsMainPage() {
  const navigate = useNavigate();
  const navToNewAttraction = () =>
    navigate(`${PATHS.newAttractions}/${SUB_PATHS.general}`);
  const { accessCreateAttractions, accessViewAttractions } =
    getAccessScopesControl();

  const button = (
    <Button w="176px" onClick={navToNewAttraction}>
      <Box mr={2}>
        <AddIcon fill="white.900" />
      </Box>
      Add attraction
    </Button>
  );
  return (
    <Box px={10} py={6} w="full">
      <PageHeader button={accessCreateAttractions ? button : null} mb={6}>
        Attractions
      </PageHeader>
      <AttractionsContainer accessViewAttractions={accessViewAttractions} />
    </Box>
  );
}
