import { gql } from "@apollo/client";

export const EDIT_COMPANY = gql`
  mutation editCompany($info: EditCompany) {
    editCompany(info: $info) {
      id
      image
      adress
      companyName
      cityId
      city {
        id
        name
        country {
          id
          code
          name
          Cities {
            id
            name
            country {
              id
              code
              name
            }
            cityArea {
              id
              name
              isLiked
            }
          }
        }
        cityArea {
          id
          name
          isLiked
        }
      }
      licenceNumber
      photos {
        id
        photo
        companyId
      }
      owners {
        id
        dateBirth
        ownerName
        companyShare
        passport
        companyId
        photos {
          id
          photo
          ownerId
        }
        company {
          id
          image
          adress
          companyName
          cityId
          city {
            id
            name
          }
          licenceNumber
          photos {
            id
            photo
            companyId
          }
          owners {
            id
            dateBirth
            ownerName
            companyShare
            passport
            companyId
            company {
              id
              image
              adress
              companyName
            }
            photos {
              id
              photo
              ownerId
            }
          }
        }
      }
    }
  }
`;
