import { createContext } from "react";

const defaultValue = {
  modals: {},
  openModalHandler: () => {},
  closeModalHandler: () => {},
  updateModalState: () => {},
};

export default createContext(defaultValue);
