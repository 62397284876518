import React, { useMemo } from "react";
import { HStack, Text, IconButton } from "@chakra-ui/react";
import { ArrowLeftIcon } from "components/Icons";

// if you want to omit icon => pass null
function BackLink({ children, styles, icon: CustomIcon, onClick }) {
  const IconComponent = useMemo(() => {
    if (CustomIcon === null) return React.Fragment;
    return CustomIcon ? CustomIcon : ArrowLeftIcon;
  }, []);

  return (
    <IconButton variant="ghost" onClick={onClick} {...styles} size="xs">
      <HStack>
        <IconComponent />
        <Text size="label" lineHeight="shorter">
          {children}
        </Text>
      </HStack>
    </IconButton>
  );
}

export default BackLink;
