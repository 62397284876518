import { capitalize } from "utils/capitalize";
import { getAuthorFormat } from "utils/getAuthorFormat";
import { getFullName } from "utils/getFullName";

export const transformReviewReportData = (data) => {
  if (!data) return {};
  const modifiedData = {
    category: data.category,
    status: data.status,
    reportedBy: getAuthorFormat(data.reportedBy),
    modelName: capitalize(data.modelName),
    reportText: data.text,
  };

  if (data.modelName === "COMPANY") {
    modifiedData.parent = data.reportedEntity?.companyName;
  }

  if (data.modelName === "POST") {
    modifiedData.parent = data.reportedEntity?.title;
    modifiedData.postAuthor = getAuthorFormat(data.reportedEntity?.postAuthor);

    modifiedData.publicationDate = data.reportedEntity?.createdAt
      ? new Date(data.reportedEntity?.createdAt)
      : new Date();
  }

  if (data.modelName === "COMMENT") {
    modifiedData.parent = data.reportedEntity?.text;
    modifiedData.commentAuthor = getAuthorFormat(
      data.reportedEntity?.commentAuthor
    );
    modifiedData.publicationDate = data.reportedEntity?.createdAt
      ? new Date(data.reportedEntity?.createdAt)
      : new Date();
    modifiedData.commentText = data.reportedEntity?.text;
  }

  if (data.modelName === "USER") {
    modifiedData.parent = getFullName({
      firstName: data.reportedEntity?.firstName,
      lastName: data.reportedEntity?.lastName,
    });
  }

  return modifiedData;
};
