import {
  EditOptionIcon,
  DeleteOptionIcon,
  EyeOpenIcon,
} from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemovePost } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { useGetCurrentUser } from "graphql/hooks/useQueries";
import { useMemo } from "react";
import { useToastContext } from "hooks/useToastContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ post }) {
  const { openModal: openEditModal } = useModalContext("feedCreateEditModal");
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessDeleteFeeds } = getAccessScopesControl();

  const { showToast } = useToastContext();

  const { data } = useGetCurrentUser();

  const hasEditingPermission = useMemo(() => {
    return Number(post?.authorId) === Number(data?.currentUser?.id);
  }, [post?.authorId, data?.currentUser?.id]);

  const editPost = () => {
    openEditModal({
      editPost: post,
      isEditMode: true,
    });
  };

  const viewPost = () => {
    openEditModal({
      editPostReadOnly: true,
      editPost: post,
      isEditMode: true,
    });
  };

  const [removePost] = useRemovePost({
    variables: {
      id: post.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The post has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: post.title,
      type: "post",
      onDelete: removePost,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={
          hasEditingPermission ? (
            <EditOptionIcon sx={CHS.optionIcon} />
          ) : (
            <EyeOpenIcon sx={CHS.eyeIcon} />
          )
        }
        onMenuItemClick={hasEditingPermission ? editPost : viewPost}
      >
        {hasEditingPermission ? "Edit" : "Details"}
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteFeeds)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
