import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useDeleteUser } from "graphql/hooks/useMutations";
import { useToastContext } from "hooks/useToastContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ user }) {
  const { showToast } = useToastContext();
  const { openModal: openCreateModal } = useModalContext("createNewUserModal");
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessUpdateUsers, accessDeleteUsers } = getAccessScopesControl();

  const openModalHandler = () => {
    openCreateModal({ editUser: user, isEditMode: true });
  };

  const [removeUser] = useDeleteUser({
    variables: {
      id: user.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The user has been successfully deleted",
      });
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: user.fullName,
      type: "user",
      onDelete: removeUser,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openModalHandler}
        isDisabled={Boolean(!accessUpdateUsers)}
      >
        Edit
      </CustomMenuItem>

      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteUsers)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
