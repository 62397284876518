export default {
  fill: {
    // Note: for the content to span full height, the parent container must be display:flex
    flex: "1 1 auto",
  },
  box: {
    bgColor: "white.900",
    borderRadius: "10px",
    py: "12",
    px: "10",
  },
};
