export default {
  body: {
    color: "black.500",
    fontFamily: "normal",
  },
  // HACK 1
  ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
    overflow: "initial !important",
  },
};

// HACK 1

// Problem:
// Elements wrapped in Collapse animation (or Accordion also) can't overflow it.
// It causes problems for dropdowns.

// Solution:
// By default, it sets overflow: initial !important so that overflow is visible.
// Sometimes that's not what you want. You can then set className="chakra-dont-set-collapse".

// See more about bug:
//  https://github.com/chakra-ui/chakra-ui/issues/2966
//  https://github.com/valor-software/ngx-bootstrap/issues/5369
