import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Flex, Spinner } from "@chakra-ui/react";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Attractions/AttractionsMainPage/AttractionsTable/table.config";
import {
  transformCategoriesOptions,
  transformAttractions,
  transformEventOptions,
  transformAttractionsListSortState,
} from "connectors";
import { allCategories } from "constants/constants";
import { useOptionsContext } from "hooks/useOptionsContext";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllAttractions } from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import AttractionsTable from "pages/Attractions/AttractionsMainPage/AttractionsTable/index";
import AttractionsFilters from "pages/Attractions/AttractionsMainPage/AttractionsFilters";
import NoContentMessage from "components/TableComponents/NoContentMessage";
import useGetEventStatuses from "hooks/useGetEventStatuses.hook";

function AttractionsContainer(props) {
  const { accessViewAttractions } = props;
  useGetEventStatuses();

  const [sortState, setSortState] = useState();

  const methods = useForm();
  const { categories, eventStatuses } = useOptionsContext();
  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 7 } });

  const [category, status, name] = methods.watch([
    "category",
    "status",
    "name",
  ]);

  const { data: attractionData, loading: attractionLoading } =
    useGetAllAttractions({
      variables: {
        options: {
          search: useDebounce(name) || null,
          categoryId: Number(category?.value) || null,
          status: status?.value || null,
          limit: queryPageSize,
          offset: offsetData,
          order: transformAttractionsListSortState(sortState),
        },
      },
      fetchPolicy: "cache-and-network",
    });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAttractions(attractionData?.getAllAttractions?.rows),
        initialState: { pageSize: queryPageSize, pageIndex: queryPageIndex },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={attractionData?.getAllAttractions?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <FormProvider {...methods}>
        <AttractionsFilters
          categoryOptions={transformCategoriesOptions(
            allCategories.attractionCategory,
            categories
          )}
          statusOptions={transformEventOptions(eventStatuses)}
          methods={methods}
        />
      </FormProvider>
      {attractionLoading ? (
        <Flex alignItems="center" justify="center" h="100px">
          <Spinner size="xl" />
        </Flex>
      ) : attractionData?.getAllAttractions?.rows?.length &&
        accessViewAttractions ? (
        <AttractionsTable />
      ) : (
        <Flex alignItems="center" justify="center" h="100px">
          <NoContentMessage />
        </Flex>
      )}
    </TableProvider>
  );
}

export default AttractionsContainer;
