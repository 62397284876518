import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useRemoveLocation } from "graphql/hooks/useMutations";
import { useModalContext } from "hooks/useModalContext";
import { useToastContext } from "hooks/useToastContext";
import { getAccessScopesControl } from "utils/getAccessScopesControl";
import CHS from "./chakra.styles";

export default function Options({ row }) {
  const { openModal: openCreateModal } = useModalContext("createLocation");
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");
  const { accessUpdateLocations, accessDeleteLocations } =
    getAccessScopesControl();

  const editLocations = () => {
    openCreateModal({
      editLocation: row.original,
      isEditMode: true,
    });
  };

  const { showToast } = useToastContext();

  const [removeLocation] = useRemoveLocation({
    variables: {
      id: row.original.id,
    },
    onCompleted: () => {
      closeDeleteModal();
      showToast({
        description: "The location has been successfully deleted",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: row.original.name,
      type: "location",
      onDelete: removeLocation,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={editLocations}
        isDisabled={Boolean(!accessUpdateLocations)}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
        isDisabled={Boolean(!accessDeleteLocations)}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
