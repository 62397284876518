import ModalButton from "components/ModalButton";
import APUsers from "containers/users/APUsers.container";
import UsersCompanies from "containers/users/UsersCompanies.container";
import Users from "containers/users/Users.container";
import UsersInfluencers from "containers/users/UsersInfluencers.container";

// config for Users tabs
export const TabsConfig = [
  {
    id: "one",
    index: 0,
    path: "users",
    label: "Users",
    button: (
      <ModalButton modalName="createNewUserModal" label="Add new user" w={44} />
    ),
    content: Users,
  },
  {
    id: "two",
    index: 1,
    path: "ap-users",
    label: "AP Users",
    button: (
      <ModalButton modalName="createNewUserModal" label="Add new user" w={44} />
    ),
    content: APUsers,
  },
  {
    id: "three",
    index: 2,
    path: "companies",
    label: "Companies",
    button: (
      <ModalButton
        modalName="createNewCompanyModal"
        label="Add new company"
        w={52}
        data={{ isAddMode: true }}
      />
    ),
    content: UsersCompanies,
  },
  {
    id: "four",
    index: 3,
    path: "influencers",
    label: "Influencers",
    button: null,
    content: UsersInfluencers,
  },
];
