import { createContext } from "react";

const defaultValue = {
  event: {},
  getEventId: () => {},
  updateEventState: () => {},
  getGeneralInfo: () => {},
  getMenu: () => {},
  getGallery: () => {},
};

export default createContext(defaultValue);
