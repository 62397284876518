import { gql } from "@apollo/client";

export const CREATE_OWNERS = gql`
  mutation createOwners($companyId: Int!, $usersInfo: [CompanyOwners]) {
    createOwners(companyId: $companyId, usersInfo: $usersInfo) {
      id
      dateBirth
      ownerName
      companyShare
      passport
      companyId
      company {
        id
        image
        adress
        companyName
        cityId
        city {
          id
          name
          country {
            id
            code
            name
          }
          cityArea {
            id
            name
            isLiked
          }
        }
        licenceNumber
        photos {
          id
          photo
          companyId
        }
        owners {
          id
          dateBirth
          ownerName
          companyShare
          passport
          companyId
          company {
            id
            image
            adress
            companyName
          }
          photos {
            id
            photo
            ownerId
          }
        }
      }
      photos {
        id
        photo
        ownerId
      }
    }
  }
`;
