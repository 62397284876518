export const transformVenuesOffersSortState = (state) => {
  if (!state) {
    return null;
  }

  const requestObj = {
    type: state.desc ? "DESC" : "ASC",
    field: state.id,
  };

  if (state.id === "name") requestObj.field = "title";
  if (state.id === "total") requestObj.field = "forInfluencerCount";
  if (state.id === "userType") requestObj.field = "isForInfluencerOnly";

  return requestObj;
};
