import { Box, Flex } from "@chakra-ui/react";
import TableImage from "components/TableComponents/Image";

export const COLUMNS = [
  {
    Header: "",
    accessor: "photo",
    Cell: ({ value }) => {
      return (
        <Flex>
          <TableImage
            src={`${process.env.REACT_APP_API_URL}/${value}`}
            sx={{
              w: 16,
              h: 16,
              marginRight: 3,
              borderRadius: "md",
            }}
          />
        </Flex>
      );
    },
  },
  {
    Header: "",
    accessor: "name",
    Cell: ({ value }) => {
      return <Box>{value || "Not found"}</Box>;
    },
  },
];
