import { forwardRef, useContext, useEffect, useRef } from "react";
import { Checkbox as ChakraCheckbox } from "@chakra-ui/react";
import TableContext from "contexts/table/table.context";
import { AcceptIcon } from "components/Icons";

export const TableCheckbox = forwardRef((props, ref) => {
  const {
    indeterminate,
    name,
    isChecked,
    children,
    row,
    onChangeHandler,
    onChange,
    ...rest
  } = props;

  const { selectedRowIds } = useContext(TableContext);

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const _onChange = (e) => {
    onChangeHandler?.(row.original.id);
    onChange(e);
  };

  return (
    <ChakraCheckbox
      name={name}
      ref={resolvedRef}
      defaultChecked={isChecked}
      isChecked={selectedRowIds.includes(row.original.id)}
      onChange={_onChange}
      {...rest}
      icon={<AcceptIcon width="22px" height="22px" fill="blue.500" />}
    >
      {children}
    </ChakraCheckbox>
  );
});
