export const transformCategoriesOptions = (nameCategory, data = []) => {
  if (data && nameCategory) {
    const categories = data.filter(
      (category) => category.modelName === nameCategory
    );

    return categories.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }
  return [];
};
