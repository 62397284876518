import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FLOOR_MAP_SCHEMA } from "schemas";
import TableProvider from "contexts/table/table.provider";
import { COLUMNS } from "pages/Venues/VenueManagement/FloorMap/table.config";
import FloorMapTable from "pages/Venues/VenueManagement/FloorMap";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/constants";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import { useGetAllHalls } from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import { useVenueContext } from "hooks/useVenueContext.hook";
import { useUpdateVenueCompleteStatus } from "graphql/hooks/useMutations";
import { useState } from "react";
import { transformVenuesFloormapSortState } from "connectors";
import { useToastContext } from "hooks/useToastContext";

function FloorMapContainer() {
  const [sortState, setSortState] = useState();

  const navigate = useNavigate();
  const { getVenueId } = useVenueContext();
  const methods = useForm({
    defaultValues: {
      search: "",
    },
    resolver: yupResolver(FLOOR_MAP_SCHEMA),
  });

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 7 } });

  const search = methods.watch("search");

  const { showToast } = useToastContext();

  const [updateVenueCompleteStatus] = useUpdateVenueCompleteStatus();

  const completeStatus = () => {
    updateVenueCompleteStatus({
      variables: {
        input: {
          venueId: getVenueId(),
          isCompleted: true,
        },
      },
      onCompleted: () => {
        navigate(PATHS.venues);
        showToast({
          description: "Successfully completed!",
        });
      },
      onError: (error) => {
        console.log(error?.message);
      },
    });
  };

  const { data: allHallsData, loading: hallsLoading } = useGetAllHalls({
    variables: {
      options: {
        venueId: getVenueId(),
        limit: queryPageSize,
        offset: offsetData,
        search: useDebounce(search) || null,
        order: transformVenuesFloormapSortState(sortState),
      },
    },
    skip: !getVenueId(),
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: allHallsData?.halls?.rows || [],
        initialState: {
          pageSize: queryPageSize,
          pageIndex: queryPageIndex,
        },
        pageCount: pagesQuantity,
        manualPagination: true,
        manualSortBy: true,
      }}
      dataCount={allHallsData?.halls?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      onSetSortState={setSortState}
    >
      <FloorMapTable
        isVenueId={getVenueId()}
        hallsLoading={hallsLoading}
        methods={methods}
        completeStatus={completeStatus}
        hasData={allHallsData?.halls?.rows?.length}
      />
    </TableProvider>
  );
}

export default FloorMapContainer;
