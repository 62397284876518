import { Flex, Spacer, Box, Divider, Avatar, Text } from "@chakra-ui/react";
import { CalendarIcon } from "components/Icons";
import ProfileMenu from "components/Header/ProfileMenu";
import HeaderSearch from "components/Header/HeaderSearch";
import dayjs from "dayjs";
import { useOptionsContext } from "hooks/useOptionsContext";

export default function Header() {
  const { currentUser } = useOptionsContext();

  return (
    <>
      <Flex py="16px" px="40px" alignItems="center">
        <Box>
          <HeaderSearch />
        </Box>
        <Spacer />
        <Flex>
          <Flex alignItems="center">
            <Box as="button">
              <CalendarIcon w="24px" h="24px" />
            </Box>
            <Text pl="12px" fontSize="xs" fontFamily="medium">
              {dayjs(Date.now()).format("dddd, MMMM DD, YYYY")}
            </Text>
          </Flex>

          <Flex px="33px" alignItems="center">
            {/* <Box as="button">
              <NotifyIcon w="24px" h="24px" />
            </Box> */}
          </Flex>

          <Flex />
          <Box>
            <Avatar
              w="60px"
              h="60px"
              mr="16px"
              bg="blue.500"
              src={
                currentUser?.currentUser?.photo
                  ? `${process.env.REACT_APP_API_URL}/${currentUser?.currentUser?.photo}`
                  : null
              }
            />
          </Box>
          <Flex alignItems="center">
            <ProfileMenu />
          </Flex>
        </Flex>
      </Flex>
      <Divider />
    </>
  );
}
