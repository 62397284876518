import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VOUCHER_EVENTS_DATA } from "mock/VOUCHER_EVENTS_DATA";
import { VOUCHER_TYPES_DATA } from "mock/VOUCHER_TYPES_DATA";
import { VOUCHER_OFFER_TYPES_DATA } from "mock/VOUCHER_OFFER_TYPES_DATA";
import { useModalContext } from "hooks/useModalContext";
import { ADD_VENUES_VOUCHER_SCHEMA } from "schemas";
import AddVenuesVoucherModal from "components/Modals/AddVenuesVoucherModal";

function AddNewVouchersContainer() {
  const { modal, closeModal } = useModalContext("addVenuesVoucherModal");

  const methods = useForm({
    resolver: yupResolver(ADD_VENUES_VOUCHER_SCHEMA.schema),
    defaultValues: {
      name: "",
      event: null,
      voucher_type: null,
      price: "",
      special_price: "",
      offer_type: null,
      quota: "",
    },
  });

  const onChangeEventHandler = (value) => {
    console.log("event", value);
  };

  const onChangeVoucherTypeHandler = (value) => {
    console.log("ticket type", value);
  };

  const onChangeOfferTypeHandler = (value) => {
    console.log("offer type", value);
  };

  const cancelHandler = () => {
    methods.reset();
    closeModal();
  };

  const onSubmit = (value) => {
    console.log("submit", value);
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <AddVenuesVoucherModal
        isOpen={modal.isOpen}
        onClose={closeModal}
        cancelHandler={cancelHandler}
        onSubmit={onSubmit}
        eventOptions={VOUCHER_EVENTS_DATA}
        onChangeEventHandler={onChangeEventHandler}
        voucherTypeOptions={VOUCHER_TYPES_DATA}
        onChangeVoucherTypeHandler={onChangeVoucherTypeHandler}
        offerTypeOptions={VOUCHER_OFFER_TYPES_DATA}
        onChangeOfferTypeHandler={onChangeOfferTypeHandler}
      />
    </FormProvider>
  );
}

export default AddNewVouchersContainer;
