import dayjs from "dayjs";

export const transformAddCompanyOwners = (data) => {
  if (data) {
    return data.owners?.map((owner) => {
      return {
        ownerName: owner.owner,
        dateBirth: dayjs(owner.birthDate).format("YYYY-MM-DD"),
        companyShare: Number(owner.share),
        passport: owner.passport,
        photos: owner.passportPhoto,
      };
    });
  }

  return {};
};
