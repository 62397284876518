import { List, ListItem, Box } from "@chakra-ui/react";

function FileRejectionsErrors({ fileRejections = [] }) {
  return (
    <Box>
      {fileRejections.map(({ file, errors }) => (
        <List key={file.path} paddingLeft={2.5}>
          {errors.map((err) => (
            <ListItem key={err.code} color="red.500" fontSize="2xs">
              {err.message}
            </ListItem>
          ))}
        </List>
      ))}
    </Box>
  );
}

export default FileRejectionsErrors;
