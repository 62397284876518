import { transformAllUsersAddToCompany } from "connectors";
import TableProvider from "contexts/table/table.provider";
import { useGetAllUsers } from "graphql/hooks/useQueries";
import { useDebounce } from "hooks/useDebounce";
import { usePaginationReducer } from "hooks/usePaginationReducer";
import AddUsers from "./AddUsers";
import { COLUMNS } from "./table.config";

export default function AddNewUsersWrapper(props) {
  const { onSubmit, cancelHandler, closeHandler, name, methods } = props;

  const {
    queryPageIndex,
    queryPageSize,
    offsetData,
    pagesQuantity,
    onPageChanged,
    onPageSizeChanged,
    onTotalCountChanged,
  } = usePaginationReducer({ paginationState: { queryPageSize: 6 } });

  const { data, loading } = useGetAllUsers({
    variables: {
      options: {
        limit: queryPageSize,
        offset: offsetData,
        search: useDebounce(name) || null,
      },
    },
  });

  return (
    <TableProvider
      options={{
        columns: COLUMNS,
        data: transformAllUsersAddToCompany(data?.getAllUsers),
        initialState: {
          pageSize: queryPageSize,
          pageIndex: queryPageIndex,
        },
        pageCount: pagesQuantity,
        manualPagination: true,
      }}
      dataCount={data?.getAllUsers?.count}
      onPageChanged={onPageChanged}
      onPageSizeChanged={onPageSizeChanged}
      onTotalCountChanged={onTotalCountChanged}
      isRowSelectionEnabled
    >
      <AddUsers
        onSubmit={onSubmit}
        cancelHandler={cancelHandler}
        closeHandler={closeHandler}
        loading={loading}
        methods={methods}
        data={data}
      />
    </TableProvider>
  );
}
