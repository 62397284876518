import { createContext } from "react";

const defaultValue = {
  company: {},
  getCompanyId: () => {},
  updateCompanyState: () => {},
  getAddNewCompany: () => {},
  getOwners: () => {},
  getUsersCompany: () => {},
  clearCompanyState: () => {},
};

export default createContext(defaultValue);
