import * as yup from "yup";
import { TEL_REGEX } from "../constants/constants";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required!")
    .max(30, "First name is too long!"),
  lastName: yup
    .string()
    .required("Last name is required!")
    .max(30, "Last name is too long!"),
  photo: yup.mixed().nullable(),
  gender: yup.string(),
  password: yup.string().matches(/^.{8,22}$/, {
    excludeEmptyString: true,
    message: "Password must be between 8 and 22 characters",
  }),
  phone: yup
    .string()
    .matches(TEL_REGEX, "Invalid phone number format")
    .required("Phone number is required!"),
  email: yup.string().email("Invalid email!").required("Email is required!"),
  country: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  city: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable(),
  userRole: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  sendToEmail: yup.boolean(),
});

export default { schema };
