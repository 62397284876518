export const transformUpdateProfile = (data) => {
  if (data) {
    return {
      cityId: Number(data.city?.value),
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      description: data.description,
    };
  }
  return {};
};
