import * as yup from "yup";

const schema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  country: yup
    .object()
    .shape({
      value: yup.string().required("Country is required!"),
      label: yup.string().required(),
    })
    .nullable()
    .required("Country is required!"),
  city: yup
    .object()
    .shape({
      value: yup.string().required("City is required!"),
      label: yup.string().required(),
    })
    .nullable()
    .required("City is required!"),
  address: yup.string().required("Address is required"),
  license: yup
    .number()
    .typeError("License must be a number")
    .required("License is required"),
  license_photo: yup
    .mixed()
    .test("fileFormat", "JPEG, JPG or PNG only required", (value) => {
      if (!value) {
        return true;
      }
      return value?.every((item) => {
        return item?.preview;
      });
    })
    .nullable(),
});

export default schema;
