import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "components/Button";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import { useContext } from "react";
import { ArrowLeftIcon } from "components/Icons";
import TableContext from "contexts/table/table.context";

export function PaginationDots() {
  const methods = useForm();
  const { handleSubmit } = methods;

  const { gotoPage } = useContext(TableContext);

  const onSubmit = (formData) => {
    gotoPage(Number(formData.page_number) - 1, false);
  };

  const onPopoverClose = () => {
    methods.reset();
  };

  return (
    <Popover onClose={onPopoverClose}>
      <PopoverTrigger>
        <Box>...</Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Go to page:</PopoverHeader>
        <PopoverBody>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex>
                <TextFieldRHF
                  name="page_number"
                  inputPlaceholder="№"
                  w={20}
                  mr={4}
                />
                <Button type="submit" w={20}>
                  Go
                  <Box ml={2} mt={0.5} transform="rotate(180deg)">
                    <ArrowLeftIcon stroke="currentColor" />
                  </Box>
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
