import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { EyeOpenIcon } from "../Icons";

function TextField(props) {
  const {
    hasError,
    id,
    label,
    inputType = "text",
    inputPlaceholder,
    inputRightElement,
    inputLeftElement,
    isDisabled,
    inputProps,
    errorMessage,
    value,
    onChange,
    ...textFieldProps
  } = props;

  const [currentInputType, setCurrentInputType] = useState(inputType);
  const handleChangeType = useCallback(() => {
    setCurrentInputType((prevState) => {
      return prevState === "password" ? "text" : "password";
    });
  }, []);

  return (
    <FormControl isInvalid={hasError} mb={0} {...textFieldProps}>
      {label ? <FormLabel htmlFor={id}>{label}</FormLabel> : null}
      <InputGroup>
        {inputLeftElement && (
          <InputLeftElement
            width="12"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              variant="iconButton"
              size="iconButton"
              icon={inputLeftElement}
            />
          </InputLeftElement>
        )}
        <Input
          id={id}
          value={value}
          onChange={onChange}
          type={currentInputType}
          placeholder={inputPlaceholder}
          isDisabled={isDisabled}
          borderColor={hasError ? "red.500" : "white.500"}
          {...inputProps}
        />
        {inputRightElement && (
          <InputRightElement
            width="15"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {inputType === "password" ? (
              <IconButton
                variant="iconButton"
                size="iconButton"
                icon={
                  currentInputType === "password" ? (
                    <EyeOpenIcon
                      fill="white.900"
                      width="6"
                      height="5.5"
                      stroke="dark.500"
                    />
                  ) : (
                    <EyeOpenIcon
                      fill="white.900"
                      width="6"
                      height="5.5"
                      stroke="dark.500"
                    />
                  )
                }
                onClick={handleChangeType}
              />
            ) : (
              <IconButton
                variant="iconButton"
                size="iconButton"
                icon={inputRightElement}
              />
            )}
          </InputRightElement>
        )}
      </InputGroup>

      {hasError && errorMessage && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}

export default TextField;
