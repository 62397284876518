import * as yup from "yup";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required!")
    .min(8, "Password is too short!")
    .max(22, "Password is too long!"),
  confirmPassword: yup.string().when("newPassword", {
    is: (newPassword) => newPassword.length > 7,
    then: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords do not match!"),
  }),
});

export default { schema };
