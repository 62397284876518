import { gql } from "@apollo/client";

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID) {
    getUserById(id: $id) {
      id
      firstName
      lastName
      email
      phone
      gender
      status
      photo
      languageId
      currencyId
      cityId
      roleId
      role {
        id
        name
        visibility
        scopes {
          id
          scope
        }
      }
      address
      description
      influencer {
        socialAccount1
        socialAccount2
        socialAccount3
        userId
        level
        status
        user {
          id
          firstName
          lastName
        }
      }
      acceptOffers {
        count
        rows {
          id
        }
      }
      companyId
      company {
        id
        image
        adress
        companyName
        cityId
        licenceNumber
      }
    }
  }
`;
