import { Box, Button, VStack } from "@chakra-ui/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { useModalContext } from "hooks/useModalContext";
import { useRemoveEventSectorRow } from "graphql/hooks/useMutations";
import { useGetEventSectorRowsBySectorId } from "graphql/hooks/useQueries";
import SectorRow from "./SectorRow";
import CHS from "./chakra.styles";

function SectorRows({ sectorId }) {
  const { getValues } = useFormContext();

  const { data: rowsData } = useGetEventSectorRowsBySectorId({
    variables: {
      eventSectorId: sectorId,
    },
  });

  const [removeRow] = useRemoveEventSectorRow();

  const { closeModal: closeCurrentModal } = useModalContext(
    "sectorManagementModal"
  );

  const { openModal: openSetNewRowsCountModal } = useModalContext(
    "setNewRowsCountModal"
  );

  const { openModal: openEditSectorRowModal } =
    useModalContext("editSectorRowModal");

  const setNewRowsCount = useCallback(() => {
    openSetNewRowsCountModal({
      sectorName: getValues("sector_name"),
      sectorId,
    });
    closeCurrentModal();
  }, [sectorId]);

  const editSectorRow = useCallback(
    (data) => () => {
      openEditSectorRowModal({
        sectorName: getValues("sector_name"),
        sectorId,
        ...data,
      });
      closeCurrentModal();
    },
    [sectorId]
  );

  const removeSectorRow = useCallback(
    (rowId) => {
      removeRow({
        variables: { id: rowId },
      });
    },
    [sectorId]
  );

  return (
    <VStack w="full" alignItems="flex-start">
      <Box w="full" sx={CHS.sectorRows}>
        {rowsData?.getEventSectorRowsByEventSectorId?.rows?.map(
          (row, index) => {
            return (
              <Box key={row?.id} w="full">
                <SectorRow
                  sequenceNumber={index + 1}
                  seats={row.placeCount}
                  onEdit={editSectorRow({
                    seatsCount: row.placeCount,
                    sequenceNumber: index + 1,
                    rowId: row.id,
                  })}
                  onRemove={() => removeSectorRow(row.id)}
                />
              </Box>
            );
          }
        )}
      </Box>

      <Button minW="144px" alignSelf="flex-end" onClick={setNewRowsCount}>
        Set new rows count
      </Button>
    </VStack>
  );
}

export default SectorRows;
