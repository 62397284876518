import { Box, Button } from "@chakra-ui/react";
import PageHeader from "components/PageHeader";
import { AddIcon } from "components/Icons";
import VenuesListContainer from "containers/venues/venuesList.container";
import { useNavigate } from "react-router-dom";
import { PATHS, SUB_PATHS } from "constants/constants";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

export default function VenuesMainPage() {
  const navigate = useNavigate();
  const navToNewVenue = () =>
    navigate(`${PATHS.newVenues}/${SUB_PATHS.general}`);
  const { accessCreateVenues, accessViewVenues } = getAccessScopesControl();

  const button = (
    <Button w="154px" onClick={navToNewVenue}>
      <Box mr={2}>
        <AddIcon fill="white.900" />
      </Box>
      Add venue
    </Button>
  );
  return (
    <Box px={10} py={6} w="full">
      <PageHeader button={accessCreateVenues ? button : null} mb={6}>
        Venues
      </PageHeader>
      <VenuesListContainer accessViewVenues={accessViewVenues} />
    </Box>
  );
}
