import { Box, Flex, Text } from "@chakra-ui/react";
import {
  AcceptIcon,
  AlertErrorIcon,
  AlertWarningIcon,
  AlertInfoIcon,
} from "components/Icons";
import { getToastBgColor } from "utils/getToastBgColor";
import CHS from "./chakra.styles";

export default function Toast({ status, description, customIcon, title }) {
  const SuccessIcon = customIcon || <AcceptIcon w={6} h={5} fill="none" />;
  const ErrorIcon = customIcon || (
    <AlertErrorIcon w={5.5} h={5} fill="white.900" />
  );
  const WarningIcon = customIcon || (
    <AlertWarningIcon w={5.5} h={5} fill="white.900" />
  );
  const InfoIcon = customIcon || (
    <AlertInfoIcon w={5.5} h={5} fill="white.900" />
  );

  const iconObj = {
    success: SuccessIcon,
    error: ErrorIcon,
    warning: WarningIcon,
    info: InfoIcon,
  };

  return (
    <Box sx={CHS.toastContainer} bg={getToastBgColor(status)}>
      {iconObj[status]}
      <Flex sx={CHS.toastInfoWrap}>
        <Text sx={CHS.toastTitle}>{title}</Text>
        <Text sx={CHS.toastDesc}>{description}</Text>
      </Flex>
    </Box>
  );
}
