const optionIcon = {
  w: "20px",
  h: "20px",
  fill: "dark.500",
  _groupHover: { fill: "blue.500" },
};

const icon = {
  minW: 0,
  w: 10,
  h: 10,
};

const sectorRows = {
  maxH: 365,
  paddingRight: 2.5,
  marginBottom: 4,
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "white.500",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "blue.500",
    borderRadius: "3px",
  },
  "::-webkit-scrollbar-button": {
    backgroundColor: "transparent",
    height: 0,
  },
};

export default {
  optionIcon,
  icon,
  sectorRows,
};
