export const transformReceivedUpdateVenueMenu = (responseData) => {
  if (responseData) {
    const contentValues = responseData.tr.reduce(
      (acc, valueObj) => {
        acc.name[valueObj.language.code] = valueObj.name;
        acc.description[valueObj.language.code] = valueObj.description;
        acc.shortDescription[valueObj.language.code] =
          valueObj.shortDescription;
        return acc;
      },
      {
        name: {},
        description: {},
        shortDescription: {},
      }
    );

    const imageData = responseData.image
      ? {
          image: {
            preview: `${process.env.REACT_APP_API_URL}/${responseData.image}`,
          },
        }
      : null;

    const fileData = responseData.fullMenu
      ? {
          file: {
            path: responseData.fullMenu.slice(
              responseData.fullMenu.lastIndexOf("/") + 1
            ),
          },
        }
      : null;

    return {
      venueMenu: {
        id: responseData?.id,
        name: contentValues?.name,
        language: { label: "English", value: { id: 1, code: "en-US" } },
        short_description: contentValues?.shortDescription,
        description: contentValues?.description,
        ...imageData,
        ...fileData,
      },
    };
  }

  return {};
};
