const title = {
  textTransform: "capitalize",
};

const subTitle = {
  textTransform: "capitalize",
  color: "inherit",
  fontFamily: "inherit",
  fontSize: "inherit",
};

const helperText = {
  fontSize: "12px",
  margin: "8px 0px 0px 8px",
  color: "#ccc",
};

export default { title, subTitle, helperText };
