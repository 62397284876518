import { useEffect } from "react";
import { useModalContext } from "hooks/useModalContext";
import { FormProvider, useForm } from "react-hook-form";
import ArticleCreateEditModal from "components/Modals/ArticleCreateEdit";
import {
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  transformBeforeEditArticle,
  transformCategoriesOptions,
  transformCreateArticle,
  transformEditArticle,
  transformLanguageSelect,
  transformLanguagesToCodeList,
} from "connectors";
import { onLanguageChange } from "utils/onLanguageChange";
import { useOptionsContext } from "hooks/useOptionsContext";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CREATE_ARTICLE_SCHEMA, EDIT_ARTICLE_SCHEMA } from "schemas";
import { useCreateArticle, useEditArticle } from "graphql/hooks/useMutations";
import { allCategories } from "constants/constants";
import { GET_ALL_ARTICLE_ADMIN, GET_ALL_COMMENTS } from "graphql/queries";
import { useToastContext } from "hooks/useToastContext";
import { transformStatusOptions } from "connectors/transformStatusOptions";
import useGetArticleStatuses from "hooks/useGetArticleStatuses.hook";

export default function ArticleCreateEditModalContainer() {
  useGetArticleStatuses();

  const { modal, closeModal } = useModalContext("articleCreateEditModal");

  const { showToast } = useToastContext();

  const { languages, categories, articleStatuses } = useOptionsContext();

  const codeList = transformLanguagesToCodeList(languages);
  const translatedValues = codeList.reduce((acc, code) => {
    acc[code] = "";
    return acc;
  }, {});

  const INITIAL_VALUES = {
    title: translatedValues,
    language: { label: "English", value: { id: 1, code: "en-US" } },
    url: "",
    show_on_main: false,
    meta_title: translatedValues,
    meta_description: translatedValues,
    promo_image: null,
    promo_text: translatedValues,
    tagsEdit: null,
    tagsCreate: "",
    category: null,
    main_image: null,
    main_text: translatedValues,
    author_name: "",
    author_avatar: null,
    publication_date: null,
    status: null,
  };

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode
        ? EDIT_ARTICLE_SCHEMA.schema(languages)
        : CREATE_ARTICLE_SCHEMA.schema(languages)
    ),
  });

  const { reset, watch } = methods;

  const [createArticle] = useCreateArticle();
  const [editArticle] = useEditArticle();

  const closeHandler = () => {
    reset(INITIAL_VALUES);
    closeModal();
  };

  const selectedLanguage = watch("language");

  const languageChangeHandler = () => {
    onLanguageChange({
      getCurrentFormState: methods.getValues,
      reset: methods.reset,
    });
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(
        transformBeforeEditArticle(
          modal.editArticle,
          categories,
          translatedValues,
          languages
        )
      );
    }
  }, [modal.isEditMode]);

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      // edit Article
      editArticle({
        variables: {
          info: transformEditArticle(
            formData,
            modal.editArticle?.id,
            languages
          ),
        },
        refetchQueries: modal?.editArticleFromComments
          ? [GET_ALL_COMMENTS]
          : [GET_ALL_ARTICLE_ADMIN],
        onCompleted: () => {
          showToast({
            description: "The article has been successfully updated",
          });
          closeHandler();
        },
        onError: (error) => {
          if (error) {
            switch (error?.message) {
              case "SequelizeUniqueConstraintError: Validation error":
                methods.setError("url", {
                  type: "custom",
                  message: "Article under this URL already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    } else {
      // create Article
      createArticle({
        variables: { info: transformCreateArticle(formData, languages) },
        refetchQueries: [GET_ALL_ARTICLE_ADMIN],
        onCompleted: () => {
          showToast({
            description: "The article has been successfully created",
          });
          closeHandler();
        },
        onError: (error) => {
          if (error) {
            switch (error?.message) {
              case "Article under this url already exists":
                methods.setError("url", {
                  type: "custom",
                  message: "Article under this URL already exists",
                });
                break;
              default:
                console.log(error?.message);
            }
          }
        },
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>
              {modal.isEditMode ? "Article moderation" : "Add article"}
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <ArticleCreateEditModal
            onSubmit={methods.handleSubmit(onSubmit)}
            cancelHandler={closeHandler}
            isEditMode={modal.isEditMode}
            selectedLanguage={selectedLanguage}
            languageOptions={transformLanguageSelect(languages)}
            languageChangeHandler={languageChangeHandler}
            statusOptions={transformStatusOptions(articleStatuses)}
            categoriesOptions={transformCategoriesOptions(
              allCategories.articleCategory,
              categories
            )}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
