import { useContext, useMemo } from "react";
import { useGetAllVenueOptions } from "graphql/hooks/useQueries";
import AuthContext from "contexts/auth/auth.context";
import { useOptionsContext } from "hooks/useOptionsContext";

const useGetVenuesOptions = () => {
  const { isAuth } = useContext(AuthContext);
  const {
    updateOptionsState,
    venueStatuses,
    venueCategories,
    venueRanks,
    venueServices,
  } = useOptionsContext();

  const shouldSkip = useMemo(() => {
    // skip if user is not authorized or options are fetched already
    return (
      !isAuth ||
      !!venueStatuses.length ||
      !!venueCategories.length ||
      !!venueRanks.length ||
      !!venueServices.length
    );
  }, [
    isAuth,
    venueStatuses.length,
    venueCategories.length,
    venueRanks.length,
    venueServices.length,
  ]);

  useGetAllVenueOptions({
    onCompleted: (response) => {
      updateOptionsState({
        venueStatuses: response?.getAllOptionsVenues?.venueStatus,
        venueCategories: response?.getAllOptionsVenues?.categories,
        venueRanks: response?.getAllOptionsVenues?.venueRanks,
        venueServices: response?.getAllOptionsVenues?.services,
      });
    },
    skip: shouldSkip,
  });
};

export default useGetVenuesOptions;
