export default {
  baseStyle: {
    field: {
      w: "100%",
      minWidth: 0,
      outline: 0,
      appearance: "none",
      transitionProperty: "common",
      transitionDuration: "normal",
    },
  },
  // sizes: {
  //   base: {
  //     field: {
  //       w: "full",
  //       h: 10.5,
  //       py: 2.25,
  //       pr: 6,
  //       pl: 5,
  //     },
  //   },
  // },
  variants: {
    base: {
      field: {
        w: "full",
        h: 10.5,
        py: 2.25,
        borderColor: "white.500",
        borderWidth: "px",
        borderStyle: "solid",
        borderRadius: "5xl",
        bgColor: "white.900",
        fontFamily: "normal",
        fontWeight: "normal",
        fontSize: "sm",
        lineHeight: "5",
        color: "black.900",
        _active: {
          bgColor: "white.900",
        },
        _focus: {
          bgColor: "white.900",
        },
        _autofill: {
          bgColor: "white.900",
        },
        _disabled: {
          color: "dark.300",
        },
        _readOnly: {
          bgColor: "grey.100",
          color: "dark.300",
        },
      },
    },
  },
  // TODO OlehZ. defaultProps doesn`t work correctly !?
  defaultProps: {
    // size: "base",
    variant: "base",
  },
};
