import Status from "components/TableComponents/Status";
import { LocationIcon } from "components/Icons";
import { Flex, Text } from "@chakra-ui/react";
import URLHeader from "components/TableComponents/URLHeader";
import { getAdminsRoles } from "utils/getUserRoleName";
import Options from "./Options";
import CHS from "./chakra.styles";

export const COLUMNS = () => {
  const { isSuperAdmin, isAdmin } = getAdminsRoles();
  const accessRank = isSuperAdmin || isAdmin;

  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => {
        return <Text>{value || "—"}</Text>;
      },
    },
    {
      Header: () => <URLHeader title="URL" />,
      accessor: "url",
      Cell: ({ value }) => {
        return <Text>{value || "—"}</Text>;
      },
    },
    {
      Header: "Address",
      accessor: "address",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Flex>
            <LocationIcon fill="blue.500" w={5} height={5} mr={1} />
            <Text sx={CHS.addressSplit}>{value || "not available"}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category",
      disableSortBy: true,
    },
    ...(accessRank
      ? [
          {
            Header: "Rank",
            accessor: "rank",
          },
        ]
      : []),
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        return <Status value={value || "—"} />;
      },
    },
    {
      Header: "Options",
      accessor: "options",
      isNumeric: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return <Options row={row} />;
      },
    },
  ];
};

export const ROWS_PER_PAGE = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];
