import { HStack, Avatar, Text } from "@chakra-ui/react";

export default function ClientCell({ data }) {
  return (
    <HStack spacing={4}>
      <Avatar
        size="sm"
        src={`${process.env.REACT_APP_API_URL}/${data.avatar}`}
      />

      <Text>{data.client}</Text>
    </HStack>
  );
}
