import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import CHS from "./chakra.styles";

function Options() {
  const { openModal } = useModalContext("addVenuesTicketModal");

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openModal}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem icon={<DeleteOptionIcon sx={CHS.optionIcon} />}>
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}

export default Options;
