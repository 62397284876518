export const getToastBgColor = (toastStatus) => {
  switch (toastStatus) {
    case "success":
      return "green.500";
    case "error":
      return "red.500";
    case "warning":
      return "yellow.500";
    case "info":
      return "blue.500";
    default:
      return "dark.300";
  }
};
