import * as yup from "yup";
import { TEL_REGEX } from "../constants/constants";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required!")
    .max(30, "First name is too long!"),
  photo: yup.mixed().nullable(),
  lastName: yup
    .string()
    .required("Last name is required!")
    .max(30, "Last name is too long!"),
  gender: yup.string(),
  password: yup
    .string()
    .required("Password is required!")
    .min(8, "Password is too short!")
    .max(22, "Password is too long!"),
  phone: yup
    .string()
    .matches(TEL_REGEX, "Invalid phone number format")
    .required("Phone number is required!"),
  email: yup.string().email("Invalid email!").required("Email is required!"),
  country: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("Country is required!"),
  city: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("City is required!"),
  userRole: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("Role is required!"),
  sendToEmail: yup.boolean(),
});

export default { schema };
