export const transformBeforeEditScheduleAttraction = ({ data = [] }) => {
  if (data) {
    const scheduleObj = {
      days: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      daySchedule: {},
    };

    data?.forEach((item) => {
      const startDate = new Date();
      startDate.setHours(item.startTime.split(":")[0]);
      startDate.setMinutes(item.startTime.split(":")[1]);

      const stopDate = new Date();
      stopDate.setHours(item.stopTime.split(":")[0]);
      stopDate.setMinutes(item.stopTime.split(":")[1]);

      const durationDate = new Date();
      durationDate.setHours(item.upTime.split(":")[0]);
      durationDate.setMinutes(item.upTime.split(":")[1]);

      scheduleObj.daySchedule[item.dayOfWeek] = {
        id: item.id,
        from: startDate,
        to: stopDate,
        duration: durationDate,
        enabled: item.status === "Enabled" ? true : false,
      };
    });

    return scheduleObj;
  }
  return [];
};
