export const transformSectorCreatingData = (data) => {
  if (!data) return {};
  return {
    sector: data.sector_name,
    image: data.map_image || undefined,
    shortDescription: data.short_description,
    EventSectorRowCount: Number(data.rows_count),
    EventSectorRowPlaceCount: Number(data.seats_per_row),
  };
};

export const transformSectorUpdatingData = (data) => {
  if (!data) return {};

  const reguestObj = {
    sector: data.sector_name,
    shortDescription: data.short_description,
  };

  if (data.map_image?.preview && data.map_image?.path) {
    Object.assign(reguestObj, { image: data.map_image });
  }

  return reguestObj;
};

export const transformSectorFormData = (data) => {
  if (!data) return {};
  return {
    sector_name: data.sector,
    map_image: { preview: `${process.env.REACT_APP_API_URL}/${data.image}` },
    short_description: data.shortDescription,
  };
};
