import { Text } from "@chakra-ui/react";
import { useModalContext } from "hooks/useModalContext";

export default function Offers({ influencer }) {
  const { openModal } = useModalContext("acceptedOffersModal");

  const openAcceptedOffersModal = () => {
    openModal({ influencer });
  };

  if (!influencer?.user?.acceptOffers?.count) return "_";

  return (
    <Text
      as="button"
      textDecoration="underline"
      onClick={openAcceptedOffersModal}
    >
      {influencer?.user?.acceptOffers?.count}
    </Text>
  );
}
