export const USERS_COMPANIES_SEARCH_DATA = [
  {
    title: "Users",
    count: "3",
    items: [
      {
        link: "Michael Peеrterson",
        path: "Users/Users",
      },
      {
        link: "Michael Peеrs",
        path: "Users/Users",
      },
      {
        link: "Yvonne Peеrs",
        path: "Users/Users",
      },
    ],
  },
  {
    title: "Companies",
    count: "2",
    items: [
      {
        link: "Peеr 2 Peer Bar",
        path: "Users/Companies",
      },
      {
        link: "Michael Peеrs",
        path: "Users/Companies",
      },
    ],
  },
];
