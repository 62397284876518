const item = {
  minW: "110px",
  width: "50%",
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderColor: "white.500",
  bg: "white.900",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "42px",
  cursor: "pointer",
};

const activeItem = {
  bg: "blue.500",
  "& > *": {
    color: "white.900",
  },
};

const firstItem = {
  borderLeftRadius: "4xl",
  borderLeft: "1px solid",
  borderLeftColor: "white.500",
};

const lastItem = {
  borderRightRadius: "4xl",
  borderRight: "1px solid",
  borderRightColor: "white.500",
};

export default {
  item,
  activeItem,
  firstItem,
  lastItem,
};
