import { gql } from "@apollo/client";

export const GET_TABLES_BY_AREA_ID = gql`
  query getTablesByAreaId($options: GetTablesByAreaId!) {
    zoneTables: getTablesByAreaId(options: $options) {
      count
      rows {
        id
        table
        amount
        venueAreaId
        seats
      }
    }
  }
`;
