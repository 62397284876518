import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  InputGroup,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "components/Icons";
import ReactSelect, { components } from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { useSelectStyles } from "hooks/useSelectStyles.hook";

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Button size="xs" variant="brandRotate">
        <ArrowDownIcon stroke="white.900" w="10px" h="6px" fill="none" />
      </Button>
    </components.DropdownIndicator>
  );
}

export default function SelectRHF(props) {
  const {
    name,
    label,
    options,
    placeholder,
    defaultValue,
    isSearchable = false,
    isDisabled = false,
    isLoading = false,
    isClearable = false,
    errorMessage,
    onChangeHandler,
    wrapperProps,
    customStyles,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useSelectStyles(errors[name], isDisabled, customStyles);

  const handleChange = (hookChangeFunc) => (option) => {
    hookChangeFunc(option);
    onChangeHandler?.(option);
  };

  return (
    <FormControl isInvalid={errors[name]} {...wrapperProps}>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <InputGroup size="lg">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              name={name}
              value={value}
              onChange={handleChange(onChange)}
              options={options}
              defaultValue={defaultValue}
              placeholder={placeholder}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isSearchable={isSearchable}
              components={{ DropdownIndicator }}
              styles={styles}
            />
          )}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
