const numericStyles = {
  "&[data-is-numeric=true]": {
    textAlign: "end",
  },
};

export default {
  variants: {
    base: {
      table: {},
      th: {
        pr: "none",
        fontFamily: "medium",
        fontSize: "sm",
        fontWeight: "medium",
        lineHeight: 5,
        color: "blue.500",
        textTransform: "capitalized",
        height: 10,
        ...numericStyles,
      },
      tr: {
        borderBottomColor: "white.500",
        borderBottomWidth: "px",
        borderBottomStyle: "solid",
      },
      td: {
        fontSize: "sm",
        fontWeight: "normal",
        lineHeight: 5,
        color: "black.500",
        height: 19,
        ...numericStyles,
      },
    },
    modalTable: {
      table: {},
      th: {
        paddingLeft: 0,
        fontFamily: "medium",
        fontSize: "xs",
        fontWeight: "medium",
        color: "dark.500",
        textTransform: "capitalized",
        height: 12.5,
        ...numericStyles,
      },
      tr: {
        borderBottomColor: "white.500",
        borderBottomWidth: "px",
        borderBottomStyle: "solid",
      },
      td: {
        paddingLeft: 0,
        fontSize: "sm",
        fontWeight: "normal",
        lineHeight: 5,
        color: "black.900",
        height: 14,
        ...numericStyles,
      },
    },
    modalTable2: {
      table: {},
      th: {
        display: "none",
      },
      tbody: {
        display: "inline-block",
        marginTop: "28px",
        marginBottom: "6px",
      },
      tr: {
        border: "none",
      },
      td: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: "12px",
        paddingTop: "12px",
        fontSize: "sm",
        fontWeight: "normal",
        lineHeight: 5,
        color: "black.900",
        height: 14,
        ...numericStyles,
        label: {
          width: "none",
          marginRight: "48px",
        },
        img: {
          marginRight: "12px",
        },
      },
    },
    modalTable3: {
      table: { marginTop: "32px" },
      th: {
        display: "none",
      },
      tbody: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "6px",
        "tr:not(:last-child)": {
          borderBottomColor: "white.500",
          borderBottomWidth: "px",
          borderBottomStyle: "solid",
        },
      },
      tr: {
        display: "flex",
        alignItems: "center",
      },
      td: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: "12px",
        paddingTop: "12px",
        fontSize: "sm",
        fontWeight: "normal",
        lineHeight: 5,
        color: "black.900",
        height: 14,
        ...numericStyles,
        label: {
          width: "none",
          marginRight: "48px",
        },
        img: {
          marginRight: "16px",
        },
      },
    },
  },
  defaultProps: {
    variant: "base",
  },
};
