import { gql } from "@apollo/client";

export const CREATE_ATTRACTION_SCHEDULE = gql`
  mutation createAttractionSchedule($input: [CreateAttractionSchedule]) {
    createAttractionSchedule(input: $input) {
      id
      attractionId
      dayOfWeek
      status
      upTime
      startTime
      stopTime
    }
  }
`;
