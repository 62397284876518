import { Box, Flex, IconButton } from "@chakra-ui/react";
import { ArrowDownThinIcon } from "components/Icons";
import { useSetOrderStaticPage } from "graphql/hooks/useMutations";
import { GET_ALL_STATIC_PAGES } from "graphql/queries";

export default function OrderSetter({ row }) {
  const [setOrderStaticPage] = useSetOrderStaticPage();

  const handleSetOrder = (position) => () => {
    setOrderStaticPage({
      variables: { id: Number(row?.original?.id), type: position },
      refetchQueries: [GET_ALL_STATIC_PAGES],
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <Flex alignItems="center">
      <IconButton
        onClick={handleSetOrder("DOWN")}
        size="sm"
        variant="iconButton"
        icon={
          <ArrowDownThinIcon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "8px",
            }}
            w="6"
            h="6"
            color="blue.500"
          />
        }
      />
      <Box>{row?.original.order}</Box>
      <IconButton
        onClick={handleSetOrder("UP")}
        size="sm"
        variant="iconButton"
        icon={
          <ArrowDownThinIcon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: "rotate(180deg)",
              marginLeft: "8px",
            }}
            w="6"
            h="6"
            color="blue.500"
          />
        }
      />
    </Flex>
  );
}
