import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Voucher name is required"),
  event: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Event is required")
    .nullable(true),
  voucher_type: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Voucher type is required")
    .nullable(true),
  price: yup.string().required("Price is required"),
  special_price: yup.string(),
  offer_type: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Offer type is required")
    .nullable(true),
  quota: yup.string().required("Quota is required"),
});

export default { schema };
