import { gql } from "@apollo/client";

export const GET_EVENT_BY_ID = gql`
  query getEventsById($id: Int!) {
    getEventsById(id: $id) {
      id
      url
      isAddToSlider
      isTop
      isFeatured
      isUpcoming
      image
      geolocation
      price
      photo
      currencyId
      categoryId
      location {
        id
        title
      }
      category {
        id
        modelName
        name
      }
      startAt
      createdAt
      eventSEO {
        id
        languageId
        language {
          id
          code
          name
        }
        metaTitle
        metaDescription
      }
      eventVenue {
        id
        title
      }
      restrictions {
        id
        name
      }
      services {
        id
        name
      }
      status
      placeDetails {
        address
      }
      tr {
        id
        title
        description
        shortDescription
        languageId
        language {
          id
          code
          name
        }
      }
      gallery {
        id
        title
        modelName
        modelId
        description
        url
        categoryId
        date
        galleryPhoto {
          id
          modelName
          modelId
          path
          order
          description
          altTag
          isLiked
          photoAuthor {
            id
          }
        }
        galleryPhotoCount
      }
    }
  }
`;
