import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

function Tabs(props) {
  const {
    tabsData,
    variant,
    defaultTab,
    index,
    isLazy = true,
    lazyBehavior = "keepMounted",
    ...rest
  } = props;

  // tabs are controlled through the URL, so the function `onChange` is not passed!
  return (
    <ChakraTabs
      variant={variant}
      defaultIndex={defaultTab}
      index={index}
      isLazy={isLazy}
      lazyBehavior={lazyBehavior}
      {...rest}
    >
      <TabList>
        {tabsData.map((item) => {
          return (
            <NavLink to={`?tab=${item.path}`} key={item.id}>
              <Tab>{item.label}</Tab>
            </NavLink>
          );
        })}
      </TabList>

      <TabPanels>
        {tabsData.map((item) => {
          const { id, content: Content } = item;
          return <TabPanel key={id}>{Content ? <Content /> : null}</TabPanel>;
        })}
      </TabPanels>
    </ChakraTabs>
  );
}

export default Tabs;
