import { EditOptionIcon, DeleteOptionIcon } from "components/Icons";
import CustomMenuItem from "components/CustomMenuItem";
import TableOptions from "components/Table/TableOptions";
import { useModalContext } from "hooks/useModalContext";
import { useDeleteCompany } from "graphql/hooks/useMutations";
import CHS from "./chakra.styles";

export default function Options({ row }) {
  const { openModal: openCreateModal } = useModalContext(
    "createNewCompanyModal"
  );
  const { openModal: openDeleteModal, closeModal: closeDeleteModal } =
    useModalContext("deleteConfirmationModal");

  const openModalHandler = () => {
    openCreateModal({ company: row.original, isEditMode: true });
  };

  const [removeCompany] = useDeleteCompany({
    variables: {
      id: row.original.id,
    },
    onCompleted: closeDeleteModal,
  });

  const openDeleteConfirmationModal = () => {
    openDeleteModal({
      name: row.original.company,
      type: "company",
      onDelete: removeCompany,
    });
  };

  return (
    <TableOptions>
      <CustomMenuItem
        icon={<EditOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openModalHandler}
      >
        Edit
      </CustomMenuItem>
      <CustomMenuItem
        icon={<DeleteOptionIcon sx={CHS.optionIcon} />}
        onMenuItemClick={openDeleteConfirmationModal}
      >
        Delete
      </CustomMenuItem>
    </TableOptions>
  );
}
