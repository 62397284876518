import {
  AnalyticIcon,
  PayIcon,
  ArticleIcon,
  EditIcon,
  VenueIcon,
  EventIcon,
  AttractionIcon,
  OfferIcon,
  FeedIcon,
  UserIcon,
  BannerIcon,
  LocationIcon,
  AlertIcon,
  ServicesIcon,
  CommentsIcon,
  GlassIcon,
} from "components/Icons";

export const SUB_PATHS = {
  general: "general",
  menu: "menu",
  gallery: "gallery",
  entertainments: "entertainments",
  offers: "offers",
  floorMap: "floor-map",
  ticketsAndVouchers: "tickets-and-vouchers",
  ticketsAndSchedule: "tickets-and-schedule",
  shows: "shows",
};

export const PATHS = {
  home: "/",
  recoverPassword: "/recover-password",
  resetPassword: "/reset-password",
  login: "/login",
  analytics: "/analytics",
  payments: "/payments",
  articles: "/articles",
  content: "/content",
  venues: "/venues",
  newVenues: "/venues/new",
  editVenues: "/venues/:id",
  events: "/events",
  editEvents: "/events/:id",
  newEvents: "/events/new",
  attractions: "/attractions",
  editAttractions: "/attractions/:id",
  newAttractions: "/attractions/new",
  offers: "/offers",
  feeds: "/feeds",
  feed: "/feed",
  seo: "/seo",
  users: "/users",
  me: "/users/me",
  reports: "/reports",
  locations: "/locations",
  banners: "/banners",
  services: "/services",
  comments: "/comments",
};

// Service routes are those public path that must be accessible
// only when user is not authenticated
export const SERVICE_ROUTES = [
  PATHS.login,
  PATHS.recoverPassword,
  PATHS.resetPassword,
];

export const LINKS = [
  {
    icon: <AnalyticIcon w="20px" h="18px" fill="white.900" />,
    text: "Analytics",
    path: "/analytics",
    permission: "Analytic:view",
  },
  {
    icon: <PayIcon w="20px" h="18px" fill="white.900" />,
    text: "Payments",
    path: "/payments",
    permission: "Payment:view",
  },
  {
    isDivider: true,
    dividerId: 1,
  },
  {
    icon: <ArticleIcon w="21px" h="21px" fill="white.900" />,
    text: "Articles",
    path: "/articles",
    permission: "Article:view",
  },
  {
    icon: <EditIcon w="20px" h="22px" fill="white.900" />,
    text: "Content",
    path: "/content",
    permission: "Content:view",
  },
  {
    icon: <VenueIcon w="20px" h="20px" fill="white.900" />,
    text: "Venues",
    path: "/venues",
    permission: "Venue:view",
  },
  {
    icon: <EventIcon w="20px" h="20px" fill="white.900" />,
    text: "Events",
    path: "/events",
    permission: "Event:view",
  },
  {
    icon: <AttractionIcon w="24px" h="24px" fill="white.900" />,
    text: "Attractions",
    path: "/attractions",
    permission: "Attraction:view",
  },
  {
    icon: <OfferIcon w="21px" h="21px" stroke="white.900" />,
    text: "Offers",
    path: "/offers",
    permission: "Offer:view",
  },
  {
    icon: (
      <ServicesIcon w="18px" h="18px" fill="white.900" stroke="white.900" />
    ),
    text: "Services",
    path: "/services",
    permission: "Service:view",
  },
  {
    icon: <FeedIcon w="18px" h="20px" fill="white.900" stroke="white.900" />,
    text: "Feeds",
    path: "/feeds",
    permission: "Feed:view",
  },
  {
    icon: <GlassIcon w="18px" h="20px" fill="white.900" stroke="white.900" />,
    text: "SEO",
    path: "/seo",
    permission: "SeoData:read",
  },
  {
    isDivider: true,
    dividerId: 2,
  },
  {
    icon: <UserIcon w="16px" h="21px" fill="white.900" />,
    text: "Users",
    path: "/users?tab=users",
    permission: "User:view",
  },
  {
    icon: <CommentsIcon w="24px" h="24px" fill="white.900" />,
    text: "Comments",
    path: "/comments",
    permission: "Comment:view",
  },
  {
    icon: <AlertIcon w="24px" h="24px" fill="white.900" />,
    text: "Reports",
    path: "/reports",
    permission: "Report:view",
  },
  {
    icon: <LocationIcon w="18px" h="22px" fill="white.900" />,
    text: "Locations",
    path: "/locations",
    permission: "Location:view",
  },
  {
    icon: <BannerIcon w="18px" h="18px" fill="white.900" />,
    text: "Banners",
    path: "/banners?tab=ads",
    permission: "Banner:view",
  },
];

export const SIDEBAR_WIDTH = "200";

export const ACCESS = {
  private: "private",
  public: "public",
};

export const DEFAULT_TABLE_OBJECT = {
  table_name: "",
  number_of_seats: "",
  tickets: [],
};

export const DEFAULT_TICKET_OBJECT = {
  type: "",
  price: "",
};

export const DEFAULT_PAGINATION_STATE = {
  queryPageIndex: 0,
  queryPageSize: 3,
  totalCount: null,
};

export const USER_DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  photo: null,
  gender: "NOT_INDICATED",
  password: "",
  phone: "",
  email: "",
  country: null,
  city: null,
  userRole: null,
  sendToEmail: false,
};

export const INITIAL_EDIT_COMMENT_VALUES = {
  title: "",
  comment_text: "",
  author: "",
  publication_date: "",
};

export const allCategories = {
  articleCategory: "ARTICLE",
  attractionCategory: "ATTRACTION",
  eventCategory: "EVENT",
  offerCategory: "OFFER",
  venueCategory: "VENUE",
  galleryCategory: "GALLERY",
};

export const DEFAULT_ENGLISH_LANGUAGE_ID = 1; // English

export const DEFAULT_REPORT_STATUS = { label: "All", value: "ALL" };

export const REPORT_USER_ACTIONS = [
  { value: "none", label: "No action" },
  { value: "delete", label: "Delete user " },
];

export const REPORT_POST_ACTIONS = [
  { value: "none", label: "No action" },
  { value: "delete", label: "Delete post" },
];

export const REPORT_COMMENT_ACTIONS = [
  { value: "none", label: "No action" },
  { value: "delete", label: "Delete comment" },
];

export const KEYBOARD_BUTTONS_KEYS = {
  TAB: 9,
  ENTER: 13,
};

export const ROWS_PER_PAGE_DEFAULT = [
  { value: 3, label: "03" },
  { value: 5, label: "05" },
  { value: 7, label: "07" },
  { value: 9, label: "09" },
];

export const INITIAL_COORDINATES = {
  lat: 24.448626421994383,
  lng: 54.65098112115982,
};

export const TEL_REGEX = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

export const MAX_VIDEO_SIZE = 50000000;

export const RESERVED_STATIC_PAGES = [
  "about",
  "terms-and-conditions",
  "privacy-policy",
];

export const ACCESS_SCOPES = {
  VENUE_CREATE: "Venue:create",
  VENUE_READ: "Venue:read",
  VENUE_UPDATE: "Venue:update",
  VENUE_DELETE: "Venue:delete",
  VENUE_VIEW: "Venue:view",
  EVENT_CREATE: "Event:create",
  EVENT_READ: "Event:read",
  EVENT_UPDATE: "Event:update",
  EVENT_DELETE: "Event:delete",
  EVENT_VIEW: "Event:view",
  ATTRACTION_CREATE: "Attraction:create",
  ATTRACTION_READ: "Attraction:read",
  ATTRACTION_UPDATE: "Attraction:update",
  ATTRACTION_DELETE: "Attraction:delete",
  ATTRACTION_VIEW: "Attraction:view",
  OFFER_CREATE: "Offer:create",
  OFFER_READ: "Offer:read",
  OFFER_UPDATE: "Offer:update",
  OFFER_DELETE: "Offer:delete",
  OFFER_VIEW: "Offer:view",
  FEED_CREATE: "Feed:create",
  FEED_READ: "Feed:read",
  FEED_UPDATE: "Feed:update",
  FEED_DELETE: "Feed:delete",
  FEED_VIEW: "Feed:view",

  SEO_CREATE: "SeoData:create",
  SEO_READ: "SeoData:read",
  SEO_UPDATE: "SeoData:update",
  SEO_DELETE: "SeoData:delete",

  USER_CREATE: "User:create",
  USER_READ: "User:read",
  USER_UPDATE: "User:update",
  USER_DELETE: "User:delete",
  USER_VIEW: "User:view",
  GALLERY_CREATE: "Gallery:create",
  GALLERY_READ: "Gallery:read",
  GALLERY_UPDATE: "Gallery:update",
  GALLERY_DELETE: "Gallery:delete",
  GALLERY_VIEW: "Gallery:view",
  COMMENT_CREATE: "Comment:create",
  COMMENT_READ: "Comment:read",
  COMMENT_UPDATE: "Comment:update",
  COMMENT_DELETE: "Comment:delete",
  COMMENT_VIEW: "Comment:view",
  REPORT_CREATE: "Report:create",
  REPORT_READ: "Report:read",
  REPORT_UPDATE: "Report:update",
  REPORT_DELETE: "Report:delete",
  REPORT_VIEW: "Report:view",
  LOCATION_CREATE: "Location:create",
  LOCATION_READ: "Location:read",
  LOCATION_UPDATE: "Location:update",
  LOCATION_DELETE: "Location:delete",
  LOCATION_VIEW: "Location:view",
  BANNER_CREATE: "Banner:create",
  BANNER_READ: "Banner:read",
  BANNER_UPDATE: "Banner:update",
  BANNER_DELETE: "Banner:delete",
  BANNER_VIEW: "Banner:view",
  ANALYTIC_CREATE: "Analytic:create",
  ANALYTIC_READ: "Analytic:read",
  ANALYTIC_UPDATE: "Analytic:update",
  ANALYTIC_DELETE: "Analytic:delete",
  ANALYTIC_VIEW: "Analytic:view",
  PAYMENT_CREATE: "Payment:create",
  PAYMENT_READ: "Payment:read",
  PAYMENT_UPDATE: "Payment:update",
  PAYMENT_DELETE: "Payment:delete",
  PAYMENT_VIEW: "Payment:view",
  ARTICLE_CREATE: "Article:create",
  ARTICLE_READ: "Article:read",
  ARTICLE_UPDATE: "Article:update",
  ARTICLE_DELETE: "Article:delete",
  ARTICLE_VIEW: "Article:view",
  SERVICE_CREATE: "Service:create",
  SERVICE_READ: "Service:read",
  SERVICE_UPDATE: "Service:update",
  SERVICE_DELETE: "Service:delete",
  SERVICE_VIEW: "Service:view",
  CONTENT_CREATE: "Content:create",
  CONTENT_READ: "Content:read",
  CONTENT_UPDATE: "Content:update",
  CONTENT_DELETE: "Content:delete",
  CONTENT_VIEW: "Content:view",
  DASHBOARD_VIEW: "Dashboard:view",
};
