import { Flex, useTheme } from "@chakra-ui/react";
import { SortedAscIcon, SortedDescIcon } from "components/Icons";

export function TableSortedIcon({ isSortedDesc, isSortedAsc }) {
  const theme = useTheme();

  return (
    <Flex
      display="flex"
      direction="column"
      justify="center"
      align="center"
      position="absolute"
      bottom="-3px"
    >
      <SortedAscIcon
        w={2.5}
        h={1.5}
        fill={
          isSortedDesc ? theme.colors.black["900"] : theme.colors.grey["500"]
        }
      />
      <SortedDescIcon
        w={2.5}
        h={1.5}
        fill={
          isSortedAsc ? theme.colors.black["900"] : theme.colors.grey["500"]
        }
      />
    </Flex>
  );
}
