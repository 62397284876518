import { Icon } from "@chakra-ui/react";

export default function GlassIcon({ ...props }) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M18.234 9.5624C18.234 11.2741 17.726 12.9473 16.7741 14.3705C15.8223 15.7937 14.4693 16.903 12.8865 17.558C11.3036 18.213 9.56185 18.3844 7.88148 18.0505C6.2011 17.7166 4.65757 16.8923 3.44609 15.682C2.23461 14.4716 1.40958 12.9296 1.07533 11.2508C0.741082 9.572 0.912621 7.83189 1.56827 6.25051C2.22392 4.66913 3.33424 3.31751 4.7588 2.36655C6.18335 1.41559 7.85817 0.908024 9.57146 0.908024C11.8689 0.908024 14.0723 1.81982 15.6968 3.44283C17.3214 5.06585 18.234 7.26711 18.234 9.5624Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7148 22.7161L19.3383 19.0876"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
