import { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADD_NEW_USER_SCHEMA, EDIT_USER_SCHEMA } from "schemas";
import { useModalContext } from "hooks/useModalContext";
import { useOptionsContext } from "hooks/useOptionsContext";
import AddNewUser from "components/Modals/AddNewUser";
import { getDefaultLocation } from "utils/getDefaultLocation";
import { useCreateNewUser, useEditUser } from "graphql/hooks/useMutations";
import { USER_DEFAULT_VALUES } from "constants/constants";
import { GET_USERS } from "graphql/queries";
import {
  transformAddUserData,
  transformCityOptions,
  transformCountryOptions,
  transformEditUserData,
  transformPermisionRoles,
} from "connectors";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useToastContext } from "hooks/useToastContext";
import { getDefaultUserRole } from "utils/getDefaultUserRole";
import { getDefaultUserPhoto } from "utils/getDefaultUserPhoto";
import useGetUserOptions from "hooks/useGetUserOptions.hook";

export default function AddNewUserContainer() {
  const { modal, closeModal } = useModalContext("createNewUserModal");

  useGetUserOptions({ skip: !modal.isOpen });

  const { countries, userPermisionRoles, userGenders } = useOptionsContext();
  const { showToast } = useToastContext();

  const EDIT_DEFAULT_VALUES = {
    firstName: modal.editUser?.firstName,
    lastName: modal.editUser?.lastName,
    photo: getDefaultUserPhoto(modal.editUser?.photo, modal.editUserReadOnly),
    gender: modal.editUser?.gender,
    password: "",
    phone: modal.editUser?.phone,
    email: modal.editUser?.email,
    country: getDefaultLocation(countries, modal.editUser?.cityId)
      .defaultCountry,
    city: getDefaultLocation(countries, modal.editUser?.cityId).defaultCity,
    userRole: getDefaultUserRole(modal.editUser?.role, modal.editUserReadOnly),
    sendToEmail: false,
  };

  const methods = useForm({
    resolver: yupResolver(
      modal.isEditMode ? EDIT_USER_SCHEMA.schema : ADD_NEW_USER_SCHEMA.schema
    ),
    defaultValues: USER_DEFAULT_VALUES,
  });

  const [createNewUser] = useCreateNewUser();
  const [editUser] = useEditUser();

  const closeModalHandler = () => {
    methods.reset(USER_DEFAULT_VALUES);
    closeModal();
  };

  function getGraphqlError(graphQLErrors) {
    if (graphQLErrors) {
      if (
        graphQLErrors?.[0]?.extensions?.exception?.stacktrace?.[0] ===
        "Error: This phone is already in use."
      ) {
        methods.setError("phone", {
          type: "custom",
          message: "phone number is already registered!",
        });
      } else if (
        graphQLErrors?.[0]?.extensions?.exception?.errors?.[0]?.message ===
        "email must be unique"
      ) {
        methods.setError("email", {
          type: "custom",
          message: "email address is already registered!",
        });
      } else {
        console.log(
          graphQLErrors?.[0]?.extensions?.exception?.errors?.[0]?.message
        );
      }
    }
  }

  const countryChangeHandler = () => {
    methods.setValue("city", null);
  };

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      editUser({
        variables: {
          input: transformEditUserData({
            data: formData,
            editUserId: modal.editUser?.id,
          }),
        },
        refetchQueries: [GET_USERS],
        onCompleted: () => {
          showToast({
            description: "User successful updated",
          });
          closeModalHandler();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      createNewUser({
        variables: { input: transformAddUserData(formData) },
        refetchQueries: [GET_USERS],
        onCompleted: () => {
          showToast({
            description: "User successful created",
          });
          closeModalHandler();
        },
        onError: ({ graphQLErrors }) => {
          getGraphqlError(graphQLErrors);
        },
      });
    }
  };

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(EDIT_DEFAULT_VALUES);
    }
  }, [modal.isEditMode]);

  const modalTitle = useMemo(() => {
    if (modal.isEditMode) {
      if (modal.editUserReadOnly) {
        return "User information";
      }
      return "Edit user's information";
    }
    return "Add new user";
  }, [modal.isEditMode, modal.editUser]);

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModalHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <AddNewUser
            onSubmit={onSubmit}
            cancelHandler={closeModalHandler}
            genderOptions={userGenders}
            countriesOptions={transformCountryOptions(countries)}
            citiesOptions={transformCityOptions(
              methods.watch("country"),
              countries
            )}
            roleOptions={transformPermisionRoles(userPermisionRoles)}
            isEditMode={modal.isEditMode}
            editUserReadOnly={modal.editUserReadOnly}
            countryChangeHandler={countryChangeHandler}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
