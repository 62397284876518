import {
  ModalBody,
  ModalFooter,
  HStack,
  VStack,
  Flex,
  Button,
  Spacer,
  Wrap,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import { useGetLanguageKey } from "hooks/useGetLanguageKey";
import { useFormContext } from "react-hook-form";
import TextEditorRHF from "components/react-hook-form/TextEditorRHF";
import LanguageIndicators from "components/LanguageIndicators";
import SwitchRHF from "components/react-hook-form/SwitchRHF";

export default function AddLocationModal(props) {
  const {
    onSubmit,
    cancelHandler,
    languagesOptions,
    countriesOptions,
    citiesOptions,
    selectedLanguage,
    languageChangeHandler,
    isEdit,
    countryChangeHandler,
  } = props;

  const { watch } = useFormContext();

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={6}>
          <HStack spacing={6} w="full" align="stretch" justify="space-between">
            <Wrap position="relative" w="full">
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextFieldRHF
                id="title"
                name={`title.${useGetLanguageKey(selectedLanguage)}`}
                inputType="text"
                inputPlaceholder="Title goes here"
                label="Name"
                width="100%"
              />
            </Wrap>
          </HStack>
          <Grid templateColumns="repeat(6, 1fr)" w="full">
            <GridItem colSpan={5}>
              <SelectRHF
                name="language"
                label="Content language"
                placeholder="Select content language"
                options={languagesOptions}
                onChangeHandler={languageChangeHandler}
              />
            </GridItem>
            <GridItem rowSpan={1}>
              <SwitchRHF
                id="isActive"
                name="isActive"
                label="Active"
                vertical
                labelColor="dark.500"
              />
            </GridItem>
          </Grid>

          <TextFieldRHF
            id="address"
            name="address"
            label="Address"
            w="100%"
            inputType="text"
          />
          <Grid
            templateColumns="repeat(2, 1fr)"
            columnGap={6}
            w="100%"
            rowGap={4}
          >
            <GridItem rowSpan={1} colSpan={1}>
              <SelectRHF
                name="country"
                label="Country"
                options={countriesOptions}
                placeholder="Select country"
                onChangeHandler={countryChangeHandler}
              />
            </GridItem>
            <GridItem rowSpan={1} colSpan={1}>
              <SelectRHF
                name="city"
                label="City"
                options={citiesOptions}
                placeholder="Select city"
                isDisabled={!watch("country")}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <DropzoneFieldRHF
                height="170px"
                name="image"
                label="Photo"
                acceptStr="image/jpeg,image/png"
              />
            </GridItem>

            <GridItem colSpan={2} sx={{ position: "relative" }}>
              <LanguageIndicators selectedLanguage={selectedLanguage} />
              <TextEditorRHF
                name={`description.${useGetLanguageKey(selectedLanguage)}`}
                label="Description"
              />
            </GridItem>
          </Grid>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Flex w="100%" px={6}>
          <Button
            onClick={cancelHandler}
            variant="outline"
            colorScheme="blue.500"
          >
            Cancel
          </Button>
          <Spacer />
          <Button type="submit">
            {isEdit ? "Edit Location" : "Create Location"}
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}
