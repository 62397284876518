import {
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useContext, useEffect } from "react";

import { useModalContext } from "hooks/useModalContext";
import {
  useDeleteUser,
  useRemoveComment,
  useRemovePost,
  useUpdateReport,
} from "graphql/hooks/useMutations";
import {
  useGetReportById,
  useGetReportStatuses,
} from "graphql/hooks/useQueries";
import {
  transformDefaultReportStatus,
  transformReportStatuses,
  transformReviewReportData,
} from "connectors";
import { getReportActionOptions } from "utils/getReportActionOptions";
import ReportReview from "components/Modals/ReportReview";
import authContext from "contexts/auth/auth.context";
import { GET_ALL_REPORTS } from "graphql/queries";

export default function ReportReviewModalContainer() {
  const { modal, closeModal } = useModalContext("reportReviewModal");
  const auth = useContext(authContext);

  const { data: reportData } = useGetReportById({
    variables: {
      id: modal.report?.id,
    },
    skip: !modal.report?.id || !modal.isOpen,
  });

  const { data: reportStatuses } = useGetReportStatuses({
    skip: !auth.isAuth || !modal.isOpen,
  });
  const [updateReport] = useUpdateReport();

  const [deleteUser] = useDeleteUser();
  const [removePost] = useRemovePost();
  const [removeComment] = useRemoveComment();

  const INITIAL_STATE = {
    report_status: transformDefaultReportStatus(reportData?.getReport?.status),
    report_action: { value: "none", label: "No action" },
  };

  const methods = useForm({
    defaultValues: INITIAL_STATE,
  });

  const [status, action] = methods.watch(["report_status", "report_action"]);

  const onSubmit = () => {
    updateReport({
      variables: {
        input: {
          id: modal.report?.id,
          status: status?.value,
        },
      },
      onCompleted: (response) => {
        if (
          response.updateReport.status === "Reviewed" &&
          action.value === "delete"
        ) {
          if (response?.updateReport?.modelName === "USER") {
            deleteUser({
              variables: {
                id: response?.updateReport?.reportedEntity.id,
              },
              refetchQueries: [GET_ALL_REPORTS],
            });
          }
          if (response?.updateReport?.modelName === "POST") {
            removePost({
              variables: {
                id: response?.updateReport?.reportedEntity.id,
              },
              refetchQueries: [GET_ALL_REPORTS],
            });
          }
          if (response?.updateReport?.modelName === "COMMENT") {
            removeComment({
              variables: {
                id: response?.updateReport?.reportedEntity.id,
              },
              refetchQueries: [GET_ALL_REPORTS],
            });
          }
        }

        closeModal();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    methods.reset(INITIAL_STATE);
  }, [methods.reset, reportData]);

  const parentInstance = reportData?.getReport?.reportedEntity;

  return (
    <Modal isOpen={modal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Report review</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <ReportReview
            onSubmit={methods.handleSubmit(onSubmit)}
            onClose={closeModal}
            report={transformReviewReportData(reportData?.getReport)}
            statusOptions={transformReportStatuses({
              data: reportStatuses?.getReportStatuses,
              isModal: true,
            })}
            actionOptions={getReportActionOptions(
              reportData?.getReport?.modelName
            )}
            status={status?.value}
            parentInstance={parentInstance}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
