import ModalButton from "components/ModalButton";
import PageHeader from "components/PageHeader";
import ContentPagesListContainer from "containers/content/staticPagesList.container";
import AddEditLayout from "layouts/AddEditLayout";
import { getAccessScopesControl } from "utils/getAccessScopesControl";

const addArticleButton = (
  <ModalButton
    modalName="staticPageCreateEditModal"
    label="Add page"
    w={44}
    data={{ isAddMode: true }}
  />
);

export default function Content() {
  const { accessCreateContent, accessViewContent } = getAccessScopesControl();

  return (
    <AddEditLayout>
      <PageHeader button={accessCreateContent ? addArticleButton : null} mb={6}>
        Content Pages
      </PageHeader>
      <ContentPagesListContainer accessViewContent={accessViewContent} />
    </AddEditLayout>
  );
}
