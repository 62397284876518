export const transformUserCompanySortState = (state) => {
  if (!state) {
    return null;
  }

  return {
    type: state.desc ? "DESC" : "ASC",
    field: "companyName",
  };
};
