import { useModalContext } from "hooks/useModalContext";
import {
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import FeedCreateEditModal from "components/Modals/FeedCreateEdit";
import { useForm, FormProvider } from "react-hook-form";
import { useCreatePost, useEditPost } from "graphql/hooks/useMutations";
import { transformCreatePost } from "connectors/Feed/transformCreatePost";
import { transformBeforeEditPost } from "connectors/Feed/transformBeforeEditPost";
import { transformEditPost } from "connectors/Feed/transformEditPost";
import { yupResolver } from "@hookform/resolvers/yup";
import { CREATE_POST_SCHEMA, EDIT_POST_SCHEMA } from "schemas";
import { useEffect, useMemo, useState } from "react";
import { GET_ALL_COMMENTS, GET_ALL_POSTS } from "graphql/queries";
import { useToastContext } from "hooks/useToastContext";
import { useOptionsContext } from "hooks/useOptionsContext";
import { getAuthorFormat } from "utils/getAuthorFormat";

export default function FeedCreateEditModalContainer() {
  const [loading, setLoading] = useState(false);
  const { modal, closeModal } = useModalContext("feedCreateEditModal");

  const { showToast } = useToastContext();
  const { currentUser } = useOptionsContext();
  const user = currentUser?.currentUser;

  const INITIAL_VALUES = {
    title: "",
    tags: "",
    main_image: null,
    video: null,
    main_text: "",
    authorOnEdit: "",
    authorOnCreate: "",
    publication_date: "",
  };

  const methods = useForm({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(
      modal.isEditMode ? EDIT_POST_SCHEMA : CREATE_POST_SCHEMA
    ),
  });

  const { reset } = methods;

  const modalTitle = useMemo(() => {
    if (modal.isEditMode) {
      if (modal.editPostReadOnly) {
        return "Post";
      }
      return "Edit post";
    }
    return "Add post";
  }, [modal.isEditMode, modal.editPostReadOnly]);

  const closeHandler = () => {
    reset(INITIAL_VALUES);
    closeModal();
  };

  const [createPost] = useCreatePost();
  const [editPost] = useEditPost();

  useEffect(() => {
    if (modal.isEditMode) {
      methods.reset(
        transformBeforeEditPost(modal.editPost, modal.editPostFromComments)
      );
    }
  }, [modal.isEditMode]);

  const onSubmit = (formData) => {
    if (modal.isEditMode) {
      // edit Post
      setLoading(true);
      editPost({
        variables: transformEditPost(modal.editPost?.id, formData),
        refetchQueries: modal?.editPostFromComments
          ? [GET_ALL_COMMENTS]
          : [GET_ALL_POSTS],
        onCompleted: () => {
          showToast({
            description: "The post has been successfully updated",
          });
          closeHandler();
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setLoading(false);
        },
      });
    } else {
      // create Post
      setLoading(true);
      createPost({
        variables: transformCreatePost(formData, user?.companyId),
        refetchQueries: [GET_ALL_POSTS],
        onCompleted: () => {
          showToast({
            description: "The post has been successfully created",
          });
          closeHandler();
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setLoading(false);
        },
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>{modalTitle}</Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <FeedCreateEditModal
            onSubmit={methods.handleSubmit(onSubmit)}
            cancelHandler={closeHandler}
            isEditMode={modal.isEditMode}
            editPostReadOnly={modal.editPostReadOnly}
            currentUserCompanyName={getAuthorFormat(user)}
            feedId={modal?.editPost?.id}
            loading={loading}
          />
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
