import {
  Button,
  Flex,
  HStack,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import DatePickerRHF from "components/react-hook-form/DatePickerRHF";
import DropzoneFieldRHF from "components/react-hook-form/DropzoneFieldRHF";
import MultiSelectRHF from "components/react-hook-form/MultiSelectRHF";
import SelectRHF from "components/react-hook-form/SelectRHF";
import TextFieldRHF from "components/react-hook-form/TextFieldRHF";

function AddEditAd(props) {
  const {
    onSubmit,
    onClose,
    isEditMode,
    pagesOptions,
    levelsOptions,
    selectedBlocks,
    isPageSelected,
    submitError,
    statusOptions,
  } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <VStack spacing={4} align="flex-start" mb={8}>
          <HStack w="full" alignItems="flex-start">
            <SelectRHF
              id="page_name"
              name="page_name"
              label="Pages to display"
              placeholder="Select pages"
              options={pagesOptions}
            />
            <MultiSelectRHF
              id="block_ids"
              name="block_ids"
              label="Block IDs"
              options={levelsOptions}
              placeholder="Select blocks"
              isBanner
              isDisabled={!isPageSelected}
            />
          </HStack>
          {selectedBlocks?.length && (
            <Text
              size="label"
              lineHeight="normal"
              color="dark.500"
              paddingLeft={2}
              paddingBottom="60px"
            >
              Selected block IDs
            </Text>
          )}
          <Text color="black.500">
            Recommended banner size for selected blocks is min 800x200 pixels
          </Text>
        </VStack>

        <VStack spacing={4}>
          <HStack w="full" spacing={4} alignItems="flex-start">
            <DatePickerRHF
              name="rotation_start"
              label="Rotation start"
              dateFormat="dd/MM"
              minDate={new Date()}
              placeholder="Select date"
              autoComplete="off"
            />
            <DatePickerRHF
              name="rotation_end"
              label="Rotation end"
              dateFormat="dd/MM"
              minDate={new Date()}
              placeholder="Select date"
              autoComplete="off"
              errorMessage={errors?.rotation_end?.message}
            />
            <TextFieldRHF
              id="display_count"
              name="display_count"
              inputType="text"
              label="Display count"
              inputPlaceholder="Banner display"
              errorMessage={errors?.display_count?.message}
            />
          </HStack>

          <DropzoneFieldRHF
            height="170px"
            name="banner_image"
            label="Banner image"
            acceptStr="image/jpeg,image/png"
          />

          <TextFieldRHF
            id="banner_url"
            name="banner_url"
            inputType="text"
            label="Banner URL"
            inputPlaceholder="Where will banner lead us to?"
            errorMessage={errors?.banner_url?.message}
          />

          <SelectRHF
            name="status"
            label="Status"
            options={statusOptions}
            placeholder="Select status"
          />

          {submitError && (
            <Text
              color="red.500"
              fontSize="sm"
              lineHeight="normal"
              alignSelf="flex-start"
              marginTop="4px!important"
            >
              {submitError}
            </Text>
          )}
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Flex w="full" justifyContent="space-between">
          <Button variant="outline" minW="140px" onClick={onClose}>
            Cancel
          </Button>
          <Button minW="180px" type="submit">
            {isEditMode ? "Edit advertisement" : "Create advertisement"}
          </Button>
        </Flex>
      </ModalFooter>
    </form>
  );
}

export default AddEditAd;
